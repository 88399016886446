import React from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { ecomWidgets } from "../../common/data";
import { dataWidget } from './data-common';

import { datetimeformatncrm, dateformatncrm, datemonthformatncrm, dateyearsmonthdayformatncrm } from "../../helpers/FunctionalHelper/date-helper";

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { WidgetCharts } from './DashboardPieCharts';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const Widgets = (crdata) => {
    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    const current_date = new Date();
    const next_day_date = new Date(new Date().setDate(new Date().getDate()));
    const next_month_date = new Date(next_day_date.setMonth(next_day_date.getMonth() + 1));
    const previous_day_date = new Date(new Date().setDate(new Date().getDate() + 1));
    const previous_month_date = new Date(previous_day_date.setMonth(previous_day_date.getMonth() - 1));

    const draftCount     = crdata.crdata && crdata.crdata["Draft"] !== undefined ? parseInt(crdata.crdata["Draft"]) : 0;
    const rfaCount       = crdata.crdata && crdata.crdata["Request for Authorization"] !== undefined ? parseInt(crdata.crdata["Request for Authorization"]) : 0;
    const rfcCount       = crdata.crdata && crdata.crdata["Request for Change"] !== undefined ? parseInt(crdata.crdata["Request for Change"]) : 0;
    const sfaCount       = crdata.crdata && crdata.crdata["Scheduled for Approval"] !== undefined ? parseInt(crdata.crdata["Scheduled for Approval"]) : 0;
    const scheduledCount = crdata.crdata && crdata.crdata["Scheduled"] !== undefined ? parseInt(crdata.crdata["Scheduled"]) : 0;
    const completedCount = crdata.crdata && crdata.crdata["Completed"] !== undefined ? parseInt(crdata.crdata["Completed"]) : 0;
    const closedCount    = crdata.crdata && crdata.crdata["Closed"] !== undefined ? parseInt(crdata.crdata["Closed"]) : 0;

    const total = draftCount+rfaCount+rfcCount+sfaCount+scheduledCount+completedCount;

    return (
        <React.Fragment>
                <Col xl={12}>
                    <h5 className='text-white'>
                        Your Milestone
                    </h5>
                </Col>
                <Col xl={2} md={4} xs={12} >
                    <Link to="/cr-list/draft">
                    <Card className={"card-animate overflow-hidden"} style={{height: "110px", opacity: "1", backgroundColor: "rgb(2, 168, 181)", background: "linear-gradient(124deg, #02a8b5 50%, #9bc9cc 5%)"}}>
                        <CardBody style={{padding: "10px 15px", height: "100%"}}>
                            <div className="d-flex justify-content-between mt-1" style={{alignItems: "center", height: "100%"}}>
                                <div className="mt-0 pt-2 text-truncate">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <h6 className={"fs-16 text-uppercase fw-medium mb-0 text-white"}>Draft</h6>
                                        </div>
                                        <div className="flex-shrink-0">
                                        </div>
                                    </div>
                                    <h1 className={"fw-semibold ff-secondary mb-0 mt-2 text-white"}><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={""}
                                            end={draftCount}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h1>
                                </div>
                                <div className="flex-shrink-0 " style={{width: "105px", marginRight: "-15px"}}>
                                    <div className=" flex-shrink-0">
                                        <WidgetCharts
                                        seriesData={[((draftCount/total)*100).toFixed(0)]}
                                        colors={"#000000"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    </Link>
                </Col>
                <Col xl={2} md={4} xs={12}>
                    <Link to="/cr-list/rfa">
                    <Card className={"card-animate overflow-hidden"} style={{height: "110px", opacity: "1", backgroundColor: "#38618C", background: "linear-gradient(124deg, rgb(52, 118, 241) 50%, rgb(142, 172, 227) 5%)"}}>
                        <CardBody style={{padding: "10px 15px", height: "100%"}}>
                            <div className="d-flex justify-content-between mt-1" style={{alignItems: "center", height: "100%"}}>
                                <div className="mt-0 pt-2 text-truncate">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <h6 className={"fs-16 text-uppercase fw-medium mb-0 text-white"}>RFA</h6>
                                        </div>
                                        <div className="flex-shrink-0">
                                        </div>
                                    </div>
                                    <h1 className={"fw-semibold ff-secondary mb-0 mt-2 text-white"}><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={""}
                                            end={rfaCount}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h1>
                                </div>
                                <div className="flex-shrink-0 " style={{width: "105px", marginRight: "-15px"}}>
                                    <div className=" flex-shrink-0">
                                        <WidgetCharts
                                        seriesData={[((rfaCount/total)*100).toFixed(0)]}
                                        colors={"#000000"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    </Link>
                </Col>
                <Col xl={2} md={4} xs={12}>
                    <Link to="/cr-list/rfc">
                    <Card className={"card-animate overflow-hidden"} style={{height: "110px", opacity: "1", backgroundColor: "rgb(41, 156, 219)", background: "linear-gradient(124deg, rgb(41, 156, 219) 50%, rgb(128, 181, 210) 5%)"}}>
                        <CardBody style={{padding: "10px 15px", height: "100%"}}>
                            <div className="d-flex justify-content-between mt-1" style={{alignItems: "center", height: "100%"}}>
                                <div className="mt-0 pt-2 text-truncate">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <h6 className={"fs-16 text-uppercase fw-medium mb-0 text-white"}>RFC</h6>
                                        </div>
                                        <div className="flex-shrink-0">
                                        </div>
                                    </div>
                                    <h1 className={"fw-semibold ff-secondary mb-0 mt-2 text-white"}><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={""}
                                            end={rfcCount}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h1>
                                </div>
                                <div className="flex-shrink-0 " style={{width: "105px", marginRight: "-15px"}}>
                                    <div className=" flex-shrink-0">
                                        <WidgetCharts
                                        seriesData={[((rfcCount/total)*100).toFixed(0)]}
                                        colors={"#000000"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    </Link>
                </Col>
                <Col xl={2} md={4} xs={12}>
                    <Link to="/cr-list/sfa">
                    <Card className={"card-animate overflow-hidden"} style={{height: "110px", opacity: "1", backgroundColor: "rgb(240, 101, 72)", background: "linear-gradient(124deg, rgb(240, 101, 72) 50%, rgb(221, 163, 151) 5%)"}}>
                        <CardBody style={{padding: "10px 15px", height: "100%"}}>
                            <div className="d-flex justify-content-between mt-1" style={{alignItems: "center", height: "100%"}}>
                                <div className="mt-0 pt-2 text-truncate">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <h6 className={"fs-16 text-uppercase fw-medium mb-0 text-white"}>SFA</h6>
                                        </div>
                                        <div className="flex-shrink-0">
                                        </div>
                                    </div>
                                    <h1 className={"fw-semibold ff-secondary mb-0 mt-2 text-white"}><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={""}
                                            end={sfaCount}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h1>
                                </div>
                                <div className="flex-shrink-0 " style={{width: "105px", marginRight: "-15px"}}>
                                    <div className=" flex-shrink-0">
                                        <WidgetCharts
                                        seriesData={[((sfaCount/total)*100).toFixed(0)]}
                                        colors={"#000000"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    </Link>
                </Col>
                <Col xl={2} md={4} xs={12}>
                    <Link to="/cr-list/scheduled">
                    <Card className={"card-animate overflow-hidden"} style={{height: "110px", opacity: "1", backgroundColor: "rgb(247, 184, 75)", background: "linear-gradient(124deg, rgb(247, 184, 75) 50%, rgb(238, 205, 148) 5%)"}}>
                        <CardBody style={{padding: "10px 15px", height: "100%"}}>
                            <div className="d-flex justify-content-between mt-1" style={{alignItems: "center", height: "100%"}}>
                                <div className="mt-0 pt-2 text-truncate">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <h6 className={"fs-16 text-uppercase fw-medium mb-0 text-white"}>Scheduled</h6>
                                        </div>
                                        <div className="flex-shrink-0">
                                        </div>
                                    </div>
                                    <h1 className={"fw-semibold ff-secondary mb-0 mt-2 text-white"}><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={""}
                                            end={scheduledCount}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h1>
                                </div>
                                <div className="flex-shrink-0 " style={{width: "105px", marginRight: "-15px"}}>
                                    <div className=" flex-shrink-0">
                                        <WidgetCharts
                                        seriesData={[((scheduledCount/total)*100).toFixed(0)]}
                                        colors={"#000000"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    </Link>
                </Col>
                <Col xl={2} md={4} xs={12}>
                    <Link to="/cr-list/Completed">
                    <Card className={"card-animate overflow-hidden"} style={{height: "110px", opacity: "1", backgroundColor: "rgb(53, 119, 241)", background: "linear-gradient(124deg, rgb(95, 187, 117) 50%, rgb(160, 206, 171) 5%)"}}>
                        <CardBody style={{padding: "10px 15px", height: "100%"}}>
                            <div className="d-flex justify-content-between mt-1" style={{alignItems: "center", height: "100%"}}>
                                <div className="mt-0 pt-2 text-truncate">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <h6 className={"fs-16 text-uppercase fw-medium mb-0 text-white"}>Completed</h6>
                                        </div>
                                        <div className="flex-shrink-0">
                                        </div>
                                    </div>
                                    <h1 className={"fw-semibold ff-secondary mb-0 mt-2 text-white"}><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={""}
                                            end={completedCount}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h1>
                                </div>
                                <div className="flex-shrink-0 " style={{width: "105px", marginRight: "-15px"}}>
                                    <div className=" flex-shrink-0">
                                        <WidgetCharts
                                        seriesData={[((completedCount/total)*100).toFixed(0)]}
                                        colors={"#000000"}
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    </Link>
                </Col>
        </React.Fragment>
    );
};

export default Widgets;