import React from 'react'

import { Card, CardHeader, CardBody, Col, Container, Row, Collapse, Input, Button } from 'reactstrap';
// import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import classnames from "classnames";

// const api = new APIClient();
// const apiAuth = new API_CLIENT_AUTH_BEARER();
  
export default function FilterCrList(props) {

  const [dropdownFilter, setDropdownFilter] = React.useState(false);
  const [dataFilter, setDataFilter] = React.useState({});

  const openFilter = (e) => {
    setDropdownFilter(!dropdownFilter);
  }

  const handleFilterInputan = (e) => {
    const data_filter = {...dataFilter};
    const {value, name} = e.target;
    data_filter[name] = value;
    setDataFilter(data_filter);
  }

  const deleteFilterInputan = (e) => {
    const data_filter = {...dataFilter};
    for (const field_name in data_filter) {
      data_filter[field_name] = ''
    }
    setDataFilter(data_filter);
  }

  const getDataFilter = () => {
    props.doFilter(dataFilter)
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
            <div className="accordion accordion-border-box" id="genques-accordion">
            <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingOne">
                <button
                    className={classnames(
                    "accordion-button",
                    "fw-medium",
                    { collapsed: !dropdownFilter }
                    )}
                    type="button"
                    onClick={openFilter}
                    style={{ cursor: "pointer", fontSize : '12px', padding : '10px' }}
                >
                    Filter
                </button>
                </h2>
                <Collapse isOpen={dropdownFilter} className="accordion-collapse">
                    <div className="accordion-body">
                    <Row>
                        <Col lg="2" xs="6">
                        <Input type="text" className="form-control" size="sm" placeholder='CR ID' name="change_id" value={dataFilter.change_id} onChange={(e) => handleFilterInputan(e)}/>
                        </Col>
                        <Col lg="2" xs="6">
                        <Input type="text" className="form-control" size="sm" placeholder='Change Category' name="change_category" value={dataFilter.change_category} onChange={(e) => handleFilterInputan(e)}/>
                        </Col>
                        <Col lg="3" xs="6">
                        <Input type="text" className="form-control" size="sm" placeholder='Activity' name="activity_list" value={dataFilter.activity_list} onChange={(e) => handleFilterInputan(e)}/>
                        </Col>
                        <Col lg="3" xs="2">
                        </Col>
                        <Col lg="1" xs="2">
                        <Button size="sm" color="indo" onClick={deleteFilterInputan}>
                            Clear
                        </Button>
                        </Col>
                        <Col lg="1" xs="2">
                        <Button size="sm" color="secondary" onClick={getDataFilter}>
                            Filter
                        </Button>
                        </Col>
                    </Row>
                    </div>
                </Collapse>
            </div>
            </div>
        </Col>
        </Row>
    </React.Fragment>
  )
}
