import React from 'react';

import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row, Spinner, Button } from 'reactstrap';
import { HEADER_TITLE_NAME } from '../../config/config-general';
import { useDispatch } from 'react-redux';
import { loadingStatusSet } from '../../redux/reducers/notificationFlagReducer';
import { createDataUser } from '../../middleware/user-management/user-registration-thunk';
import { unwrapResult } from '@reduxjs/toolkit';
// import { USER_TITLE } from '../../dummy-data/USER_TITLE';
import { USER_TITLE } from '../../config/data/USER_TITLE';
import { DEFAULT_ROLE_ROLE } from '../../config/data/DEFAULT_ROLE_ROLE';
import Select from 'react-select';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const UserRegistration = () => {

    document.title="User Registration "+HEADER_TITLE_NAME;

    const USER_TITLE_OPTION = [...USER_TITLE.map(ut => ({ value: ut.TITLE, label: ut.TITLE, area_division : ut["AREA DIVISION"], area : ut["Area"], regional : ut["Regional"] }))]

    const ROLE_OPTIONS = [...DEFAULT_ROLE_ROLE, ...USER_TITLE_OPTION]

    const dispatch = useDispatch();

    const [dataUser, setDataUser] = React.useState([]);
    const [dataUserOption, setDataUserOption] = React.useState([]);

    const [dataForm, setDataForm] = React.useState({role : []});
    const [loadingStatus, setLoadingStatus] = React.useState(false)
    const [disabledBtn, setDisabledBtn] = React.useState(true)

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        setDataForm(dataFormInput)
        disableButton();
    }

    function handleSelectFormMulti(e, name) {
        const dataFormSelect = {...dataForm};
        const {value, label} = e;
        dataFormSelect[name] = e.map(item => item.value);
        setDataForm(dataFormSelect);
        disableButton();
    }

    function handleSelectForm(e, name) {
        const dataFormSelect = {...dataForm};
        const {value, label, area_division, area, regional, name_user} = e;
        dataFormSelect[name] = value;
        if(name === "title"){
            dataFormSelect["area_division"] = area_division;
            dataFormSelect["area"] = area;
            dataFormSelect["regional"] = regional;
            dataFormSelect["role"].push(value)
        }
        if(name === "lm_email"){
            dataFormSelect["lm_name"] = name_user;
        }
        
        setDataForm(dataFormSelect);
        disableButton();
        
    }



    const registerUserData = async(e) => {
        e.preventDefault()
        setLoadingStatus(true);
        dispatch(loadingStatusSet(true));
        const response = await dispatch(createDataUser({dataForm}))
        const res = unwrapResult(response);
        if(res){
            // // console.log("res")
        }
        setLoadingStatus(false);
    }

    const getData = async() => {
        const res = await apiAuth.get('/getUser?noPg=1');
        if(res && res.data){
            setDataUser(res.data.data);
            const listUserOption = res.data.data.map(e => ({label : e.email, value : e.email, name_user : e.first_name+" "+e.lastname}))
            setDataUserOption(listUserOption)
        }
    }
    
    React.useEffect(() => {
        getData();
    }, []);

    const disableButton = () => {
        console.log("dataForm input 2", dataForm)
        if(dataForm.first_name !== undefined && dataForm.first_name !== "" && 
            dataForm.email !== undefined && dataForm.email !== "" && 
            dataForm.password !== undefined && dataForm.password !== "" && 
            dataForm.phone_number !== undefined && dataForm.phone_number !== "" && 
            dataForm.role !== null && dataForm.role.length > 0 &&
            loadingStatus === false){
            setDisabledBtn(false)
        }else{
            setDisabledBtn(true)
        }
    }

    React.useEffect(() => {
        disableButton();
    }, [dataForm]);

    console.log("Btn", disabledBtn)

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="User Management" pageTitle="User Registration" />
                    <Row>
                        <Col xl={12}>
                            <Card >
                                <CardHeader>
                                    <h4 className="card-title mb-0">User Registration</h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="live-preview">
                                        <Form >
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="first_name" className="form-label"><span className="text-danger">*</span> First Name</Label>
                                                        <Input type="text" className="form-control" name="first_name" placeholder="" onChange={(e) => handleInputChange(e)} id="first_name" required/>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="last_name" className="form-label">Last Name</Label>
                                                        <Input type="text" className="form-control" name="last_name" placeholder="" onChange={(e) => handleInputChange(e)} id="last_name" />
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="middle_initial" className="form-label">Middle Name</Label>
                                                        <Input type="text" className="form-control" name="middle_initial" placeholder="" onChange={(e) => handleInputChange(e)} id="middle_initial" />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="email" className="form-label"><span className="text-danger">*</span> Email Address</Label>
                                                        <Input type="email" className="form-control" name="email" placeholder="" onChange={(e) => handleInputChange(e)} id="email" required/>
                                                    </div>
                                                </Col>
                                                <Col md={8}></Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="password" className="form-label"><span className="text-danger">*</span> Password</Label>
                                                        <Input type="password" className="form-control" name="password" placeholder="" onChange={(e) => handleInputChange(e)} id="password" required/>
                                                    </div>
                                                </Col>
                                                <Col md={8}></Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="phone_number" className="form-label"><span className="text-danger">*</span> Phone Number</Label>
                                                        <Input type="number" className="form-control" name="phone_number" placeholder="" onChange={(e) => handleInputChange(e)} id="phone_number" required/>
                                                    </div>
                                                </Col>
                                                <Col md={8}></Col>
                                                <Col md={8}></Col>
                                                <Col md={12} className='mt-2'>
                                                    <h4>Organization</h4>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="title" className="form-label">Title</Label>
                                                        <Select
                                                            onChange={(e) => {
                                                                handleSelectForm(e, 'title');
                                                            }}
                                                            options={USER_TITLE_OPTION}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}></Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="regional" className="form-label">Regional</Label>
                                                        <Input type="text" className="form-control" name="regional" value={dataForm.regional} placeholder="" disabled id="regional" />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="area_division" className="form-label">Area Division</Label>
                                                        <Input type="text" className="form-control" name="area_division" value={dataForm.area_division} placeholder="" disabled id="area_division" />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="area" className="form-label">Area</Label>
                                                        <Input type="text" className="form-control" name="area" value={dataForm.area} placeholder="" disabled id="area" />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="line_manager" className="form-label">Line Manager</Label>
                                                        <Select
                                                            onChange={(e) => {
                                                                handleSelectForm(e, 'lm_email');
                                                            }}
                                                            options={dataUserOption}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={8}></Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="organization_long" className="form-label">Organization Long</Label>
                                                        <Input type="text" className="form-control" name="organization_long" placeholder="" onChange={(e) => handleInputChange(e)} id="organization_long" />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="department_long" className="form-label">Department Long</Label>
                                                        <Input type="text" className="form-control" name="department_long" placeholder="" onChange={(e) => handleInputChange(e)} id="department_long" />
                                                    </div>
                                                </Col>
                                                <Col md={4}></Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="support_organization" className="form-label">Support Organization</Label>
                                                        <Input type="text" className="form-control" name="support_organization" placeholder="" onChange={(e) => handleInputChange(e)} id="support_organization" />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="support_group_name" className="form-label">Support Group Name</Label>
                                                        <Input type="text" className="form-control" name="support_group_name" placeholder="" onChange={(e) => handleInputChange(e)} id="support_group_name" />
                                                    </div>
                                                </Col>
                                                <Col md={4}></Col>
                                                <Col md={12} className='mt-2'>
                                                    <h4>Role</h4>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="phone_number" className="form-label"><span className="text-danger">*</span> User Role</Label>
                                                        <Select
                                                            isMulti={true}
                                                            value={dataForm.role && dataForm.role.map(item => ({value : item, label : item}) )}
                                                            onChange={(e) => {
                                                                handleSelectFormMulti(e, 'role');
                                                            }}
                                                            options={ROLE_OPTIONS}
                                                            required
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <Row>
                                            <Col md={12}>
                                                <div className="text-end">
                                                    <Button size="md" color="primary" onClick={(e) => registerUserData(e)} disabled={disabledBtn} >
                                                        {loadingStatus ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                                        Submit
                                                    </Button>
                                                    {/* <button onClick={(e) => registerUserData(e)} className="btn btn-primary">Submit</button> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserRegistration;