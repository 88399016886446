import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { restrictedDateDataAssign, timelineDataAdd, timelineDataAssign, timelineFirstLastDateSet, timelineNonCrDataAdd, timelineStartEndDateSet } from '../../redux/reducers/timelineReducer';
// import { notificationFlagAdd, loadingFalse, notificationFlagAssign } from '../../redux/reducers/notificationFlagReducer';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const dateNow = new Date();

export const getTimelineData = createAsyncThunk(
    "/getTimelineData",
    async (query, thunkApi) => {
        const resPromise = await getTimelineDataAsync(query.query, thunkApi);
        return resPromise;
    }
);

export const getTimelineDataAsync = async (query, thunkApi) => {

    let quert_api = !query ? '' : query

    const res = await apiAuth.get('/getTimeline/CR?lmt=200&'+quert_api);

    const resData = res.data.data

    thunkApi.dispatch(timelineDataAssign(resData))

    return res
}

export const getRestrictedDateData = createAsyncThunk(
    "/getRestrictedDateData",
    async (query, thunkApi) => {
        const resPromise = await getRestrictedDateDataAsync(query.query, thunkApi);
        return resPromise;
    }
);

export const getRestrictedDateDataAsync = async (query, thunkApi) => {

    let quert_api = !query ? '' : query

    const res = await apiAuth.get('/getHolidayManagement?lmt=200&'+quert_api);

    const resData = res.data.data;

    thunkApi.dispatch(restrictedDateDataAssign(resData))

    return res
}

export const getTimelineDataNonCr = createAsyncThunk(
    "/getTimelineDataNonCR",
    async (query, thunkApi) => {
        const resPromise = await getTimelineDataNonCrAsync(query.query, query.date_query, thunkApi);
        return resPromise;
    }
);

export const getTimelineDataNonCrAsync = async (query, date_query, thunkApi) => {

    let quert_api = !query ? '' : query;

    const res = await apiAuth.get('/getTimeline/NoCR?lmt=200&'+quert_api);

    let resData;

    if(res !== undefined && res.data !== undefined){

        resData = res.data.data;

    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }

    }

    thunkApi.dispatch(timelineNonCrDataAdd(resData))
    thunkApi.dispatch(timelineStartEndDateSet(date_query))

    return res
}