import React, { useEffect, useState, useRef } from "react";

import {
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Table
} from "reactstrap";
import { Link } from 'react-router-dom';
import { datetimeformatncrm } from "../../helpers/FunctionalHelper/date-helper";

const TimelineCalendar = (props) => {

    const {modal, toggle, data} = props;

    return(
        <React.Fragment>
            <Modal isOpen={modal} id="event-modal" centered>
                <ModalHeader toggle={toggle} tag="h5" className="p-3 bg-info-subtle modal-title">
                    Detail Timeline
                </ModalHeader>
                <ModalBody>
                <Form className="view-event">
                <div className="event-details">
                    <div className="d-flex mb-2">
                        <div className="flex-shrink-0 me-3">
                            <i className="ri-discuss-line text-muted fs-16"></i>
                        </div>
                        <div className="flex-grow-1">
                            <p
                            className="d-block fw-semibold mb-0"
                            id="event-title-tag"
                            >
                            {data && data.title ? data.title : "No Activity Description"}
                            </p>
                        </div>
                    </div>
                    {data && data.parent_id ?
                    <div className="d-flex mb-2">
                        <div className="flex-shrink-0 me-3">
                            <i className="ri-exchange-funds-line text-muted fs-16"></i>
                        </div>
                        <div className="flex-grow-1">
                            <p
                            className="d-block fw-semibold mb-0"
                            id="event-cr-number-tag"
                            >
                            {data.parent_id}
                            </p>
                        </div>
                    </div>
                    : ""
                    }
                    {data && data.description ?
                    <div className="d-flex mb-2">
                        <div className="flex-shrink-0 me-3">
                            <i className=" ri-article-line text-muted fs-16"></i>
                        </div>
                        <div className="flex-grow-1">
                            <p
                            className="d-block fw-semibold mb-0"
                            id="event-description-tag"
                            >
                            {data.description}
                            </p>
                        </div>
                    </div>
                    : ""
                    }
                    <div className="d-flex mb-2">
                        <div className="flex-grow-1 d-flex align-items-center">
                            <div className="flex-shrink-0 me-3">
                                <i className="ri-calendar-event-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                                <h6
                                    className="d-block text-muted mb-0"
                                    id="event-start-date-tag"
                                >
                                {data && datetimeformatncrm(data.start)} {data && (data.start && data.end ? "until" : "")} {data && datetimeformatncrm(data.end)}
                            </h6>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    {data && data.parent_id ?
                    <Link to={"/cr-detail/"+data.parent_id} className="btn btn-primary btn-sm">See Details</Link>
                    : ""
                    }
                    {/* <div className="d-flex align-items-center mb-2">
                    <div className="flex-shrink-0 me-3">
                        <i className="ri-map-pin-line text-muted fs-16"></i>
                    </div>
                    <div className="flex-grow-1">
                        <h6 className="d-block fw-semibold mb-0">
                        {" "}
                        <span id="event-location-tag">
                            {data && data.location !== undefined ? data.location : "No Location"}
                        </span>
                        </h6>
                    </div>
                    </div> */}
                </div>
                </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default TimelineCalendar