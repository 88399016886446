import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { notificationFlagAdd, loadingFalse, notificationFlagAssign } from '../../redux/reducers/notificationFlagReducer';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const dateNow = new Date();

export const updateDataUser = createAsyncThunk(
    "/updateUser",
    async (formData, thunkApi) => {
        const resPromise = await updateDataUserAsync({form : formData.dataForm}, thunkApi);
        return resPromise;
    }
);

export const updateDataUserAsync = async (form, thunkApi) => {

    let formData = new FormData();
    let dataUser = {...form.form}

    await formData.append('data', JSON.stringify(dataUser));

    const res = await apiAuth.patch('/updateUser/'+dataUser.email, formData, null, {"Content-Type" : "multipart/form-data"})
    let notification = {}
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_message : "User Updated successfully!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}

export const updateDataPicture = createAsyncThunk(
    "/updatePictureUser",
    async (formData, thunkApi) => {
        const resPromise = await updateDataPictureUserAsync({form : formData.dataForm, pictureAttachment : formData.dataChangePhoto, coverAttachment : formData.dataChangeBgPhoto}, thunkApi);
        return resPromise;
    }
);

export const updateDataPictureUserAsync = async (form, thunkApi) => {

    let formData = new FormData();
    let dataUser = {...form.form}

    await formData.append('data', JSON.stringify({}));
    if(form.pictureAttachment){
        await formData.append('profile_picture', form.pictureAttachment.file);
    }
    if(form.coverAttachment){
        await formData.append('background_cover', form.coverAttachment.file);
    }

    console

    const res = await apiAuth.patch('/updateUser/'+dataUser.email, formData, null, {"Content-Type" : "multipart/form-data"})
    let notification = {}
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_message : "User Updated successfully!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}