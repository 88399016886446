import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";

import { HEADER_TITLE_NAME } from '../../config/config-general';

const DashboardMain = () => {

  const [dataDate, setDataDate] = useState({});

  const date_data = (data) => {
    setDataDate(data);
  }

  document.title = "Dashboard "+HEADER_TITLE_NAME;

  const [rightColumn, setRightColumn] = useState(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardMain;
