import PropTypes from 'prop-types';
import React from "react";
import { VectorMap } from "react-jvectormap";
import "../DashboardAnalytics/jquery-jvectormap.scss";

const map = React.createRef(null);
const Vectormap = props => {
    
    return (
        <div style={{ width: props.width, height: 350 }}>
            
            <VectorMap
                map={props.value}
                zoomOnScroll={false}
                zoomButtons={true}
                markersSelectable={true}
                markerStyle={{
                    initial: {
                        fill: "#0ab39c",
                    },
                    selected: {
                        fill: "#000000",
                    },
                }}
                series= {{
                    regions: [{
                      values: {
                        // R1 Sumbagut - Aceh, Sumatera Utara
                        ID_ac: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R1 Sumbagut").color : '#dedede',
                        ID_su: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R1 Sumbagut").color : '#dedede',

                        // R2 Sumbagsel
                        ID_ja: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R2 Sumbagsel").color : '#dedede',
                        ID_ss: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R2 Sumbagsel").color : '#dedede',
                        ID_be: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R2 Sumbagsel").color : '#dedede',
                        ID_la: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R2 Sumbagsel").color : '#dedede',
                        ID_bb: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R2 Sumbagsel").color : '#dedede',

                        // R3 Jabo Inner
                        ID_jk: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R3 Inner Jabotabek").color : '#dedede',

                        // R4 West Java
                        ID_jb: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R4 West Java").color : '#dedede',

                        // R5 Central Java
                        ID_jt: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R5 Central Java").color : '#dedede',

                        // R6 East Java
                        ID_jl: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R6 East Java").color : '#dedede',

                        // R7 Bali Nusra
                        ID_ba: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R7 Bali Nusra").color : '#dedede',
                        ID_nb: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R7 Bali Nusra").color : '#dedede',
                        ID_nt: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R7 Bali Nusra").color : '#dedede',

                        // R8 Kalimantan
                        ID_kb: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R8 Kalimantan").color : '#dedede',
                        ID_ki: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R8 Kalimantan").color : '#dedede',
                        ID_ks: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R8 Kalimantan").color : '#dedede',
                        ID_kt: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R8 Kalimantan").color : '#dedede',
                        
                        // R9 Sulawesi
                        ID_sa: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R9 Sulawesi").color : '#dedede',
                        ID_sg: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R9 Sulawesi").color : '#dedede',
                        ID_st: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R9 Sulawesi").color : '#dedede',
                        ID_sn: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R9 Sulawesi").color : '#dedede',
                        ID_sr: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R9 Sulawesi").color : '#dedede',
                        ID_go: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R9 Sulawesi").color : '#dedede',

                        // R10 Sumbagteng
                        ID_sb: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R10 Sumbagteng").color : '#dedede',
                        ID_kr: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R10 Sumbagteng").color : '#dedede',
                        ID_ri: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R10 Sumbagteng").color : '#dedede',

                        // R11 Papua Maluku
                        ID_pa: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R11 Papua Maluku").color : '#dedede',
                        ID_pb: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R11 Papua Maluku").color : '#dedede',
                        ID_ma: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R11 Papua Maluku").color : '#dedede',
                        ID_mu: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R11 Papua Maluku").color : '#dedede',

                        // R3 Jabo Outer
                        ID_bt: props.colorArea !== undefined && props.colorArea.length !==0 ? props.colorArea.find(area => area.name === "R12 Outer Jabotabek").color : '#dedede',
                      }
                    }]
                }}
                labels={{
                    markers: {
                        render: function (marker) {
                            return marker.name;
                        },
                    },
                }}
                backgroundColor="transparent"
                ref={map}
                containerStyle={{
                    width: "100%",
                    height: "80%",
                }}
                regionStyle={{
                    initial: {
                        stroke: "#9599ad",
                        strokeWidth: 0.25,
                        fill: "#dedede",
                        fillOpacity: 1,
                    },
                }}
            />
        </div>
    );
};

Vectormap.propTypes = {
    color: PropTypes.string,
    value: PropTypes.any,
    width: PropTypes.any
};

export default Vectormap;
