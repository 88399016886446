import React from 'react';
import { Card, CardHeader, CardBody, Col, Container, Form, Input, Modal, ModalBody, ModalHeader, ModalFooter, Button, Label, Row } from 'reactstrap';
import Select from "react-select";

import CRFormAIUserAccessPopUp from "./CRFormAIUserAccessPopUp";
import { USER_TITLE } from '../../../config/data/USER_TITLE';

const CRFormAIUserAccess = (props) => {

    const Responsible_Approval = USER_TITLE;

    const [dataForm, setDataForm] = React.useState({region : [], responsible_approval : []});
    const [show, setShow] = React.useState(false);
    const [region, setRegion] = React.useState({});

    const [listAddUser, setListAddUser] = React.useState([]);

    const [requiredUserAccess, setRequiredUserAccess] = React.useState([false, false, false]);

    function deleteImplementor(idx){
        let dataTemp = [];
    
        for(let i=0 ; i<listAddUser.length ; i++){
          if(i !== idx){
            dataTemp.push(listAddUser[i]);
          }
        }

        if(dataTemp.length > 0){
            requiredUserAccess[2] = true
        }else{
            requiredUserAccess[2] = false
        }
        props.onChangeRequiredUserAccess(requiredUserAccess);
            
        setListAddUser(dataTemp);
    }

    function handleSelectFormMulti(e, name, idxFlag) {
        const dataFormSelect = {...dataForm};
        const {value, label} = e;
        dataFormSelect[name] = e.map(item => item.value);
        props.onChangeForm(name, e.map(item => item.value));

        if((dataFormSelect[name]).length > 0 && idxFlag !== undefined){
            requiredUserAccess[idxFlag] = true
        }else{
            requiredUserAccess[idxFlag] = false
        }
        props.onChangeRequiredUserAccess(requiredUserAccess);

        setDataForm(dataFormSelect);
    }

    const handleClose = () => setShow(false);
    const onClickAdd = () => {
        setShow(true);
    };

    const getRegionList = () => {
        const dataList = Responsible_Approval.map(ls => ls.Regional).filter((item, index, arr) => arr.indexOf(item) === index).filter(item => item !== undefined).sort((a, b) => a.number > b.number ? -1 : 1).map(item => ({label : item, value : item}));
        setRegion(dataList);
    }
    
    const dataList = Responsible_Approval.filter(item => dataForm.region).filter(item => dataForm.region.includes(item.Regional))
    .map(ls => ls.TITLE)
    .filter((item, index, arr) => arr.indexOf(item) === index)
    .map(item => ({label : item, value : item}));

    const handleAddUser = (impadd) => {
        const imp_list = [...listAddUser]
        imp_list.push(impadd)
        props.addImplementor(imp_list)

        if(imp_list.length > 0){
            requiredUserAccess[2] = true
        }else{
            requiredUserAccess[2] = false
        }
        props.onChangeRequiredUserAccess(requiredUserAccess);

        setListAddUser(imp_list)
    }

    const prepareUserAccess = (impadd) => {
        const imp_list = [...impadd]
        props.addImplementor(imp_list)
        setListAddUser((prevState) => ([
            // ...prevState,
            ...imp_list
        ]))

        if(imp_list.length > 0){
            requiredUserAccess[2] = true
        }else{
            requiredUserAccess[2] = false
        }
        props.onChangeRequiredUserAccess(requiredUserAccess);
    }

    React.useEffect(() => {
        getRegionList();
    }, []);

    React.useEffect(() => {
        if(props.dataForm){
            setDataForm({
                ...dataForm,
                ...props.dataForm
            })
        }
    }, [props.dataForm])

    React.useEffect(() => {
        if(props.dataForm){
            if(props.dataForm.ai_user_accesses){
                prepareUserAccess(props.dataForm.ai_user_accesses)
            }
        }
    }, [props.dataForm.ai_user_accesses])

    return (
        <React.Fragment>
            <Row style={{paddingTop: "10px"}}>
                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="changeType" className="form-label">Select Region <span className="text-danger">*</span></Label>
                        <Select
                            // isClearable={true}
                            value={dataForm.region && dataForm.region.map(item => ({value : item, label : item}) )}
                            // value={dataForm.region}
                            isMulti={true}
                            onChange={(e) => {
                                handleSelectFormMulti(e, 'region', 0);
                            }}
                            options={region}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="changeCategory" className="form-label">Select Responsible Approval <span className="text-danger">*</span></Label>
                        <Select
                            // isClearable={true}
                            value={dataForm.responsible_approval && dataForm.responsible_approval.map(item => ({value : item, label : item}) )}
                            // value={dataForm.responsible_approval}
                            isMulti={true}
                            onChange={(e) => {
                                handleSelectFormMulti(e, 'responsible_approval', 1);
                            }}
                            options={dataList}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div style={{minHeight: "250px", border: "1px solid"}} className="table-responsive">
                        <table className="table align-middle table-nowrap table-striped-columns mb-0">
                            <thead className="table-light">
                                <tr>
                                {/* <th scope="col">No</th> */}
                                {/* <th scope="col">Instances</th> */}
                                <th scope="col">Company</th>
                                <th scope="col">Name</th>
                                <th scope="col" style={{width: "150px"}}>Phone Number</th>
                                <th scope="col">Email</th>
                                <th scope="col">Ne Type</th>
                                <th scope="col">Network Name Elmnt</th>
                                <th scope="col">Region Ne</th>
                                <th scope="col">User Type</th>
                                <th scope="col">User Needed</th>
                                <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listAddUser.map((user, idx) =>
                                <tr key={idx}>
                                    {/* <td>{idx+1}</td> */}
                                    {/* <td>{user.vendor_name}</td> */}
                                    <td>{user.vendor_name}</td>
                                    <td>{user.executor_pic_name}</td>
                                    <td>{user.executor_pic_phone}</td>
                                    <td>{user.executor_pic_email}</td>
                                    <td>{user.ne_type}</td>
                                    <td>{user.network_element_name}</td>
                                    <td>{user.region_ne}</td>
                                    <td>{user.user_type}</td>
                                    <td>{user.user_needed}</td>
                                    <td><Button onClick={() => deleteImplementor(idx)}
                                        color="danger" size="sm" style={{ marginLeft: "5px" }}>
                                        <i className="fa fa-trash"></i> Delete
                                    </Button>
                                    </td>
                                </tr>    
                                )}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
            <Row style={{paddingTop: "10px"}}>
                <Col md={12}>
                    <div className="mb-3">
                        <Button style={{float: "left", whiteSpace: "nowrap", marginRight: "10px"}} color="primary" className="custom-toggle" onClick={() => {onClickAdd();}}>
                            <span>Add Implementor</span>
                        </Button>
                    </div>
                </Col>
            </Row>
                
            {/* Modal Reject */}
            <Modal isOpen={show} toggle={handleClose} className={'modal-md modal--box-input'} color="warning" style={{marginTop: "50px", minWidth: "600px"}}>
            {/* <ModalHeader style={{backgroundColor : '#f0f3f5'}}>Reject Confirmation</ModalHeader> */}
            <ModalBody style={{paddingTop: "25px"}}>
                <Row>
                <Col sm="12">
                <CRFormAIUserAccessPopUp 
                    addUser={handleAddUser}
                    showUser={handleClose}
                    dataFormUser={dataForm}
                />
                </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
            <div className="modal-footer">
            </div>
            </ModalFooter>
            </Modal>
            {/* end Modal Reject */}

        </React.Fragment>
    );
};

export default CRFormAIUserAccess;