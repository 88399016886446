import React from 'react';
import { Col, Row, Pagination, PaginationItem, PaginationLink} from 'reactstrap';

const PaginationTable = (props) => {

    const {totalData, currentPage, perPage, totalShowingItem} = props;

    const [showPage, setShowPage] = React.useState([]);
    const [totalItem, setTotalItem] = React.useState(0);
    const [totalShowItem, setTotalShowItem] = React.useState(perPage);
    const [showCurrentPage, setShowCurrentPage] = React.useState(1);

    const TOTAL_PAGE = Math.ceil(totalItem/perPage);

    const handleOnclick = (idx) => {
        props.onChange(idx)
        setPageShowingPagination(idx)
    }

    const setPageShowingPagination = (curr_page) => {
        let array_page = [];
        const current_page = curr_page ? curr_page : showCurrentPage;
        setShowCurrentPage(current_page)
        if(TOTAL_PAGE === 0){
            
        }else if(TOTAL_PAGE === 1){
            array_page.push(1);
        }else if(TOTAL_PAGE === current_page){
            
            const lower_page = (current_page - 2) > 0 ? (current_page - 2) : (current_page - 1) > 0 ? (current_page - 1) : current_page;
            for(let i = lower_page; i <= TOTAL_PAGE; i++){
                array_page.push(i);
            }
        }else if(current_page > 1){
            for(let i = current_page-1; i <= current_page; i++){
                array_page.push(i);
            }
            if(TOTAL_PAGE > 2){
                array_page.push(current_page+1);
            }
            
        }else if(current_page === 1 && TOTAL_PAGE !== current_page){
            for(let i = current_page; i <= current_page+1; i++){
                array_page.push(i);
            }
            if(TOTAL_PAGE > 2){
                array_page.push(current_page+2);
            }
            
        }
        setShowPage(array_page);
    }

    React.useEffect(() => {
        setTotalItem(totalData)
    }, [totalData]);

    React.useEffect(() => {
        setPageShowingPagination(currentPage);
    }, [totalItem]);

    return(
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <div className="mt-4 pagination-total--pagination-table">
                        <Pagination>
                            <PaginationItem onClick={(e) => handleOnclick(1)} disabled={TOTAL_PAGE === 0}> <PaginationLink to="#"> {"<<"} </PaginationLink> </PaginationItem>
                            <PaginationItem onClick={(e) => handleOnclick(showCurrentPage-1)} disabled={showCurrentPage === 1 || TOTAL_PAGE === 0}> <PaginationLink to="#"> {"<"} </PaginationLink> </PaginationItem>
                            {showPage.map(idx => 
                                <PaginationItem key={idx} onClick={(e) => handleOnclick(idx)} active={idx === showCurrentPage} > <PaginationLink to="#"> {idx} </PaginationLink> </PaginationItem>
                            )}
                            <PaginationItem onClick={(e) => handleOnclick( showCurrentPage >= TOTAL_PAGE ? TOTAL_PAGE : showCurrentPage+1)} disabled={showCurrentPage >= TOTAL_PAGE || TOTAL_PAGE === 0}> <PaginationLink to="#"> {">"} </PaginationLink> </PaginationItem>
                            <PaginationItem onClick={(e) => handleOnclick(TOTAL_PAGE)} disabled={TOTAL_PAGE === 0}> <PaginationLink to="#"> {">>"} </PaginationLink> </PaginationItem>
                        </Pagination>
                        <div>
                            <span className='total-item--pagination-table'>(Showing {totalShowingItem ? totalShowingItem : perPage} of {totalItem} Item)</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default PaginationTable;