import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import CountUp from "react-countup";

//Import Icons
import FeatherIcon from "feather-icons-react";

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const Widget = () => {

    const [totalData, setTotalData] = React.useState(0);

    const getData = async() => {
        const res = await apiAuth.get('/getUser?srt=createdAt:-1&noPg=1');
        if(res && res.data){
            setTotalData(res.data.totalResults)
        }
      }

      React.useEffect(() => {
        getData();
      }, []);

    return (
        <React.Fragment>
            <Row>
                <Col md={6}>
                    <Card className="card-animate" style={{height: "200px"}}>
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Total User</p>
                                    <h2 className="mt-2 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={totalData}
                                                decimals={0}
                                                duration={4}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted"><span className="badge bg-light text-success mb-0">
                                        {/* % */}
                                    </span>Total all user</p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                                            <FeatherIcon
                                                icon="users"
                                                className="text-info"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col md={6}>
                    <Card className="card-animate" style={{height: "200px"}}>
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Session Login</p>
                                    <h2 className="mt-2 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={0}
                                                decimals={0}
                                                duration={4}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted"><span className="badge bg-light text-danger mb-0">
                                        %
                                    </span> vs. previous month</p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                                            <FeatherIcon
                                                icon="activity"
                                                className="text-info"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <Card className="card-animate" style={{height: "200px"}}>
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Avg. login</p>
                                    <h2 className="mt-2 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={0}
                                                decimals={0}
                                                duration={4}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted"><span className="badge bg-light text-danger mb-0">
                                        %
                                    </span> vs. previous month</p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                                            <FeatherIcon
                                                icon="clock"
                                                className="text-info"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col md={6}>
                    <Card className="card-animate" style={{height: "200px"}}>
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Success CR Ratio</p>
                                    <h2 className="mt-2 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={0}
                                                decimals={0}
                                                duration={4}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted"><span className="badge bg-light text-success mb-0">
                                        %
                                    </span> vs. previous month</p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                                            <FeatherIcon
                                                icon="external-link"
                                                className="text-info"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Widget;