import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

//import images
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar4 from "../../../assets/images/users/user-dummy-img.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import avatar6 from "../../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../../assets/images/users/avatar-7.jpg";
import avatar8 from "../../../assets/images/users/avatar-8.jpg";
import image2 from "../../../assets/images/small/img-2.jpg";
import image3 from "../../../assets/images/small/img-3.jpg";
import image4 from "../../../assets/images/small/img-4.jpg";

const StatusTab = (props) => {

    const {data} = props

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <Card>
                            <CardBody>
                            <h5 className="card-title">Status</h5>
                            <div className="acitivity-timeline py-3">
                                {data && data.statuses && data.statuses.map((st, idx) => (
                                    <React.Fragment key={idx}>
                                        <div className="acitivity-item py-3 d-flex">
                                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                                <div className="avatar-title bg-success-subtle text-success rounded-circle">
                                                    S
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="mb-1">{st.status_value} {st.status ? `-${st.status}` : ''}</h6>
                                                <p className="text-muted mb-2">{st.status_description}</p>
                                                <p className="text-muted mb-2"><i className="ri-file-text-line align-middle ms-2"></i> {st.status_note}</p>
                                                <Row>
                                                    <div className='d-flex'>
                                                    <div className="avatar-group mb-2">
                                                        <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Christi">
                                                            <img src={avatar4} alt="" className="rounded-circle avatar-xs" />
                                                        </Link>
                                                    </div>
                                                    <p style={{alignSelf : 'flex-end'}}>{st.status_updated_by_name}</p>
                                                    </div>
                                                </Row>
                                                
                                                <small className="mb-0 text-muted">{st.status_date}</small>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card>
                            <CardBody>
                            <h5 className="card-title">Detailed Approval</h5>
                            <div className="acitivity-timeline py-3">
                                {data && data.rfa_approvals && data.rfa_approvals.map((st, idx) => (
                                    <React.Fragment key={idx}>
                                        <div className="acitivity-item py-3 d-flex">
                                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                                <div className="avatar-title bg-success-subtle text-success rounded-circle">
                                                    RFA
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="mb-1">RFA - {st.role}</h6>
                                                {/* <p className="text-muted mb-2">{st.status}</p> */}
                                                {/* <p className="text-muted mb-2"><i className="ri-file-text-line align-middle ms-2"></i> {st.status}</p> */}
                                                <h6 className="mb-2"><i className="ri-chat-check-line align-middle ms-2"></i> {st.status}</h6>
                                                <Row>
                                                    <div className='d-flex'>
                                                    <div className="avatar-group mb-2">
                                                        <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Christi">
                                                            <img src={avatar4} alt="" className="rounded-circle avatar-xs" />
                                                        </Link>
                                                    </div>
                                                    <p style={{alignSelf : 'flex-end'}}>{st.status !== undefined && st.name}</p>
                                                    </div>
                                                </Row>
                                                
                                                <small className="mb-0 text-muted">{st.status !== undefined && st.updatedAt}</small>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                                {data && data.sfa_approvals && data.sfa_approvals.map((st, idx) => (
                                    <React.Fragment key={idx}>
                                        <div className="acitivity-item py-3 d-flex">
                                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                                <div className="avatar-title bg-success-subtle text-success rounded-circle">
                                                    SFA
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="mb-1">SFA - {st.role}</h6>
                                                {/* <p className="text-muted mb-2">{st.status}</p> */}
                                                <h6 className="mb-2"><i className="ri-chat-check-line align-middle ms-2"></i> {st.status}</h6>
                                                <Row>
                                                    <div className='d-flex'>
                                                    <div className="avatar-group mb-2">
                                                        <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Christi">
                                                            <img src={avatar4} alt="" className="rounded-circle avatar-xs" />
                                                        </Link>
                                                    </div>
                                                    <p style={{alignSelf : 'flex-end'}}>{st.status !== undefined && st.name}</p>
                                                    </div>
                                                </Row>
                                                
                                                <small className="mb-0 text-muted">{st.status !== undefined && st.updatedAt}</small>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                            </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default StatusTab;