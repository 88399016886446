import React from 'react';

import Select from 'react-select';

import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { dateformatncrm, datetimeformatncrm } from '../../../../helpers/FunctionalHelper/date-helper';
import GeneralDataTab from '../../CRDetail/GeneralDataTab';

const CRApprovalManagementTL = (props) => {

    const {data, actionLoading, USER_PROFILE} = props;

    const [loadingStatus, setLoadingStatus] = React.useState(false)

    const [dataForm, setDataForm] = React.useState({});
    const [dataApproval, setDataApproval] = React.useState({"status_note" : ""});
    const [canEdit, setCanEdit] = React.useState(false);

    const [listSfaSpproval, setListSfaSpproval] = React.useState([]);

    const [modalReject, setModalReject] = React.useState(false);
    const [roleRejection, setRoleRejection] = React.useState(null);

    const [sfaa, setSfaa] = React.useState({});

    const toggleReject = (approval_role) => {
        if(approval_role){
            setRoleRejection(approval_role)
        }else{
            setRoleRejection(null)
        }
        setModalReject(!modalReject)
    };

    const handleInputApprovalDataChange = (e) => {
        const dataFormInput = {...dataApproval}
        const {name, value} = e.target
        dataFormInput[name] = value;
        setDataApproval(dataFormInput)
    }

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        setDataForm(dataFormInput)
    }

    const approveCrForm = (approval_role, approval_email, approval_name, status) => {
        if(modalReject === true){
            toggleReject()
        }
        let data_approval = {...dataApproval}
        data_approval["role"] = approval_role;
        data_approval["email"] = approval_email;
        data_approval["name"] = approval_name;
        data_approval["status"] = status;
        if(status === "Approved"){
            data_approval["status_note"] = "Approved";
        }
        // console.log("data_approval", data_approval)
        props.actionApproval(data_approval)
    }

    const prepareApprovalView = () => {

        let approvalParallelManagement = []
        if(data && data.sfa_approvals){
            approvalParallelManagement = data.sfa_approvals.filter(item => item.role !== "Change Manager")
        }

        let nonApprovedParallelManagement = []
        if(data && data.sfa_approvals){
            nonApprovedParallelManagement = approvalParallelManagement.filter(item => item.status !== "Approved")
        }

        let approvedChangeManager = {}
        if(data && data.sfa_approvals){
            approvedChangeManager = data.sfa_approvals.find(item => item.role === "Change Manager")
        }
        if(!approvedChangeManager){
            approvedChangeManager = {}
        }

        if(USER_PROFILE && USER_PROFILE.role && data){
            const currentUserTitle = USER_PROFILE.title;
            const currentUserRole = USER_PROFILE.role.find(rl => rl === "Admin") ? "Admin" : null;

            let sfaApprovalFind = {}

            if(currentUserRole === "Admin"){
                if(nonApprovedParallelManagement.length > 0){
                    sfaApprovalFind = nonApprovedParallelManagement[0];
                }else{
                    sfaApprovalFind = approvedChangeManager
                }
            }else if(currentUserTitle.includes("Expert")){
                sfaApprovalFind = nonApprovedParallelManagement.find(sfas => sfas.role.includes("Domain Expert") || sfas.role.includes("Expert Domain"));
            }else if(USER_PROFILE.title.includes("Manager") && USER_PROFILE.title.includes("Change and Release")){
                if(nonApprovedParallelManagement.length === 0){
                    sfaApprovalFind = approvedChangeManager
                }
                
            }else{
                sfaApprovalFind = nonApprovedParallelManagement.find(sfas => sfas.role.toLowerCase() === currentUserTitle.toLowerCase());
            }

            if(!sfaApprovalFind){
                sfaApprovalFind = {}
            }
            
            setSfaa(sfaApprovalFind)
        }
        
    }

    React.useEffect(() => {
        prepareApprovalView()
    },[data.sfa_approvals])

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <Card style={{marginBottom : '10px'}}>
                        <CardBody>
                            <div className="text-center">
                                {data && data.change_category !== "Normal Minor" ? (
                                <React.Fragment>
                                    <Row >
                                        <React.Fragment>
                                        <Col md={12} >
                                            <Row style={{marginBottom : '10px' }}>
                                                <Col>
                                                    <h5>{sfaa.role} Approval</h5>
                                                </Col>
                                            </Row>
                                            {sfaa.status !== null && (
                                            <React.Fragment>
                                            <Row>
                                                <Col xs={12}>
                                                    <span>{datetimeformatncrm(sfaa.updatedAt)}</span>
                                                </Col>
                                            </Row>
                                            </React.Fragment>
                                            )}
                                            <Row>
                                                {sfaa.role !== "Change Manager" ? (
                                                    <React.Fragment>
                                                        {sfaa.status === null ? (
                                                            <React.Fragment>
                                                                {(USER_PROFILE && USER_PROFILE.role && (USER_PROFILE.role.find(rl => rl === sfaa.role) || USER_PROFILE.role.find(rl => rl === "Admin") || (USER_PROFILE.title.toLowerCase().includes(sfaa.role.toLowerCase())) || (USER_PROFILE.title && USER_PROFILE.title.toLowerCase()  === sfaa.role.toLowerCase()) || (USER_PROFILE.title && USER_PROFILE.title.includes("Expert") && sfaa.role === "Domain Expert"))) ? (
                                                                    <React.Fragment>
                                                                    <Col md={6}>
                                                                        <Button style={{width: "100%"}} onClick={(e) => approveCrForm(sfaa.role, sfaa.email, sfaa.name, "Approved")} disabled={actionLoading}>
                                                                            {actionLoading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                                                            Approve
                                                                        </Button>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Button
                                                                            color='danger'
                                                                            
                                                                            style={{width: "100%"}}
                                                                            disabled={actionLoading}
                                                                            onClick={(e) => toggleReject(sfaa.role)} 
                                                                            // disabled={loadingStatus}
                                                                        >
                                                                        {loadingStatus ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                                                        Reject 
                                                                        </Button>
                                                                    </Col>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <h2>
                                                                        NOT YET
                                                                    </h2>
                                                                )}
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                            <h2 style={sfaa.status === "Approved" ? {color : "#219C90"} : sfaa.status === "Rejected" ? {color : "#D83F31"} : {}}>
                                                                {sfaa.status}
                                                            </h2>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {sfaa.status === null ? (
                                                            <React.Fragment>
                                                                {(USER_PROFILE.title && USER_PROFILE.title.includes("Manager") && USER_PROFILE.title.includes("Change and Release")) ? (
                                                                    <React.Fragment>
                                                                    <Col md={6}>
                                                                        <Button style={{width: "100%"}} onClick={(e) => approveCrForm(sfaa.role, sfaa.email, sfaa.name, "Approved")} disabled={actionLoading}>
                                                                            {actionLoading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                                                            Approve
                                                                        </Button>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Button
                                                                            color='danger'
                                                                            
                                                                            style={{width: "100%"}}
                                                                            disabled={actionLoading}
                                                                            onClick={(e) => toggleReject(sfaa.role)}
                                                                        >
                                                                        {loadingStatus ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                                                        Reject 
                                                                        </Button>
                                                                    </Col>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <h2>
                                                                        NOT YET
                                                                    </h2>
                                                                )}
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                            <h2 style={sfaa.status === "Approved" ? {color : "#219C90"} : sfaa.status === "Rejected" ? {color : "#D83F31"} : {}}>
                                                                {sfaa.status}
                                                            </h2>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                                
                                                {/* </Col> */}
                                            </Row>
                                            <React.Fragment>
                                            <Row style={sfaa.status === null ? {marginTop : '10px', maxWidth: "310px", marginLeft: "auto", marginRight: "auto"} : {maxWidth: "310px", marginLeft: "auto", marginRight: "auto"}}>
                                                <Col xs={12}>
                                                    <span style={{fontSize : '15px'}}>{sfaa.email}</span>
                                                </Col>
                                            </Row>
                                            </React.Fragment>
                                        </Col>
                                        </React.Fragment>
                                    </Row>
                                </React.Fragment>
                                ):(
                                    <React.Fragment>
                                        {data.current_status === "Scheduled for Approval" && (
                                            <Row>
                                                <Col xl={12}>
                                                    <Row style={{marginBottom : '10px'}}>
                                                        <Col xs={12} >
                                                            <h5>Change Manager Approval</h5>
                                                        </Col>
                                                    </Row>
                                                    {(USER_PROFILE && (USER_PROFILE.title && USER_PROFILE.title.includes("Manager") && USER_PROFILE.title.includes("Change and Release")) || (USER_PROFILE.role && (USER_PROFILE.role.find(rl => rl === "Admin") || USER_PROFILE.role.find(rl => rl === "Approval NCRM")))) ? (
                                                    <Row >
                                                        {/* <Col xs={12}> */}
                                                        
                                                            <React.Fragment>
                                                            <Col md={6}>
                                                                <Button 
                                                                    style={{width: "100%"}}
                                                                    onClick={(e) => approveCrForm("Change Manager", null, null, "Approved")}
                                                                    disabled={actionLoading}
                                                                >
                                                                {actionLoading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                                                Approve
                                                                </Button>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Button
                                                                    color='danger'
                                                                    style={{width: "100%"}}
                                                                    disabled={actionLoading}
                                                                    onClick={(e) => toggleReject("Change Manager")}
                                                                    // disabled={loadingStatus}
                                                                >
                                                                {actionLoading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                                                Reject 
                                                                </Button>
                                                            </Col>
                                                            </React.Fragment>
                                                        {/* </Col> */}
                                                    </Row>
                                                    ) : (
                                                        <React.Fragment>
                                                            <Row>
                                                                {data.current_status === "Scheduled" ? (
                                                                    <Col xs={12}>
                                                                        <h2 style={{color : "#219C90"}}>
                                                                            Approved
                                                                        </h2>
                                                                    </Col>
                                                                ) : data.current_status === "Scheduled for Approval" ? (
                                                                    <Col xs={12}>
                                                                        <h2>
                                                                            Not Yet
                                                                        </h2>
                                                                    </Col>
                                                                ) : (
                                                                    <Col xs={12}>
                                                                        <h2 style={{color : "#D83F31"}}>
                                                                            Rejected
                                                                        </h2>
                                                                    </Col>
                                                                )}
                                                                
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                        
                                    </React.Fragment>
                                )}
                            </div>
                    </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal isOpen={modalReject} toggle={toggleReject} style={{marginTop : '200px'}}>
                <ModalHeader toggle={toggleReject}>Reject {data.change_id}</ModalHeader>
                <ModalBody>
                <Row>
                    <Col md={12}>
                        <div className="mb-3">
                            <Label htmlFor="reject_remark" className="form-label">Reject Remarks</Label>
                            <Input type="textarea" className="form-control" value={dataApproval.status_note} name="status_note" onChange={(e) => handleInputApprovalDataChange(e)} id="status_note" />
                        </div>
                    </Col>
                </Row>
                </ModalBody>
                <ModalFooter>
                <Button color="danger" onClick={() => approveCrForm(roleRejection, null, null, "Rejected")} >
                    Reject
                </Button>{' '}
                <Button color="secondary" onClick={toggleReject}>
                    Cancel
                </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default CRApprovalManagementTL;