export const capitalizeFirstLetter = (string) => {
    if(!string){
        return ""
    }
    
    const str = string.toString();
    const str_new = str.charAt(0).toUpperCase() + str.slice(1);
    return str_new
}

export const capitalizeFirstLetterEachWord = (string) => {

    if(!string){
        return ""
    }

    let array_words = string.split(" ");

    array_words.filter(item => item).map((word) => { 
        return word[0].toUpperCase() + word.substring(1); 
    });

    let words = array_words.join(" ");
    
    return words
}

