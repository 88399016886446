import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { notificationFlagAdd, loadingFalse, notificationFlagAssign } from '../../redux/reducers/notificationFlagReducer';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const dateNow = new Date();

export const approvalCrForm = createAsyncThunk(
    "/approvalCr",
    async (data, thunkApi) => {
        const resPromise = await ApprovalSelect(data.formData, data.approval_data, thunkApi);
        return resPromise;
    }
);

export const ApprovalSelect = async (data, data_approval, thunkApi) => {

    let approvalData = {...data_approval}

    let res

    if(data.change_category === "Normal Significant" || data.change_category === "Normal Major"){
        res = await ApprovalCrFormNormalSignificant(data.change_id, approvalData, thunkApi)
    }

    if(data.change_category === "Normal Minor"){
        res = await ApprovalCrFormNormalMinor(data.change_id, approvalData, thunkApi)
    }

    if(data.change_category === "Standard"){
        res = await ApprovalCrFormStandard(data.change_id, approvalData, thunkApi)
    }

    return res

}

export const ApprovalCrFormNormalSignificant = async (change_id, body, thunkApi) => {

    let notification = {}

    const res = await apiAuth.patch('/updateCRMStatusMajorSignificant/'+change_id, {data : body});
    
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_message : "CR Approval successful!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}

export const ApprovalCrFormNormalMinor = async (change_id, body, thunkApi) => {

    let notification = {}

    const res = await apiAuth.patch('/updateCRMStatusMinor/'+change_id, {data : body});
    
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_message : "CR Approval successful!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}

export const ApprovalCrFormStandard = async (change_id, body, thunkApi) => {

    let notification = {}

    const res = await apiAuth.patch('/updateCRMStatusStandard/'+change_id, {data : body});
    
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_message : "CR Approval successful!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}

export const approvalErPirCrForm = createAsyncThunk(
    "/approvalErPirCr",
    async (data, thunkApi) => {
        const resPromise = await ApprovalErPir(data.data, data.crAttachment, thunkApi);
        console.log("Attachment Thunk", data.crAttachment);
        return resPromise;
    }
);

export const ApprovalErPir = async (formData, attachment, thunkApi) => {

    let formDataData = new FormData();

    await formDataData.append("data", JSON.stringify(formData));

    
    await formDataData.append("pir_attachment", attachment[0].file);

    let url = '';

    if(formData.change_category === "Normal Minor"){
        url = '/updateCRMStatusMinor/';
    }else if(formData.change_category === "Standard"){
        url = '/updateCRMStatusStandard/';
    }else if(formData.change_category === "Normal Major" || formData.change_category === "Normal Significant"){
        url = '/updateCRMStatusMajorSignificant/';
    }

    let notification = {}

    const res = await apiAuth.patch(url+formData.change_id, formDataData, null, {"Content-Type" : "multipart/form-data"});
    
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_message : "CR Approval successful!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}



// export const createDataCrAsync = async (form, thunkApi) => {
//     //formdata object
//     let formData = new FormData();

//     // let dataCr = {...form.form}

//     let dataCr = {...DATA_DUMMY_CR_FORM_CREATION}

//     dataCr["attachment"] = form.crAttachment ? form.crAttachment.map(item => ({attachment_name : item.attachment_name})) : [];

//     console.log("dataCr", JSON.stringify(dataCr))

//     await formData.append('data', JSON.stringify(dataCr));
//     for(let i = 0 ; i < form.crAttachment.length; i++){
//         const field = 'attachment_'+i;
//         console.log(field, form.crAttachment[i].file.name )
//         await formData.append(field, form.crAttachment[i].file);
//     }

//     let notification = {}

//     const res = await apiAuth.post('/createCRM', formData, null, {"Content-Type" : "multipart/form-data"});

//     console.log('res', res)
    
//     if(res !== undefined && res.data !== undefined){
//         notification = {
//             alert_status : "success",
//             alert_message : "CR Approved successfully!",
//         }
//     }else{
        
//         notification = {
//             alert_status : "warning",
//             alert_message : res.response.data,
//         }
//     }
//     thunkApi.dispatch(notificationFlagAssign(notification));
//     thunkApi.dispatch(loadingFalse());

//     return res
// }