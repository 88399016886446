import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Vector from '../DashboardEcommerce/VectorMap';

import { USER_TITLE } from '../../config/data/USER_TITLE';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const colorList = ['#004266', 
'#005289', 
'#0060af', 
'#0067c7', 
'#0078d9', 
'#008aec', 
'#0099fa', 
'#00a8fc', 
'#41b7fc', 
'#7eccfe', 
'#b2dffe', 
'#dff3fe']

const MainLocations = (crdata) => {
    const Responsible_Approval = USER_TITLE;

    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    const [region, setRegion] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [dataList, setDataList] = React.useState([]);
    const [totalData, setTotalData] = React.useState(0);
    const [dataSearchDate, setDataSearchDate] = React.useState({});
    const [dataDomain, setDataDomain] = React.useState([]);

    const current_date = new Date();
    const next_day_date = new Date(new Date().setDate(new Date().getDate()));
    const next_month_date = new Date(next_day_date.setMonth(next_day_date.getMonth() + 1));
    const previous_day_date = new Date(new Date().setDate(new Date().getDate() + 1));
    const previous_month_date = new Date(previous_day_date.setMonth(previous_day_date.getMonth() - 1));

    const getRegionList = () => {
        const dataList = Responsible_Approval.map(ls => ls.Regional).filter((item, index, arr) => arr.indexOf(item) === index).filter(item => item !== undefined).map(item => ({label : item, value : item, number : parseInt(item.split(" ")[0].replace("R",""))})).sort((a, b) => a.number > b.number ? 1 : -1).map(item => (item.value));
        setRegion(dataList);
        getChartData(dataList);
    }

    React.useEffect(() => {
        getRegionList()
    }, [crdata])

    const getChartData = async(data_list) => {
        let list = [];

        
        for(let j = 0; j < data_list.length ; j++){
            let data = {};
            
            data["value"] = crdata.crdata && crdata.crdata[data_list[j]] !== undefined ? crdata.crdata[data_list[j]] : 0;
            data["name"] = data_list[j];
            data["number"] = parseInt(data_list[j].split(" ")[0].replace("R",""));

            list.push(data);
        }

        const listSortingValue = list.sort((a, b) => a.value > b.value ? -1 : 1);

        let colorByValue = [];
        let idxcolor = 0
        for(let j = 0; j < listSortingValue.length; j++){
            let data = {
                "value" : null,
                "color" : null
            }
            if(j === 0){
                if(listSortingValue[j].value !== 0 ){
                    data["value"] = listSortingValue[j].value;
                    data["color"] = colorList[idxcolor]
                    idxcolor = idxcolor + 1;
                    colorByValue.push(data);
                }
            }else{
                if(listSortingValue[j].value !== 0 && listSortingValue[j].value !== listSortingValue[(j-1)].value){
                    data["value"] = listSortingValue[j].value;
                    data["color"] = colorList[idxcolor]
                    idxcolor = idxcolor + 1;
                    colorByValue.push(data);
                }
            }
            
        }

        for(let j = 0; j < listSortingValue.length; j++){
            if(listSortingValue[j].value !== 0){
                listSortingValue[j]["color"] = colorByValue.find(item => item.value === listSortingValue[j].value).color ;
            }else if(listSortingValue[j].value === 0){
                listSortingValue[j]["color"] = '#999999' ;
            }   
        }

        setChartData(listSortingValue);
    }

    return (
        <React.Fragment>
            <Col md={12} style={{height: "561px", marginBottom: "20px"}}>
                <Card className="card-height-100" style={{height: "100%"}}>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Total CR Per region</h4>
                    </CardHeader>

                    <CardBody>

                        <div
                            data-colors='["--vz-light", "--vz-success", "--vz-primary"]'
                            style={{ height: "269px" }} dir="ltr">
                            <Vector
                                value="indonesia"
                                colorArea={chartData}
                            />
                        </div>

                        <div className="px-2 py-2 mt-1">
                            <div className="table-responsive table-card" style={{minHeight: "85px", height: "202px"}}>
                                <table className="table table-borderless align-middle mb-0" >
                                    <tbody>
                                        {chartData.sort((a, b) => a.number > b.number ? 1 : -1).map(reg => (
                                            <React.Fragment>
                                            <p className="mt-3 mb-1">{reg.name}<span className="float-end">{reg.value}%</span></p>
                                            <div className="progress mt-2" style={{ height: "6px" }}>
                                                <div className="progress-bar progress-bar-striped bg-primary" role="progressbar"
                                                    style={{ width: reg.value+'%' }} aria-valuenow="47" aria-valuemin="0" aria-valuemax="47">
                                                </div>
                                            </div>       
                                            </React.Fragment>
                                        ))}     
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default MainLocations;