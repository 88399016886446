import React from 'react'
import Select from "react-select";

import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row, Table, Modal, ModalFooter, ModalHeader, ModalBody, Button, Spinner } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';

import { datetimeformatncrm, dateformatncrm } from "../../helpers/FunctionalHelper/date-helper";

import { createDataHoliday, deleteDataHoliday } from "../../middleware/holiday-management/holiday-thunk";

import { loadingStatusSet } from "../../redux/reducers/notificationFlagReducer";

import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

import { notificationFlagAdd, loadingFalse, notificationFlagAssign } from '../../redux/reducers/notificationFlagReducer';
import { USER_TITLE } from '../../config/data/USER_TITLE';

// const api = new APIClient();
const apiAuth = new API_CLIENT_AUTH_BEARER();
  
export default function HolidayDateManagement() {

  const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

  const [dataForm, setDataForm] = React.useState({
    // event_type : "Holiday"
  });
  const [dataArrayForm, setDataArrayForm] = React.useState([]);
  const [loadingStatus, setLoadingStatus] = React.useState(false);

  const [data, setData] = React.useState([]);
  const [totalData, setTotalData] = React.useState(0);
  const [toggleModalDelete, setToggleModalDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({});

  const [actionStatus, setActionStatus] = React.useState(null);

  const dispatch = useDispatch();

  const handleToggleModalDelete = (data) => {
    if(data){
      setDeleteData(data);
    }else{
      setDeleteData({});
    }
    setToggleModalDelete(!toggleModalDelete)
  }

  function handleSelectFormMulti(e, name) {
    const dataFormSelect = {...dataForm};
    const {value, label} = e;
    dataFormSelect[name] = e.map(item => item.value);
    setDataForm(dataFormSelect);
  }

  const handleInputChange = (e) => {
      const dataFormInput = {...dataForm}
      const {name, value} = e.target
      dataFormInput[name] = value;
      setDataForm(dataFormInput)
  }

  const createRestrictedDate = async(e) => {
      let add_dataInput = [...dataArrayForm];
      dataForm["created_by"] = userData.email;
      add_dataInput.push(dataForm);
      setDataArrayForm(add_dataInput)

      setLoadingStatus(true)
      dispatch(loadingStatusSet(true));

      const response = await dispatch(createDataHoliday(dataForm))
      const res = unwrapResult(response);
      
      setLoadingStatus(false)

      if(res && res.data){
        setActionStatus('success')
      }else{
      }
  }

  const getData = async() => {
    const res = await apiAuth.get('/getHolidayManagement');
    if(res && res.data){
        const data_sort = res.data.data
        setData(data_sort.sort((a,b) => {
          return new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
        }).reverse())
        setTotalData(res.data.totalResults)
    }
  }

  const deleteRestrictedDate = async(e) => {

    setLoadingStatus(true)

    const response = await dispatch(deleteDataHoliday(deleteData));
    const res = unwrapResult(response);

    if(res && res.data){
      getData();
      setToggleModalDelete(false)
      // setActionStatus('success')
    }else{

    }
    setLoadingStatus(false)
}

  React.useEffect(() => {
    getData();
  }, []);

  const regionList = USER_TITLE.map(ls => ls.Regional).filter((item, index, arr) => arr.indexOf(item) === index).filter(item => item !== undefined).sort((a, b) => a.number > b.number ? -1 : 1).map(item => ({label : item, value : item}))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Restricted Date" pageTitle="Restricted Date Management" />
          <Row>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Restricted Date Input</h4>
                </CardHeader>
                <CardBody>
                  <Row >
                  <Col sm={12} md={3}>
                    <Card style={{paddingBottom: "20px", boxShadow: "none"}}>
                          <div className="mb-3">
                              <Label htmlFor="start_date" className="form-label">Event Start Date</Label>
                              <Input type="date" className="form-control" name="start_date" value={dataForm.start_date} onChange={(e) => handleInputChange(e)} id="start_date" />
                          </div>
                          <div className="mb-3">
                              <Label htmlFor="end_date" className="form-label">Event End Date</Label>
                              <Input type="date" className="form-control" name="end_date" disabled={dataForm.start_date !== undefined ? false : true} min={dataForm.start_date} value={dataForm.end_date} onChange={(e) => handleInputChange(e)} id="end_date" />
                          </div>
                          <div className="mb-3">
                              <Label htmlFor="title" className="form-label">Event Name</Label>
                              <Input type="text" className="form-control" name="title" onChange={(e) => handleInputChange(e)} id="title" />
                          </div>
                          <div className="mb-3">
                              <Label htmlFor="remarks" className="form-label">Description</Label>
                              <Input type="textarea" className="form-control" name="remarks" onChange={(e) => handleInputChange(e)} id="remarks" />
                          </div>
                          <div className="mb-3">
                              <Label htmlFor="selectRegion" className="form-label">Select Region</Label>
                              <Select
                                  isClearable={true}
                                  value={dataForm.region && dataForm.region.map(item => ({value : item, label : item}) )}
                                  isMulti={true}
                                  onChange={(e) => {
                                      handleSelectFormMulti(e, 'region');
                                  }}
                                  options={regionList}
                              />
                          </div>
                          <div className="text-end">
                              <button onClick={(e) => createRestrictedDate(e)} className="btn btn-primary" disabled={ ( data.find(date => dateformatncrm(date.start_date) <= dateformatncrm(dataForm.start_date) && dateformatncrm(date.end_date) >= dateformatncrm(dataForm.start_date)) === undefined ) && dataForm.start_date !== undefined && dataForm.end_date !== undefined && dataForm.title !== undefined && dataForm.start_date <= dataForm.end_date ? false : true} >Add</button>
                          </div>
                    </Card>
                    </Col>
                    <Col sm={12} md={9}>
                        <Card style={{border: "2px solid #dedede"}}>
                          <div className="table-responsive">
                            <Table className="align-middle mb-3 text-align-header-centre">
                                <thead className="bg-primary text-white">
                                    <tr>
                                        <th scope="col">No</th>
                                        <th scope="col">Event Name</th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">Region</th>
                                        <th scope="col">Created By</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {data.map((time, idx) =>
                                    <tr key={idx} style={{textAlign: "center"}}>
                                      <td >{idx+1}</td>
                                      <td>{time.title}</td>
                                      <td style={{textAlign: "center"}}>{dateformatncrm(time.start_date)}</td>
                                      <td style={{textAlign: "center"}}>{dateformatncrm(time.end_date)}</td>
                                      <td style={{fontSize : "10px", textAlign : 'left'}}>{time.region ? time.region.join(", ") : '-' }</td>
                                      <td style={{fontSize : "12px", textAlign : 'left'}}>{time.created_by}</td>
                                      <td>
                                        <Button size='sm' color='danger' onClick={()=>handleToggleModalDelete(time)} disabled={loadingStatus}>
                                          {loadingStatus ? <Spinner size="sm"></Spinner> : <i className="ri-delete-bin-2-fill"></i> }
                                        </Button>
                                      </td>
                                    </tr>    
                                  )}
                                </tbody>
                            </Table>
                            </div>
                        </Card>
                      </Col>
                  </Row>
                </CardBody>
              </Card>
          </Row>
        </Container>
        {/* //TODO */}
        <Modal isOpen={toggleModalDelete} toggle={handleToggleModalDelete} className={'modal-md modal--box-input'} style={{marginTop: "150px"}}>
          <ModalHeader>
            Delete Confirmation
          </ModalHeader>
          <ModalBody>
            <div className='d-flex justify-content-center'>
              <span style={{fontSize : '15px'}}>Are You sure delete Restricted Date <span className='fw-bolder'>{deleteData.title}</span> ?</span>
            </div>
          </ModalBody>
          <ModalFooter>
            <div>
              <Button color="light" size='sm' style={{marginRight : '15px'}} onClick={handleToggleModalDelete}><i className="ri-close-line me-1 align-middle" /> Close</Button>
              <Button color='danger' size="sm" onClick={() => deleteRestrictedDate()} disabled={loadingStatus}>
                {loadingStatus ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-delete-bin-2-fill"></i> }  
                Delete  
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  )
}
