import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown, Label, Input } from 'reactstrap';

const AIAccessTab = (props) => {

    const {data} = props;

    const [dataForm, setDataForm] = React.useState({});
    const [canEdit, setCanEdit] = React.useState(false);

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        setDataForm(dataFormInput)
    }

    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm={6}>
                                    <div className="verti-sitemap">
                                        <ul className="list-unstyled mb-0">
                                            <li className="p-0 parent-title"><Link to="#" className="fw-medium fs-14">Region</Link></li>
                                            <li>
                                                {data.region && data.region.map((reg, idx) => (
                                                    <React.Fragment key={idx}>
                                                        <div className="first-list">
                                                            <div className="list-wrap">
                                                                <Link to="#" className="fw-medium text-primary">{reg}</Link>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="verti-sitemap">
                                        <ul className="list-unstyled mb-0">
                                            <li className="p-0 parent-title"><Link to="#" className="fw-medium fs-14">Responsible Approval</Link></li>
                                            <li>
                                                {data.responsible_approval && data.responsible_approval.map(ra => (
                                                    <React.Fragment>
                                                        <div className="first-list">
                                                            <div className="list-wrap">
                                                                <Link to="#" className="fw-medium text-primary">{ra}</Link>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <div className="d-flex align-items-center mb-4">
                                <h5 className="card-title flex-grow-1">User Access</h5>
                            </div>
                            <Row>
                                <Col lg={12}>
                                    <div className="table-responsive table-card">
                                        <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                <th scope="col">Company</th>
                                                <th scope="col">Name</th>
                                                <th scope="col" style={{width: "150px"}}>Phone Number</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Ne Type</th>
                                                <th scope="col">Network Name Elmnt</th>
                                                <th scope="col">Region Ne</th>
                                                <th scope="col">User Type</th>
                                                <th scope="col">User Needed</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.ai_user_accesses && data.ai_user_accesses.map((user, idx) =>
                                                <tr key={idx}>
                                                    {/* <td>{idx+1}</td> */}
                                                    {/* <td>{user.vendor_name}</td> */}
                                                    <td>{user.vendor_name}</td>
                                                    <td>{user.executor_pic_name}</td>
                                                    <td>{user.executor_pic_phone}</td>
                                                    <td>{user.executor_pic_email}</td>
                                                    <td>{user.ne_type}</td>
                                                    <td>{user.network_element_name}</td>
                                                    <td>{user.region_ne}</td>
                                                    <td>{user.user_type}</td>
                                                    <td>{user.user_needed}</td>
                                                </tr>    
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
        </React.Fragment>
    );
};

export default AIAccessTab;