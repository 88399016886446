import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CountUp from "react-countup";
import { useSelector, useDispatch } from "react-redux";
import { OverviewCharts } from './DashboardLineCharts';
import { getProjectChartsData } from '../../slices/thunks';
import { createSelector } from 'reselect';

const monthsList = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const LineChart = (crdata) => {
    const dispatch = useDispatch();

    console.log("CR Monthly", crdata);

    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    const [chartData, setChartData] = useState([{
        "data" : [],
        "name" : "Ongoing",
        "type" : "bar"
    },{
        "data" : [],
        "name" : "Scheduled",
        "type" : "bar"
    },{
        "data" : [],
        "name" : "Closed",
        "type" : "bar"
    }]);
    const [chartYearData, setChartYearData] = useState([]);
    const [dataList, setDataList] = React.useState([]);
    const [totalData, setTotalData] = React.useState(0);
    const [totalDataOngoing, setTotalDataOngoing] = React.useState(0);
    const [totalDataScheduled, setTotalDataScheduled] = React.useState(0);
    const [totalDataClosed, setTotalDataClosed] = React.useState(0);
    const [dataSearchDate, setDataSearchDate] = React.useState({});

    const current_date = new Date();
    const next_day_date = new Date(new Date().setDate(new Date().getDate()));
    const next_month_date = new Date(next_day_date.setMonth(next_day_date.getMonth() + 1));
    const previous_day_date = new Date(new Date().setDate(new Date().getDate() + 1));
    const previous_month_date = new Date(previous_day_date.setMonth(previous_day_date.getMonth() - 1));

    const getData = async() => {
        let filter_array = [];
        let role_array = [];

        if(userData.role && userData.role.find(role => role.includes("Admin") || role.includes("Line Manager")) !== undefined){
            filter_array.push('');
        }else{
            if(userData.role && userData.role.find(role => role.includes("Requestor")) !== undefined){
                role_array.push('{"created_by" : "'+userData.email+'"},{"lm_email" : "'+userData.email+'"}');
            }
            if(userData.role && userData.role.find(role => role.includes("Change Coordinator")) !== undefined){
                role_array.push('{"change_coordinator_email" : "'+userData.email+'"},{"lm_email" : "'+userData.email+'"}');
            }
            if((userData.role && userData.role.find(role => role.includes("Change Manager")) !== undefined) || (userData.title && userData.title.includes("Change and Release") === true)){
                role_array.push('{"change_category" : "Normal Minor"},{"change_category" : "Normal Major"},{"change_category" : "Normal Significan"},{"lm_email" : "'+userData.email+'"}');
            }
            if(userData.title && (userData.title.includes("Expert Domain") === true || userData.title.includes("Domain Expert") === true)){
                role_array.push('{"change_category" : "Normal Major"},{"change_category" : "Normal Significan"},{"lm_email" : "'+userData.email+'"}');
            }
            filter_array.push('"$or" : ['+role_array.join(',')+']');
        }

        let whereAnd = '{' + filter_array.join(',') + '}';

        const res = await apiAuth.get('/getCRM?q='+whereAnd+'&noPg=1');
        if(res && res.data){
            setDataList(res.data.data);
            setTotalData(res.data.totalResults);
            getChartData(res.data.data);
        }
    }
    
    React.useEffect(() => {
        getData();
    }, [dataSearchDate]);

    const getChartData = async(data_list) => {
        let totalOngoing = 0;
        let totalScheduled = 0;
        let totalClosed = 0;
        let index = 0;
        const year = new Date();

        for(let i = 0; i < chartData.length ; i++)  {
                if(data_list){
                    if(chartData[i].name === "Ongoing"){
                        let dataCRPush = [];
                        
                        if(data_list.filter(dt => new Date(dt.createdAt).getFullYear() === year.getFullYear()-1 )){
                            for(let j = year.getMonth()+1; j < 12 ; j++){
                                const monthYear = monthsList[j]+' '+(year.getFullYear()-1).toString();
                                
                                chartYearData[index] = monthYear;
                                index = index+1;

                                const data_push = data_list ? (data_list.filter(dt => new Date(dt.createdAt).getMonth() === j && new Date(dt.createdAt).getFullYear() === year.getFullYear()-1 )).length : 0;
                                
                                dataCRPush.push(data_push);
                                totalOngoing += data_push;
                                
                            }
                        }
                        if(data_list.filter(dt => new Date(dt.createdAt).getFullYear() === year.getFullYear() )){
                            for(let j = 0; j <= year.getMonth() ; j++){
                                const monthYear = monthsList[j]+' '+(year.getFullYear()).toString();

                                chartYearData[index] = monthYear;
                                index = index+1;

                                const data_push = data_list ? (data_list.filter(dt => new Date(dt.createdAt).getMonth() === j && new Date(dt.createdAt).getFullYear() === year.getFullYear() )).length : 0;
                                
                                dataCRPush.push(data_push);
                                totalOngoing += data_push;
                                
                            }
                        }
                        chartData[i].data = dataCRPush;
                    }
                    else if(chartData[i].name === "Scheduled"){
                        let dataCRPush = [];

                        if(data_list.filter(dt => new Date(dt.updatedAt).getFullYear() === year.getFullYear()-1 )){
                            for(let j = year.getMonth()+1; j < 12 ; j++){
                                const monthYear = monthsList[j]+' '+(year.getFullYear()-1).toString();
                                
                                chartYearData.push(monthYear);

                                const data_push = data_list ? (data_list.filter(dt => dt.current_status === "Scheduled" && new Date(dt.updatedAt).getMonth() === j && new Date(dt.updatedAt).getFullYear() === year.getFullYear()-1 )).length : 0;

                                dataCRPush.push(data_push);
                                totalScheduled += data_push;
                            }
                        }
                        if(data_list.filter(dt => new Date(dt.updatedAt).getFullYear() === year.getFullYear() )){
                            for(let j = 0; j <= year.getMonth() ; j++){
                                const monthYear = monthsList[j]+' '+(year.getFullYear()).toString();
                                
                                chartYearData.push(monthYear);

                                const data_push = data_list ? (data_list.filter(dt => dt.current_status === "Scheduled" && new Date(dt.updatedAt).getMonth() === j && new Date(dt.updatedAt).getFullYear() === year.getFullYear() )).length : 0;

                                dataCRPush.push(data_push);
                                totalScheduled += data_push;
                            }
                        }

                        chartData[i].data = dataCRPush;
                    }
                    else if(chartData[i].name === "Closed"){
                        let dataCRPush = [];
                        if(data_list.filter(dt => new Date(dt.updatedAt).getFullYear() === year.getFullYear()-1 )){
                            for(let j = year.getMonth()+1; j < 12 ; j++){
                                const monthYear = monthsList[j]+' '+(year.getFullYear()-1).toString();
                                
                                chartYearData.push(monthYear);

                                const data_push = data_list ? (data_list.filter(dt => dt.current_status === "Closed" && new Date(dt.updatedAt).getMonth() === j && new Date(dt.updatedAt).getFullYear() === year.getFullYear()-1 )).length : 0;

                                dataCRPush.push(data_push);
                                totalClosed += data_push;
                            }
                        }
                        if(data_list.filter(dt => new Date(dt.updatedAt).getFullYear() === year.getFullYear() )){
                            for(let j = 0; j <= year.getMonth() ; j++){
                                const monthYear = monthsList[j]+' '+(year.getFullYear()).toString();

                                chartYearData.push(monthYear);

                                const data_push = data_list ? (data_list.filter(dt => dt.current_status === "Closed" && new Date(dt.updatedAt).getMonth() === j && new Date(dt.updatedAt).getFullYear() === year.getFullYear() )).length : 0;

                                dataCRPush.push(data_push);
                                totalClosed += data_push;
                            }
                        }
                        chartData[i].data = dataCRPush;
                    }
                }
            }

        setTotalDataOngoing(totalOngoing);
        setTotalDataScheduled(totalScheduled);
        setTotalDataClosed(totalClosed);
        setChartYearData(chartYearData);
        setChartData(chartData);
    }

    console.log("CR Monthly 1", chartData)

    return (
        <React.Fragment>
            <Col md={12} style={{height: "520px", marginBottom: "20px"}}>
                <Card style={{height: "100%"}}>
                        <CardHeader className="border-0 align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Monthly CR Trend</h4>
                            {/* <div className="d-flex gap-1">
                                <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("all"); }}>
                                    ALL
                                </button>
                                <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("month"); }}>
                                    1M
                                </button>
                                <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("halfyear"); }}>
                                    6M
                                </button>
                                <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => { onChangeChartPeriod("year"); }}>
                                    1Y
                                </button>
                            </div> */}
                        </CardHeader>

                        <CardHeader className="p-0 border-0 bg-light-subtle">
                            <Row className="g-0 text-center">
                                <Col xs={6} sm={4}>
                                    <div className="p-3 border border-dashed border-start-0">
                                        <h5 className="mb-1"><span className="counter-value" data-target="9851">
                                            <CountUp
                                                start={0}
                                                prefix={""}
                                                suffix={""}
                                                separator={""}
                                                end={totalDataOngoing}
                                                decimals={0}
                                                duration={4}
                                            />
                                        </span></h5>
                                        <p className="text-muted mb-0">Ongoing</p>
                                    </div>
                                </Col>
                                <Col xs={6} sm={4}>
                                    <div className="p-3 border border-dashed border-start-0">
                                        <h5 className="mb-1"><span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={totalDataScheduled}
                                                separator={","}
                                                duration={4}
                                            />
                                        </span></h5>
                                        <p className="text-muted mb-0">Scheduled</p>
                                    </div>
                                </Col>
                                <Col xs={6} sm={4}>
                                    <div className="p-3 border border-dashed border-start-0">
                                        <h5 className="mb-1"><span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={totalDataClosed}
                                                separator={","}
                                                duration={4}
                                            />
                                        </span></h5>
                                        <p className="text-muted mb-0">Closed</p>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-2">
                            <div>
                                <div dir="ltr" className="apex-charts">
                                    <OverviewCharts series={chartData} monthlist={chartYearData} dataColors='["--vz-primary", "--vz-warning", "--vz-success"]' />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
            </Col>
        </React.Fragment>
    );
};

export default LineChart;