import axios from "axios";

const axiosNCRM = axios.create();

let baseUrl = 'https:///twentyplus.id/api'
// let baseUrl = 'http:///20.2.241.36:3001'

if(process.env.REACT_APP_ENVIRONEMT === "LOCALHOST" || process.env.REACT_APP_ENVIRONEMT === "DEVELOPMENT"){
  baseUrl = 'http://128.199.87.163:3001';
}

// content type
axiosNCRM.defaults.headers.post["Content-Type"] = "application/json";

const user = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")) : {};

const setAuthorizationBearer = (token) => {
  // axiosNCRM.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const token_auth = user.data ? user.data.token : null

export const getAuthorizatiBeareronHeader = () => {

  const user_token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data?.token : null;

  return user_token
  
};

// content type
// const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).token : null;

class API_CLIENT_AUTH_BEARER {
  
  get = async(url, token, params) => {

    let response;

    try{

       const response = await axiosNCRM.get(`${baseUrl+url}`, {
        headers: {
          Authorization: "Bearer " + getAuthorizatiBeareronHeader(),
        },
      });

      return response

    }catch(e){
      return e
    }
  }

  getFile = async(url, token, params) => {

    let response;

    try{

       const response = await axiosNCRM.get(`${baseUrl+url}`, {
        responseType: 'blob',
        headers: {
          Authorization: "Bearer " + getAuthorizatiBeareronHeader(),
        },
      });

      return response

    }catch(e){
      return e
    }
  }

  post = async(url, data, token, params) => {

    let response;

    try{

      response = await axiosNCRM.post(`${baseUrl+url}`, data, {
        headers: {
          Authorization: "Bearer " + getAuthorizatiBeareronHeader(),
          "Content-Type" : params ? params["Content-Type"] ? params["Content-Type"] : "application/json" : "application/json"
        },
      });

      return response

    }catch(e){

      return e

    }
    
  }

  patch = async(url, data, token, params) => {

    let response;

    try{

      response = await axiosNCRM.patch(`${baseUrl+url}`, data, {
        headers: {
          Authorization: "Bearer " + getAuthorizatiBeareronHeader(),
          "Content-Type" : params ? params["Content-Type"] ? params["Content-Type"] : "application/json" : "application/json"
        },
      });

      return response

    }catch(e){

      return e

    }
    
  }

  delete = async(url, data, token, params) => {

    let response;

    try{

      response = await axiosNCRM.delete(`${baseUrl+url}`, {
        headers: {
          Authorization: "Bearer " + getAuthorizatiBeareronHeader(),
        },
      });

      return response

    }catch(e){

      return e

    }
    
  }

}

class API_CLIENT_NON_AUTH {
  
  get = (url, params) => {

    try{

      let response;

      response = axiosNCRM.get(`${baseUrl+url}`);

      return response;

    }catch(e){
      return e
    }
    
  }

  post = (url, data, params) => {

    try{
      let response;

      response = axiosNCRM.post(`${baseUrl+url}`, data);

      return response;
    }catch(e){
      return e
    }
    
  }

}

export { API_CLIENT_AUTH_BEARER, setAuthorizationBearer, API_CLIENT_NON_AUTH };