import React from 'react';
import { Container } from 'reactstrap';
import Section from './Section';
import { HEADER_TITLE_NAME } from '../../../config/config-general';
import { useParams } from 'react-router-dom';

import { API_CLIENT_AUTH_BEARER } from '../../../helpers/api-helper';
const apiAuth = new API_CLIENT_AUTH_BEARER();


const ProjectOverview = () => {
    document.title="CR Detail "+HEADER_TITLE_NAME;

    const { id } = useParams();

    const [data, setData] = React.useState({});

    const getData = async() => {
        const res = await apiAuth.get('/getCRMDetail/'+id);
        if(res && res.data){
            setData(res.data.data)
        }
    };

    React.useEffect(() => {
        getData()
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                    
                    <Section data={data}/>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProjectOverview;