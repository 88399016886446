import React, { useState } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button } from 'reactstrap';
import { Page, Text, View, Image, Document, StyleSheet, PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import classnames from 'classnames';

//import images
import slack from '../../../assets/images/brands/slack.png';
import OverviewTab from './OverviewTab';
import DocumentsTab from './DocumentsTab';
import ActivitiesTab from './ActivitiesTab';
import TeamTab from './TeamTab';
import GeneralDataTab from './GeneralDataTab';
import CategorizationTab from './CategorizationTab';
import ClassificationTab from './ClassificationTab';
import AIAccessTab from './AIAccessTab';
import TimelineTab from './TimelineTab';
import TaskTab from './TaskTab';
import StatusTab from './StatusTab';
import { datetimeformatncrm } from '../../../helpers/FunctionalHelper/date-helper';
import AIInformationTab from './AIInformationTab';
import CabMeetingTab from './CabMeetingTab';
import CRFormTask from '../CRCreation/CRFormTask';

const Section = (props) => {

    const {data} = props
    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const [canEdit, setCanEdit] = useState(false);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    // Download PDF - CR Summary
  const PDFDocument = () => {
    const styles = StyleSheet.create({
        rowTitle: {
            display: 'flex',
            flexDirection: 'row',
            borderBottom: "0px"
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
        },
        rowTable: {
            display: 'flex',
            flexDirection: 'row',
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
        },
        rowTableBody: {
            display: 'flex',
            flexDirection: 'row',
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
        },
        co25: {
            padding: "5px", 
            width: "25%", 
            float: "left", 
            border: "1px solid black"
        },
        col30: {
            padding: "5px", 
            width: "30%", 
            float: "left", 
            border: "1px solid black"
        },
        col33: {
            padding: "5px", 
            width: "33%", 
            float: "left", 
        },
        col50: {
            padding: "5px", 
            width: "50%", 
            float: "left", 
        },
        col70: {
            padding: "5px", 
            width: "70%", 
            float: "left", 
            border: "1px solid black"
        },
        col100: {
            padding: "5px", 
            width: "100%", 
            float: "left", 
        },
        pagetitle: {
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            marginBottom: "10px"
        },
        headtitle: {
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "left",
            textDecoration: "underline"
        },
        title: {
            fontSize: "10px",
            fontWeight: "600",
            color: "#495057",
            marginBottom: "5px"
        },
        subtitle: {
            fontSize: "9px",
            color: "#9698a5"
        },
        timeline_head_table_no: {
            padding: "5px",
            width: "5%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_head_table_startdate: {
            padding: "5px",
            width: "20%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_head_table_enddate: {
            padding: "5px",
            width: "20%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_head_table_actdesc: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_head_table_status: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_head_table_impact: {
            padding: "5px",
            width: "15%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_head_table_detailimpact: {
            padding: "5px",
            width: "20%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black"
        },
        timeline_body_table_no: {
            padding: "5px",
            width: "5%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_body_table_startdate: {
            padding: "5px",
            width: "20%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_body_table_enddate: {
            padding: "5px",
            width: "20%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_body_table_actdesc: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_body_table_status: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_body_table_impact: {
            padding: "5px",
            width: "15%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        timeline_body_table_detailimpact: {
            padding: "5px",
            width: "20%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black"
        },
        implementor_head_table_no: {
            padding: "5px",
            width: "5%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_head_table_company: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_head_table_name: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_head_table_phone: {
            padding: "5px",
            width: "15%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_head_table_email: {
            padding: "5px",
            width: "15%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_head_table_netype: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_head_table_networkname: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_head_table_regionne: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_head_table_usertype: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_head_table_userneed: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            color: "#ffffff",
            backgroundColor: "#405189",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black"
        },
        implementor_body_table_no: {
            padding: "5px",
            width: "5%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_body_table_company: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_body_table_name: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_body_table_phone: {
            padding: "5px",
            width: "15%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_body_table_email: {
            padding: "5px",
            width: "15%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_body_table_netype: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_body_table_networkname: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_body_table_regionne: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_body_table_usertype: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black"
        },
        implementor_body_table_userneed: {
            padding: "5px",
            width: "10%",
            float: "left",
            fontSize: "8px",
            fontWeight: "600",
            textAlign: "center",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black"
        },
        padding: {
            padding: "5px" 
        },
        RowBottomBorder: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: "1px solid black"
        },
      });

    return (
        <Document>
            <Page size="A4" style={{padding: "40px"}}>
                <Text style={styles.pagetitle} >CR ID : {data.change_id}</Text>
                <View style={styles.rowTitle}>
                    <View style={styles.col100}>
                        <Text style={styles.headtitle}>Categorization</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Change Location Company :</Text>
                        <Text style={styles.subtitle}>{data.oc_change_location_company}</Text>
                    </View>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Request Category :</Text>
                        <Text style={styles.subtitle}>{data.oc_tier_1}</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Domain Category :</Text>
                        <Text style={styles.subtitle}>{data.oc_domain_category}</Text>
                    </View>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Sub Domain Category :</Text>
                        <Text style={styles.subtitle}>{data.oc_tier_2}</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Activity List :</Text>
                        <Text style={styles.subtitle}>{data.activity_list}</Text>
                    </View>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Change Category :</Text>
                        <Text style={styles.subtitle}>{data.change_category}</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Impact :</Text>
                        <Text style={styles.subtitle}>{data.impact}</Text>
                    </View>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Risk :</Text>
                        <Text style={styles.subtitle}>{data.risk}</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Priority :</Text>
                        <Text style={styles.subtitle}>{data.priority}</Text>
                    </View>
                    <View style={styles.col50}>
                    </View>
                </View>
                <View style={styles.row}>
                <View style={styles.col50}>
                        <Text style={styles.title}>Description of Change :</Text>
                        <Text style={styles.subtitle}>{data.description_of_change}</Text>
                    </View>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Reason for Change :</Text>
                        <Text style={styles.subtitle}>{data.reason_for_change}</Text>
                    </View>
                </View>
                <View style={styles.rowTitle}>
                    <View style={styles.col100}>
                        <Text style={styles.headtitle}>Information</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Region :</Text>
                        {data.region && data.region.map(region =>
                            <Text style={styles.subtitle}>{region}</Text>
                        )}
                    </View>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Responsible Approval :</Text>
                        {data.responsible_approval && data.responsible_approval.map(respons =>
                            <Text style={styles.subtitle}>{respons}</Text>
                        )}
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>NE ID Change Target :</Text>
                        <Text style={styles.subtitle}>{data.ne_id_change_target}</Text>
                    </View>
                    <View style={styles.col50}>
                        <Text style={styles.title}>NE Name Change Target :</Text>
                        <Text style={styles.subtitle}>{data.ne_name_change_target}</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Detailed Location :</Text>
                        <Text style={styles.subtitle}>{data.detailed_location}</Text>
                    </View>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Vendor Name :</Text>
                        {data.vendor_name && data.vendor_name.map(vendor =>
                            <Text style={styles.subtitle}>{vendor}</Text>
                        )}
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Impact to NE :</Text>
                        <Text style={styles.subtitle}>{data.impact_to_ne}</Text>
                    </View>
                    <View style={styles.col50}>
                    </View>
                </View>
                {data.impact_to_ne === "Yes" &&
                <React.Fragment>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>NE Name (Impacted) List :</Text>
                        <Text style={styles.subtitle}>{data.ne_name_impacted_list}</Text>
                    </View>
                    <View style={styles.col50}>
                        <Text style={styles.title}>NE Impact Description :</Text>
                        <Text style={styles.subtitle}>{data.ne_impact_description}</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>NE Outage Duration (min) :</Text>
                        <Text style={styles.subtitle}>{data.ne_outage_duration_min}</Text>
                    </View>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Affected Service Description :</Text>
                        <Text style={styles.subtitle}>{data.affected_services_description}</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.col50}>
                        <Text style={styles.title}>Service Outage Duration (min) :</Text>
                        <Text style={styles.subtitle}>{data.service_outage_duration_min}</Text>
                    </View>
                    <View style={styles.col50}>
                    </View>
                </View>
                </React.Fragment>
                }
                <View style={styles.rowTitle}>
                    <View style={styles.col100}>
                        <Text style={styles.headtitle}>Timeline</Text>
                    </View>
                </View>
                <View style={{padding: "5px"}}>
                    <View style={styles.rowTable}>
                        <Text style={styles.timeline_head_table_no}>No</Text>
                        <Text style={styles.timeline_head_table_startdate}>Plan Start</Text>
                        <Text style={styles.timeline_head_table_enddate}>Plan End</Text>
                        <Text style={styles.timeline_head_table_actdesc}>Act Desc</Text>
                        <Text style={styles.timeline_head_table_status}>Status</Text>
                        <Text style={styles.timeline_head_table_impact}># Impacted NE</Text>
                        <Text style={styles.timeline_head_table_detailimpact}>Detail of Impacted NE</Text>
                    </View>
                    {data.timelines && data.timelines.map((time, idx) =>
                        <View style={styles.rowTableBody}>
                            <Text style={styles.timeline_body_table_no}>{idx+1}</Text>
                            <Text style={styles.timeline_body_table_startdate}>{datetimeformatncrm(time.plan_start_time)}</Text>
                            <Text style={styles.timeline_body_table_enddate}>{datetimeformatncrm(time.plan_end_time)}</Text>
                            <Text style={styles.timeline_body_table_actdesc}>{time.activity_description}</Text>
                            <Text style={styles.timeline_body_table_status}>{time.plan_status}</Text>
                            <Text style={styles.timeline_body_table_impact}>{time.number_of_impacted_ne}</Text>
                            <Text style={styles.timeline_body_table_detailimpact}>{time.detailed_of_impacted_ne}</Text>
                        </View>
                    )}
                    <View style={styles.RowBottomBorder}></View>
                </View>
                <View style={styles.rowTitle}>
                    <View style={styles.col100}>
                        <Text style={styles.headtitle}>User Access Management (Implementor)</Text>
                    </View>
                </View>
                <View style={{padding: "5px"}}>
                    <View style={styles.rowTable}>
                        <Text style={styles.implementor_head_table_no}>No</Text>
                        <Text style={styles.implementor_head_table_company}>Company</Text>
                        <Text style={styles.implementor_head_table_name}>Name</Text>
                        <Text style={styles.implementor_head_table_phone}>Phone</Text>
                        <Text style={styles.implementor_head_table_email}>Email</Text>
                        <Text style={styles.implementor_head_table_netype}>Ne Type</Text>
                        <Text style={styles.implementor_head_table_networkname}>Network Name Element</Text>
                        <Text style={styles.implementor_head_table_regionne}>Region Ne</Text>
                        <Text style={styles.implementor_head_table_usertype}>User Type</Text>
                        <Text style={styles.implementor_head_table_userneed}>User Needed</Text>
                    </View>
                    {data.ai_user_accesses && data.ai_user_accesses.map((user, idx) =>
                        <View style={styles.rowTableBody}>
                            <Text style={styles.implementor_body_table_no}>{idx+1}</Text>
                            <Text style={styles.implementor_body_table_company}>{user.vendor_name}</Text>
                            <Text style={styles.implementor_body_table_name}>{user.executor_pic_name}</Text>
                            <Text style={styles.implementor_body_table_phone}>{user.executor_pic_phone}</Text>
                            <Text style={styles.implementor_body_table_email}>{user.executor_pic_email}</Text>
                            <Text style={styles.implementor_body_table_netype}>{user.ne_type}</Text>
                            <Text style={styles.implementor_body_table_networkname}>{user.network_element_name}</Text>
                            <Text style={styles.implementor_body_table_regionne}>{user.region_ne}</Text>
                            <Text style={styles.implementor_body_table_usertype}>{user.user_type}</Text>
                            <Text style={styles.implementor_body_table_userneed}>{user.user_needed}</Text>
                        </View>
                    )}
                    <View style={styles.RowBottomBorder}></View>
                </View>
            </Page>
        </Document>
    );
  }

  const downloadReportDocument = async (e, blob) => {

      e.preventDefault()
      e.persist();
      saveAs(blob, `Report.pdf`)
  }

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4">
                        <div className="bg-success-subtle">
                            <CardBody className="pt-4 pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            {/* <div className="col-md"> */}
                                                <Col md={12} style={{position: "absolute", margin: "0px", top: "0", zIndex: "99999"}}>
                                                    <BlobProvider document={PDFDocument()}>
                                                        {({ blob, url, loading, error }) => {
                                                            return (
                                                                <Button
                                                                    color="primary"
                                                                    size="md"
                                                                    onClick={(e) => downloadReportDocument(e, blob)}
                                                                    style={{maxWidth: "130px", float: "right"}}
                                                                >
                                                                    <i className='ri-save-line'></i><span> Save as PDF</span>
                                                                </Button>
                                                            )
                                                        }}
                                                    </BlobProvider>
                                                </Col>
                                                {/* <Col md={8}> */}
                                                    <Col md={12}>
                                                    <h4 className="fw-bold">CR ID : {data.change_id}</h4>
                                                    </Col>

                                                    <div className="hstack gap-3 flex-wrap">
                                                      {/* <Col md={3} style={{borderRight: "2px solid #000"}}> */}
                                                      <h6>Status : <p className="fw-medium mb-0 text-black">{data.current_status ? data.current_status : "-"}</p></h6>
                                                      {/* </Col> */}
                                                      <div className="vr"></div>
                                                      {/* <Col md={4} style={{borderRight: "2px solid #000"}}> */}
                                                      <h6>Submit Date : <p className="fw-medium mb-0 text-black">{data.createdAt ? datetimeformatncrm(data.createdAt) : "-"}</p></h6>
                                                      {/* </Col> */}
                                                      <div className="vr"></div>
                                                      {/* <Col md={3}> */}
                                                      <h6>Change Requestor : <p className="fw-medium mb-0 text-black">{data.created_by_name ? data.created_by_name : "-"}</p></h6>
                                                      {/* </Col> */}
                                                    </div>

                                                    <div className="hstack gap-3 flex-wrap mt-2">
                                                        {/* <Col md={6}> */}
                                                        <h6>Summary : <p className="fw-medium mb-0 text-black">{data.summary ? data.summary : "-"}</p></h6>
                                                        {/* </Col> */}
                                                        {/* <Col md={1} style={{borderRight: "2px solid #000"}}> */}
                                                            {/* <p> </p> */}
                                                        {/* </Col> */}
                                                        <div className="vr"></div>
                                                        {/* <Col md={3}> */}
                                                        <h6 className="badge rounded-pill bg-primary fs-12">{data.change_category}</h6>
                                                        {/* </Col> */}
                                                    </div>
                                                {/* </Col> */}
                                            {/* </div> */}
                                        </Row>
                                    </div>
                                </Row>

                                <Nav pills className="cr-detail-view nav-justified border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-semibold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' }, "fw-semibold")}
                                            onClick={() => { toggleTab('2'); }}
                                            href="#">
                                            Categorization
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' }, "fw-semibold")}
                                            onClick={() => { toggleTab('3'); }}
                                            href="#">
                                            Classification
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '4' }, "fw-semibold")}
                                            onClick={() => { toggleTab('4'); }}
                                            href="#">
                                            Information
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '5' }, "fw-semibold")}
                                            onClick={() => { toggleTab('5'); }}
                                            href="#">
                                            Timeline
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '8' }, "fw-semibold")}
                                            onClick={() => { toggleTab('8'); }}
                                            href="#">
                                            User Access Management
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '10' }, "fw-semibold")}
                                            onClick={() => { toggleTab('10'); }}
                                            href="#">
                                            CAB Meeting
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '9' }, "fw-semibold")}
                                            onClick={() => { toggleTab('9'); }}
                                            href="#">
                                            Status
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '7' }, "fw-semibold")}
                                            onClick={() => { toggleTab('7'); }}
                                            href="#">
                                            Task
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                        <Row>
                            <Col xl={12} lg={12}>
                                <Card>
                                    <CardBody>
                                        <GeneralDataTab data={data} onEdit={canEdit}/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col xl={12} lg={12}>
                                <Card>
                                    <CardBody>
                                        <CategorizationTab data={data} onEdit={canEdit}/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3">
                        <ClassificationTab data={data} onEdit={canEdit} />
                    </TabPane>
                    
                    <TabPane tabId="4">
                        <AIInformationTab data={data} onEdit={canEdit} />
                    </TabPane>
                    <TabPane tabId="5">
                        <TimelineTab data={data} onEdit={canEdit}/>
                    </TabPane>
                    <TabPane tabId="7">
                    <Row>
                        <Col xl={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <CRFormTask data={data} dataForm={data} onEdit={canEdit}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    </TabPane>
                    <TabPane tabId="8">
                        <AIAccessTab data={data} onEdit={canEdit} />
                    </TabPane>
                    <TabPane tabId="9">
                        <StatusTab data={data} onEdit={canEdit}/>
                    </TabPane>
                    <TabPane tabId="10">
                    <Row>
                        <Col xl={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <CabMeetingTab data={data} onEdit={canEdit}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    </TabPane>
                    {/* <TabPane tabId="8">
                        <TeamTab />
                    </TabPane> */}
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;