import React from 'react';

import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import { dateformatncrm } from '../../../helpers/FunctionalHelper/date-helper';

const CabMeetingTab = (props) => {

    const {data} = props;

    const [dataForm, setDataForm] = React.useState({});
    const [canEdit, setCanEdit] = React.useState(false);

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        setDataForm(dataFormInput)
    }

    let LIST_CAB_FORM_PARENT = [];
    if(data.cab_forms){
        LIST_CAB_FORM_PARENT = data.cab_forms.filter(item => item.no === item.parent_no);
    }

    return (
        <React.Fragment>
            <Row>
                <Col md={12} className='mt-3 mb-2'>
                    <h5>CAB Meeting</h5>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="table-responsive table-card">
                        <table className="table align-middle table-striped-columns mb-3">
                            <thead className="table-light">
                                <tr>
                                    <th scope='col'>No</th>
                                    <th scope="col">Question</th>
                                    <th scope="col" style={{width: "100px"}}>Yes / No</th>
                                    <th scope="col" style={{width: "350px"}}>Remark</th>
                                </tr>
                            </thead>
                            <tbody>
                                {LIST_CAB_FORM_PARENT.map((form, idx) =>
                                <React.Fragment>
                                    <tr key={idx}>
                                        <td>{form.parent_no}</td>
                                        <td>{form.question}</td>
                                        <td>
                                            {form.no !== 10 ? form.yes_or_no : null}
                                        </td>
                                        <td>
                                            {form.remarks}
                                        </td>
                                    </tr>
                                    {data.cab_forms.filter(item => item.parent_no === form.parent_no && item.no !== form.parent_no).map((form_child, idx_child) =>
                                        <React.Fragment>
                                            <tr key={idx+'#'+idx_child}>
                                                <td></td>
                                                <td>{form_child.question}</td>
                                                <td>
                                                    {form.no !== 10 ? form_child.yes_or_no : null}
                                                </td>
                                                <td>
                                                    {form_child.remarks}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CabMeetingTab;