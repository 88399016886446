import React from 'react'

import { Card, CardHeader, CardBody, Col, Container, Input, Label, Row, Table } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import PaginationTable from '../../Components/TableComponent/PaginationTable';
import { APIClient } from '../../helpers/api_helper';
import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const api = new APIClient();
const apiBearer = new API_CLIENT_AUTH_BEARER();
  
export default function TestPageList() {

  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [totalData, setTotalData] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);

  const onChangePage = (e) => {
    setPage(e);
  }

  const getData = async() => {
    const api_page = ((page-1)*10).toString();
    const testApi = await apiBearer.get('https://dummyjson.com/products?limit='+perPage+'&skip='+api_page);
    setTotalData(testApi.total);
    setData(testApi.products);
  }

  React.useEffect(() => {
    getData();
  }, [page]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Test Page List" pageTitle="TestPageList" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Test Page List</h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="m-3">
                      <div className="table-responsive table-card">
                          <table className="table align-middle table-nowrap table-striped-columns mb-0">
                              <thead className="table-light">
                                  <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Brand</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Category</th>
                                    <th scope="col" style={{width: "150px"}}>Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                {data.map(d => 
                                  <tr key={d.id}>
                                    <td>{d.id}</td>
                                    <td>{d.title}</td>
                                    <td>{d.brand}</td>
                                    <td>{d.price}</td>
                                    <td>{d.category}</td>
                                    <td>
                                      <button type="button" className="btn btn-sm btn-light">Details</button>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                          </table>
                        </div>
                    </Col>
                  </Row>
                  <PaginationTable 
                    totalData={totalData}
                    perPage={perPage}
                    currentPage={page}
                    onChange={onChangePage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
