import React from 'react'

import { Card, CardHeader, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import PaginationTable from '../../Components/TableComponent/PaginationTable';
import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { Link } from 'react-router-dom';

// const api = new APIClient();
const apiAuth = new API_CLIENT_AUTH_BEARER();
  
export default function UserManagement() {

  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [totalData, setTotalData] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);

  const onChangePage = (e) => {
    setPage(e);
  }

  const getData = async() => {
    const res = await apiAuth.get('/getUser?srt=createdAt:-1&lmt='+perPage+'&pg='+page);
    if(res && res.data){
        setData(res.data.data)
        setTotalData(res.data.totalResults)
    }
  }

  React.useEffect(() => {
    getData();
  }, [page]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="User List" pageTitle="User List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">User List</h4>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive">
                        <table className="table align-middle table-nowrap table-striped-columns mb-0" style={{border: "2px solid #dedede"}}>
                            <thead className="bg-primary text-white">
                                <tr>
                                <th scope="col"></th>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone Number</th>
                                <th scope="col" style={{width: "150px"}}>Title</th>
                                <th scope="col" style={{width: "150px"}}>Role</th>
                                </tr>
                            </thead>
                            <tbody>
                            {data.map((d, idx) => 
                                <tr key={idx}>
                                <td>
                                  <Link to={'/user-detail/'+d.email}>
                                    <button type="button" className="btn btn-sm btn-info">
                                      Details
                                    </button>
                                  </Link>
                                </td>
                                <td>{d.first_name}</td>
                                <td>{d.last_name}</td>
                                <td>{d.email}</td>
                                <td>{d.phone_number}</td>
                                <td>{d.title}</td>
                                <td>{d.role?.join(', ')}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                  <PaginationTable 
                    totalData={totalData}
                    perPage={perPage}
                    currentPage={page}
                    onChange={onChangePage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
