import React from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { ecomWidgets } from "../../common/data";
import { dataWidget } from './data-common';

import { datetimeformatncrm, dateformatncrm, datemonthformatncrm, dateyearsmonthdayformatncrm } from "../../helpers/FunctionalHelper/date-helper";

import { tileBoxs1, tileBoxs2, tileBoxs3, tileBoxes4, tileBoxes5 } from "../../common/data/index";

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const PendingApprovalWidgets = () => {

    const isUserExpertDomain = (user_data) => {
        if(user_data.title && user_data.title.includes("Expert Domain")){
            return true
        }else{
            return false
        }
    }

    const isUserChangeManager = (user_data) => {
        if(user_data.title && user_data.title.includes("Manager") && user_data.title.includes("Change and Release")){
            return true
        }else{
            return false
        }
    }

    const [dataList, setDataList] = React.useState([]);
    const [totalData, setTotalData] = React.useState(0);
    const [totalPendingApproval, setTotalPendingApproval] = React.useState(0);
    const [totalCompleted, setTotalCompleted] = React.useState(0);
    const [totalClosed, setTotalClosed] = React.useState(0);

    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    const current_date = new Date();
    const next_day_date = new Date(new Date().setDate(new Date().getDate()));
    const next_month_date = new Date(next_day_date.setMonth(next_day_date.getMonth() + 1));
    const previous_day_date = new Date(new Date().setDate(new Date().getDate() + 1));
    const previous_month_date = new Date(previous_day_date.setMonth(previous_day_date.getMonth() - 1));

    const getData = async() => {
        let filter_array = [];
        let role_array = [];
        let where = '{}';

        // if(userData.role && userData.role.find(role => role.includes("Line Manager")) !== undefined){
        //     role_array.push('{"current_status" : "Draft"},{"current_status" : "Completed"},{"current_status" : "Closed"}');
        // }
        if(userData.role && userData.role.find(role => role.includes("Requestor")) !== undefined){
            role_array.push('{"created_by" : "'+userData.email+'"},{"lm_email" : "'+userData.email+'"}');
        }
        if(userData.role && userData.role.find(role => role.includes("Change Coordinator")) !== undefined){
            role_array.push('{"change_coordinator_email" : "'+userData.email+'"},{"lm_email" : "'+userData.email+'"}');
        }
        if((userData.role && userData.role.find(role => role.includes("Change Manager")) !== undefined) || (userData.title && userData.title.includes("Change and Release") === true)){
            role_array.push('{"change_category" : "Normal Minor"},{"change_category" : "Normal Major"},{"change_category" : "Normal Significan"},{"lm_email" : "'+userData.email+'"}');
        }
        if(userData.title && (userData.title.includes("Expert Domain") === true || userData.title.includes("Domain Expert") === true)){
            role_array.push('{"change_category" : "Normal Major"},{"change_category" : "Normal Significan"},{"lm_email" : "'+userData.email+'"}');
        }
        filter_array.push('"$or" : ['+role_array.join(',')+']');

        if(filter_array){
            where = '{' + filter_array.join(',') + '}';
        }

        
        const res = await apiAuth.get('/getCRM?q='+where+'&noPg=1');

        if(res && res.data){
            setDataList(res.data.data)
            setTotalData(res.data.totalResults)
        }
    }
    
    React.useEffect(() => {
        getData();
    },[]);

    const pendingApproval = async() => {
        let data_total = totalPendingApproval;
        let data_draft = 0;
        let data_rfa = 0;
        let data_rfc = 0;
        let data_sfa = 0;
        let data_scheduled = 0;
        let data_completed = 0;
        let data_closed = 0;

        if(dataList !== undefined){
            if(userData.role && userData.role.find(role => role.includes("Line Manager")) !== undefined){
                data_draft =+ (dataList.filter(dt => dt.current_status === "Draft")).length
            }else{
                data_draft =+ (dataList.filter(dt => dt.current_status === "Draft" && dt.lm_email === userData.email )).length
            }
            if(userData.role && userData.role.find(role => role.includes("Change Coordinator")) !== undefined){
                data_rfa =+ (dataList.filter(dt => dt.current_status === "Request for Authorization" && dt.change_coordinator_email === userData.email )).length
                data_rfc =+ (dataList.filter(dt => dt.current_status === "Request for Change" && dt.change_coordinator_email === userData.email )).length
                data_scheduled =+ (dataList.filter(dt => dt.current_status === "Scheduled" && dt.change_coordinator_email === userData.email )).length
            }
            if((userData.role && userData.role.find(role => role.includes("Change Manager")) !== undefined) || (userData.title && userData.title.includes("Change and Release") === true)){
                data_sfa =+ (dataList.filter(dt => dt.current_status === "Scheduled for Approval" && (dt.change_category === "Normal Minor" || dt.change_category === "Normal Major" || dt.change_category === "Normal Significant" ))).length
            }
            if(userData.title && (userData.title.includes("Expert Domain") === true || userData.title.includes("Domain Expert") === true)){
                data_sfa =+ (dataList.filter(dt => dt.current_status === "Scheduled for Approval" && (dt.change_category === "Normal Major" || dt.change_category === "Normal Significant" ))).length
            }
            if(isUserExpertDomain(userData) ){
                let data_temp = dataList.filter(dt => dt.current_status === "Scheduled for Approval")
                data_temp = data_temp.filter(dt => dt.sfa_approvals && (dt.sfa_approvals.find(sfa => sfa.role === "Expert Domain") !== undefined || dt.sfa_approvals.find(sfa => sfa.role === "Domain Expert") !== undefined));
                data_sfa = data_temp.length
            }
        }

        data_total = data_draft + data_rfa + data_rfc + data_sfa + data_scheduled;

        data_completed =+ (dataList.filter(dt => dt.current_status === "Completed")).length

        data_closed =+ (dataList.filter(dt => dt.current_status === "Closed")).length


        setTotalPendingApproval(data_total);
        setTotalCompleted(data_completed);
        setTotalClosed(data_closed);
    } 
    
    React.useEffect(() => {
        pendingApproval();
    },[dataList]);

    const draftCount = dataList ? (dataList.filter(dt => dt.current_status === "Draft")).length : 0;
    const rfaCount = dataList ? (dataList.filter(dt => dt.current_status === "Request for Authorization")).length : 0;
    const rfcCount = dataList ? (dataList.filter(dt => dt.current_status === "Request for Change")).length : 0;
    const sfaCount = dataList ? (dataList.filter(dt => dt.current_status === "Scheduled for Approval")).length : 0;
    const scheduledCount = dataList ? (dataList.filter(dt => dt.current_status === "Scheduled")).length : 0;
    const CompletedCount = dataList ? (dataList.filter(dt => dt.current_status === "Completed")).length : 0;
    const ClosedCount = dataList ? (dataList.filter(dt => dt.current_status === "Closed")).length : 0;

    const total = draftCount+rfaCount+rfcCount+sfaCount+scheduledCount+CompletedCount+ClosedCount;

    return (
        <React.Fragment>
            <Row style={{margin: "0"}}>
                <Col xl={12}>
                    <Card className="crm-widget">
                        <CardBody className="p-0">
                            <Row className="row-cols-md-3 row-cols-1" style={{background: "linear-gradient(90deg, rgba(64,81,137,1) 0%, rgba(53,119,241,1) 100%)"}}>

                                {/* {(tileBoxs2 || []).map((item, key) => ( */}
                                    <Col className={"col-lg pending-widget-dashboard--dashboard-page"} key={1}>
                                        <div className="mt-2 mt-md-0 py-2 px-2 text-center" style={{padding: "0px"}}>
                                            <i className={`text-white align-middle mdi mdi-bullhorn-outline`} style={{fontSize: "40px"}}></i>
                                            <h5 className="text-white text-uppercase fs-13">Your Status</h5>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    {/* <i className={"display-6 text-white " + item.icon}></i> */}
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    {/* <h1 className="mb-0 text-white"><span className="counter-value">
                                                        <CountUp
                                                            start={0}
                                                            prefix={""}
                                                            suffix={""}
                                                            separator={""}
                                                            end={totalPendingApproval}
                                                            decimals={0}
                                                            duration={4}
                                                        /></span></h1> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className={"col-lg"} key={1}>
                                    <Link to={"/cr-list/pending/"+userData.email}>
                                        <div className="mt-3 mt-md-0 py-3 px-3">
                                            <h5 className="text-white text-uppercase fs-13" style={{minHeight: "35px"}}>Pending Approval</h5>
                                            {/* <i className={`fs-18 float-end align-middle text-success ri-file-edit-fill`}></i> */}
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    {/* <i className={"display-6 text-white " + item.icon}></i> */}
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h1 className="mb-0 text-white"><span className="counter-value">
                                                        <CountUp
                                                            start={0}
                                                            prefix={""}
                                                            suffix={""}
                                                            separator={""}
                                                            end={totalPendingApproval}
                                                            decimals={0}
                                                            duration={4}
                                                        /></span></h1>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    </Col>
                                    <Col className={"col-lg"} key={2}>
                                    <Link to="/cr-list/Closed/">
                                        <div className="mt-3 mt-md-0 py-3 px-3">
                                            <h5 className="text-white text-uppercase fs-13" style={{minHeight: "35px"}}>Closed</h5>
                                            {/* <i className={`fs-18 float-end align-middle text-info ri-task-fill`}></i> */}
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    {/* <i className={"display-6 text-white " + item.icon}></i> */}
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h1 className="mb-0 text-white"><span className="counter-value">
                                                        <CountUp
                                                            start={0}
                                                            prefix={""}
                                                            suffix={""}
                                                            separator={""}
                                                            end={totalClosed}
                                                            decimals={0}
                                                            duration={4}
                                                        /></span></h1>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    </Col>
                                    <Col className={"col-lg"} key={1}>
                                        <div className="mt-3 mt-md-0 py-3 px-3">
                                            <h5 className="text-white text-uppercase fs-13" style={{minHeight: "35px"}}>Implementation In Progress</h5>
                                            {/* <i className={`fs-18 float-end align-middle text-warning ri-clipboard-line`}></i> */}
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    {/* <i className={"display-6 text-white " + item.icon}></i> */}
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h1 className="mb-0 text-white"><span className="counter-value">
                                                        <CountUp
                                                            start={0}
                                                            prefix={""}
                                                            suffix={""}
                                                            separator={""}
                                                            end={0}
                                                            decimals={0}
                                                            duration={4}
                                                        /></span></h1>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className={"col-lg border-end"} key={2}>
                                        <div className="mt-3 mt-md-0 py-3 px-3">
                                            <h5 className="text-white text-uppercase fs-13" style={{minHeight: "35px"}}>Implementation Done</h5>
                                            {/* <i className={`fs-18 float-end align-middle text-primary ri-survey-fill`}></i> */}
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    {/* <i className={"display-6 text-white " + item.icon}></i> */}
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h1 className="mb-0 text-white"><span className="counter-value">
                                                        <CountUp
                                                            start={0}
                                                            prefix={""}
                                                            suffix={""}
                                                            separator={""}
                                                            end={0}
                                                            decimals={0}
                                                            duration={4}
                                                        /></span></h1>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* ))} */}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* <Row>
                 <Col xl={3} md={6}>
                    <Link to={"/cr-list/pending/"+userData.email}>
                        <Card className="card-animate">
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <h6 className="fs-16 text-uppercase fw-medium text-white  mb-0" style={{height: "40px"}}>Pending <br/>Approval</h6>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h6 className={"fs-16 mb-0 text-success"} style={{height: "35px"}}>
                                            { totalPendingApproval !== 0 ? [((totalPendingApproval/total)*100).toFixed(0)] : [(0).toFixed(0)] } %
                                        </h6>
                                    </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-1">
                                    <div>
                                        <h1 className="fw-semibold ff-secondary mb-0"><span className="counter-value" data-target="559.25">
                                            <CountUp
                                                start={0}
                                                prefix={""}
                                                suffix={""}
                                                separator={""}
                                                end={totalPendingApproval}
                                                decimals={0}
                                                duration={4}
                                            />
                                        </span></h1>
                                    </div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded fs-3 bg-success-subtle"}>
                                            <i className={`text-success ri-file-edit-fill`}></i>
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Link>
                </Col>
                <Col xl={3} md={6}>
                    <Link to="/cr-list/Closed/">
                        <Card className="card-animate">
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <h6 className="fs-16 text-uppercase fw-medium text-white  mb-0" style={{height: "40px"}}>Closed</h6>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h6 className={"fs-16 mb-0 text-info"} style={{height: "35px"}}>
                                            { totalClosed !== 0 ? [((totalClosed/total)*100).toFixed(0)] : [(0).toFixed(0)] } %
                                        </h6>
                                    </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-1">
                                    <div>
                                        <h1 className="fw-semibold ff-secondary mb-0"><span className="counter-value" data-target="559.25">
                                            <CountUp
                                                start={0}
                                                prefix={""}
                                                suffix={""}
                                                separator={""}
                                                end={totalClosed}
                                                decimals={0}
                                                duration={4}
                                            />
                                        </span></h1>
                                    </div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded fs-3 bg-info-subtle"}>
                                            <i className={`text-info ri-task-fill`}></i>
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Link>
                </Col>
                <Col xl={3} md={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <h6 className="fs-16 text-uppercase fw-medium text-white  mb-0" style={{height: "40px"}}>Implementation <br/>In Progress</h6>
                                </div>
                                <div className="flex-shrink-0">
                                    <h6 className={"fs-16 mb-0 text-warning"} style={{height: "35px"}}>
                                        { [(0).toFixed(0)] } %
                                    </h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-1">
                                <div>
                                    <h1 className="fw-semibold ff-secondary mb-0"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={""}
                                            end={0}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h1>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={"avatar-title rounded fs-3 bg-warning-subtle"}>
                                        <i className={`text-warning bx bx-user-circle`}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <h6 className="fs-16 text-uppercase fw-medium text-white  mb-0" style={{height: "40px"}}>Implementation <br/>Done</h6>
                                </div>
                                <div className="flex-shrink-0">
                                    <h6 className={"fs-16 mb-0 text-primary"} style={{height: "35px"}}>
                                        { [(0).toFixed(0)] } %
                                    </h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-1">
                                <div>
                                    <h1 className="fw-semibold ff-secondary mb-0"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={""}
                                            suffix={""}
                                            separator={""}
                                            end={0}
                                            decimals={0}
                                            duration={4}
                                        />
                                    </span></h1>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={"avatar-title rounded fs-3 bg-primary-subtle"}>
                                        <i className={`text-primary bx bx-shopping-bag`}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row> */}
        </React.Fragment>
    );
};

export default PendingApprovalWidgets;