import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { notificationFlagAdd, loadingFalse, notificationFlagAssign } from '../../redux/reducers/notificationFlagReducer';

const DATA_DUMMY_CR_FORM_CREATION = {
    "email": "test6@test.com",
    "first_name": "Test",
    "middle_initial": "Hahihu",
    "last_name": "6",
    "phone_number": "0808080",
    "organization_long": "Org",
    "department_long": "Dept",
    "support_organization": "Supp",
    "support_group_name": "Supp",
    "title": "Manager Core, Transport and Datacenter Operations Sumbagut",
    "current_status": "Draft",
    "version": "1",
    "oc_change_location_company": "PT Telekomunikasi Selular",
    "oc_tier_1": "AI",
    "oc_domain_category": "CORE & INTERCONNECT",
    "oc_tier_2": "DSP",
    "oc_tier_3": "Create/Modify/Delete Configuration",
    "optimization_request": "on",
    "coordinator_group": "Test Coordinator Group",
    "change_coordinator": "Change Coordinatro",
    "target_date": "2023-10-21",
    "priority": "1",
    "change_type": "Change Type 1",
    "activity_list": "Software Update/patch with impact",
    "change_category": "Normal Major",
    "impact": "2-Significant/Large",
    "risk": "4-Low",
    "release_required": "No",
    "description_of_change": "Need Patch for performance",
    "reason_for_change": "Need Patch for performance",
    "region": [
      "R1 Sumbagut"
    ],
    "responsible_approval": [
      "Manager Network Operations and Productivity Aceh"
    ],
    "ne_id_primary": "Ne ID",
    "ne_name_primary": "NE Name",
    "detailed_location": "HQ",
    "impact_to_ne": "No",
    "ne_name_impacted_list": "No",
    "ne_impact_description": "No",
    "ne_outage_duration_min": "0",
    "affected_services" : ["sms", "mms"],
    "affected_services_description": "Voice mill kresek kresek",
    "service_outage_duration_min": "10",
    "stability_test_required": "Yes",
    "stability_test_duration": "10",
    "vendor_name": [
      "Nokia",
      "Others"
    ],
    "timeline": [
      {
        "start_time": "23:00",
        "end_time": "04:00",
        "start_date": "2023-10-06",
        "end_date": "2023-10-07",
        "activity_description": "Activity Day 1",
        "number_of_impacted_ne": "50",
        "detailed_of_impacted_ne": "Site Lemot",
        "plan_status": "Purposed",
        "plan_start_time": "2023-10-06T16:00:00.000Z",
        "plan_end_time": "2023-10-06T21:00:00.000Z"
      }
    ],
    "summary": "Software Update/patch with impact # Normal Significant # 2-Significant/Large",
    "ai_user_access": [
      {
        "executor_pic_name": "Ahmad",
        "executor_pic_phone": "Test",
        "executor_pic_email": "ahmad@test.com",
        "ne_type": "Datacom",
        "network_element_name": "Network Name Element",
        "vendor_name": "Nokia",
        "region_ne": "Regional 04",
        "user_type": "User TCS (TACAS/Radius)",
        "user_needed": "ACS"
      }
    ]
  }

const apiAuth = new API_CLIENT_AUTH_BEARER();

const dateNow = new Date();

export const createDataCr = createAsyncThunk(
    "/createCRM",
    async (formData, thunkApi) => {
        const resPromise = await createDataCrAsync({form : formData.formData, crAttachment : formData.listCrAttachment}, thunkApi);
        return resPromise;
    }
);

export const createDataCrAsync = async (form, thunkApi) => {
    //formdata object
    let formData = new FormData();

    let dataCr = {...form.form}

    // let timeline_end = dataCr.timeline.find
    // let timeline_start = 

    // let dataCr = {...DATA_DUMMY_CR_FORM_CREATION}

    dataCr["attachment"] = form.crAttachment ? form.crAttachment.filter(item => item.attachment_name && item.file).map(item => ({attachment_name : item.attachment_name})) : [];

    await formData.append('data', JSON.stringify(dataCr));
    for(let i = 0 ; i < form.crAttachment.length; i++){
        const field = 'attachment_'+i;
        if(form.crAttachment[i].attachment_name && form.crAttachment[i].file){
          
          await formData.append(field, form.crAttachment[i].file);
        }
    }
    

    let notification = {}

    const res = await apiAuth.post('/createCRM', formData, null, {"Content-Type" : "multipart/form-data"});
    
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_message : "CR Created successfully!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}