import React from 'react';

import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Card, CardHeader, CardBody, Col, Container, Spinner, Button, Form, Input, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter, FormText } from 'reactstrap';
import Select from 'react-select';

import { useDispatch } from 'react-redux';
import { loadingStatusSet } from '../../redux/reducers/notificationFlagReducer';

import { updateDataUser } from '../../middleware/user-management/user-update-thunk';
import { unwrapResult } from '@reduxjs/toolkit';

import { HEADER_TITLE_NAME } from '../../config/config-general';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

import { USER_TITLE } from '../../config/data/USER_TITLE';

import { DEFAULT_ROLE_ROLE } from '../../config/data/DEFAULT_ROLE_ROLE';

import { useParams } from 'react-router-dom';
import { deleteDataUser } from '../../middleware/user-management/user-delete-thunk';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const UserDetail = () => {

  document.title="User Detail "+HEADER_TITLE_NAME;

  const { id } = useParams();
  // console.log("Param", useParams());

  const dispatch = useDispatch();

  const USER_TITLE_OPTION = [...USER_TITLE.map(ut => ({ value: ut.TITLE, label: ut.TITLE, area_division : ut["AREA DIVISION"], area : ut["Area"], regional : ut["Regional"] }))]
  const ROLE_OPTIONS = [...DEFAULT_ROLE_ROLE, ...USER_TITLE_OPTION]

  const [dataForm, setDataForm] = React.useState({});

  const [dataUserOption, setDataUserOption] = React.useState([]);

  const [disabledBtn, setDisabledBtn] = React.useState(true);

  const [loadingStatus, setLoadingStatus] = React.useState(false);

  const [modalDeactivate, setModalDeactivate] = React.useState(false);
//   const [roleRejection, setRoleRejection] = React.useState(null);

  const toggleDeactivate = () => {
    setModalDeactivate(!modalDeactivate)
  };

  // console.log("Data User Detail", dataForm)

  const setEdit = () => {
    if(disabledBtn === false){
      setDisabledBtn(true);
    }else{
      setDisabledBtn(false);
    }
  }

  React.useEffect(() => {
    setEdit
  },[])

  const updateUser = async(e) => {
        e.preventDefault()
        setLoadingStatus(true);

        dispatch(loadingStatusSet(true));
        const response = await dispatch(updateDataUser({dataForm}))
        const res = unwrapResult(response);
        if(res){
            // // console.log("res")
        }
        setLoadingStatus(false);
    }

  const getData = async() => {
    const res = await apiAuth.get('/getUserDetail/'+id);
    if(res && res.data){
        let dataUser = {...res.data.data}
        delete dataUser.password
        setDataForm(dataUser)
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  const handleInputChange = (e) => {
    const dataFormInput = {...dataForm}
    const {name, value} = e.target
    dataFormInput[name] = value;
    setDataForm(dataFormInput)
  }

  function handleSelectFormMulti(e, name) {
      const dataFormSelect = {...dataForm};
      const {value, label} = e;
      dataFormSelect[name] = e.map(item => item.value);
      setDataForm(dataFormSelect);
  }

  function handleSelectForm(e, name) {
      const dataFormSelect = {...dataForm};
      const {value, label, area_division, area, regional, name_user} = e;
      dataFormSelect[name] = value;
      if(name === "title"){
          dataFormSelect["area_division"] = area_division;
          dataFormSelect["area"] = area;
          dataFormSelect["regional"] = regional;
          dataFormSelect["role"].push(value)
      }
      if(name === "lm_email"){
          dataFormSelect["lm_name"] = name_user;
      }
      
      setDataForm(dataFormSelect);
  }

  const getDataLM = async() => {
      const res = await apiAuth.get('/getUser?noPg=1');
      if(res && res.data){
          const listUserOption = res.data.data.map(e => ({label : e.email, value : e.email, name_user : e.first_name+" "+e.lastname}))
          setDataUserOption(listUserOption)
      }
  }

    const deleteUser = async(e) => {
        setModalDeactivate(false)
        setLoadingStatus(true);

        // dispatch(loadingStatusSet(true));
        const response = await dispatch(deleteDataUser({dataForm}))
        const res = unwrapResult(response);
        if(res){
            // // console.log("res")
        }
        setLoadingStatus(false);
    }

  React.useEffect(() => {
      getDataLM();
  }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="User Management" pageTitle="User Detail" />
                      <Row>
                        <Col md={12}>
                          <Card style={{marginBottom: "10px"}}>
                            <CardBody>
                              <h2 className="card-title mb-4" style={{marginBottom: "0px !important"}}>Detail</h2>
                              <Form >
                                    {dataForm.deleted === 1 && (
                                        <Row style={{marginBottom: "10px"}}>
                                            <Col md={4}>
                                                <h4 style={{color : 'red'}}>
                                                    User Deleted
                                                </h4>
                                            </Col>
                                        </Row>
                                    )}
                                    
                                  <Row>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="first_name" className="form-label"><span className="text-danger">*</span> First Name</Label>
                                              <Input type="text" className="form-control" name="first_name" placeholder="" value={dataForm.first_name} onChange={(e) => handleInputChange(e)} id="first_name" disabled={disabledBtn} required/>
                                          </div>
                                      </Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="last_name" className="form-label">Last Name</Label>
                                              <Input type="text" className="form-control" name="last_name" placeholder="" value={dataForm.last_name} onChange={(e) => handleInputChange(e)} id="last_name" disabled={disabledBtn}/>
                                          </div>
                                      </Col>
                                      <Col md={2}>
                                          <div className="mb-3">
                                              <Label htmlFor="middle_initial" className="form-label">Middle Name</Label>
                                              <Input type="text" className="form-control" name="middle_initial" placeholder="" value={dataForm.middle_initial} onChange={(e) => handleInputChange(e)} id="middle_initial" disabled={disabledBtn}/>
                                          </div>
                                      </Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="email" className="form-label">Email Address</Label>
                                              <Input type="email" className="form-control" name="email" placeholder="" disabled value={dataForm.email} onChange={(e) => handleInputChange(e)} id="email" required/>
                                          </div>
                                      </Col>
                                      <Col md={8}></Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="password" className="form-label">Password</Label>
                                              <Input type="password" className="form-control" name="password" value={dataForm.password} onChange={(e) => handleInputChange(e)} id="password" disabled={disabledBtn}/>
                                                <FormText>
                                                    Enter the password only if want to change the password
                                                </FormText>
                                          </div>
                                      </Col>
                                      <Col md={8}></Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="phone_number" className="form-label"><span className="text-danger">*</span> Phone Number</Label>
                                              <Input type="number" className="form-control" name="phone_number" placeholder="" value={dataForm.phone_number} onChange={(e) => handleInputChange(e)} id="phone_number" disabled={disabledBtn} required/>
                                          </div>
                                      </Col>
                                      <Col md={8}></Col>
                                      <Col md={8}></Col>
                                      <Col md={12} className='mt-2'>
                                          <h4>Organization</h4>
                                      </Col>
                                      <Col md={6}>
                                          <div className="mb-3">
                                              <Label htmlFor="title" className="form-label">Title</Label>
                                              <Select
                                                  value={{value : dataForm.title, label : dataForm.title}}
                                                  onChange={(e) => {
                                                      handleSelectForm(e, 'title');
                                                  }}
                                                  options={USER_TITLE_OPTION}
                                                  isDisabled={disabledBtn}
                                              />
                                          </div>
                                      </Col>
                                      <Col md={6}></Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="regional" className="form-label">Regional</Label>
                                              <Input type="text" className="form-control" name="regional" value={dataForm.regional} placeholder="" disabled id="regional" />
                                          </div>
                                      </Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="area_division" className="form-label">Area Division</Label>
                                              <Input type="text" className="form-control" name="area_division" value={dataForm.area_division} placeholder="" disabled id="area_division" />
                                          </div>
                                      </Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="area" className="form-label">Area</Label>
                                              <Input type="text" className="form-control" name="area" value={dataForm.area} placeholder="" disabled id="area" />
                                          </div>
                                      </Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="line_manager" className="form-label">Line Manager</Label>
                                              <Select
                                                  value={{value : dataForm.lm_email, label : dataForm.lm_email}}
                                                  onChange={(e) => {
                                                      handleSelectForm(e, 'lm_email');
                                                  }}
                                                  options={dataUserOption}
                                                  isDisabled={disabledBtn}
                                              />
                                          </div>
                                      </Col>
                                      <Col md={8}></Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="organization_long" className="form-label">Organization Long</Label>
                                              <Input type="text" className="form-control" name="organization_long" placeholder="" value={dataForm.organization_long} onChange={(e) => handleInputChange(e)} id="organization_long" disabled={disabledBtn}/>
                                          </div>
                                      </Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="department_long" className="form-label">Department Long</Label>
                                              <Input type="text" className="form-control" name="department_long" placeholder="" value={dataForm.department_long} onChange={(e) => handleInputChange(e)} id="department_long" disabled={disabledBtn}/>
                                          </div>
                                      </Col>
                                      <Col md={4}></Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="support_organization" className="form-label">Support Organization</Label>
                                              <Input type="text" className="form-control" name="support_organization" placeholder="" value={dataForm.support_organization} onChange={(e) => handleInputChange(e)} id="support_organization" disabled={disabledBtn}/>
                                          </div>
                                      </Col>
                                      <Col md={4}>
                                          <div className="mb-3">
                                              <Label htmlFor="support_group_name" className="form-label">Support Group Name</Label>
                                              <Input type="text" className="form-control" name="support_group_name" placeholder="" value={dataForm.support_group_name} onChange={(e) => handleInputChange(e)} id="support_group_name" disabled={disabledBtn}/>
                                          </div>
                                      </Col>
                                      <Col md={4}></Col>
                                      <Col md={12} className='mt-2'>
                                          <h4>Role</h4>
                                      </Col>
                                      <Col md={6}>
                                          <div className="mb-3">
                                              <Label htmlFor="phone_number" className="form-label"><span className="text-danger">*</span> User Role</Label>
                                              <Select
                                                  isMulti={true}
                                                  value={dataForm.role && dataForm.role.map(item => ({value : item, label : item}) )}
                                                  onChange={(e) => {
                                                      handleSelectFormMulti(e, 'role');
                                                  }}
                                                  options={ROLE_OPTIONS}
                                                  isDisabled={disabledBtn}
                                                  required
                                              />
                                          </div>
                                      </Col>
                                  </Row>
                              </Form>
                              {dataForm.deleted !== 1 && (
                              <Col lg={12} className="mt-4 d-flex" style={{justifyContent : 'space-between'}}>
                                <div style={{float: "left"}}>
                                  {disabledBtn === false &&(<React.Fragment>
                                    <Button type="submit" color="success" onClick={(e) => updateUser(e)} style={{marginRight: "10px"}} disabled={loadingStatus} >
                                        {loadingStatus ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                        Update
                                    </Button>
                                  </React.Fragment>)}

                                  <Button type="submit" color="primary" onClick={(e) => setEdit(e)}>
                                    {disabledBtn === true ?
                                    "Can Edit"
                                    : "Cancel"
                                    }
                                  </Button>
                                </div>
                                <div>
                                {disabledBtn === false &&(<React.Fragment>
                                    <Button color="danger" onClick={(e) => toggleDeactivate()} style={{marginRight: "10px"}} disabled={loadingStatus} >
                                    {loadingStatus ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                        Delete User
                                    </Button>
                                  </React.Fragment>)}
                                </div>
                              </Col>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                </Container>
            </div>

            <Modal isOpen={modalDeactivate} toggle={toggleDeactivate} style={{marginTop : '200px'}}>
                <ModalHeader toggle={toggleDeactivate}>Delete User</ModalHeader>
                <ModalBody>
                <Row>
                    <Col md={12}>
                        <div className="mb-3">
                            Are you sure delete {dataForm.email} ?
                        </div>
                    </Col>
                </Row>
                </ModalBody>
                <ModalFooter>
                <Button color="danger" onClick={() => deleteUser()}>
                    Delete
                </Button>{' '}
                <Button color="secondary" onClick={toggleDeactivate}>
                    Cancel
                </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default UserDetail;