import React from 'react';
import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row, Button, Spinner } from 'reactstrap';
import Select from "react-select";

// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { API_CLIENT_AUTH_BEARER } from '../../../helpers/api-helper';
import { approvalErPirCrForm } from '../../../middleware/cr-form/cr-approval-thunk';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

const ActivityStatusOptions = [
    { value: 'Successful', label: 'Successful' },
    { value: 'Successful with note', label: 'Successful with note' },
    { value: 'Fallback', label: 'Fallback' },
    { value: 'Cancel', label: 'Cancel' },
];

const AttachmentNameOptions = [
    { value: 'Post Implementation Report', label: 'Post Implementation Report' },
    { value: 'Execution Report', label: 'Execution Report' },
];

const apiAuth = new API_CLIENT_AUTH_BEARER();

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CRFormTask = (props) => {

    const {actionLoading, USER_PROFILE} = props

    const dispatch = useDispatch();

    const history = useNavigate();

    // console.log("prop", props);

    const [dataForm, setDataForm] = React.useState({"status": "Approved", 
                                                    "status_note": "",
                                                    "change_id": null,
                                                    "change_category": null
                                                    });
    
    const [dataApproval, setDataApproval] = React.useState({"status_note" : ""});
    const [dataFile, setDataFile] = React.useState({});
    const [dataAddSub, setDataAddSub] = React.useState([{}])
    const [loadingStatus, setLoadingStatus] = React.useState(false)

    const dataCompleted = props.dataForm;
    
    let data_execution_report = {};

    if(dataCompleted !== undefined && dataCompleted.attachments !== undefined){
        data_execution_report = dataCompleted.attachments.find(item => item.attachment_name === "Execution Report" || item.attachment_name === "Post Implementation Report");
    }

    if(!data_execution_report){
        data_execution_report = {};
    }

    const data_status = props.dataForm.current_status;

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        props.onChangeForm(name, value);
        setDataForm(dataFormInput)
    }

    const handleInputFileForm = (e, name) => {
        const file_data = e.target.files[0];

        dataAddSub[0]["file"] = file_data;

        const data_file = {...dataFile};
        data_file["file_attacment"] = dataAddSub;
        
        props.addAttachment(data_file.file_attacment);
        setDataFile(data_file);
    };

    function handleSelectForm(e, name) {
        const dataFormSelect = {...dataForm};
        const {value, label} = e;
        dataFormSelect[name] = value;
        props.onChangeForm(name, value);
        setDataForm(dataFormSelect);
    }

    const downloadExecutionReport = async (e, file, index) => {
        e.preventDefault()
        if(file !== undefined)  {
            const resFile = await apiAuth.getFile(''+file.url);
            
            if(resFile !== undefined){
                saveAs(new Blob([resFile.data], {type:file.mime_type}), file.file_name);
            }
        }
    }

    const uploadErPirApproval = async() => {
        setLoadingStatus(true)
        const crAttachment = dataFile.file_attacment;
        const data = dataForm;
        data["change_id"] = props.dataForm.change_id;
        data["change_category"] = props.dataForm.change_category;
        
        let response = await dispatch(approvalErPirCrForm({data, crAttachment }));
        
        const res = unwrapResult(response);
    
        if(res && res.data){
            const link = "/cr-detail/"+data.change_id;
            setTimeout(() => {
            history(link)
            setLoadingStatus(false)
            }, 1000);
        //   setActionStatus('success');
        }else{
            setLoadingStatus(false)
        }
    }

    const downloadPIRFile = async (e) => {
        // e.preventDefault()
        const resFile = await apiAuth.getFile('/downloadTemplate/PIR');
        // console.log("resFile", resFile)
        if(resFile !== undefined && resFile.data){
            saveAs(new Blob([resFile.data], {type:resFile.data.type}), "Template PIR All Domain_v1.docx");
        }
    }
    
    const downloadERFile = async (e) => {
        // e.preventDefault()
        const resFile = await apiAuth.getFile('/downloadTemplate/ER');
        // console.log("resFile", resFile)
        if(resFile !== undefined && resFile.data){
            saveAs(new Blob([resFile.data], {type:resFile.data.type}), "Template ER All Domain_v1.docx");
        }
    }

    const approveCrForm = (status) => {
        // if(modalReject === true){
        //     toggleReject()
        // }
        let data_approval = {...dataApproval};
        data_approval["status"] = status;
        if(status === "Approved"){
            data_approval["status_note"] = "Approved";
        }
        // console.log("data_approval", data_approval)
        props.actionApproval(data_approval)
    }

    return (
        <React.Fragment>
            {data_status === "Scheduled" ?(
            <React.Fragment>

            <Row>
                <Col md={6} style={{float: "right"}}>
                    <Row>
                        <Col md={12}>
                            <Label htmlFor="attachment_activity_status" className="form-label">Activity Status</Label>
                            <Select
                                isClearable={true}
                                // value={dataForm.responsible_approval}
                                value={dataForm && ({value : dataForm.attachment_activity_status, label : dataForm.attachment_activity_status})}
                                onChange={(e) => {
                                    handleSelectForm(e, 'attachment_activity_status');
                                }}
                                options={ActivityStatusOptions}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Label htmlFor="attachment_name" className="form-label">Execution Report/Post Implementation Report</Label>
                            <Select
                                isClearable={true}
                                // value={dataForm.responsible_approval}
                                value={dataForm && ({value : dataForm.attachment_name, label : dataForm.attachment_name})}
                                onChange={(e) => {
                                    handleSelectForm(e, 'attachment_name');
                                }}
                                options={AttachmentNameOptions}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Label htmlFor="file" className="form-label">Upload File</Label>
                            <Input
                                type="file"
                                style={{marginBottom: "10px"}}
                                name={0}
                                onChange={(e) => {
                                    handleInputFileForm(e, "file");
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Label htmlFor="attachment_note" className="form-label">Note :</Label>
                            <Input type="textarea" className="form-control" name="attachment_note" onChange={(e) => handleInputChange(e)} id="attachment_note"/>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} style={{float: "right"}}>
                    <div className="mb-3">
                        <Label htmlFor="size" className="form-label">Download Template</Label>
                        <Row>
                            <Col md={6} style={{float: "right"}}>
                                <Button className="form-control" name="download_template" color="light" style={{border: "1px solid #dedede"}} onClick={(e) => {downloadPIRFile(e)}}><i className={"ri-download-2-fill"}></i> PIR Template</Button>
                            </Col>
                            <Col md={6} style={{float: "right"}}>
                                <Button className="form-control" name="download_template" color="light" style={{border: "1px solid #dedede"}} onClick={(e) => {downloadERFile(e)}}><i className={"ri-download-2-fill"}></i> ER Template</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
                
            <Row>
                <Col md={12} className='mt-5'>
                    <Button size="md" color="warning" onClick={uploadErPirApproval} disabled={(dataForm.attachment_activity_status === "Successful with note" && !dataForm.attachment_note) || loadingStatus} >
                        {loadingStatus ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                        Submit
                    </Button>
                </Col>
            </Row>
            </React.Fragment>
            ) : data_status === "Completed" || data_status === "Closed" ?
            (<React.Fragment><Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">{data_execution_report.attachment_name}</h4>
                        </CardHeader>
                        <CardBody>
                            <div className="table-responsive">
                                <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                    <thead className="table-light">
                                        <tr>
                                        <th scope="col">CRM Change ID</th>
                                        <th scope="col">File</th>
                                        <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {dataCompleted !== undefined && dataCompleted.attachments.map((d, idx) => 
                                        (d.attachment_name === "Execution Report" || d.attachment_name === "Post Implementation Report") &&
                                        <tr key={d.crmChangeId}>
                                        <td>{d.crmChangeId}</td>
                                        <td>{d.file_name}</td>
                                        <td>
                                            <button type="button" className="btn btn-sm btn-info" onClick={(e) => {downloadExecutionReport(e, d, idx)}}>
                                                Download
                                            </button>
                                        </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {data_status === "Completed" && (
                <React.Fragment>
                {(USER_PROFILE && USER_PROFILE.role && (USER_PROFILE.role.find(rl => rl === "Change Coordinator") || USER_PROFILE.role.find(rl => rl === "Admin"))) && (
                <React.Fragment>
                <div className="text-center" style={{marginBottom : '2vh'}}>
                <Row>
                    <Col xs={12} style={{marginTop : '3vh'}}>
                        <h4>Close CR ?</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='mt-5'>
                        <Button 
                            onClick={() => approveCrForm("Approved")} 
                            disabled={actionLoading}
                            // disabled={loadingStatus}
                        >
                        {actionLoading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                        Approve
                        </Button>
                    </Col>
                </Row>
                </div>
                </React.Fragment>
                )}
                </React.Fragment>
            )}
            
            </React.Fragment>
            )
            : (<React.Fragment></React.Fragment>)
            }
            <Row>
            </Row>
        </React.Fragment>
    );
};

export default CRFormTask;