import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

//Import Icons
import FeatherIcon from "feather-icons-react";

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

import { datetimeformatncrm, dateformatncrm, datemonthformatncrm, dateyearsmonthdayformatncrm } from "../../helpers/FunctionalHelper/date-helper";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import Flatpickr from "react-flatpickr";

//redux
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

//Simple bar
import SimpleBar from "simplebar-react";
import UpcommingEvents from './UpcommingEvents';
import listPlugin from '@fullcalendar/list';

import {
  getEvents as onGetEvents,
  getCategories as onGetCategories,
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  updateEvent as onUpdateEvent,
  resetCalendar,
} from "../../slices/thunks";
import { createSelector } from "reselect";
import { dataEventsTimeline } from "./data-common";
import TimelineCalendar from "../../Components/Calendar/TimelineCalendar";

import CalenderFilter from "./CalenderFilter";
import { getRestrictedDateData, getTimelineData } from "../../middleware/getter-data/timeline-redux-thunk";

const apiAuth = new API_CLIENT_AUTH_BEARER();

const Calender = (item) => {

  const dispatch = useDispatch();

  const [event, setEvent] = useState({});
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedNewDay, setSelectedNewDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditButton, setIsEditButton] = useState(true);
  const [upcommingevents, setUpcommingevents] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [page, setPage] = React.useState(1);
  const [dataList, setDataList] = React.useState([]);
  const [totalData, setTotalData] = React.useState(0);
  const [dataHoliday, setDataHoliday] = React.useState([]);
  const [dataCRNumber, setDataCRNumber] = React.useState(null);
  const [totalDataHoliday, setTotalDataHoliday] = React.useState(0);
  const [dataSearchDate, setDataSearchDate] = React.useState({});

  const next_day_date = new Date(new Date().setDate(new Date().getDate()));
  const next_month_date = new Date(next_day_date.setMonth(next_day_date.getMonth() + 1));
  const previous_day_date = new Date(new Date().setDate(new Date().getDate() + 1));
  const previous_month_date = new Date(previous_day_date.setMonth(previous_day_date.getMonth() - 1));

  const reducerTimelineData = useSelector(state => state.TimelineData);

  const date_data = (data) => {
    setDataSearchDate(data);
  }

  const [rightColumn, setRightColumn] = useState(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };

  const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

  const selectLayoutState = (state) => state.Calendar;
  const calendarDataProperties = createSelector(
    selectLayoutState,
    (state) => ({
      events: state.events,
      categories: state.categories,
      isEventUpdated: state.isEventUpdated,
    })
  );
  
  // Inside your component
  const {
    events, categories, isEventUpdated
  } = useSelector(calendarDataProperties);

  useEffect(() => {
    dispatch(onGetEvents());
    dispatch(onGetCategories());
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, [dispatch]);

  useEffect(() => {

    setUpcommingevents(events);

    upcommingevents.slice().sort(function (o1, o2) {
      return new Date(o1.start) - new Date(o2.start);
    });

  }, [events, upcommingevents]);

  useEffect(() => {
    if (isEventUpdated) {
      setIsEdit(false);
      setEvent({});
      setTimeout(() => {
        dispatch(resetCalendar("isEventUpdated", false));
      }, 500);
    }
  }, [dispatch, isEventUpdated]);

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
      setIsEdit(false);
      setIsEditButton(true);
    } else {
      setModal(true);
    }
  };
  /**
   * Handling date click on calendar
   */

  const handleDateClick = (arg) => {
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );

    const modifiedData = { ...arg, date: modifiedDate };

    setSelectedNewDay(date);
    setSelectedDay(modifiedData);
    toggle();
  };

  const str_dt = function formatDate(date) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var d = new Date(date),
      month = "" + monthNames[d.getMonth()],
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day + " " + month, year].join(",");
  };

  const date_r = function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg) => {
    const event = arg.event;

    const st_date = event.start;
    const ed_date = event.end;
    const r_date =
      ed_date == null
        ? str_dt(st_date)
        : str_dt(st_date) + " to " + str_dt(ed_date);
    const er_date =
      ed_date == null
        ? date_r(st_date)
        : date_r(st_date) + " to " + date_r(ed_date);

    setEvent({
      id: event.id,
      title: event.title,
      start: event.start,
      end: event.end,
      parent_id: event.extendedProps.cr_number,
      className: event.classNames,
      category: event.classNames[0],
      location: event._def.extendedProps.location,
      description: event._def.extendedProps.description,
      defaultDate: er_date,
      datetag: r_date
    });

    setIsEdit(true);
    setIsEditButton(false);
    toggle();
  };
  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    dispatch(onDeleteEvent(event.id));
    setDeleteModal(false);
    toggle();
  };

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
      location: (event && event.location) || "",
      description: (event && event.description) || "",
      defaultDate: (event && event.defaultDate) || "",
      datetag: (event && event.datetag) || "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: (values) => {
      var updatedDay = "";
      if (selectedNewDay) {
        updatedDay = new Date(selectedNewDay[1]);
        updatedDay.setDate(updatedDay.getDate() + 1);
      }

      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          className: values.category,
          start: selectedNewDay ? selectedNewDay[0] : event.start,
          end: selectedNewDay ? updatedDay : event.end,
          location: values.location,
          description: values.description,
        };
        // update event
        dispatch(onUpdateEvent(updateEvent));
        validation.resetForm();
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedNewDay[0] : new Date(),
          end: selectedDay ? updatedDay : new Date(),
          className: values.category,
          location: values["location"],
          description: values["description"],
        };
        // save new event
        dispatch(onAddNewEvent(newEvent));
        validation.resetForm();
      }
      setSelectedDay(null);
      setSelectedNewDay(null);
      toggle();
    },
  });

  /**
   * On calendar drop event
   */
  const onDrop = (event) => {
    const date = event["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );

    const draggedEl = event.draggedEl;
    const draggedElclass = draggedEl.className;
    if (
      draggedEl.classList.contains("external-event") &&
      draggedElclass.indexOf("fc-event-draggable") === -1
    ) {
      const modifiedData = {
        id: Math.floor(Math.random() * 1000),
        title: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      };
      dispatch(onAddNewEvent(modifiedData));
    }
  };

  const getDataHoliday = async() => {

    if(reducerTimelineData && reducerTimelineData.restricted_date_list_data && reducerTimelineData.restricted_date_list_data.length > 0){

      setDataHoliday(reducerTimelineData.restricted_date_list_data)
      setTotalDataHoliday(reducerTimelineData.restricted_date_list_data.length);
      
    }else{

      const response = await dispatch(getRestrictedDateData({query : ''}))
      const res = unwrapResult(response);

      if(res && res.data){
          const data_sort = res.data.data
          setDataHoliday(data_sort.sort((a,b) => {
            return new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
          }).reverse())
          setTotalDataHoliday(res.data.totalResults)
      }
    }

  }

  React.useEffect(() => {
    getDataHoliday();
  }, []);

  const getCRData = async() => {

    console.log("test CR")
    let filter_array = [];

    if(dataSearchDate.user === false){
      if(userData.role && userData.role.find(role => role.includes("Requestor") || role.includes("Change Coordinator")) !== undefined){
        filter_array.push('"$or" : [{"created_by" : "'+userData.email+'"},{"current_status" : "Request for Authorization", "change_coordinator_email" : "'+userData.email+'"},{"current_status" : "Request for Change", "change_coordinator_email" : "'+userData.email+'"}]');
      }else if(userData.title && (userData.title.includes("Expert Domain") === true || userData.title.includes("Domain Expert") === true)){
        filter_array.push('"$or" : [{"current_status" : "Scheduled for Approval", "change_category" : "Normal Major"},{"current_status" : "Scheduled for Approval", "change_category" : "Normal Significan"}]');
      }
    }

    if(dataSearchDate.region !== "All" && dataSearchDate.region !== undefined && dataSearchDate.region !== null){
      filter_array.push('"region" : {"$in" : ["'+dataSearchDate.region+'"]}');
    }

    if(dataSearchDate.oc_domain_category !== "All" && dataSearchDate.oc_domain_category !== undefined && dataSearchDate.oc_domain_category !== null){
      filter_array.push('"oc_domain_category" : "'+dataSearchDate.oc_domain_category+'"');
    }

    let whereAnd = '{' + filter_array.join(',') + '}';

    setDataCRNumber([])
    const res = await apiAuth.get('/getCRM?q='+whereAnd+'&noPg=1');
    
    if(res && res.data){
        const CRNumber = [];

        if(res.data.data){
          const data = res.data.data;
          
          data.map(cr =>
            CRNumber.push('"'+cr.change_id+'"')
          )
        }
        setDataCRNumber(CRNumber)
    }
  }

  React.useEffect(() => {
      getCRData();
  }, [dataSearchDate]);

  const getData = async() => {
    let filter_array = [];

    console.log("test Timeline")

    if(dataSearchDate.data_time && dataSearchDate.data_time.start_date !== undefined && dataSearchDate.data_time.end_date !== undefined && dataSearchDate.data_time.start_date !== null && dataSearchDate.data_time.end_date !== null){
      filter_array.push('"plan_start_time":{"$between":["'+dateyearsmonthdayformatncrm(dataSearchDate.data_time.start_date)+' 00:00:00", "'+dateyearsmonthdayformatncrm(dataSearchDate.data_time.end_date)+' 23:59:59"]}');
      
    }else{
      filter_array.push('"plan_start_time":{"$between":["'+dateyearsmonthdayformatncrm(previous_month_date)+' 00:00:00", "'+dateyearsmonthdayformatncrm(next_month_date)+' 23:59:59"]}');
    }
    if(dataCRNumber !== null){
      filter_array.push('"crmChangeId" : {"$in" : ['+dataCRNumber+']}');
    }

    let where = 'q={' + filter_array.join(',') + '}';
    // const res = await apiAuth.get('/getTimeline?q='+where+'&noPg=1');

    const response = await dispatch(getTimelineData({query : where}))
    const res = unwrapResult(response);

    if(res && res.data){
      let scheduled = {};
      
      if(dataCRNumber !== null){
        scheduled = (res.data.data.filter(dt => dt.crm).filter(dt => dt.crm.current_status === "Scheduled"));
      }else if(dataSearchDate.user === undefined && dataSearchDate.oc_domain_category === undefined && dataSearchDate.region === undefined){
        scheduled = (res.data.data.filter(dt => dt.crm).filter(dt => dt.crm.current_status === "Scheduled"));
      }

      setDataList(scheduled);
    }
  }

  React.useEffect(() => {
    if(dataCRNumber !== null && dataCRNumber.length !== 0){
      getData();
    }
    
  }, [dataSearchDate, dataSearchDate, dataCRNumber]);

  const prepareEvents = () => {
    let add_timeline = [];
    
    dataList.filter(item => item.release_id).forEach(dt => {
      add_timeline.push({
        ...dt, 
        "className" :       dt.crm !== null && dt.crm.oc_domain_category === "Broadband" ? "bg-warning-subtle text-warning" :
                            dt.crm !== null && dt.crm.oc_domain_category === "Transport" ? "bg-info-subtle text-info" :
                            dt.crm !== null && dt.crm.oc_domain_category === "Datacomm" ? "bg-purple-subtle text-purple" :
                            dt.crm !== null && dt.crm.oc_domain_category === "RAN" ? "bg-primary-subtle text-primary" :
                            dt.crm !== null && dt.crm.oc_domain_category === "Core & Interconnect" ? "bg-warning-subtle text-warning" :
                            dt.crm !== null && dt.crm.oc_domain_category === "Cloud" ? "bg-secondary-subtle text-secondary" :
                            "",
        "start" : dt.plan_start_time,
        "end" : dt.plan_end_time,
        "title" : dt.release_id+' - '+dt.activity_description,
        "cr_number" : dt.crmChangeId
      })
    })

    dataHoliday.forEach(dt => {
      add_timeline.push({
        ...dt, 
        "backgroundColor" : "bg-danger",
        "className" :   "bg-danger bg-danger-subtle text-danger" ,
        "start" : dt.start_date,
        "end" : dt.end_date,
        "title" : dt.title,
        "description" : dt.remarks,
      })
    })
    const list_timeline = [...add_timeline]

    setEventsList(list_timeline)
  }

  React.useEffect(() => {
    prepareEvents()
  }, [dataList, dataHoliday]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Col md={12}>
            <Card className="card-h-100" style={{height: "960px"}}>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Calender Scheduled</h4>
              </CardHeader>
              <div id="external-events">
              </div>
              <CalenderFilter 
                rightClickBtn={toggleRightColumn} 
                searchDate={date_data}
                nextMonthDate={next_month_date}
                previousMonthDate={previous_month_date}
              />
              <CardBody className="dashboard-main-calender" style={{zIndex: "000", height: "788px"}}>
                  <FullCalendar
                    plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        interactionPlugin,
                        listPlugin
                    ]}
                    initialView="dayGridMonth"
                    slotDuration={"00:15:00"}
                    themeSystem="bootstrap"
                    headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                    }}
                    events={eventsList}
                    editable={true}
                    droppable={true}
                    selectable={true}
                    dateClick={handleDateClick}
                    eventClick={handleEventClick}
                    drop={onDrop}
                  />

              </CardBody>
              <div className="align-items-center text-center mt-2 mb-4">
                <span className="text-muted" style={{padding: "5px", margin: "3px"}}><i className="text-success mdi mdi-checkbox-blank-circle font-size-11 me-2" /> Broadband</span>
                <span className="text-muted" style={{padding: "5px", margin: "3px"}}><i className="text-info mdi mdi-checkbox-blank-circle font-size-11 me-2" /> Transport</span>
                <span className="text-muted" style={{padding: "5px", margin: "3px"}}><i className="text-purple mdi mdi-checkbox-blank-circle font-size-11 me-2" /> Datacomm</span>
                <span className="text-muted" style={{padding: "5px", margin: "3px"}}><i className="text-primary mdi mdi-checkbox-blank-circle font-size-11 me-2" /> RAN</span>
                <span className="text-muted" style={{padding: "5px", margin: "3px"}}><i className="text-warning mdi mdi-checkbox-blank-circle font-size-11 me-2" /> Core & Interconnect</span>
                <span className="text-muted" style={{padding: "5px", margin: "3px"}}><i className="text-secondary mdi mdi-checkbox-blank-circle font-size-11 me-2" /> Cloud</span>
              </div>
            </Card>

            <div style={{ clear: "both" }}></div>

            <TimelineCalendar 
              modal={modal}
              data={event}
              toggle={toggle}
            />
      </Col>
    </React.Fragment>
  );
};

Calender.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default Calender;