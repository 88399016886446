import React from 'react';
import { Card, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { teamMembers } from '../../common/data';
import { TeamMembersCharts } from './DashboardPieCharts';

import avatardummy from "../../assets/images/users/user-dummy-img1.jpg";

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
const apiAuth = new API_CLIENT_AUTH_BEARER();

const TeamMembers = (crdata) => {
    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    const [dataList, setDataList] = React.useState([]);
    const [dataMember, setDataMember] = React.useState([]);
    const [totalData, setTotalData] = React.useState(0);
    const [dataCRMList, setDataCRMList] = React.useState([]);
    const [totalDataTime, setTotalDataTime] = React.useState(0);

    const getData = async() => {
        let where = '{}';
        const res = await apiAuth.get('/getUser?q='+where+'&noPg=1');
        if(res && res.data){
            setDataList(res.data.data)
            setTotalData(res.data.totalResults)
        }
    }
    
    React.useEffect(() => {
        getData();
    }, []);

    const getMember = () => {
        const member_list = [];
        if((crdata.crdata).length > 0){
            dataRequestor.map((item, idx) => {
                const member_data = {
                    "first_name" : item.first_name,
                    "last_name" : item.last_name,
                    "email" : item.email,
                    "title" : item.title,
                    "scheduled_task" : crdata.crdata.find(dt => dt.created_by === item.email) !== undefined ? crdata.crdata.find(dt => dt.created_by === item.email).scheduled : 0,
                    "closed_task" : crdata.crdata.find(dt => dt.created_by === item.email) !== undefined ? crdata.crdata.find(dt => dt.created_by === item.email).closed : 0,
                    "success_rate" : crdata.crdata.find(dt => dt.created_by === item.email) !== undefined ? crdata.crdata.find(dt => dt.created_by === item.email).success_rate : '0%',
                };
    
                member_list.push(member_data);
            });
        }else{
            dataRequestor.map((item, idx) => {
                const member_data = {
                    "first_name" : item.first_name,
                    "last_name" : item.last_name,
                    "email" : item.email,
                    "title" : item.title,
                    "scheduled_task" : 0,
                    "closed_task" : 0,
                    "success_rate" : '0%',
                };
    
                member_list.push(member_data);
            });
        }
        setDataMember(member_list);
    }

    React.useEffect(() => {
        getMember();
      }, [crdata]);

    const dataRequestor = dataList.filter(dt => dt.role.includes("Requestor"));

    return (
        <React.Fragment>
            <Col md={12} style={{height: "520px", marginBottom: "20px"}}>
                <Card style={{height: "100%"}}>
                    <CardHeader className="align-items-center d-flex bg-primary text-white">
                        <h4 className="card-title mb-0 flex-grow-1 text-white">My Team Member</h4>
                    </CardHeader>

                    <div className="card-body">

                        <div className="table-responsive table-card" style={{minHeight: "85px", height: "417px"}}>
                            <table className="table table-borderless align-middle mb-0" >
                                <thead className="table-light text-muted">
                                    <tr>
                                        <th scope="col" style={{fontSize: "12px", verticalAlign: "bottom", textAlign: "left"}}>Member</th>
                                        <th scope="col" style={{fontSize: "12px", verticalAlign: "bottom", textAlign: "center"}}>Scheduled Tasks</th>
                                        <th scope="col" style={{fontSize: "12px", verticalAlign: "bottom", textAlign: "center"}}>Closed Tasks</th>
                                        <th scope="col" style={{fontSize: "12px", verticalAlign: "bottom", textAlign: "center"}}>Success Rate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(dataMember || []).sort((a, b) => a.closed_task - b.closed_task).reverse().map((item, key) => (
                                    <tr key={key}>
                                        <td className="d-flex">
                                            <img src={avatardummy} alt="" className="avatar-xs rounded-3 me-2" style={{objectFit: "cover", objectPosition: "center"}}/>
                                            <div>
                                                <h5 className="fs-12 mb-0">{item.first_name + " " + item.last_name}</h5>
                                                <p className="mb-0 text-muted" style={{fontSize: "9px"}}>{item.title ? item.title : "-"}</p>
                                            </div>
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                            {item.scheduled_task}
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                            {item.closed_task}
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                            {item.success_rate}<i className="ri-bar-chart-fill text-success fs-16 align-middle ms-2"></i>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default TeamMembers;