import React from 'react';
import { Card, CardHeader, CardBody, Col, Container, Form, Input, Modal, ModalBody, ModalHeader, ModalFooter, Button, Label, Row } from 'reactstrap';
import Select from "react-select";

import { USER_TITLE } from '../../../config/data/USER_TITLE';
import { inputTypeNumberAvoidNonNumber } from '../../../helpers/FunctionalHelper/input-helper';

const ImpacttoNEOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];

const VendorNameOptions = [
    { value: 'Ericsson', label: 'Ericsson' },
    { value: 'Huawei', label: 'Huawei' },
    { value: 'ZTE', label: 'ZTE' }
];

const CRFormAIInformation = (props) => {

    const Responsible_Approval = USER_TITLE;

    const [dataForm, setDataForm] = React.useState({region : [], responsible_approval : [], affected_services : []});
    const [region, setRegion] = React.useState({});

    const [requiredInformation, setRequiredInformation] = React.useState([false, false, false, false, false, false, false]);

    function handleSelectFormMulti(e, name, idxFlag) {
        const dataFormSelect = {...dataForm};
        const {value, label} = e;
        dataFormSelect[name] = e.map(item => item.value);
        props.onChangeForm(name, e.map(item => item.value));

        console.log("Data Test Inf", (dataFormSelect[name]).length);

        if((dataFormSelect[name]).length > 0 && idxFlag !== undefined){
            requiredInformation[idxFlag] = true
        }else{
            requiredInformation[idxFlag] = false
        }
        props.onChangeRequiredInformation(requiredInformation);

        setDataForm(dataFormSelect);
    }

    function functionAvoidNonNumberInputTypeNumber(){
        document.querySelector(".input-type-number__ncrm").addEventListener("keypress", function (evt) {
            if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57)
            {
                evt.preventDefault();
            }
        });
    }

    function handleSelectForm(e, name, idxFlag) {
        const dataFormSelect = {...dataForm};
        const {value, label} = e;
        dataFormSelect[name] = value;
        if(name === "impact_to_ne" && value === "No"){
            dataFormSelect['ne_name_impacted_list'] = ''
            dataFormSelect['ne_impact_description'] = ''
            dataFormSelect['ne_outage_duration_min'] = 0
            dataFormSelect['affected_services_description'] = ''
            dataFormSelect['service_outage_duration_min'] = 0
            dataFormSelect['affected_services'] = []
            props.onChangeFormAll(dataFormSelect);
        }else{
            props.onChangeForm(name, value);
        }

        if(value !== null && value !== "" && idxFlag !== undefined){
            requiredInformation[idxFlag] = true
        }else{
            requiredInformation[idxFlag] = false
        }
        props.onChangeRequiredInformation(requiredInformation);

        setDataForm(dataFormSelect);
    }

    const handleInputChange = (e, idxFlag) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        props.onChangeForm(name, value);

        if(value !== null && value !== "" && idxFlag !== undefined){
            requiredInformation[idxFlag] = true
        }else{
            requiredInformation[idxFlag] = false
        }
        props.onChangeRequiredInformation(requiredInformation);

        setDataForm(dataFormInput)
    }

    const handleCheckboxChange = (e, name, value) => {
        
        const dataFormInput = {...dataForm}
        const {checked} = e.target;
        if(name === "affected_services") {
            let data_affected = dataFormInput["affected_services"];
            if(checked){
                data_affected.push(value)
            }else{
                data_affected = data_affected.filter(item => item !== value)
            }
            dataFormInput[name] = data_affected;
            props.onChangeForm(name, data_affected);
            setDataForm(dataFormInput)
        }else{
            dataFormInput[name] = checked;
            props.onChangeForm(name, checked);
            setDataForm(dataFormInput)
        }
    }

    const getRegionList = () => {
        const dataList = Responsible_Approval.map(ls => ls.Regional).filter((item, index, arr) => arr.indexOf(item) === index).filter(item => item !== undefined).sort((a, b) => a.number > b.number ? -1 : 1).map(item => ({label : item, value : item}));
        setRegion(dataList);
    }
    
    const dataList = Responsible_Approval.filter(item => dataForm.region).filter(item => dataForm.region.includes(item.Regional))
    .map(ls => ls.TITLE)
    .filter((item, index, arr) => arr.indexOf(item) === index)
    .map(item => ({label : item, value : item}));

    React.useEffect(() => {
        getRegionList();
        // functionAvoidNonNumberInputTypeNumber();
    }, []);

    React.useEffect(() => {
        if(props.dataForm){
            setDataForm({
                ...dataForm,
                ...props.dataForm
            })
        }
    }, [props.dataForm])

    return (
        <React.Fragment>
            <Row>
                <Col md={6}>
                    <Row style={{paddingTop: "10px"}}>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="changeType" className="form-label">Select Region <span className="text-danger">*</span></Label>
                                <Select
                                    isClearable={true}
                                    value={dataForm.region && dataForm.region.map(item => ({value : item, label : item}) )}
                                    isMulti={true}
                                    onChange={(e) => {
                                        handleSelectFormMulti(e, 'region', 0);
                                    }}
                                    options={region}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="changeCategory" className="form-label">Select Responsible Approval <span className="text-danger">*</span></Label>
                                <Select
                                    isClearable={true}
                                    value={dataForm.responsible_approval && dataForm.responsible_approval.map(item => ({value : item, label : item}) )}
                                    isMulti={true}
                                    onChange={(e) => {
                                        handleSelectFormMulti(e, 'responsible_approval', 1);
                                    }}
                                    options={dataList}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="ne_id_change_target" className="form-label">NE ID Change Target <span className="text-danger">*</span></Label>
                                <Input type="text" className="form-control" name="ne_id_change_target" value={dataForm.ne_id_change_target} onChange={(e) => handleInputChange(e, 2)} id="ne_id_change_target" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="ne_name_change_target" className="form-label">NE Name Change Target <span className="text-danger">*</span></Label>
                                <Input type="text" className="form-control" name="ne_name_change_target" value={dataForm.ne_name_change_target} onChange={(e) => handleInputChange(e, 3)} id="ne_name_change_target" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="detailed_location" className="form-label">Detailed Location <span className="text-danger">*</span></Label>
                                <Input type="text" className="form-control" name="detailed_location" value={dataForm.detailed_location} onChange={(e) => handleInputChange(e, 4)} id="detailed_location" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="vendor_name_ai_info" className="form-label">Vendor Name <span className="text-danger">*</span></Label>
                                <Select
                                    isClearable={true}
                                    isMulti={true}
                                    value={dataForm.vendor_name && dataForm.vendor_name.map(item => ({value : item, label : item}) )}
                                    onChange={(e) => {
                                        handleSelectFormMulti(e, "vendor_name", 5);
                                    }}
                                    options={VendorNameOptions}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="impact_to_ne" className="form-label">Impact to NE <span className="text-danger">*</span></Label>
                                <Select
                                    isClearable={true}
                                    // value={dataForm.responsible_approval}
                                    value={dataForm && ({value : dataForm.impact_to_ne, label : dataForm.impact_to_ne})}
                                    onChange={(e) => {
                                        handleSelectForm(e, 'impact_to_ne', 6);
                                    }}
                                    options={ImpacttoNEOptions}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="ne_name_impacted_list" className="form-label">NE Name (Impacted) List</Label>
                                <Input type="text" className="form-control" disabled={dataForm.impact_to_ne === "No"} name="ne_name_impacted_list" value={dataForm.ne_name_impacted_list} onChange={(e) => handleInputChange(e)} id="ne_name_impacted_list" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="ne_impact_description" className="form-label">NE Impact Description</Label>
                                <Input type="textarea" className="form-control" disabled={dataForm.impact_to_ne === "No"} name="ne_impact_description" value={dataForm.ne_impact_description} onChange={(e) => handleInputChange(e)} id="ne_impact_description" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="ne_outage_duration_min" className="form-label">NE Outage Duration (min)</Label>
                                <Input type="number" onKeyDown={ (e) => inputTypeNumberAvoidNonNumber(e)} className="form-control input-type-number__ncrm" disabled={dataForm.impact_to_ne === "No"} name="ne_outage_duration_min" value={dataForm.ne_outage_duration_min} onChange={(e) => handleInputChange(e)} id="ne_outage_duration_min" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="affected_services" className="form-label" style={{marginRight: "10px"}}>Affected Service</Label>
                                <Input className="form-check-input" type="checkbox" id="affected_services_sms" name="affected_services" disabled={dataForm.impact_to_ne === "No"} onChange={(e) => handleCheckboxChange(e, "affected_services", "sms")} checked={dataForm.affected_services ? dataForm.affected_services.find(item => item === "sms") : false}/>
                                <Label className="form-check-label" htmlFor="optimization_request" style={{marginLeft : '5px', marginRight : '10px'}}>
                                    SMS
                                </Label>
                                <Input className="form-check-input" type="checkbox" id="affected_services_mms" name="affected_services_mms" disabled={dataForm.impact_to_ne === "No"} onChange={(e) => handleCheckboxChange(e, "affected_services", "mms")} checked={dataForm.affected_services ? dataForm.affected_services.find(item => item === "mms") : false}/>
                                <Label className="form-check-label" htmlFor="optimization_request" style={{marginLeft : '5px', marginRight : '10px'}}>
                                    MMS
                                </Label>
                                <Input className="form-check-input" type="checkbox" id="affected_services_data" name="affected_services_data" disabled={dataForm.impact_to_ne === "No"} onChange={(e) => handleCheckboxChange(e, "affected_services", "data")} checked={dataForm.affected_services ? dataForm.affected_services.find(item => item === "data") : false}/>
                                <Label className="form-check-label" htmlFor="optimization_request" style={{marginLeft : '5px', marginRight : '10px'}}>
                                    Data
                                </Label>
                                <Input className="form-check-input" type="checkbox" id="affected_services_voice" name="affected_services_voice" disabled={dataForm.impact_to_ne === "No"} onChange={(e) => handleCheckboxChange(e, "affected_services", "voice")} checked={dataForm.affected_services ? dataForm.affected_services.find(item => item === "voice") : false}/>
                                <Label className="form-check-label" htmlFor="optimization_request" style={{marginLeft : '5px', marginRight : '10px'}}>
                                    Voice
                                </Label>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="affected_services_description" className="form-label">Affected Service Description</Label>
                                <Input type="textarea" className="form-control" disabled={dataForm.impact_to_ne === "No"} name="affected_services_description" value={dataForm.affected_services_description} onChange={(e) => handleInputChange(e)} id="affected_services_description" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="service_outage_duration_min" className="form-label">Service Outage Duration (min)</Label>
                                <Input type="number" className="form-control input-type-number__ncrm" onKeyDown={ (e) => inputTypeNumberAvoidNonNumber(e)} disabled={dataForm.impact_to_ne === "No"} name="service_outage_duration_min" value={dataForm.service_outage_duration_min} onChange={(e) => handleInputChange(e)} id="service_outage_duration_min" />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </React.Fragment>
    );
};

export default CRFormAIInformation;