import { combineReducers } from "redux";

// Front
import LayoutReducer from "./slices/layouts/reducer";

// Authentication
import LoginReducer from "./slices/auth/login/reducer";
import AccountReducer from "./slices/auth/register/reducer";
import ForgetPasswordReducer from "./slices/auth/forgetpwd/reducer";
import ProfileReducer from "./slices/auth/profile/reducer";

//Calendar
import CalendarReducer from "./slices/calendar/reducer";
//Chat
import chatReducer from "./slices/chat/reducer";
//Ecommerce
import EcommerceReducer from "./slices/ecommerce/reducer";

//Project
import ProjectsReducer from "./slices/projects/reducer";

// Tasks
import TasksReducer from "./slices/tasks/reducer";

//Crypto
import CryptoReducer from "./slices/crypto/reducer";

//TicketsList
import TicketsReducer from "./slices/tickets/reducer";
//Crm
import CrmReducer from "./slices/crm/reducer";

//Invoice
import InvoiceReducer from "./slices/invoice/reducer";

//Mailbox
import MailboxReducer from "./slices/mailbox/reducer";

// Dashboard Analytics
import DashboardAnalyticsReducer from "./slices/dashboardAnalytics/reducer";

// Dashboard CRM
import DashboardCRMReducer from "./slices/dashboardCRM/reducer";

// Dashboard Ecommerce
import DashboardEcommerceReducer from "./slices/dashboardEcommerce/reducer";

// Dashboard Cryto
import DashboardCryptoReducer from "./slices/dashboardCrypto/reducer";

// Dashboard Cryto
import DashboardProjectReducer from "./slices/dashboardProject/reducer";

// Dashboard NFT
import DashboardNFTReducer from "./slices/dashboardNFT/reducer";

// Pages > Team
import TeamDataReducer from "./slices/team/reducer";

// File Manager
import FileManagerReducer from "./slices/fileManager/reducer";

// To do
import TodosReducer from "./slices/todos/reducer";

// Job
import JobReducer from "./slices/jobs/reducer";

// API Key
import APIKeyReducer from "./slices/apiKey/reducer";

import NotificationFlagSlice from "./redux/reducers/notificationFlagReducer";

import TimelineDataSlice from "./redux/reducers/timelineReducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Calendar: CalendarReducer,
    Chat: chatReducer,
    Projects: ProjectsReducer,
    Ecommerce: EcommerceReducer,
    Tasks: TasksReducer,
    Crypto: CryptoReducer,
    Tickets: TicketsReducer,
    Crm: CrmReducer,
    Invoice: InvoiceReducer,
    Mailbox: MailboxReducer,
    DashboardAnalytics: DashboardAnalyticsReducer,
    DashboardCRM: DashboardCRMReducer,
    DashboardEcommerce: DashboardEcommerceReducer,
    DashboardCrypto: DashboardCryptoReducer,
    DashboardProject: DashboardProjectReducer,
    DashboardNFT: DashboardNFTReducer,
    Team: TeamDataReducer,
    FileManager: FileManagerReducer,
    Todos: TodosReducer,
    Jobs: JobReducer,
    NotificationFlag : NotificationFlagSlice,
    TimelineData : TimelineDataSlice,
    APIKey: APIKeyReducer
});

export default rootReducer;