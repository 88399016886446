import React from 'react';

import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row, Table, Button } from 'reactstrap';
import { Page, Text, View, Image, Document, StyleSheet, PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import { dateformatncrm } from '../../../helpers/FunctionalHelper/date-helper';

import { datetimeformatncrm } from "../../../helpers/FunctionalHelper/date-helper";

import { API_CLIENT_AUTH_BEARER } from "../../../helpers/api-helper";

const apiAuth = new API_CLIENT_AUTH_BEARER();

const GeneralDataTab = (props) => {

    const {data} = props;

    const [dataForm, setDataForm] = React.useState({});
    const [canEdit, setCanEdit] = React.useState(false);

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        setDataForm(dataFormInput)
    }

    let LIST_CAB_FORM_PARENT = [];
    if(data.cab_forms){
        LIST_CAB_FORM_PARENT = data.cab_forms.filter(item => item.no === item.parent_no);
    }

    const downloadFile = async (e, file, idx) => {
        e.preventDefault()
        if(file !== undefined)  {
          const resFile = await apiAuth.getFile('/downloadAttachment/' + data.change_id+'/'+idx);
          if(resFile !== undefined){
            saveAs(new Blob([resFile.data], {type:file.mime_type}), file.file_name);
          }
        }
    }

    // Download PDF - CR Summary
    const PDFDocument = () => {
        const styles = StyleSheet.create({
            rowTitle: {
                display: 'flex',
                flexDirection: 'row',
                borderBottom: "0px"
            },
            row: {
                display: 'flex',
                flexDirection: 'row',
            },
            rowTable: {
                display: 'flex',
                flexDirection: 'row',
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
            },
            rowTableBody: {
                display: 'flex',
                flexDirection: 'row',
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
            },
            co25: {
                padding: "5px", 
                width: "25%", 
                float: "left", 
                border: "1px solid black"
            },
            col30: {
                padding: "5px", 
                width: "30%", 
                float: "left", 
                border: "1px solid black"
            },
            col33: {
                padding: "5px", 
                width: "33%", 
                float: "left", 
            },
            col50: {
                padding: "5px", 
                width: "50%", 
                float: "left", 
            },
            col70: {
                padding: "5px", 
                width: "70%", 
                float: "left", 
                border: "1px solid black"
            },
            col100: {
                padding: "5px", 
                width: "100%", 
                float: "left", 
            },
            pagetitle: {
                fontSize: "12px",
                fontWeight: "600",
                textAlign: "center",
                marginBottom: "10px"
            },
            headtitle: {
                fontSize: "12px",
                fontWeight: "600",
                textAlign: "left",
                textDecoration: "underline"
            },
            title: {
                fontSize: "10px",
                fontWeight: "600",
                color: "#495057",
                marginBottom: "5px"
            },
            subtitle: {
                fontSize: "9px",
                color: "#9698a5"
            },
            timeline_head_table_no: {
                padding: "5px",
                width: "5%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_startdate: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_enddate: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_actdesc: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_status: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_impact: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_detailimpact: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
                borderRight: "1px solid black"
            },
            timeline_body_table_no: {
                padding: "5px",
                width: "5%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_startdate: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_enddate: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_actdesc: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_status: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_impact: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_detailimpact: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
                borderRight: "1px solid black"
            },
            implementor_head_table_no: {
                padding: "5px",
                width: "5%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_company: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_name: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_phone: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_email: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_netype: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_networkname: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_regionne: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_usertype: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_userneed: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
                borderRight: "1px solid black"
            },
            implementor_body_table_no: {
                padding: "5px",
                width: "5%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_company: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_name: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_phone: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_email: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_netype: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_networkname: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_regionne: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_usertype: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_userneed: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
                borderRight: "1px solid black"
            },
            padding: {
                padding: "5px" 
            },
            RowBottomBorder: {
                display: 'flex',
                flexDirection: 'row',
                borderTop: "1px solid black"
            },
          });

        return (
            <Document>
                <Page size="A4" style={{padding: "40px"}}>
                    <Text style={styles.pagetitle} >CR ID : {data.change_id}</Text>
                    <View style={styles.rowTitle}>
                        <View style={styles.col100}>
                            <Text style={styles.headtitle}>Categorization</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Change Location Company :</Text>
                            <Text style={styles.subtitle}>{data.oc_change_location_company}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Request Category :</Text>
                            <Text style={styles.subtitle}>{data.oc_tier_1}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Domain Category :</Text>
                            <Text style={styles.subtitle}>{data.oc_domain_category}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Sub Domain Category :</Text>
                            <Text style={styles.subtitle}>{data.oc_tier_2}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Activity List :</Text>
                            <Text style={styles.subtitle}>{data.activity_list}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Change Category :</Text>
                            <Text style={styles.subtitle}>{data.change_category}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Impact :</Text>
                            <Text style={styles.subtitle}>{data.impact}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Risk :</Text>
                            <Text style={styles.subtitle}>{data.risk}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Priority :</Text>
                            <Text style={styles.subtitle}>{data.priority}</Text>
                        </View>
                        <View style={styles.col50}>
                        </View>
                    </View>
                    <View style={styles.row}>
                    <View style={styles.col50}>
                            <Text style={styles.title}>Description of Change :</Text>
                            <Text style={styles.subtitle}>{data.description_of_change}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Reason for Change :</Text>
                            <Text style={styles.subtitle}>{data.reason_for_change}</Text>
                        </View>
                    </View>
                    <View style={styles.rowTitle}>
                        <View style={styles.col100}>
                            <Text style={styles.headtitle}>Information</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Region :</Text>
                            {data.region && data.region.map(region =>
                                <Text style={styles.subtitle}>{region}</Text>
                            )}
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Responsible Approval :</Text>
                            {data.responsible_approval && data.responsible_approval.map(respons =>
                                <Text style={styles.subtitle}>{respons}</Text>
                            )}
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>NE ID Change Target :</Text>
                            <Text style={styles.subtitle}>{data.ne_id_change_target}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>NE Name Change Target :</Text>
                            <Text style={styles.subtitle}>{data.ne_name_change_target}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Detailed Location :</Text>
                            <Text style={styles.subtitle}>{data.detailed_location}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Vendor Name :</Text>
                            {data.vendor_name && data.vendor_name.map(vendor =>
                                <Text style={styles.subtitle}>{vendor}</Text>
                            )}
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Impact to NE :</Text>
                            <Text style={styles.subtitle}>{data.impact_to_ne}</Text>
                        </View>
                        <View style={styles.col50}>
                        </View>
                    </View>
                    {data.impact_to_ne === "Yes" &&
                    <React.Fragment>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>NE Name (Impacted) List :</Text>
                            <Text style={styles.subtitle}>{data.ne_name_impacted_list}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>NE Impact Description :</Text>
                            <Text style={styles.subtitle}>{data.ne_impact_description}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>NE Outage Duration (min) :</Text>
                            <Text style={styles.subtitle}>{data.ne_outage_duration_min}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Affected Service Description :</Text>
                            <Text style={styles.subtitle}>{data.affected_services_description}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Service Outage Duration (min) :</Text>
                            <Text style={styles.subtitle}>{data.service_outage_duration_min}</Text>
                        </View>
                        <View style={styles.col50}>
                        </View>
                    </View>
                    </React.Fragment>
                    }
                    <View style={styles.rowTitle}>
                        <View style={styles.col100}>
                            <Text style={styles.headtitle}>Timeline</Text>
                        </View>
                    </View>
                    <View style={{padding: "5px"}}>
                        <View style={styles.rowTable}>
                            <Text style={styles.timeline_head_table_no}>No</Text>
                            <Text style={styles.timeline_head_table_startdate}>Plan Start</Text>
                            <Text style={styles.timeline_head_table_enddate}>Plan End</Text>
                            <Text style={styles.timeline_head_table_actdesc}>Act Desc</Text>
                            <Text style={styles.timeline_head_table_status}>Status</Text>
                            <Text style={styles.timeline_head_table_impact}># Impacted NE</Text>
                            <Text style={styles.timeline_head_table_detailimpact}>Detail of Impacted NE</Text>
                        </View>
                        {data.timelines && data.timelines.map((time, idx) =>
                            <View style={styles.rowTableBody}>
                                <Text style={styles.timeline_body_table_no}>{idx+1}</Text>
                                <Text style={styles.timeline_body_table_startdate}>{datetimeformatncrm(time.plan_start_time)}</Text>
                                <Text style={styles.timeline_body_table_enddate}>{datetimeformatncrm(time.plan_end_time)}</Text>
                                <Text style={styles.timeline_body_table_actdesc}>{time.activity_description}</Text>
                                <Text style={styles.timeline_body_table_status}>{time.plan_status}</Text>
                                <Text style={styles.timeline_body_table_impact}>{time.number_of_impacted_ne}</Text>
                                <Text style={styles.timeline_body_table_detailimpact}>{time.detailed_of_impacted_ne}</Text>
                            </View>
                        )}
                        <View style={styles.RowBottomBorder}></View>
                    </View>
                    <View style={styles.rowTitle}>
                        <View style={styles.col100}>
                            <Text style={styles.headtitle}>User Access Management (Implementor)</Text>
                        </View>
                    </View>
                    <View style={{padding: "5px"}}>
                        <View style={styles.rowTable}>
                            <Text style={styles.implementor_head_table_no}>No</Text>
                            <Text style={styles.implementor_head_table_company}>Company</Text>
                            <Text style={styles.implementor_head_table_name}>Name</Text>
                            <Text style={styles.implementor_head_table_phone}>Phone</Text>
                            <Text style={styles.implementor_head_table_email}>Email</Text>
                            <Text style={styles.implementor_head_table_netype}>Ne Type</Text>
                            <Text style={styles.implementor_head_table_networkname}>Network Name Element</Text>
                            <Text style={styles.implementor_head_table_regionne}>Region Ne</Text>
                            <Text style={styles.implementor_head_table_usertype}>User Type</Text>
                            <Text style={styles.implementor_head_table_userneed}>User Needed</Text>
                        </View>
                        {data.ai_user_access && data.ai_user_access.map((user, idx) =>
                            <View style={styles.rowTableBody}>
                                <Text style={styles.implementor_body_table_no}>{idx+1}</Text>
                                <Text style={styles.implementor_body_table_company}>{user.vendor_name}</Text>
                                <Text style={styles.implementor_body_table_name}>{user.executor_pic_name}</Text>
                                <Text style={styles.implementor_body_table_phone}>{user.executor_pic_phone}</Text>
                                <Text style={styles.implementor_body_table_email}>{user.executor_pic_email}</Text>
                                <Text style={styles.implementor_body_table_netype}>{user.ne_type}</Text>
                                <Text style={styles.implementor_body_table_networkname}>{user.network_element_name}</Text>
                                <Text style={styles.implementor_body_table_regionne}>{user.region_ne}</Text>
                                <Text style={styles.implementor_body_table_usertype}>{user.user_type}</Text>
                                <Text style={styles.implementor_body_table_userneed}>{user.user_needed}</Text>
                            </View>
                        )}
                        <View style={styles.RowBottomBorder}></View>
                    </View>
                </Page>
            </Document>
        );
    }

    const downloadReportDocument = async (e, blob) => {

        e.preventDefault()
        e.persist();
        saveAs(blob, `Report.pdf`)
    }

    return (
        <React.Fragment>
            {data !== undefined ? 
            <React.Fragment>
            {/* <Row style={{marginBottom: "0px"}}>
                <Col md={3}>
                    <BlobProvider document={PDFDocument()}>
                        {({ blob, url, loading, error }) => {
                            return (
                                <Button
                                    color="primary"
                                    size="md"
                                    onClick={(e) => downloadReportDocument(e, blob)}
                                    style={{width: "100%"}}
                                >
                                    <i className='ri-save-line'></i><span> Save as PDF</span>
                                </Button>
                            )
                        }}
                    </BlobProvider>
                </Col>
            </Row> */}
            <Row style={{padding: "30px 50px", textAlign: "left", backgroundColor: "#ffffff"}}>
                <Col md={12} style={{marginBottom: "10px"}}>
                    <h3 style={{textDecoration: "underline"}}>Categorization & Clasification</h3>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Change Location Company :</h5>
                        <p className="text-muted">{data.oc_change_location_company ? data.oc_change_location_company : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Request Category :</h5>
                        <p className="text-muted">{data.oc_tier_1 ? data.oc_tier_1 : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Domain Category :</h5>
                        <p className="text-muted">{data.oc_domain_category ? data.oc_domain_category : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Sub-Domain Category :</h5>
                        <p className="text-muted">{data.oc_tier_2 ? data.oc_tier_2 : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Activity List :</h5>
                        <p className="text-muted">{data.activity_list ? data.activity_list : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Change Category :</h5>
                        <p className="text-muted">{data.change_category ? data.change_category : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Impact :</h5>
                        <p className="text-muted">{data.impact ? data.impact : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Risk :</h5>
                        <p className="text-muted">{data.risk ? data.risk : "-"}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <h5>Priority :</h5>
                        <p className="text-muted">{data.priority ? data.priority : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Description of Change :</h5>
                        <p className="text-muted">{data.description_of_change ? data.description_of_change : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Reason for Change :</h5>
                        <p className="text-muted">{data.reason_for_change ? data.reason_for_change : "-"}</p>
                    </div>
                </Col>
                {data.attachments !== undefined &&
                <Col md={12}>
                    <div className="mb-3">
                        <h5>File Attachment</h5>
                    </div>
                </Col>
                }

                {data.attachments !== undefined &&
                <Col md={6}>
                    <Row style={{marginBottom: "10px"}}>
                        <Col sm={8} style={{maxWidth: "242px"}}>
                            <h5 style={{marginLeft: "5px"}}><i className="ri-checkbox-blank-circle-fill" style={{fontSize: "12px"}}></i> Method of Procedure </h5>
                        </Col>
                        <Col sm={4}>
                            <div style={{fontSize: "16px"}}>
                                <Button style={{width: "100%", borderRadius: "10px"}} color='info' size='sm' onClick={(e) => {downloadFile(e, data.attachments[0], 0)}}>
                                    Download
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "10px"}}>
                        <Col sm={8} style={{maxWidth: "242px"}}>
                            <h5 style={{marginLeft: "5px"}}><i className="ri-checkbox-blank-circle-fill" style={{fontSize: "12px"}}></i> Evidence </h5>
                        </Col>
                        <Col sm={4}>
                            <div style={{fontSize: "16px"}}>
                                <Button style={{width: "100%", borderRadius: "10px"}} color='info' size='sm' onClick={(e) => {downloadFile(e, data.attachments[1], 1)}}>
                                    Download
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "10px"}}>
                        <Col sm={8} style={{maxWidth: "242px"}}>
                            <h5 style={{marginLeft: "5px"}}><i className="ri-checkbox-blank-circle-fill" style={{fontSize: "12px"}}></i> Supporting Attachment </h5>
                        </Col>
                        <Col sm={4}>
                            <div style={{fontSize: "16px"}}>
                                <Button style={{width: "100%", borderRadius: "10px"}} color='info' size='sm' onClick={(e) => {downloadFile(e, data.attachments[2], 2)}}>
                                    Download
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
                }


                <Col md={12} style={{borderTop: "2px solid #dedede", paddingTop: "10px", marginBottom: "10px"}}>
                    <h3 style={{textDecoration: "underline"}}>Information</h3>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Region :</h5>
                        <p className="text-muted">{data.region ? data.region.map(region =>
                                <p>{region}</p>
                            )
                            : "-"
                        }
                        </p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Responsible Approval :</h5>
                        <p className="text-muted">{data.responsible_approval ? data.responsible_approval.map(respon =>
                                <p>{respon}</p>
                            )
                            : "-"
                        }
                        </p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>NE ID Change Target :</h5>
                        <p className="text-muted">{data.ne_id_change_target ? data.ne_id_change_target : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>NE Name Change Target :</h5>
                        <p className="text-muted">{data.ne_name_change_target ? data.ne_name_change_target : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Detailed Location :</h5>
                        <p className="text-muted">{data.detailed_location ? data.detailed_location : "-"}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5>Vendor Name :</h5>
                        <p className="text-muted">{data.vendor_name ? data.vendor_name.map(vendor =>
                            <p>{vendor}</p>
                            )
                            : "-"
                        }
                        </p>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <h5>Impact to NE :</h5>
                        <p className="text-muted">{data.impact_to_ne}</p>
                    </div>
                </Col>
                {data.impact_to_ne === "Yes" &&
                    <React.Fragment>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5>NE Name (Impacted) List :</h5>
                            <p className="text-muted">{data.ne_name_impacted_list ? data.ne_name_impacted_list : "-"}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5>NE Impact Description :</h5>
                            <p className="text-muted">{data.ne_impact_description ? data.ne_impact_description : "-"}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5>NE Outage Duration (min) :</h5>
                            <p className="text-muted">{data.ne_outage_duration_min ? data.ne_outage_duration_min : "-"}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5>Affected Service :</h5>
                            <p className="text-muted">{data.affected_services ? data.affected_services.join(", ") : "-"}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5>Affected Service Description :</h5>
                            <p className="text-muted">{data.affected_services_description ? data.affected_services_description : "-"}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5>Service Outage Duration (min) :</h5>
                            <p className="text-muted">{data.service_outage_duration_min ? data.service_outage_duration_min : "-"}</p>
                        </div>
                    </Col>
                    </React.Fragment>
                }


                <Col md={12} style={{borderTop: "2px solid #dedede", paddingTop: "10px", marginBottom: "10px"}}>
                    <h3 style={{textDecoration: "underline"}}>Timeline</h3>
                </Col>
                {data.timelines && 
                    <React.Fragment>
                    <Col md={12}>
                        <div className="table-responsive">
                        <Table className="align-middle mb-3 text-align-header-centre" style={{border: "1px solid #dedede"}}>
                        <thead style={{backgroundColor: "#405189", color: "#ffffff"}}>
                            <tr>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>No</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Release ID</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Plan Start</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Plan End</th>
                                <th scope="col" style={{Width : '125px', borderRight: "1px solid #ffffff"}}>Act Desc</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Status</th>
                                <th scope="col" style={{Width : '150px', borderRight: "1px solid #ffffff"}}># Impacted NE</th>
                                <th scope="col" style={{Width : '150px'}}>Detail of Impacted NE</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.timelines.map((time, idx) =>
                            <tr className="text-center" key={idx}>
                            <td>{idx+1}</td>
                            <td>{time.release_id}</td>
                            <td>{datetimeformatncrm(time.plan_start_time)}</td>
                            <td>{datetimeformatncrm(time.plan_end_time)}</td>
                            <td>{time.activity_description}</td>
                            <td style={{textAlign : 'center'}}>Proposed</td>
                            <td style={{textAlign : 'center'}}>{time.number_of_impacted_ne}</td>
                            <td>{time.detailed_of_impacted_ne}</td>
                            </tr>    
                        )}
                        </tbody>
                        </Table>
                        </div>
                    </Col>
                    </React.Fragment>
                }


                <Col md={12} style={{borderTop: "2px solid #dedede", paddingTop: "10px", marginBottom: "10px"}}>
                    <h3 style={{textDecoration: "underline"}}>User Access Management (Implementor)</h3>
                </Col>
                {data.ai_user_accesses && 
                    <React.Fragment>
                    <Col md={12}>
                        <div className="table-responsive">
                        <table className="table align-middle table-nowrap table-striped-columns mb-0" style={{border: "1px solid #dedede"}}>
                            <thead style={{backgroundColor: "#405189", color: "#ffffff"}}>
                                <tr>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Company</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Name</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Phone Number</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Email</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Ne Type</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Network Name Elmnt</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Region Ne</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>User Type</th>
                                <th scope="col">User Needed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.ai_user_accesses.map((user, idx) =>
                                <tr key={idx}>
                                    <td>{user.vendor_name}</td>
                                    <td>{user.executor_pic_name}</td>
                                    <td>{user.executor_pic_phone}</td>
                                    <td>{user.executor_pic_email}</td>
                                    <td>{user.ne_type}</td>
                                    <td>{user.network_element_name}</td>
                                    <td>{user.region_ne}</td>
                                    <td>{user.user_type}</td>
                                    <td>{user.user_needed}</td>
                                </tr>    
                                )}
                            </tbody>
                        </table>
                        </div>
                    </Col>
                    </React.Fragment>
                }
            </Row>
            </React.Fragment>
            : ""
            }
        </React.Fragment>
    );
};

export default GeneralDataTab;