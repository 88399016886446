import CRForm from "../pages/CRForm";
import CRApproval from "../pages/CRForm/CRApproval";
import CRCreation from "../pages/CRForm/CRCreation";
import CRDetail from "../pages/CRForm/CRDetail";
import CRFormListApproval from "../pages/CRForm/CRFormListApproval";

const crRoutes = [
    { path: "/cr-creation", component: <CRCreation /> },
    { path: "/cr-detail/:id", component: <CRDetail /> },
    { path: "/cr-list", component: <CRForm /> },
    { path: "/cr-list-draft", component: <CRForm /> },
    { path: "/cr-list-rfa", component: <CRForm /> },
    { path: "/cr-list-rfc", component: <CRForm /> },
    { path: "/cr-list-sfa", component: <CRForm /> },
    { path: "/cr-list-scheduled", component: <CRForm /> },
    { path: "/cr-list-complete", component: <CRForm /> },
    { path: "/cr-approval", component: <CRApproval /> },
    { path: "/cr-approval/:id", component: <CRApproval /> },
    { path: "/cr-approval/:id/:milestone", component: <CRApproval /> },
    { path: "/cr-list/:milestone", component: <CRFormListApproval /> },
    { path: "/cr-list/:milestone/:email", component: <CRFormListApproval /> },
];

export { crRoutes };