import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import { notificationFlagDelete } from '../../redux/reducers/notificationFlagReducer';
import { createSelector } from 'reselect';

const toastifyNotificationConfig = {
    position: "top-right", 
    hideProgressBar: true, 
    closeOnClick: true, 
    autoClose: false
};

const showNotificationFlags = (alert_title, alert_message, alert_status, idx, onFlagClose) => {

    const defaultNotify = (alert_message, idx) => {
        toast(alert_message, { onClose: () => onFlagClose(), ...toastifyNotificationConfig, className: 'bg-primary text-white' })
    };
    const successNotify = (alert_message, idx) => {
        toast(alert_message, { onClose: () => onFlagClose(), ...toastifyNotificationConfig, className: 'bg-success text-white' })
    };
    const warningNotify = (alert_message, idx) => {
        toast(alert_message, { onClose: () => onFlagClose(), ...toastifyNotificationConfig, className: 'bg-warning text-white' })
    };
    const errorNotify = (alert_message, idx) => {
        toast(alert_message, { onClose: () => onFlagClose(), ...toastifyNotificationConfig, className: 'bg-danger text-white' })
    };

    if(alert_status === "default"){
        let message_flag = alert_message ? alert_message : "Welcome Back! This is a Toast Notification";
        message_flag = alert_title + message_flag
        return defaultNotify(message_flag, idx)
    }
    if(alert_status === "success"){
        let message_flag = alert_message ? alert_message : "Your action was successfully sent";
        message_flag = alert_title + message_flag
        return successNotify(message_flag, idx)
    }
    if(alert_status === "warning"){
        let message_flag = alert_message ? alert_message : "Warning ! Something went wrong try again";
        message_flag = alert_title + message_flag
        return warningNotify(message_flag, idx)
    }
    if(alert_status === "error"){
        let message_flag = alert_message ? alert_message : "Error ! An error occurred.";
        message_flag = alert_title + message_flag
        return errorNotify(message_flag, idx)
        
    }else{
        return null
    }
}

const NotificationFlagComponent = () => {

    const dispatch = useDispatch()
    const reducerNoficationFlag = useSelector(state => state.NotificationFlag)

    const AllState = useSelector(state => state);

    const removeNotificationFlag = (idx) => {
        dispatch(notificationFlagDelete(idx))
    }

    const generateFlagType = () => {
        for(let idx = 0; idx < reducerNoficationFlag.notification_alert.length; idx++){
            const notif = reducerNoficationFlag.notification_alert[idx]
            let alert_message
            let alert_title = ''
            if(notif.alert_title){
                alert_title = notif.alert_title+" => "
            }
            if(notif.alert_message){
                if((typeof notif.alert_message === 'object')){
                    if(notif.alert_message.message){
                        alert_message = notif.alert_message.message
                    }
                }else{
                    alert_message = notif.alert_message
                }

                alert_message = JSON.stringify(alert_message)
            }
            
            showNotificationFlags(alert_title, alert_message, notif.alert_status, idx, () => removeNotificationFlag(idx) )
        }
    }

    React.useEffect(() => {
        generateFlagType()
    }, [reducerNoficationFlag.notification_alert]);

    return(
        <React.Fragment>
            <ToastContainer />
        </React.Fragment>
    )
}

export default React.memo(NotificationFlagComponent);