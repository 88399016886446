import React from 'react'

export default function CurrentStatusBoxCrListTable(props) {

  const {currentStatus} = props;

  let class_name = 'cr-list__status-cr-background-color-table'
  
  switch(currentStatus) {
    case 'Draft':
      class_name = class_name+'-draft'
      break;
    case 'Request for Authorization':
      class_name = class_name+'-rfa'
      break;
    case 'Request for Change':
      class_name = class_name+'-rfc'
      break;
    case 'Scheduled for Approval':
      class_name = class_name+'-sfa'
      break;
    case 'Scheduled':
      class_name = class_name+'-scheduled'
      break;
    case 'Completed':
      class_name = class_name+'-completed'
      break;
    case 'Closed':
      class_name = class_name+'-closed'
      break;
    default:
      class_name = class_name
  }

  return (
    <React.Fragment>
      <div className={'cr-list__status-cr-background-color-table '+class_name}>
        {currentStatus}
      </div>
    </React.Fragment>
  )
}
