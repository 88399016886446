import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import BackgroundAuth from '../AuthenticationInner/BackgroundAuth';

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
// import { loginUser, resetLoginFlag } from "../../slices/thunks";

import logoLight from "../../assets/images/logo-ncrm1.png";
import logoTelkomselFull from "../../assets/images/Logo/Telkomsel_2021.png";
import { createSelector } from 'reselect';
import { loginUser, resetLoginFlag } from '../../middleware/auth/loginThunk';
import { HEADER_TITLE_NAME } from '../../config/config-general';

//import images

const Login = (props) => {
    
    const dispatch = useDispatch();

    const selectLayoutState = (state) => state;
    const loginpageData = createSelector(
        selectLayoutState,
        (state) => ({
            user: state.Account.user,
            error: state.Login.error,
            loading: state.Login.loading,
            errorMsg: state.Login.errorMsg,
        })
    );
    // Inside your component
    const {
        user, error, loading, errorMsg
    } = useSelector(loginpageData);

    const [userLogin, setUserLogin] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);


    useEffect(() => {
        if(sessionStorage.getItem("authUser")){
            sessionStorage.removeItem("authUser");
        }
        if (user && user) {
            const updatedUserData = process.env.REACT_APP_DEFAULTAUTH === "firebase" ? user.multiFactor.user.email : user.email;
            const updatedUserPassword = process.env.REACT_APP_DEFAULTAUTH === "firebase" ? "" : user.confirm_password;
            setUserLogin({
                email: updatedUserData,
                password: updatedUserPassword
            });
        }
    }, [user]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email || "" || '',
            password: userLogin.password || "" || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
        }
    });

    //handleTwitterLoginResponse
    // const twitterResponse = e => {}

    //for facebook and google authentication
    const socialResponse = type => {
        signIn(type);
    };


    useEffect(() => {
        if (errorMsg) {
            setTimeout(() => {
                dispatch(resetLoginFlag());
            }, 3000);
        }
    }, [dispatch, errorMsg]);
    document.title = "SignIn "+HEADER_TITLE_NAME;
    return (
        <React.Fragment>
            <BackgroundAuth>
                <div className="auth-page-content mt-lg-3">
                    <Container>
                        {/* <Row>
                            <Col lg={12} className='mt-md-30'>
                                <div className="text-center mt-sm-2 mb-2 text-white-50">
                                    <div>
                                    <Link to="/" className="d-inline-block auth-logo" style={{width: "-webkit-fill-available", maxWidth: "562px"}}>
                                            <img src={logoLight} alt="" style={{padding : '5px', marginTop : '50px'}} className='logo--login-page'/>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row> */}

                        <Row className="justify-content-center login-form-content--auth-page">
                            <Col md={8} lg={8} xl={8}>
                                <Card className="card-login--auth-page">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-sm-2 mb-2 text-white-50 logo-company--card-auth-page">
                                            <div>
                                                <Link to="/" className="d-inline-block auth-logo" style={{width: "-webkit-fill-available", width: "100%", maxWidth: "562px"}}>
                                                    <img src={logoTelkomselFull} alt="" className='side-login-logo--auth-page' style={{margin: "0px"}}/>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="text-center mt-sm-2 mb-2 text-white-50">
                                            <div>
                                                <Link to="/" className="d-inline-block auth-logo" style={{width: "-webkit-fill-available", width: "100%", maxWidth: "562px"}}>
                                                    <img src={logoLight} alt="" style={{padding : '5px', width: "100%"}} className='logo--login-page' />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue to CHAIRMAN.</p>
                                        </div>
                                        {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        {/* <Link to="/forgot-password" className="text-muted">Forgot password?</Link> */}
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password || ""}
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="danger" disabled={error ? null : loading ? true : false} className="btn btn-chairman--auth-page btn-danger w-100" type="submit">
                                                        {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                        Sign In
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                                {/* <div className="mt-2 text-center">
                                    <p className="mb-0">Don't have an account ? <p className="fw-semibold text-primary text-decoration-underline"> Please Contact Administrator </p> </p>
                                </div> */}

                            </Col>
                        </Row>
                    </Container>
                </div>
            </BackgroundAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);