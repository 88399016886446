import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { ServiceAffectedCharts } from './DashboardPieCharts';
import { useSelector, useDispatch } from "react-redux";
import { getSalesChartsData } from '../../slices/thunks';
import { createSelector } from 'reselect';
import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const ServiceAffected = (crdata) => {
    const dispatch = useDispatch();

    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    const [chartData, setchartData] = useState([{
        "name" : "SMS",
        "data" : [0]
    },{
        "name" : "MMS",
        "data" : [0]
    },{
        "name" : "Data",
        "data" : [0]
    },{
        "name" : "Voice",
        "data" : [0]
    }]);
    const [dataList, setDataList] = React.useState([]);
    const [totalData, setTotalData] = React.useState(0);
    const [dataSearchDate, setDataSearchDate] = React.useState({});

    const current_date = new Date();
    const next_day_date = new Date(new Date().setDate(new Date().getDate()));
    const next_month_date = new Date(next_day_date.setMonth(next_day_date.getMonth() + 1));
    const previous_day_date = new Date(new Date().setDate(new Date().getDate() + 1));
    const previous_month_date = new Date(previous_day_date.setMonth(previous_day_date.getMonth() - 1));

    const getChartData = async() => {
        for(let i = 0; i < chartData.length ; i++)  {
            chartData[i].data[0] = crdata.crdata && crdata.crdata[(chartData[i].name).toLowerCase()] !== undefined ? parseInt(crdata.crdata[(chartData[i].name).toLowerCase()]) : 0;
        }
        setchartData(chartData);
    }

    React.useEffect(() => {
        getChartData();
    }, [crdata]);

    return (
        <React.Fragment>
            <Col md={12}>
                <Card style={{height: "418px"}}>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Service Affected</h4>
                        <div className="flex-shrink-0">
                        </div>
                    </CardHeader>
                    <div className="card-body pb-0">
                        <div id="sales-forecast-chart" className="apex-charts">
                            <ServiceAffectedCharts series={chartData} dataColors='["--vz-primary", "--vz-success", "--vz-warning"]'/>
                        </div>
                    </div>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default ServiceAffected;