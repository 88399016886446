import React from 'react';
import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row, Button } from 'reactstrap';
import Select from "react-select";

// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { API_CLIENT_AUTH_BEARER } from '../../../helpers/api-helper';
import { CR_CURRENT_STATUS_CAN_EDIT } from '../../../config/data/CR_CURRENT_STATUS_CAN_EDIT';
import { attachements } from '../../../common/data/chat';
import { useRef } from 'react';

const apiAuth = new API_CLIENT_AUTH_BEARER();

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const InitialAttachmentPlaceholderState = [
    {
        "attachment_name" : "Method of Procedure",
        "file" : null,
        "size" : null,
        "file_name" : null,
    },{
        "attachment_name" : "Evidence",
        "file" : null,
        "size" : null,
        "file_name" : null,
    },{
        "attachment_name" : "Supporting Attachment",
        "file" : null,
        "size" : null,
        "file_name" : null,
    }
];

const ClassificationAttachmentName = [
    {
        name : 'Method of Procedure', 
        title : 'Method of Procedure',
        info : "Berisi dokumen step by step activity, PIC, scenario fallback, dll. Anda dapat download template MOP disamping.",
        accepted_file_type : ".doc, .docx, .xlsx, .xls, .pdf, .jpg, .png, .gif, .txt, .zip, .rar",
        info_file_type : "docx, xls, xlsx, pdf, jpg, png, gif, txt, zip, rar",
        attachment_template : true,
    },
    {
        name : 'Evidence', 
        title : 'Evidence',
        info : "Berisi dokumen evidence sebagai dasar permohonan activity seperti Nota Dinas, Email, Capture Alarm, MOM, dll.",
        accepted_file_type : ".doc, .docx, .xlsx, .xls, .pdf, .jpg, .png, .gif, .txt, .zip, .rar",
        info_file_type : "docx, xls, xlsx, pdf, jpg, png, gif, txt, zip, rar"
    },
    {
        name : 'Supporting Attachment', 
        title : 'Supporting Attachment',
        info : "Berisi dokumen pendukung kelengkapan activity seperti script, timeplan, dll.",
        accepted_file_type : ".doc, .docx, .xlsx, .xls, .pdf, .jpg, .png, .gif, .txt, .zip, .rar",
        info_file_type : "docx, xls, xlsx, pdf, jpg, png, gif, txt, zip, rar"
    }
];

const ViewInputFileClassification = (props) => {

    let attachmentRef = useRef(null);

    const {indexAttachment, handleInputFileForm, handleDeleteFileForm, downloadAttachmentTemplate, attachmentList, currentAttachmentName, currentAttachmentTitle, currentAttachmentInfo, attachmentAcceptTypeFile, currentAttachmentFileTypeInformation, attachmentTemplate} = props

    const indexNameFile = attachmentList.findIndex(item => item.attachment_name === currentAttachmentName);

    let objectNameFile

    if(indexNameFile >= 0){
        objectNameFile = attachmentList[indexNameFile];
    }

    const downloadMOPFile = async (e) => {
        const resFile = await apiAuth.getFile('/downloadTemplate/MOP');
        if(resFile !== undefined && resFile.data !== undefined){
            saveAs(new Blob([resFile.data], {type:resFile.data.type}), "Template MOP All Domain.docx");
        }
    }

    const deleteFileAttachment = (e, index) => {
        attachmentRef.current._reactInternals.key = indexNameFile+"uploadFile";
        if(index >= 0){
            handleDeleteFileForm(index)
            deleteFileAttachment()
        }
        
    }

    if(objectNameFile){
        return (
            <React.Fragment>
                <Row key={indexAttachment} style={{paddingTop: "10px"}}>
                    <Col md={4}>
                        <div className="mb-3">
                            <Label htmlFor="attachmentName" className="form-label">Attachment Name
                            </Label>
                            <Input type="text" className="form-control" name="attachment_name" style={{backgroundColor: "#ffffff"}} disabled={true} value={currentAttachmentName} id="attachment_name" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <Label htmlFor="file" className="form-label">Upload File
                                <div style={{marginLeft: "5px"}} className={"icon-clasification-attachment"}> <i className={"ri-information-line"}></i> <span class="icon-text-clasification-attachment">{currentAttachmentTitle}<br/>
                                    {currentAttachmentInfo}</span>
                                </div>
                            </Label>
                            <Input
                                ref={attachmentRef}
                                type="file"
                                style={{marginBottom: "10px"}}
                                accept={attachmentAcceptTypeFile}
                                key={indexAttachment}
                                name={0}
                                onChange={(e) => {
                                    handleInputFileForm(e, "file", indexNameFile);
                                }}
                            />
                            <span style={{color: "#707070"}}><b>File Type</b> : {currentAttachmentFileTypeInformation}</span>
                        </div>
                    </Col>
                    <Col md={1}>
                        <div className="mb-3">
                            <Label htmlFor="delete_attachment" className="form-label">.</Label>
                            <Button className="form-control" name="download_template" size='sm' color="light" style={{color : '#FF6D60', marginTop : '5px'}} onClick={(e) => {deleteFileAttachment(e, indexNameFile)}}><i className={"ri-delete-bin-line"}>&nbsp;</i>Clear</Button>
                        </div>
                    </Col>
                    <Col md={3}>
                        {attachmentTemplate && (
                            <div className="mb-3">
                                <Label htmlFor="download_attachment" className="form-label">Download Template</Label>
                                <Button className="form-control" name="download_template" color="light" style={{border: "1px solid #dedede"}} onClick={(e) => {downloadMOPFile(e)}}><i className={"ri-download-2-fill"}></i> {currentAttachmentTitle} Template</Button>
                            </div>
                        )}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }else{
        return (
            <React.Fragment></React.Fragment>
        )
    }

    
}

const CRFormClasification = (props) => {

    const [optionActivityList, setOptionActivityList] = React.useState([]);

    const [dataForm, setDataForm] = React.useState({
        file_attacment : [...InitialAttachmentPlaceholderState]
    });
    const [isLoadingActivityList, setIsLoadingActivityList] = React.useState(false);
    const [dataAddAttachment, setDataAddAttachment] = React.useState([...InitialAttachmentPlaceholderState]);

    const [requiredClasification, setRequiredClasification] = React.useState([false, false, false]);

    const handleInputChange = (e, idxFlag) => {
        console.log("Data Test Flag", idxFlag);
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        props.onChangeForm(name, value);

        if(value !== null && value !== "" && idxFlag !== undefined){
            requiredClasification[idxFlag] = true
        }else{
            requiredClasification[idxFlag] = false
        }
        props.onChangeRequiredClasification(requiredClasification);

        setDataForm(dataFormInput)
    }

    const handleSelectForm = (e, name, idxFlag) => {
        const data_form = {...dataForm};
        const {value, label, impact, risk, change_category} = e;
        data_form[name] = value;
        if(name === "activity_list"){
            data_form["change_category"] = change_category;
            data_form["impact"] = impact;
            data_form["risk"] = risk;
            props.onChangeFormAll(data_form);
        }else{
            props.onChangeForm(name, value);
        }

        if(value !== null && value !== "" && idxFlag !== undefined){
            requiredClasification[idxFlag] = true
        }else{
            requiredClasification[idxFlag] = false
        }
        props.onChangeRequiredClasification(requiredClasification);

        setDataForm(data_form);
    }

    const handleInputFileForm = (e, name, index) => {
        let data_attachment = [...dataAddAttachment];
        const data_form = {...dataForm};

        const file_data = e.target.files[0];        

        data_attachment[index]["file"] = file_data;
        data_attachment[index]["size"] = file_data ? file_data.size : null;
        data_attachment[index]["file_name"] = file_data ? file_data.name : null;

        data_form["file_attacment"] = data_attachment;
        
        setDataAddAttachment(data_attachment);
        props.addAttachment(data_attachment);
        setDataForm(data_form);
    };

    const getDataActivityList = async() => {
        setIsLoadingActivityList(true)
        let oc_tier_1 = dataForm.oc_tier_1;
        if(oc_tier_1){
            if(oc_tier_1.includes('&')){
                oc_tier_1 = oc_tier_1.split(' & ')[1]
            }
        }
        const res = await apiAuth.get('/getActivityList?&srt=activity_description:-1&noPg=1&q={"categorization_tier_1" : {"$substring" : "'+oc_tier_1+'"}, "domain_category" : "'+dataForm.oc_domain_category+'", "categorization_tier_2" : "'+dataForm.oc_tier_2+'" }');
        if(res && res.data){
            let option_format = res.data.data.map(item => ( {value: item.activity_description, label: item.activity_description, impact : item.impact, risk : item.risk, change_category : item.change_category} ));
            option_format = [...new Map(option_format.map(item =>[item["value"], item])).values()];
            setOptionActivityList(option_format)
        }
        setIsLoadingActivityList(false)
    }

    const handleDeleteFileForm = (index) => {
        const data_form = {...dataForm};
        let data_attachment = [...dataAddAttachment];

        data_attachment[index]["file"] = null;
        data_attachment[index]["size"] = null;
        data_attachment[index]["file_name"] = null;

        data_form["file_attacment"] = data_attachment;
        
        setDataAddAttachment(data_attachment);
        props.addAttachment(data_attachment);
        setDataForm(data_form);
    }

    React.useEffect(() => {
        if(props.dataForm){
            setDataForm({
                ...dataForm,
                ...props.dataForm
            })
        }
    }, [props.dataForm])

    React.useEffect(() => {
        if(dataForm.current_status === undefined || dataForm.current_status === null || CR_CURRENT_STATUS_CAN_EDIT.includes(dataForm.current_status)){
            if(dataForm.oc_tier_2){
                getDataActivityList()
            }
        }
    }, [dataForm.oc_tier_2]);

    return (
        <React.Fragment>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="changeType" className="form-label">Activity List <span className="text-danger">*</span></Label>
                                <Select
                                    value={dataForm && ({value : dataForm.activity_list, label : dataForm.activity_list})}
                                    onChange={(e) => {
                                        handleSelectForm(e, 'activity_list', 0);
                                    }}
                                    options={optionActivityList}
                                    isLoading={isLoadingActivityList}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="changeCategory" className="form-label">Change Category</Label>
                                <Input type="text" className="form-control" name="change_category" disabled value={dataForm.change_category} onChange={(e) => handleInputChange(e)} id="descriptionOfChange" />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="impact" className="form-label">Impact</Label>
                                <Input type="text" className="form-control" name="impact" value={dataForm.impact} onChange={(e) => handleInputChange(e)} id="impact" disabled/>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="risk" className="form-label">Risk</Label>
                                <Input type="text" className="form-control" name="risk" value={dataForm.risk} onChange={(e) => handleInputChange(e)} id="risk" disabled/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="priority" className="form-label">Priority</Label>
                                <Input type="text" className="form-control" name="priority" value={dataForm.priority} onChange={(e) => handleInputChange(e)} id="priority" disabled/>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>

                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="descriptionOfChange" className="form-label">Description Of Change <span className="text-danger">*</span></Label>
                                <Input type="textarea" className="form-control" name="description_of_change" value={dataForm.description_of_change} onChange={(e) => handleInputChange(e, 1)} id="descriptionOfChange" />
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="reasonForChange" className="form-label">Reason For Change <span className="text-danger">*</span></Label>
                                <Input type="textarea" className="form-control" name="reason_for_change" value={dataForm.reason_for_change} onChange={(e) => handleInputChange(e, 2)} id="reasonForChange" />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div style={{border : '1px solid black', padding : '10px'}}>
                {ClassificationAttachmentName.map((caIdx, idx) => (
                    <React.Fragment>
                        <ViewInputFileClassification
                            indexAttachment={idx}
                            attachmentList={dataAddAttachment}
                            handleInputFileForm={handleInputFileForm}
                            currentAttachmentName={caIdx.name}
                            currentAttachmentTitle={caIdx.title}
                            currentAttachmentInfo={caIdx.info}
                            attachmentAcceptTypeFile={caIdx.accepted_file_type}
                            currentAttachmentFileTypeInformation={caIdx.info_file_type}
                            attachmentTemplate={caIdx.attachment_template}
                            handleDeleteFileForm={handleDeleteFileForm}
                        />
                    </React.Fragment>
                ))}
            </div>               
        </React.Fragment>
    );
};

export default CRFormClasification;