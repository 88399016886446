export const dataWidget = [
  {
    "id": 1,
    "bgColor": "bg-success",
    "cardColor": "primary",
    "color": "white",
    "label": "Draft",
    "badge": "ri-arrow-right-up-line",
    "badgeClass": "success",
    "counter": "125",
    "link": "View net earnings",
    "icon": "bx bxs-user-account",
    "iconClass" : "white bg-opacity-25",
    "counterClass": "text-white",
    "iconClass": "white",
    "labelClass": "white-50",
    "decimals": 0,
    "prefix": "",
    "suffix": ""
  },
  {
    "id": 2,
    "bgColor": "bg-primary",
    "cardColor": "primary",
    "color": "white",
    "label": "RFA",
    "badge": "ri-arrow-right-up-line",
    "badgeClass": "success",
    "percentage": "+16.24",
    "counter": "5",
    "link": "View net earnings",
    "icon": "mdi mdi-file-document-edit",
    "counterClass": "text-white",
    "iconClass": "white",
    "labelClass": "white-50",
    "decimals": 0,
    "prefix": "",
    "suffix": ""
  },
  {
    "id": 3,
    "bgColor": "bg-info",
    "cardColor": "primary",
    "color": "white",
    "label": "RFC",
    "badge": "ri-arrow-right-up-line",
    "badgeClass": "success",
    "percentage": "+16.24",
    "counter": "5",
    "link": "View net earnings",
    "icon": "mdi mdi-file-document-edit",
    "counterClass": "text-white",
    "iconClass": "white",
    "labelClass": "white-50",
    "decimals": 0,
    "prefix": "",
    "suffix": ""
  },
  {
    "id": 4,
    "bgColor": "bg-danger",
    "cardColor": "primary",
    "color": "white",
    "label": "SFA",
    "badge": "ri-arrow-right-up-line",
    "badgeClass": "success",
    "percentage": "+16.24",
    "counter": "50",
    "link": "View net earnings",
    "icon": "mdi mdi-file-document-edit",
    "counterClass": "text-white",
    "iconClass": "white",
    "labelClass": "white-50",
    "decimals": 0,
    "prefix": "",
    "suffix": ""
  },
  {
    "id": 5,
    "bgColor": "bg-warning",
    "cardColor": "primary",
    "color": "white",
    "label": "Scheduled",
    "badge": "ri-arrow-right-up-line",
    "badgeClass": "success",
    "percentage": "+16.24",
    "counter": "85",
    "link": "View net earnings",
    "icon": "mdi mdi-calendar",
    "counterClass": "text-white",
    "iconClass": "white",
    "labelClass": "white-50",
    "decimals": 0,
    "prefix": "",
    "suffix": ""
  },
  {
    "id": 6,
    "bgColor": "bg-secondary",
    "cardColor": "primary",
    "color": "white",
    "label": "Complete",
    "badge": "ri-arrow-right-up-line",
    "badgeClass": "success",
    "percentage": "+16.24",
    "counter": "100",
    "link": "View net earnings",
    "icon": "mdi mdi-check-bold",
    "counterClass": "text-white",
    "iconClass": "white",
    "labelClass": "white-50",
    "decimals": 0,
    "prefix": "",
    "suffix": ""
  }
]

export const dataEventsTimeline = [
  {
    "id": 161,
    "plan_start_time": "2023-10-17T11:00:00.000Z",
    "plan_end_time": "2023-10-18T04:00:00.000Z",
    "scheduled_start_time": null,
    "scheduled_end_time": null,
    "actual_start_time": null,
    "actual_end_time": null,
    "activity_description": "Activity Plan 1",
    "number_of_impacted_ne": 100,
    "detailed_of_impacted_ne": "site mati",
    "plan_number": 1,
    "plan_status": null,
    "approval_status": null,
    "reject_reason": null,
    "createdAt": "2023-10-02T06:56:23.374Z",
    "updatedAt": "2023-10-02T06:56:23.374Z",
    "crmChangeId": "CRQ000000000004",
    "className": "bg-info-subtle text-info",
    "start": "2023-10-17T11:00:00.000Z",
    "end": "2023-10-18T04:00:00.000Z",
    "title": "Replace Cable - Activity Plan 1",
    "parent_id": "CRQ000000000004"
  },
  {
    "id": 171,
    "plan_start_time": "2023-10-01T11:00:00.000Z",
    "plan_end_time": "2023-10-02T04:00:00.000Z",
    "scheduled_start_time": null,
    "scheduled_end_time": null,
    "actual_start_time": null,
    "actual_end_time": null,
    "activity_description": "Activity Plan 2",
    "number_of_impacted_ne": 50,
    "detailed_of_impacted_ne": "site mati",
    "plan_number": 2,
    "plan_status": null,
    "approval_status": null,
    "reject_reason": null,
    "createdAt": "2023-10-02T06:56:23.374Z",
    "updatedAt": "2023-10-02T06:56:23.374Z",
    "crmChangeId": "CRQ000000000004",
    "className": "bg-info-subtle text-info",
    "start": "2023-10-01T11:00:00.000Z",
    "end": "2023-10-02T04:00:00.000Z",
    "title": "New Configure OAM Interface >50 BTS - Activity Plan 2",
    "parent_id": "CRQ000000000004"
  },
  {
    "id": 181,
    "plan_start_time": "2023-10-03T11:00:00.000Z",
    "plan_end_time": "2023-10-04T04:00:00.000Z",
    "scheduled_start_time": null,
    "scheduled_end_time": null,
    "actual_start_time": null,
    "actual_end_time": null,
    "activity_description": "Activity Plan 3",
    "number_of_impacted_ne": 55,
    "detailed_of_impacted_ne": "site mati",
    "plan_number": 3,
    "plan_status": null,
    "approval_status": null,
    "reject_reason": null,
    "createdAt": "2023-10-02T06:56:23.374Z",
    "updatedAt": "2023-10-02T06:56:23.374Z",
    "crmChangeId": "CRQ000000000004",
    "className": "bg-info-subtle text-info",
    "start": "2023-10-03T11:00:00.000Z",
    "end": "2023-10-04T04:00:00.000Z",
    "title": "New Configure OAM Interface >50 BTS - Activity Plan 3",
    "parent_id": "CRQ000000000004"
  },
  {
    "id": 16,
    "plan_start_time": "2023-10-06T11:00:00.000Z",
    "plan_end_time": "2023-10-07T04:00:00.000Z",
    "scheduled_start_time": null,
    "scheduled_end_time": null,
    "actual_start_time": null,
    "actual_end_time": null,
    "activity_description": "Activity Plan 1",
    "number_of_impacted_ne": 100,
    "detailed_of_impacted_ne": "site mati",
    "plan_number": 1,
    "plan_status": null,
    "approval_status": null,
    "reject_reason": null,
    "createdAt": "2023-10-02T06:56:23.374Z",
    "updatedAt": "2023-10-02T06:56:23.374Z",
    "crmChangeId": "CRQ000000000005",
    "className": "bg-success-subtle text-success",
    "start": "2023-10-06T11:00:00.000Z",
    "end": "2023-10-07T04:00:00.000Z",
    "title": "Re-Configure Existing OAM Interface 20-49 BTS - Activity Plan 1",
    "parent_id": "CRQ000000000005"
  },
  {
    "id": 17,
    "plan_start_time": "2023-10-04T11:00:00.000Z",
    "plan_end_time": "2023-10-05T04:00:00.000Z",
    "scheduled_start_time": null,
    "scheduled_end_time": null,
    "actual_start_time": null,
    "actual_end_time": null,
    "activity_description": "Activity Plan 2",
    "number_of_impacted_ne": 50,
    "detailed_of_impacted_ne": "site mati",
    "plan_number": 2,
    "plan_status": null,
    "approval_status": null,
    "reject_reason": null,
    "createdAt": "2023-10-02T06:56:23.374Z",
    "updatedAt": "2023-10-02T06:56:23.374Z",
    "crmChangeId": "CRQ000000000005",
    "className": "bg-success-subtle text-success",
    "start": "2023-10-04T11:00:00.000Z",
    "end": "2023-10-05T04:00:00.000Z",
    "title": "New Configuration GB Interface - Activity Plan 2",
    "parent_id": "CRQ000000000005"
  },
  {
    "id": 18,
    "plan_start_time": "2023-10-07T11:00:00.000Z",
    "plan_end_time": "2023-10-08T04:00:00.000Z",
    "scheduled_start_time": null,
    "scheduled_end_time": null,
    "actual_start_time": null,
    "actual_end_time": null,
    "activity_description": "Activity Plan 3",
    "number_of_impacted_ne": 55,
    "detailed_of_impacted_ne": "site mati",
    "plan_number": 3,
    "plan_status": null,
    "approval_status": null,
    "reject_reason": null,
    "createdAt": "2023-10-02T06:56:23.374Z",
    "updatedAt": "2023-10-02T06:56:23.374Z",
    "crmChangeId": "CRQ000000000005",
    "className": "bg-success-subtle text-success",
    "start": "2023-10-07T11:00:00.000Z",
    "end": "2023-10-08T04:00:00.000Z",
    "title": "New Configuration GB Interface - Activity Plan 3",
    "parent_id": "CRQ000000000005"
  },
  {
    "id": 19,
    "plan_start_time": "2023-10-12T11:00:00.000Z",
    "plan_end_time": "2023-10-13T04:00:00.000Z",
    "scheduled_start_time": null,
    "scheduled_end_time": null,
    "actual_start_time": null,
    "actual_end_time": null,
    "activity_description": "Activity Plan 1",
    "number_of_impacted_ne": 55,
    "detailed_of_impacted_ne": "site mati",
    "plan_number": 3,
    "plan_status": null,
    "approval_status": null,
    "reject_reason": null,
    "createdAt": "2023-10-05T06:56:23.374Z",
    "updatedAt": "2023-10-06T06:56:23.374Z",
    "crmChangeId": "CRQ000000000006",
    "className": "bg-warning-subtle text-warning",
    "start": "2023-10-12T11:00:00.000Z",
    "end": "2023-10-13T04:00:00.000Z",
    "title": "Re-configure Parameter GB Interface - Activity Plan 1",
    "parent_id": "CRQ000000000006"
  }
]