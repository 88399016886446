import UserManagement from "../pages/UserManagement";
import UserDetail from "../pages/UserManagement/UserDetail";
import UserRegistration from "../pages/UserManagement/UserRegistration";


const userRoutes = [
    { path: "/user-list", component: <UserManagement /> },
    { path: "/user-detail/:id", component: <UserDetail /> },
    { path: "/user-registration", component: <UserRegistration /> },
];

export { userRoutes };