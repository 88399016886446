import React from 'react';
import { Card, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { StoreVisitsCharts } from './DashboardVisitCharts';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const StoreVisits = (crdata) => {
    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    const [isLoading, setIsLoading] = React.useState(true);
    const [chartData, setChartData] = React.useState([]);
    const [dataList, setDataList] = React.useState([]);
    const [totalData, setTotalData] = React.useState(0);
    const [dataSearchDate, setDataSearchDate] = React.useState({});
    const [dataDomain, setDataDomain] = React.useState([]);

    const current_date = new Date();
    const next_day_date = new Date(new Date().setDate(new Date().getDate()));
    const next_month_date = new Date(next_day_date.setMonth(next_day_date.getMonth() + 1));
    const previous_day_date = new Date(new Date().setDate(new Date().getDate() + 1));
    const previous_month_date = new Date(previous_day_date.setMonth(previous_day_date.getMonth() - 1));

    const getDataDomainCategory = async() => {
        const reqBody = {
            "data": {
                "categorization_tier_1": "Change & Release Management"
            }
        };
        const res = await apiAuth.post('/getActivityListDomainCategory', reqBody);
        if(res && res.data){
            const option_format = res.data.data.map(item => ( item ));
            setDataDomain(option_format)
            getChartData(option_format);
        }
    }

    React.useEffect(() => {
        getDataDomainCategory()
    }, [crdata])

    const getChartData = async(data_list) => {
        
        for(let j = 0; j < dataDomain.length ; j++){
            chartData[j] = crdata.crdata && crdata.crdata[data_list[j]] !== undefined ? parseInt(crdata.crdata[data_list[j]]) : 0;
        }    
        setChartData(chartData);
        setIsLoading(false)
    }

    return (
        <React.Fragment>
            <Col md={12}>
                <Card className="card-height-100" style={{height: "424px"}}>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">CR Per Domain</h4>
                        {/* <div className="flex-shrink-0">
                            <UncontrolledDropdown className="card-header-dropdown" >
                                <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                                    <span className="text-muted">Report<i className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem>Download Report</DropdownItem>
                                    <DropdownItem>Export</DropdownItem>
                                    <DropdownItem>Import</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div> */}
                    </CardHeader>

                    <div className="card-body">
                        {(!isLoading && chartData.length > 0) && (
                            <StoreVisitsCharts seriesData={chartData} domain={dataDomain} dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'/>
                        )}
                    </div>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default StoreVisits;