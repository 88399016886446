import { createSlice } from "@reduxjs/toolkit";

const NOTIFY_FLAG_TEST = [
  {alert_status : 'default', alert_message : 'Welcome Back! This is a Toast Notification' },
  {alert_status : 'error', alert_message : 'Error ! An error occurred.' },
  {alert_status : 'error', alert_message : 'Error 2 An error occurred.' },
  {alert_status : 'error', alert_message : 'Error 3 An error occurred.' },
]

export const initialState = {
  timeline_list_data : [],
  restricted_date_list_data : [],
  timeline_non_cr_list_data : [],
  timeline_start_end_date : {start_date : null, end_date : null},
  loading_timeline_status : [],
  loading_restricted_date_status : [],
};

const TimelineDataSlice = createSlice({
  name: "TimelineDataSlice",
  initialState,
  reducers: {
    timelineStartEndDateSet(state, action) {
      const stated_query_date = state.timeline_start_end_date;
      if(action.payload.start_date && action.payload.end_date){
        if(stated_query_date.start_date === null || stated_query_date.start_date === undefined || stated_query_date.end_date === null || stated_query_date.start_date === undefined){
          state.timeline_start_end_date = {...action.payload}
        }else{
          const statedFd = new Date(stated_query_date.start_date)
          const statedLd = new Date(stated_query_date.end_date)
          const newFd = new Date(action.payload.start_date)
          const newLd = new Date(action.payload.end_date)
          const dateFiLa = {
            start_date : statedFd.getTime() > newFd.getTime() ? action.payload.start_date : stated_query_date.start_date,
            end_date : statedLd.getTime() < newLd.getTime() ? action.payload.end_date : stated_query_date.end_date
          }
          state.timeline_start_end_date = {...dateFiLa}
        }
      }
    },
    timelineDataAssign(state, action) {
      if(Array.isArray(action.payload)){
        state.timeline_list_data = [...action.payload]
      }else{
        state.timeline_list_data = [action.payload]
      }
    },
    timelineDataAdd(state, action) {
      if(Array.isArray(action.payload)){
        state.timeline_list_data = [...state.timeline_list_data, ...action.payload]
      }else{
        state.timeline_list_data = [...state.timeline_list_data, action.payload]
      }
    },
    
    timelineNonCrDataAssign(state, action) {
      if(Array.isArray(action.payload)){
        state.timeline_non_cr_list_data = [...action.payload]
      }else{
        state.timeline_non_cr_list_data = [action.payload]
      }
    },
    timelineNonCrDataAdd(state, action) {
      if(Array.isArray(action.payload)){
        state.timeline_non_cr_list_data = [...state.timeline_non_cr_list_data, ...action.payload]
      }else{
        state.timeline_non_cr_list_data = [...state.timeline_non_cr_list_data, action.payload]
      }
    },

    restrictedDateDataAssign(state, action) {
      if(Array.isArray(action.payload)){
        state.restricted_date_list_data = [...action.payload]
      }else{
        state.restricted_date_list_data = [action.payload]
      }
    },
    restrictedDateDataAdd(state, action) {
      if(Array.isArray(action.payload)){
        state.restricted_date_list_data = state.restricted_date_list_data ? [...state.restricted_date_list_data, ...action.payload] : [...action.payload] 
      }else{
        state.restricted_date_list_data = state.restricted_date_list_data ? [...state.restricted_date_list_data, action.payload] : [action.payload]
      }
    },
    loadingTimelineSet(state, action) {
      state.loading_timeline_status = action.payload;
    },
    loadingRestrictedDateSet(state, action) {
      state.loading_restricted_date_status = action.payload;
    },
  },
});

export const {
  timelineStartEndDateSet,
  timelineDataAssign,
  timelineDataAdd,
  timelineNonCrDataAssign,
  timelineNonCrDataAdd,
  restrictedDateDataAssign,
  restrictedDateDataAdd
} = TimelineDataSlice.actions

export default TimelineDataSlice.reducer;