import React from 'react';
import withRouter from '../../Components/Common/withRouter';
import { Col, Container, Row } from 'reactstrap';
import logoTelkomselName from "../../assets/images/Logo/Telkomsel_2021.png";
import logoTelkomsel from "../../assets/images/Logo/Telkomsel_2021_icon.png";
import logoTelkomselFull from "../../assets/images/Logo/Telkomsel_2021.png";
import profileBg from '../../assets/images/building-img.jpg';

const BackgroundAuth = ({ children }) => {
    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-page-wrapper--chairman pt-5">
                <Row className='row--auth-page-wrapper'>
                    <Col md={6} xl={6} sm={12} className='col-side-login-page--auth-page'>
                        <div className="profile-wid-bg-main" style={{height: "100%"}}>
                            <div className="profile-wid-bg-main" style={{height: "100%"}}>
                                <img src={profileBg} alt="" className="profile-wid-img" />
                            </div>
                        </div>
                        <div className="auth-page-content mt-lg-3" style={{paddingBottom : '30px', alignSelf : 'center'}}>
                        <Container>
                            <Row className="justify-content-center login-form-content--auth-page">
                                <Col md={8} lg={8} xl={8}>
                                    <div className='side-logo--auth-page'>
                                        <img src={logoTelkomselFull} alt="" className='side-login-logo--auth-page' style={{width: "100%", margin: "0px"}}/>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        </div>
                    </Col>
                    <Col md={6} xl={6} sm={12} className='login-form-wrapper--auth-page'>
                        {children}
                    </Col>
                </Row>
                

                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-footer-title--auth-page-footer">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">
                                        <span className='copyright-year--auth-page-footer'>&copy; {new Date().getFullYear()}</span>
                                        <span className='chairman-text--auth-page-footer'> CHAIRMAN.</span>
                                    {/* Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default withRouter(BackgroundAuth);