import React from 'react';

import Select from 'react-select';

import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row, Button, Spinner } from 'reactstrap';
import { dateformatncrm } from '../../../../helpers/FunctionalHelper/date-helper';
import GeneralDataTab from '../../CRDetail/GeneralDataTab';

const CRApprovalScheduledTL = (props) => {

    const {data, actionLoading} = props;

    const [loadingStatus, setLoadingStatus] = React.useState(false)

    const [dataForm, setDataForm] = React.useState({});
    const [dataApproval, setDataApproval] = React.useState({"status_note" : "Approved"});
    const [canEdit, setCanEdit] = React.useState(false);

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        setDataForm(dataFormInput)
    }

    const approveCrForm = (status_approval) => {
        let data_approval = {...dataApproval};
        data_approval["status"] = status_approval;
        if(status_approval === "Rejected"){
            data_approval["status_note"] = status_approval;
        }
        data_approval["approval_sfa"] = data.sfa_approvals.map(la => ({"role" : la.role, "email" : la.email, "name" : la.name}));
        props.actionApproval(data_approval);
    }

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <Card style={{marginBottom : '10px'}}>
                        <CardBody>
                            {data.current_status === "Scheduled" && (
                            <div className="text-center">
                                <Row>
                                    <Col xl={12}>
                                        <Row>
                                            <Col xs={12} >
                                                <h4>Schedule Ready</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <Row>
                                                    <Col xs={12}>
                                                    <Button
                                                        style={{width : '100%'}}
                                                        onClick={(e) => approveCrForm("Rejected")}
                                                        color='warning'
                                                        disabled={actionLoading}
                                                    >
                                                    {actionLoading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                                    Reschedule
                                                    </Button>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginTop : '10px'}}>
                                                    <Col xs={12}>
                                                        <span style={{color : 'red'}}>Please Set New Timeline before do this action</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    
                                    </Col>
                                </Row>
                            </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CRApprovalScheduledTL;