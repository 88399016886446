import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { DomainCharts } from './DashboardPieCharts';
import Select from "react-select";
import { getAllData } from "../../slices/thunks";
import { createSelector } from 'reselect';
import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const DomainCRChart = (crdata) => {
    const dispatch = useDispatch();

    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    const [chartData, setChartData] = useState([{
        "data" : [],
        "name" : "Sessions"
    }]);
    const [dataList, setDataList] = React.useState([]);
    const [totalData, setTotalData] = React.useState(0);
    const [dataSearchDate, setDataSearchDate] = React.useState({});
    const [dataDomain, setDataDomain] = React.useState([]);
    const [optionDomain, setOptionDomain] = React.useState([]);
    const [dataSelectedDomain, setDataSelectedDomain] = React.useState("RAN");

    const current_date = new Date();
    const next_day_date = new Date(new Date().setDate(new Date().getDate()));
    const next_month_date = new Date(next_day_date.setMonth(next_day_date.getMonth() + 1));
    const previous_day_date = new Date(new Date().setDate(new Date().getDate() + 1));
    const previous_month_date = new Date(previous_day_date.setMonth(previous_day_date.getMonth() - 1));

    const getDataDomainCategory = async() => {
        const reqBody = {
            "data": {
                "categorization_tier_1": "Change & Release Management"
            }
        };
        const res = await apiAuth.post('/getActivityListDomainCategory', reqBody);
        if(res && res.data){
            const option_format = res.data.data.map(item => ( {value: item, label: item} ));
            setOptionDomain(option_format.sort((a, b) => a.label > b.label ? 1 : -1));
        }
    }

    const handleSelectForm = (e) => {
        const {value, label, priority} = e;
        setDataSelectedDomain(value);
        getDataTier2(value);
    }

    const getDataTier2 = async(data_select) => {
        const reqBody = {
            "data": {
                "categorization_tier_1": "Change & Release Management",
                "domain_category": !data_select ? dataSelectedDomain : data_select
            }
        };
        const res = await apiAuth.post('/getActivityListTier2', reqBody);
        if(res && res.data){
            const option_data = res.data.data.map(item => ( item ));

            setDataDomain(option_data);
            getChartData(!data_select ? dataSelectedDomain : data_select, option_data);
        }
    }

    const getChartData = async(domain, data_list) => {

        let data_chart = [];
        data_list = !data_list ? dataDomain : data_list
        for(let j = 0; j < data_list.length ; j++){
            data_chart[j] = crdata.crdata && crdata.crdata[domain] !== undefined && crdata.crdata[domain][data_list[j]] !== undefined ? parseInt(crdata.crdata[domain][data_list[j]]) : 0;
        }    
        chartData[0].data = data_chart;
        setChartData(chartData);
    }

    React.useEffect(() => {
        getDataDomainCategory()
    }, [crdata])

    React.useEffect(() => {
        getDataTier2()
    }, [dataSelectedDomain, crdata])
    
    return (
        <React.Fragment>
                <Row className="h-100">
                    <Col xl={12}>
                        <Card className="card-height-100" style={{height: "424px"}}>
                            <div className="card-header align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">CR per Sub Domain</h4>
                                <div className="d-flex gap-1">
                                    <div className="mt-3 mt-lg-0" style={{marginRight: "10px", width: "200px"}}>
                                        <Select
                                            onChange={(e) => {
                                                handleSelectForm(e);
                                            }}
                                            options={optionDomain}
                                            placeholder={dataSelectedDomain}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div style={{paddingRight: "30px"}}>
                                    <DomainCharts series={chartData} domain={dataDomain} dataColors='["--vz-info", "--vz-info", "--vz-info", "--vz-info", "--vz-danger", "--vz-info", "--vz-info", "--vz-info", "--vz-info", "--vz-info"]' />
                                </div>
                            </div>
                        </Card>
                    </Col>

                </Row>
        </React.Fragment>
    );
};

export default DomainCRChart;