import { createSlice } from "@reduxjs/toolkit";

const NOTIFY_FLAG_TEST = [
  {alert_status : 'default', alert_message : 'Welcome Back! This is a Toast Notification' },
  {alert_status : 'error', alert_message : 'Error ! An error occurred.' },
  {alert_status : 'error', alert_message : 'Error 2 An error occurred.' },
  {alert_status : 'error', alert_message : 'Error 3 An error occurred.' },
]

export const initialState = {
  notification_alert : [],
  loading_status : false,
};

const NotificationFlagSlice = createSlice({
  name: "NotificationFlagSlice",
  initialState,
  reducers: {
    notificationFlagAssign(state, action) {
      if(Array.isArray(action.payload)){
        state.notification_alert = [...action.payload]
      }else{
        state.notification_alert = [action.payload]
      }
    },
    notificationFlagAdd(state, action) {
      if(Array.isArray(action.payload)){
        state.notification_alert = [...state.notification_alert, ...action.payload]
      }else{
        state.notification_alert = [...state.notification_alert, action.payload]
      }
    },
    notificationFlagDelete(state, action) {
      const notif_flag_list = [...state.notification_alert]
      notif_flag_list.splice(action.payload, 1);
      state.notification_alert = notif_flag_list;
    },
    loadingTrue(state) {
      state.loading_status = true;
    },
    loadingFalse(state) {
      state.loading_status = false;
    },
    loadingStatusSet(state, action) {
      state.loading_status = action.payload;
    },
  },
});

export const {
  notificationFlagAssign,
  notificationFlagAdd,
  notificationFlagDelete,
  loadingTrue,
  loadingFalse,
  loadingStatusSet
} = NotificationFlagSlice.actions

export default NotificationFlagSlice.reducer;