import React, { Suspense, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Progress,
  Button,
  Spinner,
  CardFooter,
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { loadAnimation } from "lottie-web";
import { defineElement } from "lord-icon-element";
import { HEADER_TITLE_NAME } from '../../../config/config-general';
// import CRFormRequestor from "./CRFormRequestor";
import CRFormTimelineInputan from "../CRCreation/CRFormTimelineInputan";
import CRFormGeneralData from "../CRCreation/CRFormGeneralData";
// import CRFormCategorization from "./CRFormCategorization";
import CRFormClasification from "../CRCreation/CRFormClasification";
import CRFormAIUserAccess from "../CRCreation/CRFormAIUserAccess";
import CRFormTask from "../CRCreation/CRFormTask";

import { ACTIVITY_LIST_DUMMY } from "../../../dummy-data/ACTIVITY_LIST_DUMMY";
import CRFormAIInformation from "../CRCreation/CRFormAIInformation";
import { useDispatch } from "react-redux";
// import { createDataCr } from "../../../middleware/cr-form/cr-creation-thunk";
import { loadingStatusSet } from "../../../redux/reducers/notificationFlagReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { API_CLIENT_AUTH_BEARER } from "../../../helpers/api-helper";
import { useNavigate , useParams } from "react-router-dom";
import { datetimeformatncrm } from "../../../helpers/FunctionalHelper/date-helper";
import { approvalCrForm } from "../../../middleware/cr-form/cr-approval-thunk";
import StatusTab from "../CRDetail/StatusTab";
import ClassificationTab from "../CRDetail/ClassificationTab";
import { updateDataCr } from "../../../middleware/cr-form/cr-update-thunk";
import CabMeetingTab from "../CRDetail/CabMeetingTab";
import CRApprovalManagementTL from "./CRApprovalComponent/CRApprovalManagementTL";
import CRApprovalScheduledTL from "./CRApprovalComponent/CRApprovalScheduledTL";
// import CRUpdateClasification from "../CRUpdate/CRUpdateClasification";
// import CRApprovalRfc from "./CRApprovalRfc";

// const api = new APIClient();
const apiAuth = new API_CLIENT_AUTH_BEARER();

// import CRApprovalLineManager from "./CRApprovalLineManager";

const CRApprovalRfcTL = React.lazy(() =>
  import("./CRApprovalComponent/CRApprovalRfcTL")
);

const CRApprovalRfaTL = React.lazy(() =>
  import("./CRApprovalComponent/CRApprovalRfaTL")
);

const CRApprovalLineManagerTL = React.lazy(() =>
  import("./CRApprovalComponent/CRApprovalLineManagerTL")
);

const CRUpdateClassification = React.lazy(() =>
  import("../CRUpdate/CRUpdateClassification")
);

const CRApprovalScheduled = React.lazy(() =>
  import("./CRApprovalScheduled")
);

const CRApprovalManagement = React.lazy(() =>
  import("./CRApprovalManagement")
);

const CRApprovalRfc = React.lazy(() =>
  import("./CRApprovalRfc")
);

const CRApprovalRfa = React.lazy(() =>
  import("./CRApprovalRfa")
);

const CRApprovalLineManager = React.lazy(() =>
  import("./CRApprovalLineManager")
);

const GeneralDataTab = React.lazy(() =>
  import("../CRDetail/GeneralDataTab")
);

const CRFormTimeline = React.lazy(() =>
  import("../CRCreation/CRFormTimeline")
);

const CRFormRequestor = React.lazy(() =>
  import("../CRCreation/CRFormRequestor")
);

const CRFormCategorization = React.lazy(() =>
  import("../CRCreation/CRFormCategorization")
);
// import CRFormTimeline from "./CRFormTimeline";

// register lottie and define custom element
defineElement(loadAnimation);

const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};
  
export default function CRApproval() {

  const history = useNavigate();

  document.title="CR Form Approval "+HEADER_TITLE_NAME;

  const USER_PROFILE = JSON.parse(sessionStorage.getItem("authUser")).data;

  const { id, milestone } = useParams();

  const Activity_List = ACTIVITY_LIST_DUMMY;

  const [activeTab, setActiveTab] = useState("0");
  const [activeArrowTab, setactiveArrowTab] = useState(2);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
  const [listUserImplementor, setListUserImplementor] = useState([]);
  const [listCrAttachment, setListCrAttachment] = useState([]);
  const [listPirAttachment, setListPirAttachment] = useState([]);
  const [listTimeline, setListTimeline] = useState([]);
  const [dataForm, setDataForm] = useState({});
  const [data, setData] = useState({});
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [actionStatus, setActionStatus] = useState(null)
  const [requiredUserAccess, setRequiredUserAccess] = useState([false, false, false])

  const dispatch = useDispatch();

  function toggleArrowTab(tab) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];

      setactiveArrowTab(tab);
      setPassedarrowSteps(modifiedSteps);
    }
  }

  const handleAddTimeline = (timeline) => {
    const timeline_list = [...listTimeline]
    timeline_list.push(timeline)
    setListTimeline(timeline_list)
  }

  const handleUserImplementor = (implementor) => {
    setListUserImplementor(implementor)
  }

  const handleCrAttachment = (attach) => {
    setListCrAttachment(attach)
  }

  const handlePirAttachment = (attach) => {
    // console.log("File Attach 1", attach)
    setListPirAttachment(attach)
  }

  const handleDelTimeline = (idx) => {
    const timeline_list = [...listTimeline]
    timeline_list.splice(idx, 1);
    setListTimeline(timeline_list)
  }

  const handleInputChange = (name, value) => {
    const dataFormInput = {...dataForm}
    dataFormInput[name] = value;
    setDataForm((prevState) => ({
      ...prevState,
      ...dataFormInput
    }))
  }

  const handleFormChange = (form_data) => {
    const dataFormInput = {...form_data}
    setDataForm(dataFormInput)
  }

  const getData = async(after_process_on_hide_loading) => {
      const res = await apiAuth.get('/getCRMDetail/'+id);
      if(res && res.data){
          setData(res.data.data)
      }
      // if(after_process_on_hide_loading){
      setLoadingStatus(false)
      // }
  };

  React.useEffect(() => {
      getData()
  }, []);

  const prepareDataForm = () => {

    const intial_state = {
      ...data,
    };

    setDataForm(intial_state)

    if(data.timelines){
      const timeline_list = [...data.timelines]
      setListTimeline(timeline_list)
    }

    if(data.ai_user_accesses){
      const user_access_list = [...data.ai_user_accesses]
      setListUserImplementor(user_access_list)
    }

  }

  React.useEffect(() => {
    prepareDataForm()
  }, [data]);

  const updateDataForm = async() => {
    const summary = [dataForm.activity_list, dataForm.change_category, dataForm.impact].filter(item => item !== undefined).join(" # ")
    const formData = {
      ...dataForm,
      timeline : [
        ...listTimeline
      ],
      summary : summary,
      ai_user_access : [
        ...listUserImplementor
      ]
    }

    // console.log("File Attach Form", formData)
    setLoadingStatus(true)
    dispatch(loadingStatusSet(true));
    
    let response = await dispatch(updateDataCr({formData, listCrAttachment}))
    const res = unwrapResult(response);

    setLoadingStatus(false)
    if(res && res.data){
      setActionStatus('success');
    }else{
      
    }

  }

  const approveCrForm = async(data_approval) => {

    let approval_data = {...data_approval}

    if(approval_data.status === "Rejected" && dataForm.current_status === "Scheduled"){
      approval_data["timeline"] = [...listTimeline]
    }
    
    let formData = {...dataForm}

    setLoadingStatus(true)
    dispatch(loadingStatusSet(true));
    const response = await dispatch(approvalCrForm({formData, approval_data}))
    const res = unwrapResult(response);
    if(res && res.data){
      setActionStatus('success');
      if(dataForm.current_status === "Scheduled"){
        const link = "/cr-detail/"+dataForm.change_id;
        setTimeout(() => {
          history(link)
          setLoadingStatus(false)
        }, 1000);
      }else{
        getData()
        // setLoadingStatus(false)
      }
      
    }else{
      setLoadingStatus(false)
    }
    
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
        setActiveTab(tab);
    }
  };

  const handleRequiredUserAccess = (flag_data) => {
    console.log("Data Require Index View", flag_data)
    console.log("Data Require Index View", flag_data.includes(false))
    const dataInput = flag_data
    setRequiredUserAccess(dataInput)
  }

  // console.log("form parent", dataForm)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="CR Form Approval" pageTitle="CR Form Approval" />
            <Row>
              <Col lg={12}>
                  <Card className="mt-n4 mx-n4">
                      <div className="bg-success-subtle">
                        <Row>
                          <Col md={9}>
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">

                                            <Col md={8}>
                                                <Col md={12} style={{marginBottom : '20px'}}>
                                                <h4 className="fw-bold">CR ID : {data.change_id}</h4>
                                                </Col>

                                                <div className="hstack gap-3 flex-wrap">
                                                  {/* <Col md={3} style={{borderRight: "2px solid #000"}}> */}
                                                  <h6>Status : <p className="fw-medium mb-0 text-black">{data.current_status ? data.current_status : "-"}</p></h6>
                                                  {/* </Col> */}
                                                  <div className="vr"></div>
                                                  {/* <Col md={4} style={{borderRight: "2px solid #000"}}> */}
                                                  <h6>Submit Date : <p className="fw-medium mb-0 text-black">{data.createdAt ? datetimeformatncrm(data.createdAt) : "-"}</p></h6>
                                                  {/* </Col> */}
                                                  <div className="vr"></div>
                                                  {/* <Col md={3}> */}
                                                  <h6>Change Requestor : <p className="fw-medium mb-0 text-black">{data.created_by_name ? data.created_by_name : "-"}</p></h6>
                                                  {/* </Col> */}
                                                </div>

                                                <div className="hstack gap-3 flex-wrap mt-2">
                                                    {/* <Col md={6}> */}
                                                    <h6>Summary : <p className="fw-medium mb-0 text-black">{data.summary ? data.summary : "-"}</p></h6>
                                                    {/* </Col> */}
                                                    {/* <Col md={1} style={{borderRight: "2px solid #000"}}> */}
                                                        {/* <p> </p> */}
                                                    {/* </Col> */}
                                                    <div className="vr"></div>
                                                    {/* <Col md={3}> */}
                                                    <h6 className="badge rounded-pill bg-primary fs-12">{data.change_category}</h6>
                                                    {/* </Col> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Row>
                            </CardBody>
                          </Col>
                          <Col md={3}>
                            <CardBody>
                              <Row>
                                <Col className="d-flex justify-content-center">
                                  <h5>Action</h5>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                <Card>
                                  <CardBody>
                                {milestone && milestone.toLowerCase() === "draft" && (
                                  <React.Fragment>
                                    <Suspense>
                                      <CRApprovalLineManagerTL USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                    </Suspense>
                                  </React.Fragment>
                                ) }

                                {milestone && milestone.toLowerCase() === "rfa" && (
                                  <React.Fragment>
                                    <Suspense>
                                      <CRApprovalRfaTL USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                    </Suspense>
                                  </React.Fragment>
                                )}

                                {milestone && milestone.toLowerCase() === "rfc" && (
                                  <React.Fragment>
                                    <Suspense>
                                      <CRApprovalRfcTL USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                    </Suspense>
                                  </React.Fragment>
                                )}

                                {milestone && milestone.toLowerCase() === "sfa" && (
                                  <React.Fragment>
                                    <Suspense>
                                      <CRApprovalManagementTL USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                    </Suspense>
                                  </React.Fragment>
                                )}

                                {milestone && milestone.toLowerCase() === "scheduled" && (
                                  <React.Fragment>
                                    <Suspense>
                                      <CRApprovalScheduledTL USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                    </Suspense>
                                  </React.Fragment>
                                )}
                                  {dataForm && ((dataForm.current_status === "Draft" || dataForm.current_status === "Request for Authorization"  || dataForm.current_status === "Request for Change") && activeTab !== "0") && (
                                    <Button size="sm" color="warning" onClick={updateDataForm} disabled={loadingStatus} style={{float : 'right'}}>
                                      {loadingStatus ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                      Update
                                    </Button>
                                  )}
                                  </CardBody>
                                </Card>
                                </Col>
                              </Row>
                            </CardBody>
                          </Col>
                        </Row>
                        
                        <Row className="row-tab-menu-section--approval-page">
                          <Col>
                            <Nav pills className="cr-detail-view nav-justified border-bottom-0" role="tablist">
                              <NavItem>
                                  <NavLink
                                      className={classnames({ active: activeTab === '0' }, "fw-semibold")}
                                      onClick={() => { toggleTab('0'); }}
                                      href="#">
                                      Approval Tab
                                  </NavLink>
                              </NavItem>
                              <NavItem>
                                  <NavLink
                                      className={classnames({ active: activeTab === '1' }, "fw-semibold")}
                                      onClick={() => { toggleTab('1'); }}
                                      href="#">
                                      Overview
                                  </NavLink>
                              </NavItem>
                              <NavItem>
                                  <NavLink
                                      className={classnames({ active: activeTab === '2' }, "fw-semibold")}
                                      onClick={() => { toggleTab('2'); }}
                                      href="#">
                                      Categorization
                                  </NavLink>
                              </NavItem>
                              <NavItem>
                                  <NavLink
                                      className={classnames({ active: activeTab === '3' }, "fw-semibold")}
                                      onClick={() => { toggleTab('3'); }}
                                      href="#">
                                      Classification
                                  </NavLink>
                              </NavItem>
                              <NavItem>
                                  <NavLink
                                      className={classnames({ active: activeTab === '4' }, "fw-semibold")}
                                      onClick={() => { toggleTab('4'); }}
                                      href="#">
                                      Information
                                  </NavLink>
                              </NavItem>
                              <NavItem>
                                  <NavLink
                                      className={classnames({ active: activeTab === '5' }, "fw-semibold")}
                                      onClick={() => { toggleTab('5'); }}
                                      href="#">
                                      Timeline
                                  </NavLink>
                              </NavItem>
                              <NavItem>
                                  <NavLink
                                      className={classnames({ active: activeTab === '8' }, "fw-semibold")}
                                      onClick={() => { toggleTab('8'); }}
                                      href="#">
                                      User Access Management
                                  </NavLink>
                              </NavItem>
                              <NavItem>
                                  <NavLink
                                      className={classnames({ active: activeTab === '10' }, "fw-semibold")}
                                      onClick={() => { toggleTab('10'); }}
                                      href="#">
                                      CAB Meeting
                                  </NavLink>
                              </NavItem>
                              <NavItem>
                                  <NavLink
                                      className={classnames({ active: activeTab === '7' }, "fw-semibold")}
                                      onClick={() => { toggleTab('7'); }}
                                      href="#">
                                      Task
                                  </NavLink>
                              </NavItem>
                              
                              <NavItem>
                                  <NavLink
                                      className={classnames({ active: activeTab === '9' }, "fw-semibold")}
                                      onClick={() => { toggleTab('9'); }}
                                      href="#">
                                      Status
                                  </NavLink>
                              </NavItem>
                          </Nav>
                          </Col>
                        </Row>
                      </div>
                  </Card>
              </Col>
          </Row>
          <Row>
          <Col xl={12} style={{padding: "0px"}}>
              {/* <Card> */}
                {/* <CardBody> */}
                  <Form className="form-steps">
                    <Row style={{margin: "0px"}}>
                      <Col xl={12}>
                        <TabContent activeTab={activeTab}>
                          <TabPane id="steparrow-gen-info" tabId={"0"}>

                            {/* {console.log("test milestone", milestone)} */}

                            {milestone && milestone.toLowerCase() === "draft" ? (
                              <React.Fragment>
                                <Suspense>
                                  <CRApprovalLineManager USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                </Suspense>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {!milestone && dataForm.current_status === "Draft" && (
                                  <React.Fragment>
                                    <Suspense>
                                      <CRApprovalLineManager USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                    </Suspense>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}

                            {milestone && milestone.toLowerCase() === "rfa" ? (
                              <React.Fragment>
                                <Suspense>
                                  <CRApprovalRfa USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                </Suspense>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {!milestone && dataForm.current_status === "Request for Authorization" && (
                                  <React.Fragment>
                                    <Suspense>
                                      <CRApprovalRfa USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                    </Suspense>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}

                            {milestone && milestone.toLowerCase() === "rfc" ? (
                              <React.Fragment>
                                <Suspense>
                                  <CRApprovalRfc USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                </Suspense>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {!milestone && dataForm.current_status === "Request for Change" && (
                                  <React.Fragment>
                                    <Suspense>
                                      <CRApprovalRfc USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                    </Suspense>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}

                            {milestone && milestone.toLowerCase() === "sfa" ? (
                              <React.Fragment>
                                <Suspense>
                                  <CRApprovalManagement USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                </Suspense>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {!milestone && dataForm.current_status === "Scheduled for Approval" && (
                                  <React.Fragment>
                                    <Suspense>
                                      <CRApprovalManagement USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                    </Suspense>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}

                            {milestone && milestone.toLowerCase() === "scheduled" ? (
                              <React.Fragment>
                                <Suspense>
                                  <CRApprovalScheduled USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                </Suspense>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {!milestone && dataForm.current_status === "Scheduled" && (
                                  <React.Fragment>
                                    <Suspense>
                                      <CRApprovalScheduled USER_PROFILE={USER_PROFILE} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                    </Suspense>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}

                            {milestone && milestone.toLowerCase() === "completed" ? (
                              <React.Fragment>
                                <Suspense>
                                  <CRFormTask USER_PROFILE={USER_PROFILE} dataForm={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                </Suspense>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {!milestone && dataForm.current_status === "completed" && (
                                  <React.Fragment>
                                    <Suspense>
                                      <CRFormTask USER_PROFILE={USER_PROFILE} dataForm={dataForm} data={dataForm} onEdit={false} actionApproval={approveCrForm} actionLoading={loadingStatus}/>
                                    </Suspense>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}
                          
                          </TabPane>

                          <TabPane id="steparrow-gen-info" tabId={"1"}>
                          <Card>
                            <CardBody>
                            
                              <Suspense>
                                <GeneralDataTab data={dataForm} onEdit={false} />
                              </Suspense>
                          </CardBody>
                          </Card>
                          </TabPane>
                            
                          <TabPane id="steparrow-gen-info" tabId={"2"}>
                          <Row>
                              <Col xl={12}>
                              <Card>
                                <CardBody>
                                {activeTab === "2" && (
                                <Suspense>
                                  <CRFormCategorization 
                                    onChangeForm = {handleInputChange}
                                    onChangeFormAll = {handleFormChange}
                                    dataForm = {dataForm}
                                    activityList={Activity_List}
                                  />
                                </Suspense>
                                )}
                                </CardBody>
                              </Card>
                              </Col>
                          </Row>
                          </TabPane>

                          <TabPane id="steparrow-gen-info" tabId={"3"}>
                            <Row>
                              <Col xl={12}>
                              <Card>
                              <CardBody>
                                {/* <ClassificationTab data={data} onEdit={false} /> */}
                                {activeTab === "3" && (
                                  <Suspense>
                                    <CRUpdateClassification 
                                      dataForm = {dataForm}
                                      activityList={Activity_List}
                                      onChangeFormAll = {handleFormChange}
                                      onChangeForm = {handleInputChange}
                                      addAttachment = {handleCrAttachment}
                                    />
                                  </Suspense>
                                )}
                                </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPane>

                          <TabPane id="steparrow-gen-info" tabId={"4"}>
                            <Row>
                              <Col xl={12}>
                              <Card>
                              <CardBody>
                                <CRFormAIInformation 
                                  dataForm = {dataForm}
                                  onChangeFormAll = {handleFormChange}
                                  onChangeForm = {handleInputChange}
                                />
                              </CardBody>
                              </Card>
                              </Col>
                            </Row>
                          </TabPane>

                          <TabPane id="steparrow-gen-info" tabId={"5"}>
                          <Row>
                            <Col xl={3}>
                              <CRFormTimelineInputan 
                                addTimeline={handleAddTimeline}
                              />
                            </Col>
                            <Col xl={9}>
                            {activeTab === "5" && (
                              <Suspense>
                                
                                  <CRFormTimeline
                                    dataForm = {dataForm}
                                    listTimeline={listTimeline}
                                    dataTimeline={listTimeline}
                                    onDeleteTimeline={() => handleDelTimeline()}
                                  />
                              </Suspense>
                            )}
                            </Col>
                          </Row>
                          </TabPane>

                          <TabPane id="steparrow-description-info" tabId={"7"}>
                            <Row>
                              <Col xl={12}>
                              <Card>
                                <CardBody>
                                <CRFormTask 
                                  dataForm = {dataForm}
                                  onChangeForm = {handleInputChange}
                                  addAttachment = {handlePirAttachment}
                                />
                                </CardBody>
                              </Card>
                              </Col>
                            </Row>
                          </TabPane>

                          <TabPane id="steparrow-description-info" tabId={"8"}>
                          <Row>
                            <Col xl={12}>
                            <Card>
                              <CardBody>
                              <CRFormAIUserAccess 
                                dataForm = {dataForm}
                                listUserImplementor = {listUserImplementor}
                                addImplementor = {handleUserImplementor}
                                onChangeForm = {handleInputChange}
                                onChangeRequiredUserAccess = {handleRequiredUserAccess}
                              />
                              </CardBody>
                            </Card>
                            </Col>
                          </Row>
                          </TabPane>

                          <TabPane id="pills-experience" tabId={"10"}>
                          <Card>
                            <CardBody>
                            <CabMeetingTab data={dataForm} onEdit={false}/>
                            </CardBody>
                          </Card>
                          </TabPane>

                          <TabPane id="pills-experience" tabId={"9"}>
                          <Card>
                            <CardBody>
                            <StatusTab data={dataForm} onEdit={false}/>
                            </CardBody>
                          </Card>
                          </TabPane>

                        </TabContent>
                      </Col>
                    </Row>
                  </Form>
                {/* </CardBody> */}
                <Card>
                {dataForm && (dataForm.current_status === "Draft" || dataForm.current_status === "Request for Authorization"  || dataForm.current_status === "Request for Change") && (
                <CardFooter>
                  
                    <Button size="sm" color="warning" onClick={updateDataForm} disabled={loadingStatus} >
                      {loadingStatus ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                      Update
                    </Button>
                  
                </CardFooter>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
