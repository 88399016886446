import React from 'react';

import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row } from 'reactstrap';

const CRFormGeneralData = (props) => {

    const [dataForm, setDataForm] = React.useState({});

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        props.onChangeForm(name, value);
        setDataForm(dataFormInput)
    }

    React.useEffect(() => {
        if(props.dataForm){
            setDataForm({...props.dataForm})
        }
    }, [props.dataForm])

    return (
        <React.Fragment>
            <Row style={{border: "1px solid #f7f7f7", paddingTop: "10px"}}>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="firstNameinput" className="form-label">CR Form Status</Label>
                        <Input type="text" className="form-control" value={"Draft"} name="current_status" disabled onChange={(e) => handleInputChange(e)} id="firstNameinput" />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="version" className="form-label">Version</Label>
                        <Input type="text" className="form-control" value={1} name="version" disabled onChange={(e) => handleInputChange(e)} id="version" />
                    </div>
                </Col>
                <Col md={12}>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="coordinator_group" className="form-label">Coordinator Group</Label>
                        <Input type="text" className="form-control" name="coordinator_group" value={dataForm.coordinator_group} onChange={(e) => handleInputChange(e)} id="coordinator_group" />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="change_coordinator" className="form-label">Change Coordinator</Label>
                        <Input type="text" className="form-control" name="change_coordinator" value={dataForm.change_coordinator} onChange={(e) => handleInputChange(e)} id="change_coordinator" />
                    </div>
                </Col>
                {/* <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="location" className="form-label">Location</Label>
                        <Input type="text" className="form-control" name="location" value={dataForm.location} onChange={(e) => handleInputChange(e)} id="location" />
                    </div>
                </Col> */}
                <Col md={6}></Col>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="target_date" className="form-label">Target Date</Label>
                        <Input type="date" className="form-control" name="target_date" value={dataForm.target_date} onChange={(e) => handleInputChange(e)} id="target_date" />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="impact" className="form-label">Impact</Label>
                        <Input type="text" className="form-control" name="impact" value={dataForm.impact} onChange={(e) => handleInputChange(e)} id="impact" disabled/>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="risk" className="form-label">Risk</Label>
                        <Input type="text" className="form-control" name="risk" value={dataForm.risk} onChange={(e) => handleInputChange(e)} id="risk" disabled/>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="priority" className="form-label">Priority</Label>
                        <Input type="text" className="form-control" name="priority" value={dataForm.priority} onChange={(e) => handleInputChange(e)} id="priority"/>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CRFormGeneralData;