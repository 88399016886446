import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { notificationFlagAdd, loadingFalse, notificationFlagAssign } from '../../redux/reducers/notificationFlagReducer';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const dateNow = new Date();

export const createDataUser = createAsyncThunk(
    "/registerUser",
    async (formData, thunkApi) => {
        const resPromise = await createDataUserAsync(formData.dataForm, thunkApi);
        return resPromise;
    }
);

export const createDataUserAsync = async (form, thunkApi) => {
    let dataUser = {...form}

    console.log("test", dataUser)

    // const dataUser = {
    //     "email": "test3@test.com",
    //     "password": "test",
    //     "first_name": "Test",
    //     "middle_initial": "Coba",
    //     "last_name": "3",
    //     "phone_number": "0808080",
    //     "organization_long": "Org",
    //     "department_long": "Dept",
    //     "support_organization": "Supp",
    //     "support_group_name": "Supp",
    //     "title": "Admin"
    // }

    const res = await apiAuth.post('/registerUser', {data : dataUser})
    console.log('res', res)
    let notification = {}
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_message : "User registered successfully!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res    

    // return res
}