import { createAsyncThunk, current } from '@reduxjs/toolkit';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { notificationFlagAdd, loadingFalse, notificationFlagAssign } from '../../redux/reducers/notificationFlagReducer';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const dateNow = new Date();

export const updateDataCr = createAsyncThunk(
    "/createCRM",
    async (formData, thunkApi) => {
        const resPromise = await updateDataCrAsync({form : formData.formData, crAttachment : formData.listCrAttachment}, thunkApi);
        return resPromise;
    }
);

export const updateDataCrAsync = async (form, thunkApi) => {
    //formdata object
    let formData = new FormData();

    let dataCr = {...form.form}

    dataCr = JSON.parse(JSON.stringify(dataCr));

    if(dataCr.timeline && dataCr.timeline.length > 0){
        const resUpdateTimeline = await updateTimelineCrAsync(dataCr.timeline, dataCr.change_id, thunkApi);
    }

    if(dataCr.ai_user_access && dataCr.ai_user_access.length > 0){
        const ai_user_accessess = dataCr.ai_user_access.filter(item => item.executor_pic_email)
        const resUpdateImplementator = await updateUserImplementationCrAsync(ai_user_accessess, dataCr.change_id, thunkApi);        
    }

    if(form.crAttachment && form.crAttachment.length > 0){
        const resUpdateAttachment = await updateAttachmentCrAsync(form.crAttachment, dataCr.change_id, thunkApi);        
    }

    delete dataCr.current_status

    let notification = {}

    const res = await apiAuth.patch('/updateCRM/'+dataCr.change_id, {data : dataCr}, null);
    
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_title : "CR Data",
            alert_message : "CR Updated successfully!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_title : "CR Data",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}

export const updateAttachmentCrAsync = async (attachment, change_id, thunkApi) => {
    //formdata object
    let formData = new FormData();

    let attachmentCr = [...attachment]

    console.log("attachmentCr", attachmentCr)

    const crNameData = attachmentCr ? attachmentCr.filter(item => item.attachment_name && item.file).map(item => ({attachment_name : item.attachment_name})) : [];

    await formData.append('data', JSON.stringify(crNameData));
    let counting_data = 0
    for(let i = 0 ; i < attachmentCr.length; i++){
        const field = 'attachment_'+counting_data;
        if(attachmentCr[i].attachment_name && attachmentCr[i].file){
          await formData.append(field, attachmentCr[i].file);
          counting_data = counting_data+1
        }
    }

    let notification = {}

    const res = await apiAuth.patch('/updateAttachment/'+change_id, formData, null, {"Content-Type" : "multipart/form-data"});
    
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_title : "CR Attachment Data",
            alert_message : "CR Updated successfully!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_title : "CR Attachment Data",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}

export const updateUserImplementationCrAsync = async (user_implementation_data, change_id, thunkApi) => {
    //formdata object

    let userImplementationCr = [...user_implementation_data]

    let notification = {}

    const res = await apiAuth.patch('/updateAIUserAccess/'+change_id, {data : userImplementationCr}, null);
    
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_title : "CR User Implementator Data",
            alert_message : "CR Updated successfully!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_title : "CR User Implementator Data",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}

export const updateTimelineCrAsync = async (timeline_data, change_id, thunkApi) => {
    //formdata object

    let timelineCr = [...timeline_data]

    let notification = {}

    const res = await apiAuth.patch('/updateTimeline/'+change_id, {data : timelineCr}, null);
    
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_title : "CR Timeline Data",
            alert_message : "CR Updated successfully!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_title : "CR Timeline Data",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}