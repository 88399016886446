import React from 'react';

import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row } from 'reactstrap';

const CategorizationTab = (props) => {

    const {data} = props;

    const [dataForm, setDataForm] = React.useState({});
    const [canEdit, setCanEdit] = React.useState(false);

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        setDataForm(dataFormInput)
    }

    return (
        <React.Fragment>
            <Row>
                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="oc_change_location_company" className="form-label">Change Location Company</Label>
                        <Input type="text" className="form-control" value={data.oc_change_location_company} name="oc_change_location_company" disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="oc_change_location_company" />
                    </div>
                </Col>
                <Col md={6}></Col>
                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="oc_tier_1" className="form-label">Tier 1</Label>
                        <Input type="text" className="form-control" value={data.oc_tier_1} name="oc_tier_1" disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="oc_tier_1" />
                    </div>
                </Col>
                <Col md={6}></Col>
                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="oc_domain_category" className="form-label">Domain Category</Label>
                        <Input type="text" className="form-control" value={data.oc_domain_category} name="oc_domain_category" disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="oc_domain_category" />
                    </div>
                </Col>
                <Col md={6}></Col>
                {/* <Col md={4}></Col> */}
                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="oc_tier_2" className="form-label">Tier 2</Label>
                        <Input type="text" className="form-control" value={data.oc_tier_2} name="oc_tier_2" disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="oc_tier_2" />
                    </div>
                </Col>
                <Col md={6}>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="oc_tier_3" className="form-label">Tier 3</Label>
                        <Input type="text" className="form-control" value={data.oc_tier_3} name="oc_tier_3" disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="oc_tier_3" />
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CategorizationTab;