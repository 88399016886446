import React, { useState, useEffect, Component } from "react";
import { get, isEmpty } from "lodash";
import { Page, Text, View, Image, Document, StyleSheet, PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';


import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardHeader,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
  Modal, ModalBody, ModalHeader, ModalFooter, 
} from "reactstrap";

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import { Link } from 'react-router-dom';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import avatar from "../../assets/images/users/avatar-1.jpg";
import avatarDummy1 from "../../assets/images/users/user-dummy-img1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../slices/thunks";
import { createSelector } from "reselect";

import BreadCrumb from '../../Components/Common/BreadCrumb';

import Select from 'react-select';
import { loadingStatusSet } from '../../redux/reducers/notificationFlagReducer';

import { updateDataUser, updateDataPicture } from '../../middleware/user-management/user-update-thunk';
import { unwrapResult } from '@reduxjs/toolkit';

import { HEADER_TITLE_NAME } from '../../config/config-general';

import profileBg from '../../assets/images/futuristic-network-usage.jpg';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

import { USER_TITLE } from '../../config/data/USER_TITLE';

import { DEFAULT_ROLE_ROLE } from '../../config/data/DEFAULT_ROLE_ROLE';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const UserProfile = () => {

  const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

  const dispatch = useDispatch();

  const USER_TITLE_OPTION = [...USER_TITLE.map(ut => ({ value: ut.TITLE, label: ut.TITLE, area_division : ut["AREA DIVISION"], area : ut["Area"], regional : ut["Regional"] }))]
  const ROLE_OPTIONS = [...DEFAULT_ROLE_ROLE, ...USER_TITLE_OPTION]

  const [activeTab, setActiveTab] = useState("1");
  const tabChange = (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
  };
  
  const [first_name, setfirstName] = useState("");
  const [last_name, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [title, settitle] = useState("");
  const [department, setdepartment] = useState("");
  const [division, setdivision] = useState("");
  const [area, setarea] = useState("");
  const [regional, setregional] = useState("");
  const [role, setrole] = useState([]);
  const [idx, setidx] = useState("1");

  const [dataChangePhoto, setDataChangePhoto] = React.useState({});
  const [dataChangeBgPhoto, setDataChangeBgPhoto] = React.useState({});
  const [imgData, setImgData] = React.useState(null);
  const [imgBgData, setImgBgData] = React.useState(null);
  const [getImagePhoto, setGetImagePhoto] = React.useState([]);
  const [getImageBgPhoto, setGetImageBgPhoto] = React.useState([]);

  const [disabledBtn, setDisabledBtn] = useState(true);
  const [userName, setUserName] = useState("");
  const [dataForm, setDataForm] = React.useState({});
  const [dataUserOption, setDataUserOption] = React.useState([]);
  const [loadingStatus, setLoadingStatus] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [showCover, setShowCover] = React.useState(false);

  const handleClose = () => {
    setShow(false);
    setImgData(null);
    getProfilePicture();
  }

  const handleCloseBgCover = () => {
    setShowCover(false);
    setImgBgData(null);
    getCoverPicture();
  }

  const onClickChangePhoto = () => {
    setShow(true);
  };

  const onClickChangeBgPhoto = () => {
    setShowCover(true);
  };

  const closeChangePhoto = (e) => {
    setShow(false);
    setImgData(null);
    getProfilePicture();
  }

  const closeChangeBgPhoto = (e) => {
    setShowCover(false);
    setImgBgData(null);
    getCoverPicture();
  }

  const selectLayoutState = (state) => state.Profile;

  const userprofileData = createSelector(
    selectLayoutState,
    (state) => ({
      user: state.user,
      success: state.success,
      error: state.error
    })
  );

  // Inside your component
  const {
    user, success, error 
  } = useSelector(userprofileData);

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));

      // console.log("User Data", obj);

      if (!isEmpty(user)) {
        obj.data.first_name = user.first_name;
        sessionStorage.removeItem("authUser");
        sessionStorage.setItem("authUser", JSON.stringify(obj));
      }

      setUserName(obj.data.first_name);
      setfirstName(obj.data.first_name);
      setlastName(obj.data.last_name);
      setemail(obj.data.email);
      setemail(obj.data.email);
      setphone(obj.data.phone_number);
      settitle(obj.data.title);
      setdepartment(obj.data.department_long);
      setdivision(obj.data.area_division);
      setarea(obj.data.area);
      setregional(obj.data.regional);
      setrole(obj.data.role);
      setidx(obj.data._id || "1");

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: userName || 'Admin',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    }
  });

  // Vertical Nav Tabs
  const [verticalTab, setverticalTab] = useState("1");

  const toggleVertical = (tab) => {
      if (verticalTab !== tab) {
          setverticalTab(tab);
      }
  };

  const setEdit = () => {
    if(disabledBtn === false){
      setDisabledBtn(true);
    }else{
      setDisabledBtn(false);
    }
  }

  // React.useEffect(() => {
  //   setEdit
  // },[])

  const update = async(e) => {
    e.preventDefault()
    setLoadingStatus(true);

    dispatch(loadingStatusSet(true));
    const response = await dispatch(updateDataUser({dataForm}))
    const res = unwrapResult(response);
    setLoadingStatus(false);
  }

  const updatePicture = async(e) => {
    e.preventDefault()
    setLoadingStatus(true);

    dispatch(loadingStatusSet(true));
    const response = await dispatch(updateDataPicture({dataForm, dataChangePhoto, dataChangeBgPhoto}))
    const res = unwrapResult(response);
    setLoadingStatus(false);
  }

  const getData = async() => {
    const res = await apiAuth.get('/getUserDetail/'+userData.email);
    if(res && res.data){
      let dataUser = {...res.data.data}
      delete dataUser.password
      setDataForm(dataUser)
      getProfilePicture(dataUser)
      getCoverPicture(dataUser)
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  const handleInputChange = (e) => {
    const dataFormInput = {...dataForm}
    const {name, value} = e.target
    dataFormInput[name] = value;
    setDataForm(dataFormInput)
  }

  const handleInputPhoto = (e, name) => {
    const file_data = e.target.files[0];

    dataChangePhoto["file"] = file_data;

    const data_form = {...dataForm};
    data_form["profile_picture"] = dataChangePhoto;
    
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }

    setDataChangePhoto(dataChangePhoto);
    setDataForm(data_form);
  };

  const handleInputBgPhoto = (e, name) => {
    const file_data = e.target.files[0];

    dataChangeBgPhoto["file"] = file_data;

    const data_form = {...dataForm};
    data_form["coverAttachment"] = dataChangeBgPhoto;
    
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgBgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }

    setDataChangeBgPhoto(dataChangeBgPhoto);
    setDataForm(data_form);
  };

  const getProfilePicture = async (data_user) => {
      const res = await apiAuth.getFile('/downloadProfile/'+userData.email+'/profile_picture', userData.token)
      if (res && res.data) {
        if(data_user && data_user.profiles){
          showPhoto(res.data, data_user.profiles.find(fl => fl.file_type === "profile_picture"), "profile");
        }
      } else {
        return null
      }
  }

  const getCoverPicture = async (data_user) => {
    const res = await apiAuth.getFile('/downloadProfile/'+userData.email+'/background_cover', userData.token)
    if (res && res.data) {
      if(data_user && data_user.profiles){
        showPhoto(res.data, data_user.profiles.find(fl => fl.file_type === "background_cover"), "cover");
      }
    } else {
      return null
    }
}

  const showPhoto = (file, data_image, flag) => {
    let images = [];
    let data_image_file = data_image;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      const sfbase = reader.result.replace('application/octet-stream', 'image/png');
      data_image_file["image_view_file"] = sfbase;
      images.push(data_image_file)

      if(flag === "profile"){
        setGetImagePhoto(images)
      }else if(flag === "cover"){
        setGetImageBgPhoto(images)
      }
      
    }
  }

  function handleSelectFormMulti(e, name) {
      const dataFormSelect = {...dataForm};
      const {value, label} = e;
      dataFormSelect[name] = e.map(item => item.value);
      setDataForm(dataFormSelect);
  }

  function handleSelectForm(e, name) {
      const dataFormSelect = {...dataForm};
      const {value, label, area_division, area, regional, name_user} = e;
      dataFormSelect[name] = value;
      if(name === "title"){
          dataFormSelect["area_division"] = area_division;
          dataFormSelect["area"] = area;
          dataFormSelect["regional"] = regional;
          dataFormSelect["role"].push(value)
      }
      if(name === "lm_email"){
          dataFormSelect["lm_name"] = name_user;
      }
      
      setDataForm(dataFormSelect);
  }

  const getDataLM = async() => {
      const res = await apiAuth.get('/getUser?noPg=1');
      if(res && res.data){
          const listUserOption = res.data.data.map(e => ({label : e.email, value : e.email, name_user : e.first_name+" "+e.lastname}))
          setDataUserOption(listUserOption)
      }
  }

  React.useEffect(() => {
      getDataLM();
  }, []);

  document.title = "User Profile | CHAIRMAN";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card style={{marginBottom: "10px"}}>
                <CardBody>
                  <div className="profile-foreground">
                      <div className="profile-wid-bg-db-main" style={{height: "246px"}}>
                        {getImageBgPhoto.length !== 0 ?
                        <img src={getImageBgPhoto[0].image_view_file} alt="" className="profile-wid-img"/>
                        :
                        <img src={profileBg} alt="" className="profile-wid-img"/>
                        }
                      </div>
                  </div>

                  <Row>
                    <Col md="12" className="text-center">
                        <div className="d-none d-md-block mb-2 mt-3">
                            <Button type="submit" style={{float: "right", opacity: "0.7", backgroundColor: "white", color: "#000000"}} onClick={() => {onClickChangeBgPhoto();}}>
                              Edit Background
                            </Button>
                        </div>
                        <div className="" style={{aspectRatio: 1 / 1, maxWidth: "150px", marginBottom: "10px", marginLeft: "auto", marginRight: "auto"}}>
                          {getImagePhoto.length === 0 ?
                          <img src={avatarDummy1} style={{borderRadius: "50%", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                          :
                          <img src={getImagePhoto[0].image_view_file} style={{borderRadius: "50%", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                          }
                        </div>
                        <div className="mx-3">
                          <div className="flex-grow-1">
                            <Button type="submit" color="primary" onClick={() => {onClickChangePhoto();}}>
                              Change Photo
                            </Button>
                          </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Nav pills className="flex-column text-center" id="v-pills-tab">
                  <NavItem>
                      <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                              "mb-2": true,
                              active: verticalTab === "1",
                          })}
                          onClick={() => {
                              toggleVertical("1");
                          }}
                          id="v-pills-home-tab"
                      >
                          User Information
                      </NavLink>
                  </NavItem>
              </Nav>
          </Col>
                                
            <Col lg="9">
              <Card>
                <CardBody>
                  <TabContent
                      activeTab={verticalTab}
                      className="text-muted mt-4 mt-md-0"
                      id="v-pills-tabContent"
                  >
                      <TabPane tabId="1" id="v-pills-home">
                        <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="first_name" className="form-label"><span className="text-danger">*</span> First Name</Label>
                                        <Input type="text" className="form-control" name="first_name" placeholder="" value={dataForm.first_name} onChange={(e) => handleInputChange(e)} id="first_name" disabled={disabledBtn} required/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="last_name" className="form-label">Last Name</Label>
                                        <Input type="text" className="form-control" name="last_name" placeholder="" value={dataForm.last_name} onChange={(e) => handleInputChange(e)} id="last_name" disabled={disabledBtn}/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="middle_initial" className="form-label">Middle Name</Label>
                                        <Input type="text" className="form-control" name="middle_initial" placeholder="" value={dataForm.middle_initial} onChange={(e) => handleInputChange(e)} id="middle_initial" disabled={disabledBtn}/>
                                    </div>
                                </Col>
                                <Col md={6}></Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="email" className="form-label">Email Address</Label>
                                        <Input type="email" className="form-control" name="email" placeholder="" disabled value={dataForm.email} onChange={(e) => handleInputChange(e)} id="email" required/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="phone_number" className="form-label"><span className="text-danger">*</span> Phone Number</Label>
                                        <Input type="number" className="form-control" name="phone_number" placeholder="" value={dataForm.phone_number} onChange={(e) => handleInputChange(e)} id="phone_number" disabled={disabledBtn} required/>
                                    </div>
                                </Col>
                                <Col md={8}></Col>
                                <Col md={12} className='mt-2'>
                                    <h4>Organization {disabledBtn === false ? <span className="text-danger" style={{fontSize: "12px"}}>(Organizational changes need to be confirmed with Admin)</span> : ""}</h4>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="title" className="form-label">Title</Label>
                                        <Input type="text" className="form-control" name="title" value={dataForm.title} placeholder="" disabled id="title" />
                                    </div>
                                </Col>
                                
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="regional" className="form-label">Regional</Label>
                                        <Input type="text" className="form-control" name="regional" value={dataForm.regional} placeholder="" disabled id="regional" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="area_division" className="form-label">Area Division</Label>
                                        <Input type="text" className="form-control" name="area_division" value={dataForm.area_division} placeholder="" disabled id="area_division" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="area" className="form-label">Area</Label>
                                        <Input type="text" className="form-control" name="area" value={dataForm.area} placeholder="" disabled id="area" />
                                    </div>
                                </Col>
                                <Col md={6}></Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="line_manager" className="form-label">Line Manager</Label>
                                        <Input type="text" className="form-control" name="line_manager" value={dataForm.lm_email} placeholder="" disabled id="line_manager" />
                                    </div>
                                </Col>
                                <Col md={6}></Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="organization_long" className="form-label">Organization Long</Label>
                                        <Input type="text" className="form-control" name="organization_long" placeholder="" value={dataForm.organization_long} onChange={(e) => handleInputChange(e)} id="organization_long" disabled/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="department_long" className="form-label">Department Long</Label>
                                        <Input type="text" className="form-control" name="department_long" placeholder="" value={dataForm.department_long} onChange={(e) => handleInputChange(e)} id="department_long" disabled/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="support_organization" className="form-label">Support Organization</Label>
                                        <Input type="text" className="form-control" name="support_organization" placeholder="" value={dataForm.support_organization} onChange={(e) => handleInputChange(e)} id="support_organization" disabled/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="support_group_name" className="form-label">Support Group Name</Label>
                                        <Input type="text" className="form-control" name="support_group_name" placeholder="" value={dataForm.support_group_name} onChange={(e) => handleInputChange(e)} id="support_group_name" disabled/>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        <Col lg={12}>
                          <div className="mt-4" style={{float: "left"}}>
                            {disabledBtn === false &&
                            <Button type="submit" color="success" onClick={(e) => update(e)} style={{marginRight: "10px"}} disabled={loadingStatus} >
                              {loadingStatus ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                              Update
                            </Button>
                            }

                            <Button type="submit" color="primary" onClick={(e) => setEdit(e)}>
                              {disabledBtn === true ?
                              "Can Edit"
                              : "Cancel"
                              }
                            </Button>
                          </div>
                        </Col>
                      </TabPane>
                    </TabContent>
                      
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Modal Change Photo */}
      <Modal isOpen={show} toggle={handleClose} className={'modal-md modal--box-input'} color="warning" style={{marginTop: "165px", maxWidth: "600px"}}>
      <ModalHeader style={{backgroundColor : '#f0f3f5'}}></ModalHeader>
      <ModalBody style={{paddingTop: "25px"}}>
          <Row>
            <Col sm="12">
              <div style={{aspectRatio: 1 / 1, maxWidth: "200px", marginLeft: "auto", marginRight: "auto"}}>
                {imgData !== null ?
                <img src={imgData} style={{borderRadius: "50%", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                :
                getImagePhoto.length === 0 ?
                <img src={avatarDummy1} style={{borderRadius: "50%", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                :
                <img src={getImagePhoto[0].image_view_file} style={{borderRadius: "50%", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                }
                <Input type="file" name="profile" onChange={(e) => {handleInputPhoto(e, "file");}} style={{marginTop: "15px"}}/>
              </div>
            </Col>
          </Row>
      </ModalBody>
      <ModalFooter>
          <div className="text-end">
              <button onClick={(e) => updatePicture(e)} className="btn btn-primary btn-sm">Update Photo</button>
          </div>
          <div className="text-end">
              <button onClick={(e) => closeChangePhoto(e)} className="btn btn-danger btn-sm">Close</button>
          </div>
      </ModalFooter>
      </Modal>
      {/* Modal Change Photo */}

      {/* Modal Change Cover */}
      <Modal isOpen={showCover} toggle={handleCloseBgCover} className={'modal-md modal--box-input'} color="warning" style={{marginTop: "165px", maxWidth: "800px"}}>
      <ModalHeader style={{backgroundColor : '#f0f3f5'}}></ModalHeader>
      <ModalBody style={{paddingTop: "25px"}}>
          <Row>
            <Col sm="12">
              <div style={{maxWidth: "670px", marginLeft: "auto", marginRight: "auto"}}>
                {imgBgData !== null ?
                <img src={imgBgData} style={{width: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                :
                getImageBgPhoto.length === 0 ?
                <img src={profileBg} style={{width: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                :
                <img src={getImageBgPhoto[0].image_view_file} style={{width: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                }
                <Input type="file" name="profile" onChange={(e) => {handleInputBgPhoto(e, "file");}} style={{marginTop: "15px"}}/>
              </div>
            </Col>
          </Row>
      </ModalBody>
      <ModalFooter>
          <div className="text-end">
              <button onClick={(e) => updatePicture(e)} className="btn btn-primary btn-sm">Update Background Cover</button>
          </div>
          <div className="text-end">
              <button onClick={(e) => closeChangeBgPhoto(e)} className="btn btn-danger btn-sm">Close</button>
          </div>
      </ModalFooter>
      </Modal>
      {/* Modal Change Photo */}
</React.Fragment>
  );
};

export default UserProfile;
