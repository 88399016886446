import React from 'react';
import addEventListener from 'react-use-event-listener'

import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row, UncontrolledAlert, Alert } from 'reactstrap';
import { datetimeformatiso } from '../../../helpers/FunctionalHelper/date-helper';
import { datetimeformatncrm, dateformatncrm } from "../../../helpers/FunctionalHelper/date-helper";

import { API_CLIENT_AUTH_BEARER } from '../../../helpers/api-helper';
import { inputTypeNumberAvoidNonNumber } from '../../../helpers/FunctionalHelper/input-helper';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

// const api = new APIClient();
const apiAuth = new API_CLIENT_AUTH_BEARER();

const CRFormTimelineInputan = (props) => {

    const {dataFormRegion} = props

    // const reducerTimelineData = useSelector(state => state.TimelineData);

    const selectReducerTimeline = createSelector(
        (state) => state.TimelineData.restricted_date_list_data,
        (restricted_date_list_data) => restricted_date_list_data
      );
    // Inside your component
    const restrictedDateListData = useSelector(selectReducerTimeline);

    const [dataForm, setDataForm] = React.useState({
        start_time : "23:00",
        end_time : "04:00"
    });
    const [dataHoliday, setDataHoliday] = React.useState([]);
    const [totalDataHoliday, setTotalDataHoliday] = React.useState(0);

    const [validationDataWeekend, setValidationDataWeekend] = React.useState(null);

    const current_next_date = new Date().toISOString().split("T")[0];

    const startDate = (e) => {
        const picker = document.getElementById('start_date');
        picker.addEventListener('input', function(e){
        const day = new Date(this.value).getUTCDay();
        if([6,0].includes(day)){
            e.preventDefault();
            this.value = '';
            // alert('Weekends not allowed');

            // console.log("Alert value", e);
        }
        // console.log("Alert day", day);
        setValidationDataWeekend(day)
        });
    }

    const endDate = (e) => {
        const picker = document.getElementById('end_date');
        picker.addEventListener('input', function(e){
        const day = new Date(this.value).getUTCDay();
        if([6,0].includes(day)){
            e.preventDefault();
            this.value = '';
        }
        setValidationDataWeekend(day)
        });
    }

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        setDataForm(dataFormInput)
    }

    const submitTimeline = (e) => {
        e.preventDefault()
        const formTimeline = {...dataForm};
        formTimeline["plan_status"] = "Proposed"
        formTimeline["plan_start_time"] = datetimeformatiso(formTimeline.start_date, formTimeline.start_time)
        formTimeline["plan_end_time"] = datetimeformatiso(formTimeline.end_date, formTimeline.end_time)
        props.addTimeline(formTimeline)
    }

    const getDataHoliday = async() => {

        if(restrictedDateListData && restrictedDateListData.length > 0){

            let data_sort = restrictedDateListData;
      
            if(dataFormRegion && (process.env.REACT_APP_ENVIRONEMT === "LOCALHOST" || process.env.REACT_APP_ENVIRONEMT === "DEVELOPMENT" || process.env.REACT_APP_ENVIRONEMT === "PRODUCTION")){
                let datasortAllRegion = data_sort.filter(item => item.region === null);
                data_sort = data_sort.filter(item => item.region !== null);
                data_sort = data_sort.filter(item => item.region.some(reg=> dataFormRegion.includes(reg)));
                data_sort = data_sort.concat([...datasortAllRegion])
            }
      
            setDataHoliday(data_sort);
      
            setTotalDataHoliday(data_sort.length);
            
        }

      }
    
      React.useEffect(() => {
        getDataHoliday();
      }, [restrictedDateListData]);

      React.useEffect(() => {
        startDate();
      }, []);

      React.useEffect(() => {
        endDate();
      }, []);

    // console.log("working day", validationDataWeekend);

    return (
        <React.Fragment>
            <Row>
                <Card>
                <Col md={12} className='mb-3 mt-3'>
                    <h4>Add Date Activity</h4>
                </Col>
                <Col md={12}>
                    {validationDataWeekend === 0 || validationDataWeekend === 6 ?
                    <div style={{marginBottom: "15px"}}>
                        <React.Fragment>
                        <Alert color="danger" className="mb-xl-0">
                            Please select a Working Day
                        </Alert>
                        </React.Fragment>
 
                    </div>
                    :
                    dataHoliday.find(date => (dateformatncrm(date.start_date) <= dateformatncrm(dataForm.start_date) && dateformatncrm(date.end_date) >= dateformatncrm(dataForm.start_date)) || (dateformatncrm(date.start_date) <= dateformatncrm(dataForm.end_date) && dateformatncrm(date.end_date) >= dateformatncrm(dataForm.end_date))) !== undefined ?
                    <div style={{marginBottom: "15px"}}>
                        <React.Fragment>
                        <Alert color="danger" className="mb-xl-0">
                            The Selected Date is "<strong>{dataHoliday.find(date => (dateformatncrm(date.start_date) <= dateformatncrm(dataForm.start_date) && dateformatncrm(date.end_date) >= dateformatncrm(dataForm.start_date)) || (dateformatncrm(date.start_date) <= dateformatncrm(dataForm.end_date) && dateformatncrm(date.end_date) >= dateformatncrm(dataForm.end_date))).title}</strong>"
                        </Alert>
                        </React.Fragment>
 
                    </div>
                    : ""
                    }
                    <div className="mb-3">
                        <Label htmlFor="start_date" className="form-label">Plan Start Date <span className="text-danger">*</span></Label>
                        <Input type="date" className="form-control" name="start_date" min={current_next_date} blocked-days={[0, 6]} value={dataForm.start_date} onChange={(e) => handleInputChange(e)} id="start_date" />
                    </div>
                </Col>
                <Col md={8}>
                    <div className="mb-3">
                        <Label htmlFor="start_time" className="form-label">Plan Start Time</Label>
                        <Input type="time" className="form-control" name="start_time" value={dataForm.start_time} onChange={(e) => handleInputChange(e)} id="start_time" />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="end_date" className="form-label">Plan End Date <span className="text-danger">*</span></Label>
                        <Input type="date" className="form-control" name="end_date" disabled={dataForm.start_date !== undefined ? false : true} blocked-days={[0, 6]} min={dataForm.start_date} value={dataForm.end_date} onChange={(e) => handleInputChange(e)} id="end_date" />
                    </div>
                </Col>
                <Col md={8}>
                    <div className="mb-3">
                        <Label htmlFor="end_time" className="form-label">Plan End Time</Label>
                        <Input type="time" className="form-control" name="end_time" disabled={dataForm.start_date !== undefined ? false : true} value={dataForm.end_time} onChange={(e) => handleInputChange(e)} id="end_time" />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="activity_description" className="form-label">Activity of Release <span className="text-danger">*</span></Label>
                        <Input type="text" className="form-control" name="activity_description" onChange={(e) => handleInputChange(e)} id="activity_description" />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="number_of_impacted_ne" className="form-label">Number of Impacted NE</Label>
                        <Input type="number" className="form-control form-control input-type-number__ncrm" onKeyDown={ (e) => inputTypeNumberAvoidNonNumber(e)} name="number_of_impacted_ne" onChange={(e) => handleInputChange(e)} id="number_of_impacted_ne" />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <Label htmlFor="detailed_of_impacted_ne" className="form-label">Detailed of Impacted NE</Label>
                        <Input type="textarea" className="form-control" name="detailed_of_impacted_ne" onChange={(e) => handleInputChange(e)} id="detailed_of_impacted_ne" />
                    </div>
                </Col>
                <Col md={12} className='mb-3'>
                    <div className="text-end">
                        <button onClick={(e) => submitTimeline(e)} className="btn btn-primary" disabled={ ( dataHoliday.find(date => dateformatncrm(date.start_date) <= dateformatncrm(dataForm.start_date) && dateformatncrm(date.end_date) >= dateformatncrm(dataForm.start_date)) === undefined ) && dataForm.start_date !== undefined && dataForm.end_date !== undefined &&  dataForm.start_date <= dataForm.end_date && dataForm.activity_description !== undefined && dataForm.activity_description !== "" ? false : true}>Add</button>
                    </div>
                </Col>
                </Card>
            </Row>
        </React.Fragment>
    );
};

export default CRFormTimelineInputan;