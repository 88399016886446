import React from 'react';
import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import Select from "react-select";

const NEOptions = [
    { value: 'Core-CS', label: 'Core-CS' },
    { value: 'Core-PS', label: 'Core-PS' },
    { value: 'Datacom', label: 'Datacom' },
    { value: 'NCO', label: 'NCO' },
    { value: 'Others', label: 'Others' },
    { value: 'RAN', label: 'RAN' },
    { value: 'Security', label: 'Security' },
];

const VendorNameOptions = [
    { value: 'DDI', label: 'DDI' },
    { value: 'Ericsson', label: 'Ericsson' },
    { value: 'Huawei', label: 'Huawei' },
    { value: 'MTM', label: 'MTM' },
    { value: 'Nokia', label: 'Nokia' },
    { value: 'Others', label: 'Others' },
    { value: 'ZTE', label: 'ZTE' },
];

const RegionNEOptions = [
    { value: 'HQ', label: 'HQ' },
    { value: 'Regional 01', label: 'Regional 01' },
    { value: 'Regional 02', label: 'Regional 02' },
    { value: 'Regional 03', label: 'Regional 03' },
    { value: 'Regional 04', label: 'Regional 04' },
    { value: 'Regional 05', label: 'Regional 05' },
    { value: 'Regional 06', label: 'Regional 06' },
    { value: 'Regional 07', label: 'Regional 07' },
    { value: 'Regional 08', label: 'Regional 08' },
    { value: 'Regional 09', label: 'Regional 09' },
    { value: 'Regional 10', label: 'Regional 10' },
    { value: 'Regional 11', label: 'Regional 11' },
];

const UserTypeOptions = [
    { value: 'Others', label: 'Others' },
    { value: 'User TCS (TACAS/Radius)', label: 'User TCS (TACAS/Radius)' },
    { value: 'User NE (Direct Access)', label: 'User NE (Direct Access)' },
    { value: 'User OSS', label: 'User OSS' },
];

const UserNeededOptions = [
    { value: 'ACS', label: 'ACS' },
    { value: 'ENM7', label: 'ENM7' },
    { value: 'Others', label: 'Others' },
];

const CRFormAIUserAccessPopUp = (props) => {

    const [errorImplementorEmail , setErrorImplementorEmail] = React.useState(false)

    const [dataForm, setDataForm] = React.useState({
        "network_element_name" : props.dataFormUser.ne_name_change_target
    });

    const [email, setEmail] = React.useState('');

    function isValidEmail(email) {
        return /\S+@telkomsel+\.co.id$/.test(email);
    }

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        if(name === "executor_pic_email"){
            setEmail(value);
        }else{
            dataFormInput[name] = value;
        }
        setDataForm(dataFormInput)
    }

    function handleSelectForm(e, name) {
        const dataFormSelect = {...dataForm};
        const {value, label} = e;
        dataFormSelect[name] = value;
        setDataForm(dataFormSelect);
    }

    const checkImplementorEmail = (email) => {
        const checkingEmail = email.includes('@telkomsel.co.id')
        setErrorImplementorEmail(!checkingEmail)
    }

    const submitUser = (e) => {
        const formAddUser = {...dataForm};
        props.addUser(formAddUser)
        props.showUser(false)
    }

    const closeUser = (e) => {
        props.showUser(false)
    }

    const checkEmail = (e) => {
        let email_data = '';
        const dataFormInput = {...dataForm}
        if(isValidEmail(email)){
            email_data = email;
        }
        dataFormInput["executor_pic_email"] = email_data;
        setDataForm(dataFormInput);
    }

    React.useEffect(() => {
        checkEmail()
    }, [email]);

    return (
        <React.Fragment>
            <Row>
                <Col md={2}>
                </Col>
                <Col md={8}>
                    {/* <div className="mb-3">
                        <Label htmlFor="userAccessLogin" className="form-label">User Access Login</Label>
                        <Input type="text" className="form-control" name="useraccesslogin" onChange={(e) => handleInputChange(e)} id="userAccessLogin" />
                    </div> */}
                    <div className="mb-3">
                        <Label htmlFor="executorPICName" className="form-label">Executor PIC Name</Label>
                        <Input type="text" className="form-control" name="executor_pic_name" onChange={(e) => handleInputChange(e)} id="executorPICName" required/>
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="executorPICPhone" className="form-label">Executor PIC Phone</Label>
                        <Input type="text" className="form-control" name="executor_pic_phone" onChange={(e) => handleInputChange(e)} id="executorPICPhone" />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="executorPICEmail" className="form-label">Executor PIC Email (@telkomsel.co.id)</Label>
                        <Input type="text" className="form-control" name="executor_pic_email" 
                            placeholder="username@telkomsel.co.id"
                            pattern=".+@telkomsel\.co\.id$/"
                            title="Please provide only a corporate email address"
                            onChange={(e) => handleInputChange(e)} id="executorPICEmail" 
                            required/>
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="neType" className="form-label">NE Type</Label>
                        <Select
                            isClearable={true}
                            onChange={(e) => {
                                handleSelectForm(e, "ne_type");
                            }}
                            options={NEOptions}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="networkElementName" className="form-label">Network Element Name</Label>
                        <Input type="text" className="form-control" name="network_element_name" value={dataForm.network_element_name} disabled={true} onChange={(e) => handleInputChange(e)} id="networkElementName" />
                    </div>
                    <div className="mb-3">          
                        <Label htmlFor="vendorName" className="form-label">Vendor Name</Label>
                        <Select
                            isClearable={true}
                            onChange={(e) => {
                                handleSelectForm(e, "vendor_name");
                            }}
                            options={props.dataFormUser.vendor_name && props.dataFormUser.vendor_name.map(item => ({label : item, value : item}))}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="regionNE" className="form-label">Region NE</Label>
                        <Select
                            isClearable={true}
                            onChange={(e) => {
                                handleSelectForm(e, "region_ne");
                            }}
                            options={RegionNEOptions}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="userType" className="form-label">User Type</Label>
                        <Select
                            isClearable={true}
                            onChange={(e) => {
                                handleSelectForm(e, "user_type");
                            }}
                            options={UserTypeOptions}
                        />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="userNeeded" className="form-label">User Needed</Label>
                        <Select
                            isClearable={true}
                            onChange={(e) => {
                                handleSelectForm(e, "user_needed");
                            }}
                            options={UserNeededOptions}
                        />
                    </div>
                </Col>
                <Col md={2}>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                </Col>
                <Col md={8} className='mb-3 d-flex justify-content-between' style={{textAlign: "center", alignItems : 'flex-end'}}>
                    <div className="text-end">
                        <button onClick={(e) => submitUser(e)} disabled={(!dataForm.executor_pic_email || !dataForm.executor_pic_name)} className="btn btn-primary">Submit</button>
                    </div>
                    <div className="text-end">
                        <button onClick={(e) => closeUser(e)} className="btn btn-danger btn-sm">Close</button>
                    </div>
                </Col>
                <Col md={2}>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CRFormAIUserAccessPopUp;