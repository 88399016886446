import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Nav, NavItem, NavLink, TabContent, Table, TabPane, UncontrolledCollapse, UncontrolledDropdown } from "reactstrap";

// Show Charts
import CRSummaryWidgets from "./CRStatusWidgets";
import CRPendingApprovalWidgets from "./PendingApprovalWidgets";
import CRScheduledCalendar from "./Calendar";
import CRStatusPieChart from "./CRStatusPieChart";
import CRRegionMainLocations from "./MainLocations";
import CRMonthlyTrendLineChart from "./LineChart";
import TeamMembers from "./TeamMembers";
import TotalUserWidgets from "./WidgetsBawah";
import CRDomainDoughnutChart from "./DoughnutChart";
import CRSubDomainBarChart from "./DomainCRChart";
import CRServiceAffectedBarChart from "./ServiceAffected";
import CRServiceOutagePolarChart from "./ServiceOutagePolarChart";
import StatusCRLineChart from "./StatusLineChart";

// Title Header
import { HEADER_TITLE_NAME } from '../../config/config-general';

import SwiperCore, { Autoplay } from "swiper";

// Photo & Background Image
import profileBg from '../../assets/images/bg-template.png';
import avatarDummy1 from '../../assets/images/users/user-dummy-img1.jpg';
import logoTelkomselFull from "../../assets/images/Logo/Telkomsel_2021.png";

// User Name Capital
import { capitalizeFirstLetterEachWord } from "../../helpers/FunctionalHelper/string-helper";

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

// API
const apiAuth = new API_CLIENT_AUTH_BEARER();

const DashboardMain = () => {
  document.title = "Dashboard "+HEADER_TITLE_NAME;

  const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

  SwiperCore.use([Autoplay]);

  const [dataDate, setDataDate] = useState({});
  const [dataForm, setDataForm] = React.useState({});
  const [getImagePhoto, setGetImagePhoto] = React.useState([]);
  const [getImageBgPhoto, setGetImageBgPhoto] = React.useState([]);
  const [rightColumn, setRightColumn] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [activityTab, setActivityTab] = useState('1');

  // Set Data
  const [dataCRPerStatus, setDataCRPerStatus] = useState({});
  const [dataCRPerRegion, setDataCRPerRegion] = useState({});
  const [dataCRPerDomain, setDataCRPerDomain] = useState({});
  const [dataCRPerSubDomain, setDataCRPerSubDomain] = useState({});
  const [dataCRPerServiceAffected, setDataCRPerServiceAffected] = useState({});
  const [dataCRPerServiceOutageDuration, setDataCRPerServiceOutageDuration] = useState({});
  const [dataCRMonthlyTrend, setDataCRMonthlyTrend] = useState({});
  const [dataTeamMember, setDataTeamMember] = useState({});

  const date_data = (data) => {
    setDataDate(data);
  }

  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };

  const toggleTab = (tab) => {
      if (activeTab !== tab) {
          setActiveTab(tab);
      }
  };

  const toggleActivityTab = (tab) => {
      if (activityTab !== tab) {
          setActivityTab(tab);
      }
  };

  // Get Data - User Detail
  const getData = async() => {
    const res = await apiAuth.get('/getUserDetail/'+userData.email);
    if(res && res.data){
        setDataForm(res.data.data)
        getProfilePicture(res.data.data)
        getCoverPicture(res.data.data)
    }
  }

  React.useEffect(() => {
    getData();
  }, []);
  
  // Get Data - Photo Profile
  const getProfilePicture = async (data_user) => {
      const res = await apiAuth.getFile('/downloadProfile/'+userData.email+'/profile_picture', userData.token)
      if (res && res.data) {
        if(data_user && data_user.profiles){
          showPhoto(res.data, data_user.profiles.find(fl => fl.file_type === "profile_picture"), "profile");
        }
      } else {
        return null
      }
  }

  // Get Data - Cover Backaground Image
  const getCoverPicture = async (data_user) => {
    const res = await apiAuth.getFile('/downloadProfile/'+userData.email+'/background_cover', userData.token)
    if (res && res.data) {
      if(data_user && data_user.profiles){
        showPhoto(res.data, data_user.profiles.find(fl => fl.file_type === "background_cover"), "cover");
      }
    } else {
      return null
    }
  }

  // Show Photo & Cover Background Image
  const showPhoto = (file, data_image, flag) => {
    let images = [];
    let data_image_file = data_image;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      const sfbase = reader.result.replace('application/octet-stream', 'image/png');
      data_image_file["image_view_file"] = sfbase;
      images.push(data_image_file)

      if(flag === "profile"){
        setGetImagePhoto(images)
      }else if(flag === "cover"){
        setGetImageBgPhoto(images)
      }
      
    }
  }

  // Get Data - CR Per Status
  const getDataCRStatusFromAPI = async() => {
    const res = await apiAuth.get("/getDashboardStatusCount")

    if(res && res.data){
        setDataCRPerStatus(res.data.data);
    }
  }

  // Get Data - CR Per Region
  const getDataCRRegionFromAPI = async() => {
    const res = await apiAuth.get("/getCRPerRegion")

    if(res && res.data){
        setDataCRPerRegion(res.data.data);
    }
  }

  // Get Data - CR Per Domain
  const getDataCRDomainFromAPI = async() => {
    const res = await apiAuth.get("/getCRPerDomain")

    if(res && res.data){
        setDataCRPerDomain(res.data.data);
    }
  }

  // Get Data - CR Per Sub Domain
  const getDataCRSubDomainFromAPI = async() => {
    const res = await apiAuth.get("/getCRPerSubDomain")

    if(res && res.data){
        setDataCRPerSubDomain(res.data.data);
    }
  }

  // Get Data - CR Service Affected
  const getDataCRServiceAffectedFromAPI = async() => {
    const res = await apiAuth.get("/getCRPerServiceAffected")

    if(res && res.data){
        setDataCRPerServiceAffected(res.data.data);
    }
  }

  // Get Data - CR Service Outage Duration
  const getDataCRServiceOutageDurationFromAPI = async() => {
    const res = await apiAuth.get("/getCRServiceOutageDuration")

    if(res && res.data){
        setDataCRPerServiceOutageDuration(res.data.data);
    }
  }

  // Get Data - CR Monthly Trend
  const getDataCRMonthlyTrendFromAPI = async() => {
    const res = await apiAuth.get("/getMonthlyCRTrend")

    if(res && res.data){
        setDataCRMonthlyTrend(res.data.data);
    }
  }

  // Get Data - Team Member
  const getDataTeamMemberFromAPI = async() => {
    const res = await apiAuth.get("/getTeamMemberStatusCount")

    if(res && res.data){
        setDataTeamMember(res.data.data);
    }
  }

  React.useEffect(() => {
      getDataCRStatusFromAPI();
      getDataCRRegionFromAPI();
      getDataCRDomainFromAPI();
      getDataCRSubDomainFromAPI();
      getDataCRServiceAffectedFromAPI();
      getDataCRServiceOutageDurationFromAPI();
      getDataCRMonthlyTrendFromAPI();
      getDataTeamMemberFromAPI();
  }, []);

  return (
    <React.Fragment>
      <div className="pt-0 mt-0 pb-lg-3" style={{marginBottom: "-125px"}}>
        <Row className="g-4" style={{height: "275px", margin: "0px", borderBottomLeftRadius: "185px"}}>
          {/* <img src={profileBg} alt="" style={{height: "275px", marginTop: "30px", margin: "0px", padding: "0px", width: "100%", borderBottomLeftRadius: "185px"}} /> */}
        </Row>
      </div>
      <div className="page-content mt-0 pt-0">
        <Container fluid>
          <div className="pt-4 mb-4 mb-lg-2 pb-lg-3" style={{display: "none"}}>
              <Row className="g-4">
                  <div className="col-auto">
                      <div className="avatar-lg" style={{aspectRatio: 1 / 1, maxWidth: "200px", marginLeft: "auto", marginRight: "auto"}}>
                          {getImagePhoto.length === 0 ?
                          <img src={avatarDummy1} className="img-thumbnail" style={{borderRadius: "50%", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                          :
                          <img src={getImagePhoto[0].image_view_file} className="img-thumbnail" style={{borderRadius: "50%", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                          }
                      </div>
                  </div>

                  <Col>
                      <div className="p-2">
                          <h3 className="text-white mb-1">{capitalizeFirstLetterEachWord([userData.first_name, userData.last_name].filter(item => item).join(" "))}</h3>
                          <Row>
                          <p className="text-white text-opacity-75">
                            {userData.role && userData.role.map((role,idx) =>
                              <div style={{float: "left"}}>{idx !== 0 && ", "}{role}</div>
                            )}
                          </p>
                          </Row>
                          <Row>
                          <div className="hstack text-white gap-1">
                                <div className="me-2">
                                  <i className="ri-mail-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{userData.email ? userData.email : "-"}
                                </div>
                                <div className="me-2">
                                  <i className="ri-phone-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{userData.phone_number ? userData.phone_number : "-"}
                                </div>
                          </div>
                          <div className="hstack text-white gap-1">
                                <div className="me-2">
                                  <i className="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{userData.title ? userData.title : "-"}
                                </div>
                          </div>
                          </Row>
                      </div>
                  </Col>
                  <Col className="d-none d-md-flex justify-content-center">
                      <div className="p-2 justify-content-end" style={{maxWidth: "238px", marginLeft: "auto", alignSelf: "center"}}>
                          <div className="d-flex justify-content-center justify-content-end">
                              <img src={logoTelkomselFull} alt="" className='side-login-logo--auth-page' style={{width: "100%", margin: "0px"}}/>
                          </div>
                      </div>
                  </Col>
              </Row>
          </div>
          <Row>
              <Col lg={12}>
                  <div>
                      <Row>
                        <Col>
                          <div className="h-100">
                            <Row>
                              <CRSummaryWidgets crdata={dataCRPerStatus}/>
                            </Row>
                            <Row>
                              <Col md={12} xl={8}>
                                <CRPendingApprovalWidgets/>
                                <CRScheduledCalendar key={dataDate} item={dataDate} />
                              </Col>
                              <Col md={12} xl={4}>
                                <CRStatusPieChart crdata={dataCRPerStatus}/>
                                <CRRegionMainLocations crdata={dataCRPerRegion}/>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} xl={8}>
                                <CRMonthlyTrendLineChart crdata={dataCRMonthlyTrend}/>
                              </Col>
                              <Col md={12} xl={4}>
                                <TeamMembers crdata={dataTeamMember}/>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} xl={4}>
                                <TotalUserWidgets />
                              </Col>
                              <Col md={12} xl={4}>
                                <CRDomainDoughnutChart crdata={dataCRPerDomain}/>
                              </Col>
                              <Col md={12} xl={4}>
                                <CRSubDomainBarChart crdata={dataCRPerSubDomain}/>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} xl={4}>
                                <CRServiceAffectedBarChart crdata={dataCRPerServiceAffected}/>
                              </Col>
                              <Col md={12} xl={4}>
                                <CRServiceOutagePolarChart crdata={dataCRPerServiceOutageDuration}/>
                              </Col>
                              <Col md={12} xl={4}>
                                <StatusCRLineChart />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                  </div>
              </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardMain;
