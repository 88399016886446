//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from "../../slices/auth/login/reducer";
import { API_CLIENT_NON_AUTH } from '../../helpers/api-helper';

const apiBasic = new API_CLIENT_NON_AUTH();

// import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer';

export const loginUser = (user, history) => async (dispatch) => {
  try{

    const user_data = {
      email: user.email,
      password: user.password,
    }

    const response = await apiBasic.post('/login', user_data);
    let finallogin = JSON.stringify(response.data);
    finallogin = JSON.parse(finallogin);
    let data = {...finallogin};
    data['user_login_on'] = JSON.stringify(new Date());
    if (data.email) {
      sessionStorage.setItem("authUser", JSON.stringify({data : data}));
      dispatch(loginSuccess(data));
      history('/dashboard')
    } else {
      dispatch(apiError(finallogin));
    }
  } catch (error) {
    if(error.response.data){
      if(error.response.data.message){
        dispatch(apiError({data : error.response.data.message}));
      }else{
        dispatch(apiError({data : JSON.stringify(error.response.data)}));
      }
    }else{
      dispatch(apiError({data : JSON.stringify(error.response)}));
    }
  }

};

export const logoutUser = () => async (dispatch) => {
  // try {
  //   sessionStorage.removeItem("authUser");
  //   let fireBaseBackend = getFirebaseBackend();
  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const response = fireBaseBackend.logout;
  //     dispatch(logoutUserSuccess(response));
  //   } else {
  //     dispatch(logoutUserSuccess(true));
  //   }

  // } catch (error) {
  //   dispatch(apiError(error));
  // }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};