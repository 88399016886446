import React from 'react';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import { Line, Bar, Pie, Doughnut, Radar, PolarArea} from "react-chartjs-2";


const PortfolioCharts = ({ seriesData, dataColors }) => {
    const series = seriesData;

    const options = {
        chart: {
            type: 'pie',
        },
        labels: ["Draft","RFA", "RFC", "SFA", "Scheduled", "Completed"],
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 270
            },
        },
        dataLabels: {
            formatter: (value) => {
                let sum = series.reduce((partialSum, a) => partialSum + a, 0);

                let percentage = ((value / sum)* 100).toFixed(2) + "%";

                return value.toFixed(0) + "%" ;
            },
        },
        legend: {
            position: 'bottom',
        },
        colors: ["#02a8b5","#38618C","#299cdb","#f06548","#f7b84b","#3577f1"],
    }

    return (
        <ReactApexChart dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="pie"
            height={"440"}
        />

    )
}

const DomainCharts = ({ dataColors, series, domain }) => {
    var seriesData = series;
    // console.log("Domain in Data", seriesData)
    var barchartCountriesColors = getChartColorsArray(dataColors);
    var options = {
        chart: {
            type: 'bar',
            height: 360,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        colors: ["#02a8b5","#38618C","#299cdb","#f06548","#f7b84b","#3577f1"],
        dataLabels: {
            enabled: true,
            offsetX: 32,
            style: {
                fontSize: '12px',
                fontWeight: 400,
                colors: ['#adb5bd']
                // colors:['#878a99']
            }
        },

        legend: {
            show: false,
        },
        grid: {
            show: false,
        },
        xaxis: {
            categories: domain
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={seriesData}
                type="bar"
                height="360"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

//Status Polar Chart
const ServiceOutagePolarChart = ({seriesData, dataColors}) => {
    var polarAreaChartColors =  getChartColorsArray(dataColors);
    // const series = seriesData;

    const data = {
        labels: ["0-10 min","10-30 min", "30-60 min", "1-2 hr", "2-6 hr", "6-12 hr", "12-24 hr", "more than 1 day"],
        datasets: [{
            data: seriesData,
            backgroundColor: ["#02a8b5","#38618C","#299cdb","#f06548","#f7b84b","#3577f1","#a535f1","#7083ce"],
            label: ' Data ', // for legend
            hoverBorderColor: "#fff"
        }]
    }
    const option= {
        plugins: {
            legend: {
                labels: {
                    font: {
                        family: 'Poppins',
                    }
                }
            },
        },
    }
  return (
    <React.Fragment>
      <PolarArea className="chartjs-chart" 
        // series={series}
        data={data}
        options={option} />
    </React.Fragment>
  )
}

const ServiceAffectedCharts = ({ dataColors, series }) => {
    var areachartSalesColors = getChartColorsArray(dataColors);

    var options = {
        chart: {
            type: 'bar',
            height: 341,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '65%',
            },
        },
        stroke: {
            show: true,
            width: 5,
            colors: ['transparent']
        },
        xaxis: {
            categories: [''],
            axisTicks: {
                show: false,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0
            },
            title: {
                // text: '',
                offsetX: 0,
                offsetY: -30,
                style: {
                    color: '#78909C',
                    fontSize: '12px',
                    fontWeight: 400,
                },
            },
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return value;
                }
            },
            tickAmount: 4,
            min: 0
        },
        fill: {
            opacity: 1
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontWeight: 500,
            offsetX: 0,
            offsetY: -14,
            itemMargin: {
                horizontal: 8,
                vertical: 0
            },
            markers: {
                width: 10,
                height: 10,
            }
        },
        colors: areachartSalesColors
    };

    // console.log("series", series)
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="bar"
                height="341"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const StatusCRLineCharts = ({ dataColors, series }) => {
    var revenueExpensesChartsColors = getChartColorsArray(dataColors);

    var options = {
        chart: {
            height: 290,
            type: 'area',
            toolbar: 'false',
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return value;
                }
            },
            tickAmount: 4,
            min: 0,
        },
        colors: ["#02a8b5","#38618C","#299cdb","#f06548","#f7b84b","#3577f1","#a535f1","#7083ce"],
        fill: {
            opacity: 0.06,
            colors: revenueExpensesChartsColors,
            type: 'solid'
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="area"
                height="290"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const TeamMembersCharts = ({ seriesData, chartsColor }) => {
    const series = [seriesData];

    const options = {
        chart: {
            type: 'radialBar',
            width: 36,
            height: 36,
            sparkline: {
                enabled: !0
            }
        },
        dataLabels: {
            enabled: !1
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '50%'
                },
                track: {
                    margin: 1
                },
                dataLabels: {
                    show: !1
                }
            }
        },
        colors: [chartsColor]
    };
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={[...series]}
                type="radialBar"
                height="36"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const TeamPendingApprovalCharts = ({ seriesData, chartsColor }) => {
    const series = [seriesData];

    const options = {
        chart: {
            type: 'radialBar',
            width: 36,
            height: 36,
            sparkline: {
                enabled: !0
            }
        },
        dataLabels: {
            enabled: !1
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '50%'
                },
                track: {
                    margin: 1
                },
                dataLabels: {
                    show: !1
                }
            }
        },
        colors: [chartsColor]
    };
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={[...series]}
                type="radialBar"
                height="36"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const WidgetCharts = ({ seriesData, colors }) => {
    const series = [seriesData];
    const options = {
      chart: {
        type: "radialBar",
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "57%",
          },
          track: {
            margin: 1,
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "18px",
              fontWeight: 600,
              offsetY: 8,
            },
          },
        },
      },
      colors: [colors],
    };
    return (
      <React.Fragment>
        <ReactApexChart dir="ltr"
          options={options}
          series={[...series]}
          type="radialBar"
          id="total_jobs"
          height="100"
          className="apex-charts"
        />
      </React.Fragment>
    );
  };


export {PortfolioCharts, TeamMembersCharts, TeamPendingApprovalCharts, WidgetCharts, ServiceOutagePolarChart, ServiceAffectedCharts, StatusCRLineCharts, DomainCharts };