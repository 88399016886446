import React, { Suspense, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Progress,
  Button,
  Spinner,
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { loadAnimation } from "lottie-web";
import { defineElement } from "lord-icon-element";
import { HEADER_TITLE_NAME } from '../../../config/config-general';
// import CRFormRequestor from "./CRFormRequestor";
import CRFormTimelineInputan from "./CRFormTimelineInputan";
import CRFormGeneralData from "./CRFormGeneralData";
// import CRFormCategorization from "./CRFormCategorization";
import CRFormClasification from "./CRFormClasification";
import CRFormAIUserAccess from "./CRFormAIUserAccess";
import CRFormSummary from "./CRFormSummary";

import { ACTIVITY_LIST_DUMMY } from "../../../dummy-data/ACTIVITY_LIST_DUMMY";
import CRFormAIInformation from "./CRFormAIInformation";
import { useDispatch } from "react-redux";
import { createDataCr } from "../../../middleware/cr-form/cr-creation-thunk";
import { loadingStatusSet } from "../../../redux/reducers/notificationFlagReducer";
import { unwrapResult } from "@reduxjs/toolkit";

const CRFormTimeline = React.lazy(() =>
  import("./CRFormTimeline")
);

const CRFormRequestor = React.lazy(() =>
  import("./CRFormRequestor")
);

const CRFormCategorization = React.lazy(() =>
  import("./CRFormCategorization")
);
// import CRFormTimeline from "./CRFormTimeline";

// register lottie and define custom element
defineElement(loadAnimation);

const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};
  
export default function CRCreation() {

  document.title="CR Form Creation "+HEADER_TITLE_NAME;

  const Activity_List = ACTIVITY_LIST_DUMMY;

  const [activeTab, setactiveTab] = useState(1);
  const [activeArrowTab, setactiveArrowTab] = useState(2);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
  const [listUserImplementor, setListUserImplementor] = useState([]);
  const [listCrAttachment, setListCrAttachment] = useState([]);
  const [listTimeline, setListTimeline] = useState([]);
  const [dataForm, setDataForm] = useState({"oc_tier_1" : "Change & Release Management"});
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [actionStatus, setActionStatus] = useState(null)
  const [canSubmit, setCanSubmit] = useState(false)

  const [requiredCatgorization, setRequiredCatgorization] = useState([false, true, false, false, false]);
  const [requiredClasification, setRequiredClasification] = useState([false, false, false]);
  const [requiredInformation, setRequiredInformation] = useState([false, false, false, false, false, false, false]);
  const [requiredTimeline, setRequiredTimeline] = useState([false]);
  const [requiredUserAccess, setRequiredUserAccess] = useState([false, false, false]);

  const dispatch = useDispatch();

  function toggleArrowTab(tab) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];

      setactiveArrowTab(tab);
      setPassedarrowSteps(modifiedSteps);
    }
  }

  const handleAddTimeline = (timeline) => {
    const timeline_list = [...listTimeline]
    timeline_list.push(timeline)
    setListTimeline(timeline_list)
  }

  const handleUserImplementor = (implementor) => {
    setListUserImplementor(implementor)
  }

  const handleCrAttachment = (attach) => {
    const findClassMop = attach.find(item => item.attachment_name === "Method of Procedure")
    const findClassEvidence = attach.find(item => item.attachment_name === "Evidence")
    let can_submit = false
    if(findClassMop && findClassEvidence){
      if(findClassMop.file && findClassEvidence.file){
        can_submit = true
      }
    }
    setCanSubmit(can_submit)
    setListCrAttachment(attach)
  }

  const handleDelTimeline = (idx) => {
    const timeline_list = [...listTimeline]
    timeline_list.splice(idx, 1);
    setListTimeline(timeline_list)
  }

  const handleInputChange = (name, value) => {
    const dataFormInput = {...dataForm}
    dataFormInput[name] = value;
    setDataForm((prevState) => ({
      ...prevState,
      ...dataFormInput
    }))
  }

  const handleFormChange = (form_data) => {
    const dataFormInput = {...form_data}
    setDataForm(dataFormInput)
  }

  // Check Mandatory Fields
  const handleRequiredCategorization = (flag_data) => {
    const dataInput = flag_data
    setRequiredCatgorization(dataInput)
  }

  const handleRequiredClasification = (flag_data) => {
    const dataInput = flag_data
    setRequiredClasification(dataInput)
  }

  const handleRequiredInformation = (flag_data) => {
    const dataInput = flag_data
    setRequiredInformation(dataInput)
  }

  const handleRequiredTimeline = (flag_data) => {
    const dataInput = flag_data
    setRequiredTimeline(dataInput)
  }

  const handleRequiredUserAccess = (flag_data) => {
    console.log("Data Require Index View", flag_data)
    console.log("Data Require Index View", flag_data.includes(false))
    const dataInput = flag_data
    setRequiredUserAccess(dataInput)
  }

  const prepareDataForm = () => {

    const intial_state = {
      ...userData,
      current_status : "Draft",
      version : "1"
    };
    setDataForm(intial_state)

  }

  React.useEffect(() => {
    prepareDataForm()
  }, []);

  const submitDataForm = async() => {
    const summary = [dataForm.activity_list, dataForm.change_category, dataForm.impact].filter(item => item !== undefined).join(" # ")

    let first_timeline = null;
    let last_timeline = null;
    if(listTimeline && listTimeline.length > 0){
      let timeline_sort_plan_start = [...listTimeline];
      timeline_sort_plan_start.sort((a, b) => new Date(a.plan_start_time )- new Date(b.plan_start_time));
      first_timeline = timeline_sort_plan_start[0].plan_start_time;
      let timeline_sort_plan_end = [...listTimeline];
      timeline_sort_plan_end.sort((a, b) => new Date(b.plan_end_time )- new Date(a.plan_end_time));
      last_timeline = timeline_sort_plan_end[0].plan_end_time;
    }
    
    const formData = {
      ...dataForm,
      implementation_end : last_timeline,
      implementation_start : first_timeline,
      timeline : [
        ...listTimeline
      ],
      summary : summary,
      ai_user_access : [
        ...listUserImplementor
      ]
    }

    setLoadingStatus(true)
    dispatch(loadingStatusSet(true));
    const response = await dispatch(createDataCr({formData, listCrAttachment}))
    const res = unwrapResult(response);
    setLoadingStatus(false)
    if(res && res.data){
      setActionStatus('success')
      // console.log("res")
    }else{
      
    }
  }

  // console.log("data Form Parent", dataForm, listCrAttachment, listUserImplementor)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="CR Form Creation" pageTitle="CR Form Creation" />
          <Row>
          <Col xl={12}>
              <Card>
                {/* <CardHeader>
                  <h4 className="card-title mb-0">Arrow Nav Steps</h4>
                </CardHeader> */}
                <CardBody>
                  <Form className="form-steps">
                    <div className="step-arrow-nav mb-4">
                      <Nav
                        className="nav-pills custom-nav nav-justified"
                        role="tablist"
                      >
                        {/* <NavItem>
                          <NavLink
                          href="#"
                            id="steparrow-gen-info-tab"
                            className={classnames({
                              active: activeArrowTab === 1,
                              done: activeArrowTab <= 7 && activeArrowTab > 0,
                            })}
                            onClick={() => {toggleArrowTab(1);}}
                          >
                            Requestor
                          </NavLink>
                        </NavItem> */}
                        <NavItem>
                          <NavLink
                          href="#"
                            id="steparrow-gen-info-tab"
                            className={classnames({
                              active: activeArrowTab === 2,
                              done: activeArrowTab <= 7 && activeArrowTab > 1,
                            })}
                            onClick={() => {
                              toggleArrowTab(2);
                            }}
                          >
                            Categorization
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                          href="#"
                            id="steparrow-gen-info-tab"
                            className={classnames({
                              active: activeArrowTab === 3,
                              done: activeArrowTab <= 7 && activeArrowTab > 2,
                            })}
                            onClick={() => {
                              toggleArrowTab(3);
                            }}
                          >
                            Classification
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                          href="#"
                            id="steparrow-gen-info-tab"
                            className={classnames({
                              active: activeArrowTab === 4,
                              done: activeArrowTab <= 7 && activeArrowTab > 3,
                            })}
                            onClick={() => {
                              toggleArrowTab(4);
                            }}
                          >
                            Information
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                          href="#"
                            id="steparrow-gen-info-tab"
                            className={classnames({
                              active: activeArrowTab === 5,
                              done: activeArrowTab <= 7 && activeArrowTab > 4,
                            })}
                            onClick={() => {
                              toggleArrowTab(5);
                            }}
                          >
                            Timeline
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                          href="#"
                            id="steparrow-gen-info-tab"
                            className={classnames({
                              active: activeArrowTab === 6,
                              done: activeArrowTab <= 7 && activeArrowTab > 5,
                            })}
                            onClick={() => {
                              toggleArrowTab(6);
                            }}
                          >
                            User Access Management
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                          href="#"
                            id="steparrow-gen-info-tab"
                            className={classnames({
                              active: activeArrowTab === 7,
                              done: activeArrowTab <= 7 && activeArrowTab > 6,
                            })}
                            onClick={() => {
                              toggleArrowTab(7);
                            }}
                          >
                            Finish
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    

                    <Row style={{margin: "0px"}}>
                      <Col xl={12}>
                        <TabContent activeTab={activeArrowTab}>
                        <TabPane id="steparrow-gen-info" tabId={0}>

                          <Suspense>

                            <CRFormRequestor dataForm = {dataForm} />
                          </Suspense>
                          </TabPane>

                          <TabPane id="steparrow-gen-info" tabId={1}>
                          <Suspense>
                            <CRFormRequestor dataForm = {dataForm} />
                          </Suspense>
                          </TabPane>
                            
                          <TabPane id="steparrow-gen-info" tabId={2}>
                          <Row>
                              <Col md={6}>
                                <Suspense>
                                  <CRFormCategorization 
                                    onChangeForm = {handleInputChange}
                                    onChangeFormAll = {handleFormChange}
                                    onChangeRequiredCategorization = {handleRequiredCategorization}
                                    dataForm = {dataForm}
                                    activityList={Activity_List}
                                  />
                                </Suspense>
                              </Col>
                              <Col md={6}>
                                <Col md={12} style={{background: "cadetblue", borderRadius: "4px", padding: "20px", height: "100%"}}>
                                  <h5 className="text-white"><i className="fs-22 text-warning ri-lightbulb-line"></i> Tips</h5>
                                  <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                  <p className="text-white">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                </Col>
                              </Col>
                          </Row>
                          </TabPane>

                          <TabPane id="steparrow-gen-info" tabId={3}>
                          <Row>
                              {/* <Col xl={4}>
                                <CRFormGeneralData 
                                  onChangeForm = {handleInputChange}
                                  dataForm = {dataForm}
                                />
                              </Col> */}
                              <Col xl={12}>
                                <CRFormClasification 
                                  dataForm = {dataForm}
                                  activityList={Activity_List}
                                  onChangeForm = {handleInputChange}
                                  onChangeFormAll = {handleFormChange}
                                  addAttachment = {handleCrAttachment}
                                  onChangeRequiredClasification = {handleRequiredClasification}
                                />
                              </Col>
                          </Row>
                          </TabPane>

                          <TabPane id="steparrow-gen-info" tabId={4}>
                          <Row>
                              {/* <Col xl={4}>
                                <CRFormGeneralData 
                                  dataForm = {dataForm}
                                  onChangeForm = {handleInputChange}
                                />
                              </Col> */}
                              <Col xl={12}>
                                <CRFormAIInformation 
                                  dataForm = {dataForm}
                                  onChangeFormAll = {handleFormChange}
                                  onChangeForm = {handleInputChange}
                                  onChangeRequiredInformation = {handleRequiredInformation}
                                />
                              </Col>
                          </Row>
                          </TabPane>

                          <TabPane id="steparrow-gen-info" tabId={5}>
                          {activeArrowTab === 5 && (
                          <Row>
                            <Col xl={3}>
                              <CRFormTimelineInputan
                                dataFormRegion={dataForm.region}
                                addTimeline={handleAddTimeline}
                              />
                            </Col>
                            <Col xl={9}>
                              <Suspense>
                                  <CRFormTimeline 
                                    dataTimeline={listTimeline}
                                    dataFormRegion={dataForm.region}
                                    onDeleteTimeline={() => handleDelTimeline()}
                                    onChangeRequiredTimeline = {handleRequiredTimeline}
                                  />
                              </Suspense>
                            </Col>
                          </Row>
                          )}
                          </TabPane>

                          <TabPane id="steparrow-description-info" tabId={6}>
                          <Row>
                            {/* <Col xl={4}>
                              <CRFormGeneralData 
                                dataForm = {dataForm}
                                onChangeForm = {handleInputChange}
                              />
                            </Col> */}
                            <Col xl={12}>
                              <CRFormAIUserAccess 
                                dataForm = {dataForm}
                                addImplementor = {handleUserImplementor}
                                onChangeForm = {handleInputChange}
                                onChangeRequiredUserAccess = {handleRequiredUserAccess}
                              />
                            </Col>
                        </Row>
                          
                        </TabPane>

                          <TabPane id="pills-experience" tabId={7}>
                            {/* <div className="text-center">
                              <div className="avatar-md mt-5 mb-4 mx-auto">
                                <div className="avatar-title bg-light text-success display-4 rounded-circle">
                                  <i className="ri-checkbox-circle-fill"></i>
                                </div>
                              </div>
                              <h5>Completed</h5>
                              <p className="text-muted">
                              </p>
                            </div> */}
                            <div className="text-center">
                              {actionStatus === 'success' ? (
                                <React.Fragment>
                                  <div className="avatar-md mt-5 mb-4 mx-auto">
                                    <div className="avatar-title bg-light text-success display-4 rounded-circle">
                                      <i className="ri-checkbox-circle-fill"></i>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <Row>
                                    <Col xs={12} style={{marginTop : '15vh'}}>
                                      <h4>Please Submit Data</h4>
                                      <p className="text-muted">To complete your work please submit the data</p>
                                    </Col>
                                  </Row>
                                  {canSubmit ? (
                                    <Row style={{marginBottom : '15vh'}}>
                                      <Col xs={12} style={{marginBottom: "10px"}}>
                                        {/* {console.log("Data Form Summary", {...dataForm, timeline : [...listTimeline], summary : [dataForm.activity_list, dataForm.change_category, dataForm.impact].filter(item => item !== undefined).join(" # "), ai_user_access : [...listUserImplementor]})} */}
                                        <Button onClick={submitDataForm} disabled={loadingStatus}>
                                          {loadingStatus ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null }
                                          Submit
                                        </Button>
                                      </Col>
                                      
                                      {process.env.REACT_APP_ENVIRONEMT === "LOCALHOST" && (
                                        <Row>
                                          <Col md={12} style={{padding: "20px", backgroundColor: "#dedede"}}>
                                            <CRFormSummary 
                                              dataForm = {{...dataForm, timeline : [...listTimeline], summary : [dataForm.activity_list, dataForm.change_category, dataForm.impact].filter(item => item !== undefined).join(" # "), ai_user_access : [...listUserImplementor]}}
                                            />
                                          </Col>
                                        </Row>
                                      )}
                                    </Row>
                                  ) : requiredCatgorization.includes(false) !== false ? (
                                    <Row style={{marginBottom : '15vh'}}>
                                      <Col xs={12}>
                                        <p style={{color : "#856404"}}>Please check Categorization Mandatory Fields</p>
                                      </Col>
                                    </Row>
                                  ) : requiredClasification.includes(false) !== false ? (
                                    <Row style={{marginBottom : '15vh'}}>
                                      <Col xs={12}>
                                        <p style={{color : "#856404"}}>Please check Clasification Mandatory Fields</p>
                                      </Col>
                                    </Row>
                                  ) : requiredInformation.includes(false) !== false ? (
                                    <Row style={{marginBottom : '15vh'}}>
                                      <Col xs={12}>
                                        <p style={{color : "#856404"}}>Please check Information Mandatory Fields</p>
                                      </Col>
                                    </Row>
                                  ) : requiredTimeline.includes(false) !== false ? (
                                    <Row style={{marginBottom : '15vh'}}>
                                      <Col xs={12}>
                                        <p style={{color : "#856404"}}>Please check Timeline Mandatory Fields</p>
                                      </Col>
                                    </Row>
                                  ) : requiredUserAccess.includes(false) !== false ? (
                                    <Row style={{marginBottom : '15vh'}}>
                                      <Col xs={12}>
                                        <p style={{color : "#856404"}}>Please check User Access Management Mandatory Fields</p>
                                      </Col>
                                    </Row>
                                  ) : (
                                    <Row style={{marginBottom : '15vh'}}>
                                      <Col xs={12}>
                                        <p style={{color : "#856404"}}>Please check Method of Procedure and Evidence attachments file</p>
                                      </Col>
                                    </Row>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                    {activeArrowTab !== 7 && (
                      <Row>
                        <Col>
                          <div className="d-flex align-items-start gap-3 mt-4">
                            {activeArrowTab !== 1 &&
                            <button
                              type="button"
                              className="btn btn-light btn-label previestab"
                              onClick={() => {
                                toggleArrowTab(activeArrowTab - 1);
                              }}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                              Back
                            </button>
                            }
                            {activeArrowTab !== 7 &&
                            <button
                              type="button"
                              className="btn btn-success btn-label right ms-auto nexttab nexttab"
                              onClick={() => {
                                toggleArrowTab(activeArrowTab + 1);
                              }}
                            >
                              <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                              Next
                            </button>
                          }
                        </div>
                      </Col>
                    </Row>
                  )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
