import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { notificationFlagAdd, loadingFalse, notificationFlagAssign } from '../../redux/reducers/notificationFlagReducer';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const dateNow = new Date();

export const createDataHoliday = createAsyncThunk(
    "/createHolidayManagement",
    async (data, thunkApi) => {
        const resPromise = await createDataHolidayAsync(data, thunkApi);
        return resPromise;
    }
);

export const createDataHolidayAsync = async (dataHoliday, thunkApi) => {

    const res = await apiAuth.post('/createHolidayManagement', {data : dataHoliday});

    let notification = {}
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_message : "Created successfully!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res
}

export const deleteDataHoliday = createAsyncThunk(
    "/deleteHolidayManagement",
    async (data, thunkApi) => {
        const resPromise = await deleteDataHolidayAsync(data, thunkApi);
        return resPromise;
    }
);

export const deleteDataHolidayAsync = async (data, thunkApi) => {

    const res = await apiAuth.delete('/deleteHolidayManagement/'+data.id);

    let notification = {}
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_message : "Created successfully!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));

    return res
}