import React from 'react';
import { Card, CardHeader, CardBody, Col, Container, Form, Input, Label, Row, Button, Table } from 'reactstrap';
import { Page, Text, View, Image, Document, StyleSheet, PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';

import { datetimeformatncrm } from "../../../helpers/FunctionalHelper/date-helper";

const CRFormSummary = (props) => {

    const [dataForm, setDataForm] = React.useState({});

    React.useEffect(() => {
        if(props.dataForm){
            setDataForm({
                ...dataForm,
                ...props.dataForm
            })
        }
    }, [props.dataForm])

    // Download PDF - CR Summary
    const PDFDocument = () => {
        const styles = StyleSheet.create({
            rowTitle: {
                display: 'flex',
                flexDirection: 'row',
                borderBottom: "0px"
            },
            row: {
                display: 'flex',
                flexDirection: 'row',
            },
            rowTable: {
                display: 'flex',
                flexDirection: 'row',
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
            },
            rowTableBody: {
                display: 'flex',
                flexDirection: 'row',
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
            },
            co25: {
                padding: "5px", 
                width: "25%", 
                float: "left", 
                border: "1px solid black"
            },
            col30: {
                padding: "5px", 
                width: "30%", 
                float: "left", 
                border: "1px solid black"
            },
            col33: {
                padding: "5px", 
                width: "33%", 
                float: "left", 
            },
            col50: {
                padding: "5px", 
                width: "50%", 
                float: "left", 
            },
            col70: {
                padding: "5px", 
                width: "70%", 
                float: "left", 
                border: "1px solid black"
            },
            col100: {
                padding: "5px", 
                width: "100%", 
                float: "left", 
            },
            pagetitle: {
                fontSize: "12px",
                fontWeight: "600",
                textAlign: "center",
                marginBottom: "10px"
            },
            headtitle: {
                fontSize: "12px",
                fontWeight: "600",
                textAlign: "left",
                textDecoration: "underline"
            },
            title: {
                fontSize: "10px",
                fontWeight: "600",
                color: "#495057",
                marginBottom: "5px"
            },
            subtitle: {
                fontSize: "9px",
                color: "#9698a5"
            },
            timeline_head_table_no: {
                padding: "5px",
                width: "5%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_startdate: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_enddate: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_actdesc: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_status: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_impact: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_head_table_detailimpact: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
                borderRight: "1px solid black"
            },
            timeline_body_table_no: {
                padding: "5px",
                width: "5%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_startdate: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_enddate: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_actdesc: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_status: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_impact: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            timeline_body_table_detailimpact: {
                padding: "5px",
                width: "20%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
                borderRight: "1px solid black"
            },
            implementor_head_table_no: {
                padding: "5px",
                width: "5%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_company: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_name: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_phone: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_email: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_netype: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_networkname: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_regionne: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_usertype: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_head_table_userneed: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                color: "#ffffff",
                backgroundColor: "#405189",
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
                borderRight: "1px solid black"
            },
            implementor_body_table_no: {
                padding: "5px",
                width: "5%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_company: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_name: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_phone: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_email: {
                padding: "5px",
                width: "15%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_netype: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_networkname: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_regionne: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_usertype: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black"
            },
            implementor_body_table_userneed: {
                padding: "5px",
                width: "10%",
                float: "left",
                fontSize: "8px",
                fontWeight: "600",
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
                borderRight: "1px solid black"
            },
            padding: {
                padding: "5px" 
            },
            RowBottomBorder: {
                display: 'flex',
                flexDirection: 'row',
                borderTop: "1px solid black"
            },
        });

        return (
            <Document>
                <Page size="A4" style={{padding: "40px"}}>
                    <Text style={styles.pagetitle} >CR ID : {dataForm.change_id}</Text>
                    <View style={styles.rowTitle}>
                        <View style={styles.col100}>
                            <Text style={styles.headtitle}>Categorization</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Change Location Company :</Text>
                            <Text style={styles.subtitle}>{dataForm.oc_change_location_company}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Request Category :</Text>
                            <Text style={styles.subtitle}>{dataForm.oc_tier_1}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Domain Category :</Text>
                            <Text style={styles.subtitle}>{dataForm.oc_domain_category}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Sub Domain Category :</Text>
                            <Text style={styles.subtitle}>{dataForm.oc_tier_2}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Activity List :</Text>
                            <Text style={styles.subtitle}>{dataForm.activity_list}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Change Category :</Text>
                            <Text style={styles.subtitle}>{dataForm.change_category}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Impact :</Text>
                            <Text style={styles.subtitle}>{dataForm.impact}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Risk :</Text>
                            <Text style={styles.subtitle}>{dataForm.risk}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Priority :</Text>
                            <Text style={styles.subtitle}>{dataForm.priority}</Text>
                        </View>
                        <View style={styles.col50}>
                        </View>
                    </View>
                    <View style={styles.row}>
                    <View style={styles.col50}>
                            <Text style={styles.title}>Description of Change :</Text>
                            <Text style={styles.subtitle}>{dataForm.description_of_change}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Reason for Change :</Text>
                            <Text style={styles.subtitle}>{dataForm.reason_for_change}</Text>
                        </View>
                    </View>
                    <View style={styles.rowTitle}>
                        <View style={styles.col100}>
                            <Text style={styles.headtitle}>Information</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Region :</Text>
                            {dataForm.region && dataForm.region.map(region =>
                                <Text style={styles.subtitle}>{region}</Text>
                            )}
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Responsible Approval :</Text>
                            {dataForm.responsible_approval && dataForm.responsible_approval.map(respons =>
                                <Text style={styles.subtitle}>{respons}</Text>
                            )}
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>NE ID Change Target :</Text>
                            <Text style={styles.subtitle}>{dataForm.ne_id_change_target}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>NE Name Change Target :</Text>
                            <Text style={styles.subtitle}>{dataForm.ne_name_change_target}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Detailed Location :</Text>
                            <Text style={styles.subtitle}>{dataForm.detailed_location}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Vendor Name :</Text>
                            {dataForm.vendor_name && dataForm.vendor_name.map(vendor =>
                                <Text style={styles.subtitle}>{vendor}</Text>
                            )}
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Impact to NE :</Text>
                            <Text style={styles.subtitle}>{dataForm.impact_to_ne}</Text>
                        </View>
                        <View style={styles.col50}>
                        </View>
                    </View>
                    {dataForm.impact_to_ne === "Yes" &&
                    <React.Fragment>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>NE Name (Impacted) List :</Text>
                            <Text style={styles.subtitle}>{dataForm.ne_name_impacted_list}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>NE Impact Description :</Text>
                            <Text style={styles.subtitle}>{dataForm.ne_impact_description}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>NE Outage Duration (min) :</Text>
                            <Text style={styles.subtitle}>{dataForm.ne_outage_duration_min}</Text>
                        </View>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Affected Service Description :</Text>
                            <Text style={styles.subtitle}>{dataForm.affected_services_description}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col50}>
                            <Text style={styles.title}>Service Outage Duration (min) :</Text>
                            <Text style={styles.subtitle}>{dataForm.service_outage_duration_min}</Text>
                        </View>
                        <View style={styles.col50}>
                        </View>
                    </View>
                    </React.Fragment>
                    }
                    <View style={styles.rowTitle}>
                        <View style={styles.col100}>
                            <Text style={styles.headtitle}>Timeline</Text>
                        </View>
                    </View>
                    <View style={{padding: "5px"}}>
                        <View style={styles.rowTable}>
                            <Text style={styles.timeline_head_table_no}>No</Text>
                            <Text style={styles.timeline_head_table_startdate}>Plan Start</Text>
                            <Text style={styles.timeline_head_table_enddate}>Plan End</Text>
                            <Text style={styles.timeline_head_table_actdesc}>Act Desc</Text>
                            <Text style={styles.timeline_head_table_status}>Status</Text>
                            <Text style={styles.timeline_head_table_impact}># Impacted NE</Text>
                            <Text style={styles.timeline_head_table_detailimpact}>Detail of Impacted NE</Text>
                        </View>
                        {dataForm.timeline && dataForm.timeline.map((time, idx) =>
                            <View style={styles.rowTableBody}>
                                <Text style={styles.timeline_body_table_no}>{idx+1}</Text>
                                <Text style={styles.timeline_body_table_startdate}>{datetimeformatncrm(time.plan_start_time)}</Text>
                                <Text style={styles.timeline_body_table_enddate}>{datetimeformatncrm(time.plan_end_time)}</Text>
                                <Text style={styles.timeline_body_table_actdesc}>{time.activity_description}</Text>
                                <Text style={styles.timeline_body_table_status}>{time.plan_status}</Text>
                                <Text style={styles.timeline_body_table_impact}>{time.number_of_impacted_ne}</Text>
                                <Text style={styles.timeline_body_table_detailimpact}>{time.detailed_of_impacted_ne}</Text>
                            </View>
                        )}
                        <View style={styles.RowBottomBorder}></View>
                    </View>
                    <View style={styles.rowTitle}>
                        <View style={styles.col100}>
                            <Text style={styles.headtitle}>User Access Management (Implementor)</Text>
                        </View>
                    </View>
                    <View style={{padding: "5px"}}>
                        <View style={styles.rowTable}>
                            <Text style={styles.implementor_head_table_no}>No</Text>
                            <Text style={styles.implementor_head_table_company}>Company</Text>
                            <Text style={styles.implementor_head_table_name}>Name</Text>
                            <Text style={styles.implementor_head_table_phone}>Phone</Text>
                            <Text style={styles.implementor_head_table_email}>Email</Text>
                            <Text style={styles.implementor_head_table_netype}>Ne Type</Text>
                            <Text style={styles.implementor_head_table_networkname}>Network Name Element</Text>
                            <Text style={styles.implementor_head_table_regionne}>Region Ne</Text>
                            <Text style={styles.implementor_head_table_usertype}>User Type</Text>
                            <Text style={styles.implementor_head_table_userneed}>User Needed</Text>
                        </View>
                        {dataForm.ai_user_access && dataForm.ai_user_access.map((user, idx) =>
                            <View style={styles.rowTableBody}>
                                <Text style={styles.implementor_body_table_no}>{idx+1}</Text>
                                <Text style={styles.implementor_body_table_company}>{user.vendor_name}</Text>
                                <Text style={styles.implementor_body_table_name}>{user.executor_pic_name}</Text>
                                <Text style={styles.implementor_body_table_phone}>{user.executor_pic_phone}</Text>
                                <Text style={styles.implementor_body_table_email}>{user.executor_pic_email}</Text>
                                <Text style={styles.implementor_body_table_netype}>{user.ne_type}</Text>
                                <Text style={styles.implementor_body_table_networkname}>{user.network_element_name}</Text>
                                <Text style={styles.implementor_body_table_regionne}>{user.region_ne}</Text>
                                <Text style={styles.implementor_body_table_usertype}>{user.user_type}</Text>
                                <Text style={styles.implementor_body_table_userneed}>{user.user_needed}</Text>
                            </View>
                        )}
                        <View style={styles.RowBottomBorder}></View>
                    </View>
                </Page>
            </Document>
        );
    }

    const downloadReportDocument = async (e, blob) => {

        e.preventDefault()
        e.persist();
        saveAs(blob, `Report.pdf`)
    }

    // console.log("Data Form Summary 2", dataForm)

    return (
        <React.Fragment>
            {dataForm !== undefined ? 
            <React.Fragment>
            <Row style={{marginBottom: "10px"}}>
                <Col md={9} style={{textAlign: "Left"}}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>CR Creation Summary</h5>
                    </div>
                </Col>
                <Col md={3}>
                    <BlobProvider document={PDFDocument()}>
                        {({ blob, url, loading, error }) => {
                            return (
                                <Button
                                    color="primary"
                                    size="md"
                                    onClick={(e) => downloadReportDocument(e, blob)}
                                    style={{width: "100%"}}
                                >
                                    <i className='ri-save-line'></i><span> Save as PDF</span>
                                </Button>
                            )
                        }}
                    </BlobProvider>
                </Col>
            </Row>
            <Row style={{padding: "10px 0px", textAlign: "left", backgroundColor: "#ffffff"}}>
                <Col md={12} style={{marginBottom: "10px"}}>
                    <h3 style={{color: "#000000", textAlign: "left", fontSize: "bold", textDecoration: "underline"}}>Categorization & Clasification</h3>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Change Location Company :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.oc_change_location_company ? dataForm.oc_change_location_company : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Request Category :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.oc_tier_1 ? dataForm.oc_tier_1 : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Domain Category :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.oc_domain_category ? dataForm.oc_domain_category : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Sub-Domain Category :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.oc_tier_2 ? dataForm.oc_tier_2 : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Activity List :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.activity_list ? dataForm.activity_list : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Change Category :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.change_category ? dataForm.change_category : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Impact :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.impact ? dataForm.impact : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Risk :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.risk ? dataForm.risk : "-"}</div>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Priority :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.priority ? dataForm.priority : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Description of Change :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.description_of_change ? dataForm.description_of_change : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Reason for Change :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.reason_for_change ? dataForm.reason_for_change : "-"}</div>
                    </div>
                </Col>
                {dataForm.attachment !== undefined &&
                <Col md={12}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>File Attachment</h5>
                    </div>
                </Col>
                }

                {dataForm.attachment !== undefined &&
                <Col md={6}>
                    <Row style={{marginBottom: "10px"}}>
                        <Col sm={9}>
                        <h5 style={{fontWeight: "bold"}}>Method of Procedure :</h5>
                        </Col>
                        <Col sm={3}>
                        <div style={{fontSize: "16px", float: "inline-end"}}>
                            <Button color='success' onClick={(e) => {downloadFile(e, dataForm.attachment[0], 0)}}>
                                Download
                            </Button>
                        </div>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "10px"}}>
                        <Col sm={9}>
                        <h5 style={{fontWeight: "bold"}}>Evidence :</h5>
                        </Col>
                        <Col sm={3}>
                        <div style={{fontSize: "16px", float: "inline-end"}}>
                            <Button color='success' onClick={(e) => {downloadFile(e, dataForm.attachment[1], 1)}}>
                                Download
                            </Button>
                        </div>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "10px"}}>
                        <Col sm={9}>
                        <h5 style={{fontWeight: "bold"}}>Supporting Attachment :</h5>
                        </Col>
                        <Col sm={3}>
                        <div style={{fontSize: "16px", float: "inline-end"}}>
                            <Button color='success' onClick={(e) => {downloadFile(e, dataForm.attachment[2], 2)}}>
                                Download
                            </Button>
                        </div>
                        </Col>
                    </Row>
                </Col>
                }


                <Col md={12} style={{borderTop: "2px solid #dedede", paddingTop: "10px", marginBottom: "10px"}}>
                    <h3 style={{color: "#000000", fontSize: "bold", textDecoration: "underline"}}>Information</h3>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Region :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.region ? dataForm.region.map(region =>
                                <div>{region}</div>
                            )
                            : "-"
                        }
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Responsible Approval :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.responsible_approval ? dataForm.responsible_approval.map(respon =>
                                <div>{respon}</div>
                            )
                            : "-"
                        }
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>NE ID Change Target :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.ne_id_change_target ? dataForm.ne_id_change_target : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>NE Name Change Target :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.ne_name_change_target ? dataForm.ne_name_change_target : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Detailed Location :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.detailed_location ? dataForm.detailed_location : "-"}</div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Vendor Name :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.vendor_name ? dataForm.vendor_name.map(vendor =>
                            <div>{vendor}</div>
                            )
                            : "-"
                        }
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-3">
                        <h5 style={{fontWeight: "bold"}}>Impact to NE :</h5>
                        <div style={{fontSize: "16px"}}>{dataForm.impact_to_ne}</div>
                    </div>
                </Col>
                {dataForm.impact_to_ne === "Yes" &&
                    <React.Fragment>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5 style={{fontWeight: "bold"}}>NE Name (Impacted) List :</h5>
                            <div>{dataForm.ne_name_impacted_list ? dataForm.ne_name_impacted_list : "-"}</div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5 style={{fontWeight: "bold"}}>NE Impact Description :</h5>
                            <div>{dataForm.ne_impact_description ? dataForm.ne_impact_description : "-"}</div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5 style={{fontWeight: "bold"}}>NE Outage Duration (min) :</h5>
                            <div>{dataForm.ne_outage_duration_min ? dataForm.ne_outage_duration_min : "-"}</div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5 style={{fontWeight: "bold"}}>Affected Service :</h5>
                            <div>{dataForm.affected_services ? dataForm.affected_services : "-"}</div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5 style={{fontWeight: "bold"}}>Affected Service Description :</h5>
                            <div>{dataForm.affected_services_description ? dataForm.affected_services_description : "-"}</div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <h5 style={{fontWeight: "bold"}}>Service Outage Duration (min) :</h5>
                            <div>{dataForm.service_outage_duration_min ? dataForm.service_outage_duration_min : "-"}</div>
                        </div>
                    </Col>
                    </React.Fragment>
                }


                <Col md={12} style={{borderTop: "2px solid #dedede", paddingTop: "10px", marginBottom: "10px"}}>
                    <h3 style={{color: "#000000", fontSize: "bold", textDecoration: "underline"}}>Timeline</h3>
                </Col>
                {dataForm.timeline && 
                    <React.Fragment>
                    <Col md={12}>
                        <div className="table-responsive">
                        <Table className="align-middle mb-3 text-align-header-centre" style={{border: "1px solid #dedede"}}>
                        <thead style={{backgroundColor: "#dedede"}}>
                            <tr>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>No</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Plan Start</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Plan End</th>
                                <th scope="col" style={{Width : '125px', borderRight: "1px solid #ffffff"}}>Act Desc</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Status</th>
                                <th scope="col" style={{Width : '150px', borderRight: "1px solid #ffffff"}}># Impacted NE</th>
                                <th scope="col" style={{Width : '150px'}}>Detail of Impacted NE</th>
                            </tr>
                        </thead>
                        <tbody>
                        {dataForm.timeline.map((time, idx) =>
                            <tr className="text-center" key={idx}>
                            <td>{idx+1}</td>
                            <td>{datetimeformatncrm(time.plan_start_time)}</td>
                            <td>{datetimeformatncrm(time.plan_end_time)}</td>
                            <td>{time.activity_description}</td>
                            <td style={{textAlign : 'center'}}>Proposed</td>
                            <td style={{textAlign : 'center'}}>{time.number_of_impacted_ne}</td>
                            <td>{time.detailed_of_impacted_ne}</td>
                            </tr>    
                        )}
                        </tbody>
                        </Table>
                        </div>
                    </Col>
                    </React.Fragment>
                }


                <Col md={12} style={{borderTop: "2px solid #dedede", paddingTop: "10px", marginBottom: "10px"}}>
                    <h3 style={{color: "#000000", fontSize: "bold", textDecoration: "underline"}}>User Access Management (Implementor)</h3>
                </Col>
                {dataForm.ai_user_access && 
                    <React.Fragment>
                    <Col md={12}>
                        <div className="table-responsive">
                        <table className="table align-middle table-nowrap table-striped-columns mb-0" style={{border: "1px solid #dedede"}}>
                            <thead style={{backgroundColor: "#dedede"}}>
                                <tr>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Company</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Name</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Phone Number</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Email</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Ne Type</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Network Name Elmnt</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>Region Ne</th>
                                <th scope="col" style={{borderRight: "1px solid #ffffff"}}>User Type</th>
                                <th scope="col">User Needed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataForm.ai_user_access.map((user, idx) =>
                                <tr key={idx}>
                                    <td>{user.vendor_name}</td>
                                    <td>{user.executor_pic_name}</td>
                                    <td>{user.executor_pic_phone}</td>
                                    <td>{user.executor_pic_email}</td>
                                    <td>{user.ne_type}</td>
                                    <td>{user.network_element_name}</td>
                                    <td>{user.region_ne}</td>
                                    <td>{user.user_type}</td>
                                    <td>{user.user_needed}</td>
                                </tr>    
                                )}
                            </tbody>
                        </table>
                        </div>
                    </Col>
                    </React.Fragment>
                }
            </Row>
            </React.Fragment>
            : ""
            }
        </React.Fragment>
    );
};

export default CRFormSummary;