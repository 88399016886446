import React, { useState, useEffect } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Card, CardBody, Col } from 'reactstrap';
// import { useSelector, useDispatch } from "react-redux";
import { getPortfolioChartsData } from '../../slices/thunks';
//import Images
import btc from "../../assets/images/svg/crypto-icons/btc.svg";
import eth from "../../assets/images/svg/crypto-icons/eth.svg";
import ltc from "../../assets/images/svg/crypto-icons/ltc.svg";
import dash from "../../assets/images/svg/crypto-icons/dash.svg";
import { ServiceOutagePolarChart, PortfolioCharts } from './DashboardPieCharts';
import { createSelector } from 'reselect';
import { datetimeformatncrm, dateformatncrm, datemonthformatncrm, dateyearsmonthdayformatncrm } from "../../helpers/FunctionalHelper/date-helper";
import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const ServiceOutagePolar = (crdata) => {
    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    const [chartData, setchartData] = useState([]);
    const [dataList, setDataList] = React.useState([]);
    const [totalData, setTotalData] = React.useState(0);
    const [dataSearchDate, setDataSearchDate] = React.useState({});

    const current_date = new Date();
    const next_day_date = new Date(new Date().setDate(new Date().getDate()));
    const next_month_date = new Date(next_day_date.setMonth(next_day_date.getMonth() + 1));
    const previous_day_date = new Date(new Date().setDate(new Date().getDate() + 1));
    const previous_month_date = new Date(previous_day_date.setMonth(previous_day_date.getMonth() - 1));
    
    const min10 = crdata.crdata && crdata.crdata["0-10 min"] !== undefined ? parseInt(crdata.crdata["0-10 min"]) : 0;
    const min30 = crdata.crdata && crdata.crdata["11-30 min"] !== undefined ? parseInt(crdata.crdata["11-30 min"]) : 0;
    const min60 = crdata.crdata && crdata.crdata["31-60 min"] !== undefined ? parseInt(crdata.crdata["31-60 min"]) : 0;
    const min120 = crdata.crdata && crdata.crdata["1-2 hr"] !== undefined ? parseInt(crdata.crdata["1-2 hr"]) : 0;
    const min360 = crdata.crdata && crdata.crdata["2-6 hr"] !== undefined ? parseInt(crdata.crdata["2-6 hr"]) : 0;
    const min720 = crdata.crdata && crdata.crdata["6-12 hr"] !== undefined ? parseInt(crdata.crdata["0-10 hr"]) : 0;
    const min1440 = crdata.crdata && crdata.crdata["12-24 hr"] !== undefined ? parseInt(crdata.crdata["0-10 hr"]) : 0;
    const day1 = crdata.crdata && crdata.crdata["more than 1 day"] !== undefined ? parseInt(crdata.crdata["more than 1 day"]) : 0;

    useEffect(() => {
        let data = [];
        data.push(min10);
        data.push(min30);
        data.push(min60);
        data.push(min120);
        data.push(min360);
        data.push(min720);
        data.push(min1440);
        data.push(day1);
        
        setchartData(data);
    }, [crdata]);

    return (
        <React.Fragment>
            <Col md={12} style={{height: "418px", marginBottom: "20px"}}>
                <div className="card card-height-100" style={{height: "100%"}}>
                    <div className="card-header border-0 align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Service Outage Duration</h4>
                    </div>
                    <div className="card-body">
                        <ServiceOutagePolarChart seriesData={chartData} dataColors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-success"]'/>
                    </div>
                </div>
            </Col>
        </React.Fragment>
    );
};

export default ServiceOutagePolar;