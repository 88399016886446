export const dateformatncrm = (date) => {
    if(!date){
        return ""
    }
    let objectDate = new Date(date);
    
    let day = objectDate.getDate();

    let month = objectDate.getMonth();

    let year = objectDate.getFullYear();

    return day.toString().padStart(2,"0")+"/"+(month+1).toString().padStart(2,"0")+"/"+year
}

export const dateyearsmonthdayformatncrm = (date) => {
    if(!date){
        return ""
    }
    let objectDate = new Date(date);
    
    let day = objectDate.getDate();

    let month = objectDate.getMonth();

    let year = objectDate.getFullYear();

    return year+"-"+(month+1).toString().padStart(2,"0")+"-"+day.toString().padStart(2,"0")
}

export const datemonthformatncrm = (date) => {
    if(!date){
        return ""
    }
    let objectDate = new Date(date);
    
    let day = objectDate.getDate();

    let month = objectDate.getMonth();

    let year = objectDate.getFullYear();

    return day.toString().padStart(2,"0")+" "+(month+1).toString().padStart(2,"0")+" "+year
}

export const datetimeformatncrm = (date) => {
    if(!date){
        return ""
    }
    let objectDate = new Date(date);
    
    let day = objectDate.getDate();
    let month = objectDate.getMonth();
    let year = objectDate.getFullYear();

    let hours = objectDate.getHours();
    let minutes = objectDate.getMinutes();

    const dateFormat = day.toString().padStart(2,"0")+"/"+(month+1).toString().padStart(2,"0")+"/"+year

    let ampm = hours >= 12 ? 'pm' : 'am';
    
    minutes = minutes.toString().padStart('2',0);
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return dateFormat + "  " + strTime;
}

export const datetimeformatiso = (date, time) => {
    if(!date){
        return ""
    }
    
    let objectDate = new Date(date +" "+ time);

    return objectDate.toISOString()
}

export const datetimeformatfull = (date) => {
    if(!date){
        return ""
    }
    
    let objectDate = new Date(date);
    let year = objectDate.getFullYear();
    let month = objectDate.getMonth() + 1;
    let dt = objectDate.getDate();
    let hh = objectDate.getHours();
    let mm = objectDate.getMinutes();
    let ss = objectDate.getSeconds();

    if (dt < 10) {
        dt = "0" + dt;
    }
    if (month < 10) {
        month = "0" + month;
    }
    return dt.toString().padStart(2, 0) + "/" + month + "/" + year + " " + hh.toString().padStart(2, 0) + ":" + mm.toString().padStart(2, 0) + ":" + ss.toString().padStart(2, 0);

};

export const currentDurationFromDateTime = (date) => {
    if(!date){
        return ""
    }
    
    let delta = Math.abs(new Date(date) - new Date()) / 1000;

    // calculate (and subtract) whole hours
    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    return hours.toString() + "h "+minutes.toString()+"m"

};

export const dateDayBefore = (date, day_before) => {
    if(!date){
        return ""
    }
    
    const new_date = new Date(date);
    new_date.setDate(new_date.getDate() - day_before);

    return new_date
}

export const dateDayAfter = (date, day_after) => {
    if(!date){
        return ""
    }
    
    const new_date = new Date(date);
    new_date.setDate(new_date.getDate() + day_after);

    return new_date
}