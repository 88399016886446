export const USER_TITLE = [
    {
      "NAMA GM": "Nurdianto",
      "Remedy Login ID": "davidsil",
      "NAMA (MGR)": "David Erlando Silalahi",
      "TITLE": "Manager Core, Transport and Datacenter Operations Sumbagut",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagut Division",
      "Area": "Sumbagut",
      "Regional": "R1 Sumbagut"
    },
    {
      "NAMA GM": "Nurdianto",
      "Remedy Login ID": "rommyrin",
      "NAMA (MGR)": "Rommy Rinaldi",
      "TITLE": "Manager Network Operations and Productivity Aceh",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagut Division",
      "Area": "Sumbagut",
      "Regional": "R1 Sumbagut"
    },
    {
      "NAMA GM": "Nurdianto",
      "Remedy Login ID": "suryaham",
      "NAMA (MGR)": "Surya Hamdani",
      "TITLE": "Manager Network Operations and Productivity Medan",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagut Division",
      "Area": "Sumbagut",
      "Regional": "R1 Sumbagut"
    },
    {
      "NAMA GM": "Nurdianto",
      "Remedy Login ID": "ferynaldi",
      "NAMA (MGR)": "Ferynaldi",
      "TITLE": "Manager Network Operations and Productivity Binjai",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagut Division",
      "Area": "Sumbagut",
      "Regional": "R1 Sumbagut"
    },
    {
      "NAMA GM": "Nurdianto",
      "Remedy Login ID": "budipri",
      "NAMA (MGR)": "Budi Priyono",
      "TITLE": "Manager Network Operations and Productivity Pematangsiantar",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagut Division",
      "Area": "Sumbagut",
      "Regional": "R1 Sumbagut"
    },
    {
      "NAMA GM": "Nurdianto",
      "Remedy Login ID": "aryafat",
      "NAMA (MGR)": "Aria Fathana",
      "TITLE": "Manager Network Operations and Productivity Padang Sidempuan",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagut Division",
      "Area": "Sumbagut",
      "Regional": "R1 Sumbagut"
    },
    {
      "NAMA GM": "Nurdianto",
      "Remedy Login ID": "leonardsir",
      "NAMA (MGR)": "Leonard T. Sirait",
      "TITLE": "Manager Network Operations and Productivity Kisaran",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagut Division",
      "Area": "Sumbagut",
      "Regional": "R1 Sumbagut"
    },
    {
      "NAMA GM": "Nurdianto",
      "Remedy Login ID": "nurdianto",
      "TITLE": "Manager Service Quality Assurance Sumbagut",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagut Division",
      "Area": "Sumbagut",
      "Regional": "R1 Sumbagut"
    },
    {
      "NAMA GM": "Artha Abimanyu",
      "Remedy Login ID": "hotmatum",
      "NAMA (MGR)": "Hotma Eduard Hamonangan Tumanggor",
      "TITLE": "Manager Network Operations and Productivity Dumai",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagteng Division",
      "Area": "Sumbagteng",
      "Regional": "R10 Sumbagteng"
    },
    {
      "NAMA GM": "Artha Abimanyu",
      "Remedy Login ID": "yuyunsup",
      "NAMA (MGR)": "Yuyun Supriyadi",
      "TITLE": "Manager Network Operations and Productivity Batam",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagteng Division",
      "Area": "Sumbagteng",
      "Regional": "R10 Sumbagteng"
    },
    {
      "NAMA GM": "Artha Abimanyu",
      "Remedy Login ID": "fauzan",
      "NAMA (MGR)": "Fauzan",
      "TITLE": "Manager Network Operations and Productivity Pekanbaru",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagteng Division",
      "Area": "Sumbagteng",
      "Regional": "R10 Sumbagteng"
    },
    {
      "NAMA GM": "Artha Abimanyu",
      "Remedy Login ID": "andisua",
      "NAMA (MGR)": "Andy Suapril",
      "TITLE": "Manager Network Operations and Productivity Padang",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagteng Division",
      "Area": "Sumbagteng",
      "Regional": "R10 Sumbagteng"
    },
    {
      "NAMA GM": "Artha Abimanyu",
      "Remedy Login ID": "suryafir",
      "NAMA (MGR)": "Surya Firdaus",
      "TITLE": "Manager Network Operations and Productivity Indragiri",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagteng Division",
      "Area": "Sumbagteng",
      "Regional": "R10 Sumbagteng"
    },
    {
      "NAMA GM": "Artha Abimanyu",
      "Remedy Login ID": "iputurud",
      "NAMA (MGR)": "I Putu Eka Udayana Rudita",
      "TITLE": "Manager Service Quality Assurance Sumbagteng",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagteng Division",
      "Area": "Sumbagteng",
      "Regional": "R10 Sumbagteng"
    },
    {
      "NAMA GM": "Artha Abimanyu",
      "Remedy Login ID": "muhammadsup",
      "NAMA (MGR)": "Muhammad Toha Supriyadi",
      "TITLE": "Manager Core, Transport and Datacenter Operations Sumbagteng",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagteng Division",
      "Area": "Sumbagteng",
      "Regional": "R10 Sumbagteng"
    },
    {
      "NAMA GM": "Agus Sugiarto",
      "Remedy Login ID": "muhammadnou",
      "NAMA (MGR)": "Muhammad Nour",
      "TITLE": "Manager Service Quality Assurance Maluku and Papua",
      "AREA DIVISION": "Region Network Operations and Productivity Maluku And Papua Division",
      "Area": "Maluku And Papua",
      "Regional": "R11 Papua Maluku"
    },
    {
      "NAMA GM": "Agus Sugiarto",
      "Remedy Login ID": "chandrasar",
      "NAMA (MGR)": "Chandra Saranga",
      "TITLE": "Pj. Manager Network Operations and Productivity Ambon",
      "AREA DIVISION": "Region Network Operations and Productivity Maluku and Papua Division",
      "Area": "Maluku Papua",
      "Regional": "R11 Papua Maluku"
    },
    {
      "NAMA GM": "Agus Sugiarto",
      "Remedy Login ID": "faridsalim",
      "NAMA (MGR)": "Farid Syahirul Alim",
      "TITLE": "Manager Network Operations and Productivity Sorong",
      "AREA DIVISION": "Region Network Operations and Productivity Maluku and Papua Division",
      "Area": "Maluku Papua",
      "Regional": "R11 Papua Maluku"
    },
    {
      "NAMA GM": "Agus Sugiarto",
      "Remedy Login ID": "heriset",
      "NAMA (MGR)": "Heri Aji Setiawan",
      "TITLE": "Pj. Manager Network Operations and Productivity Jayapura",
      "AREA DIVISION": "Region Network Operations and Productivity Maluku and Papua Division",
      "Area": "Maluku Papua",
      "Regional": "R11 Papua Maluku"
    },
    {
      "NAMA GM": "Agus Sugiarto",
      "Remedy Login ID": "sandy",
      "NAMA (MGR)": "Sandy",
      "TITLE": "Pj. Manager Network Operations and Productivity Timika",
      "AREA DIVISION": "Region Network Operations and Productivity Maluku and Papua Division",
      "Area": "Maluku Papua",
      "Regional": "R11 Papua Maluku"
    },
    {
      "NAMA GM": "Agus Sugiarto",
      "Remedy Login ID": "kurniawanatm",
      "NAMA (MGR)": "Kurniawan Widhi Atmadji",
      "TITLE": "Manager Network Operations and Productivity Manokwari",
      "AREA DIVISION": "Region Network Operations and Productivity Maluku and Papua Division",
      "Area": "Maluku Papua",
      "Regional": "R11 Papua Maluku"
    },
    {
      "NAMA GM": "Agus Sugiarto",
      "Remedy Login ID": "jonilem",
      "NAMA (MGR)": "Joni Allo Lembang",
      "TITLE": "Pj. Manager Core, Transport and Datacenter Operations Maluku and Papua",
      "AREA DIVISION": "Region Network Operations and Productivity Maluku and Papua Division",
      "Area": "Maluku Papua",
      "Regional": "R11 Papua Maluku"
    },
    {
      "NAMA GM": "Wawan Kuswandono",
      "Remedy Login ID": "dedeset",
      "NAMA (MGR)": "Dede Setiawan",
      "TITLE": "Pj. Manager Network Operations and Productivity Jambi",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagsel Division",
      "Area": "Sumbagsel",
      "Regional": "R2 Sumbagsel"
    },
    {
      "NAMA GM": "Wawan Kuswandono",
      "Remedy Login ID": "perdanades",
      "NAMA (MGR)": "Perdana Candra Desusetyo",
      "TITLE": "Pj. Manager Network Operations and Productivity Pangkal Pinang",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagsel Division",
      "Area": "Sumbagsel",
      "Regional": "R2 Sumbagsel"
    },
    {
      "NAMA GM": "Wawan Kuswandono",
      "Remedy Login ID": "donasap",
      "NAMA (MGR)": "Dona Saputra",
      "TITLE": "Manager Network Operations and Productivity Palembang",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagsel Division",
      "Area": "Sumbagsel",
      "Regional": "R2 Sumbagsel"
    },
    {
      "NAMA GM": "Wawan Kuswandono",
      "Remedy Login ID": "muhammadrio",
      "NAMA (MGR)": "Muhammad Ridho Purnawijaya",
      "TITLE": "Manager Network Operations and Productivity Bengkulu",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagsel Division",
      "Area": "Sumbagsel",
      "Regional": "R2 Sumbagsel"
    },
    {
      "NAMA GM": "Wawan Kuswandono",
      "Remedy Login ID": "andhikawij",
      "NAMA (MGR)": "Andhika Bayu Wijaya",
      "TITLE": "Manager Network Operations and Productivity Lampung",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagsel Division",
      "Area": "Sumbagsel",
      "Regional": "R2 Sumbagsel"
    },
    {
      "NAMA GM": "Wawan Kuswandono",
      "Remedy Login ID": "alimuddin",
      "NAMA (MGR)": "Alimuddin",
      "TITLE": "Manager Network Operations and Productivity Muaraenim",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagsel Division",
      "Area": "Sumbagsel",
      "Regional": "R2 Sumbagsel"
    },
    {
      "NAMA GM": "Wawan Kuswandono",
      "Remedy Login ID": "dennispwk",
      "NAMA (MGR)": "Dennis Saputra Pwk",
      "TITLE": "Manager Service Quality Assurance Sumbagsel",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagsel Division",
      "Area": "Sumbagsel",
      "Regional": "R2 Sumbagsel"
    },
    {
      "NAMA GM": "Wawan Kuswandono",
      "Remedy Login ID": "rikiset",
      "NAMA (MGR)": "Riki Irawan Setiayuda",
      "TITLE": "Manager Core, Transport and Datacenter Operations Sumbagsel",
      "AREA DIVISION": "Region Network Operations and Productivity Sumbagsel Division",
      "Area": "Sumbagsel",
      "Regional": "R2 Sumbagsel"
    },
    {
      "NAMA GM": "Nugroho Adi Wibowo",
      "Remedy Login ID": "petrusfeb",
      "NAMA (MGR)": "Petrus Damianus Nugroho Febrianto",
      "TITLE": "Manager Network Operations and Productivity Western Jakarta",
      "AREA DIVISION": "Region Network Operations and Productivity Inner Jabotabek Division",
      "Area": "Inner Jabotabek",
      "Regional": "R3 Inner Jabotabek"
    },
    {
      "NAMA GM": "Nugroho Adi Wibowo",
      "Remedy Login ID": "ahsanlau",
      "NAMA (MGR)": "Ahsan Lau",
      "TITLE": "Manager Network Operations and Productivity Eastern Jakarta",
      "AREA DIVISION": "Region Network Operations and Productivity Inner Jabotabek Division",
      "Area": "Inner Jabotabek",
      "Regional": "R3 Inner Jabotabek"
    },
    {
      "NAMA GM": "Nugroho Adi Wibowo",
      "Remedy Login ID": "erlyawanbud",
      "NAMA (MGR)": "Erlyawan Budiman",
      "TITLE": "Manager Network Operations and Productivity Central Jakarta",
      "AREA DIVISION": "Region Network Operations and Productivity Inner Jabotabek Division",
      "Area": "Inner Jabotabek",
      "Regional": "R3 Inner Jabotabek"
    },
    {
      "NAMA GM": "Nugroho Adi Wibowo",
      "Remedy Login ID": "antoniussid",
      "NAMA (MGR)": "Antonius Verianto Sidabutar",
      "TITLE": "Manager Service Quality Assurance Inner Jabotabek",
      "AREA DIVISION": "Region Network Operations and Productivity Inner Jabotabek Division",
      "Area": "Inner Jabotabek",
      "Regional": "R3 Inner Jabotabek"
    },
    {
      "NAMA GM": "Nugroho Adi Wibowo",
      "Remedy Login ID": "nadifado",
      "NAMA (MGR)": "Nadif Adroni",
      "TITLE": "Manager Core and Datacomm Operations Jabotabek",
      "AREA DIVISION": "Region Network Operations and Productivity Inner Jabotabek Division",
      "Area": "Inner Jabotabek",
      "Regional": "R3 Inner Jabotabek"
    },
    {
      "NAMA GM": "Nugroho Adi Wibowo",
      "Remedy Login ID": "anwarzeb",
      "NAMA (MGR)": "Anwar Sadad Zebua",
      "TITLE": "Manager Transport and Power Operations Jabotabek",
      "AREA DIVISION": "Region Network Operations and Productivity Inner Jabotabek Division",
      "Area": "Inner Jabotabek",
      "Regional": "R3 Inner Jabotabek"
    },
    {
      "NAMA GM": "Permata J Simarmata",
      "Remedy Login ID": "triagu",
      "NAMA (MGR)": "Tri Saputro Agung Santoso",
      "TITLE": "Manager Network Operations and Productivity Bekasi",
      "AREA DIVISION": "Region Network Operations and Productivity Outer Jabotabek Division",
      "Area": "Jabotabek Outer",
      "Regional": "R12 Outer Jabotabek"
    },
    {
      "NAMA GM": "Permata J Simarmata",
      "Remedy Login ID": "ahmadsho",
      "NAMA (MGR)": "Ahmad Sholeh",
      "TITLE": "Manager Network Operations and Productivity Tangerang",
      "AREA DIVISION": "Region Network Operations and Productivity Outer Jabotabek Division",
      "Area": "Jabotabek Outer",
      "Regional": "R12 Outer Jabotabek"
    },
    {
      "NAMA GM": "Permata J Simarmata",
      "Remedy Login ID": "dwisug",
      "NAMA (MGR)": "Dwi Sugiharto",
      "TITLE": "Manager Network Operations and Productivity Serang",
      "AREA DIVISION": "Region Network Operations and Productivity Outer Jabotabek Division",
      "Area": "Jabotabek Outer",
      "Regional": "R12 Outer Jabotabek"
    },
    {
      "NAMA GM": "Permata J Simarmata",
      "Remedy Login ID": "bennyrum",
      "NAMA (MGR)": "Benny Hasiholan Rumahorbo",
      "TITLE": "Manager Network Operations and Productivity Bogor",
      "AREA DIVISION": "Region Network Operations and Productivity Outer Jabotabek Division",
      "Area": "Jabotabek Outer",
      "Regional": "R12 Outer Jabotabek"
    },
    {
      "NAMA GM": "Permata J Simarmata",
      "Remedy Login ID": "slametpra",
      "NAMA (MGR)": "Slamet Pranoto",
      "TITLE": "Manager Network Operations and Productivity Karawang",
      "AREA DIVISION": "Region Network Operations and Productivity Outer Jabotabek Division",
      "Area": "Jabotabek Outer",
      "Regional": "R12 Outer Jabotabek"
    },
    {
      "NAMA GM": "Permata J Simarmata",
      "Remedy Login ID": "ikhwan1105",
      "NAMA (MGR)": "Ikhwan Siregar",
      "TITLE": "Manager Network Operations and Productivity Depok",
      "AREA DIVISION": "Region Network Operations and Productivity Outer Jabotabek Division",
      "Area": "Jabotabek Outer",
      "Regional": "R12 Outer Jabotabek"
    },
    {
      "NAMA GM": "Permata J Simarmata",
      "Remedy Login ID": "harisam",
      "NAMA (MGR)": "Hari Samsuri",
      "TITLE": "Manager Network Operations and Productivity Tangerang Selatan",
      "AREA DIVISION": "Region Network Operations and Productivity Outer Jabotabek Division",
      "Area": "Jabotabek Outer",
      "Regional": "R12 Outer Jabotabek"
    },
    {
      "NAMA GM": "Permata J Simarmata",
      "Remedy Login ID": "andiokt",
      "NAMA (MGR)": "Andi oktarian",
      "TITLE": "Manager Service Quality Assurance Outer Jabotabek 2",
      "AREA DIVISION": "Region Network Operations and Productivity Outer Jabotabek Division",
      "Area": "Jabotabek Outer"
    },
    {
      "NAMA GM": "Permata J Simarmata",
      "Remedy Login ID": "gunawanadh",
      "NAMA (MGR)": "Gunawan Kartiko Adhy",
      "TITLE": "Manager Service Quality Assurance Outer Jabotabek 1",
      "AREA DIVISION": "Region Network Operations and Productivity Outer Jabotabek Division",
      "Area": "Outer Jabotabek",
      "Regional": "R3 Inner Jabotabek"
    },
    {
      "NAMA GM": "Henry Ganda Purba",
      "Remedy Login ID": "idhamikh",
      "NAMA (MGR)": "Idham Ikhsan",
      "TITLE": "Manager Network Operations and Productivity Tasikmalaya",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Barat Division",
      "Area": "Jawa Barat",
      "Regional": "R4 West Java"
    },
    {
      "NAMA GM": "Henry Ganda Purba",
      "Remedy Login ID": "agungdar",
      "NAMA (MGR)": "Agung Darmawan",
      "TITLE": "Manager Network Operations and Productivity Bandung",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Barat Division",
      "Area": "Jawa Barat",
      "Regional": "R4 West Java"
    },
    {
      "NAMA GM": "Henry Ganda Purba",
      "Remedy Login ID": "triwas",
      "NAMA (MGR)": "Tri Wasono",
      "TITLE": "Manager Network Operations and Productivity Cirebon",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Barat Division",
      "Area": "Jawa Barat",
      "Regional": "R4 West Java"
    },
    {
      "NAMA GM": "Henry Ganda Purba",
      "Remedy Login ID": "dedenher",
      "NAMA (MGR)": "Deden Hermawan",
      "TITLE": "Manager Network Operations and Productivity Soreang",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Barat Division",
      "Area": "Jawa Barat",
      "Regional": "R4 West Java"
    },
    {
      "NAMA GM": "Henry Ganda Purba",
      "Remedy Login ID": "marufmus",
      "NAMA (MGR)": "Ma'ruf Mustafa",
      "TITLE": "Manager Network Operations and Productivity Cianjur",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Barat Division",
      "Area": "Jawa Barat",
      "Regional": "R4 West Java"
    },
    {
      "NAMA GM": "Henry Ganda Purba",
      "Remedy Login ID": "maulanayus",
      "NAMA (MGR)": "Maulana Yusup",
      "TITLE": "Manager Service Quality Assurance Jawa Barat",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Barat Division",
      "Area": "Jawa Barat",
      "Regional": "R4 West Java"
    },
    {
      "NAMA GM": "Henry Ganda Purba",
      "Remedy Login ID": "subhan",
      "NAMA (MGR)": "Subhan",
      "TITLE": "Manager Core, Transport and Datacenter Operations Jawa Barat",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Barat Division",
      "Area": "Kusus Jabar",
      "Regional": "R4 West Java"
    },
    {
      "NAMA GM": "Adi Wibowo",
      "Remedy Login ID": "mukhammadhid",
      "NAMA (MGR)": "Mukhammad Yusuf Hidayat",
      "TITLE": "Manager Core, Transport and Datacenter Operations Jawa Tengah and DIY",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Tengah and DIY Division",
      "Area": "Jawa Tengah",
      "Regional": "R5 Central Java"
    },
    {
      "NAMA GM": "Adi Wibowo",
      "Remedy Login ID": "eddyput",
      "NAMA (MGR)": "Eddy Putra",
      "TITLE": "Manager Network Operations and Productivity Semarang",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Tengah and DIY Division",
      "Area": "Jawa Tengah and DIY",
      "Regional": "R5 Central Java"
    },
    {
      "NAMA GM": "Adi Wibowo",
      "Remedy Login ID": "wiwiedp",
      "NAMA (MGR)": "Wiwied Bari Priyanto",
      "TITLE": "Manager Network Operations and Productivity Purwokerto",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Tengah and DIY Division",
      "Area": "Jawa Tengah and DIY",
      "Regional": "R5 Central Java"
    },
    {
      "NAMA GM": "Adi Wibowo",
      "Remedy Login ID": "fananris",
      "NAMA (MGR)": "Fanan Yosie Risdian",
      "TITLE": "Pj. Manager Network Operations and Productivity Pekalongan",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Tengah and DIY Division",
      "Area": "Jawa Tengah and DIY",
      "Regional": "R5 Central Java"
    },
    {
      "NAMA GM": "Adi Wibowo",
      "Remedy Login ID": "Mohammadhab",
      "NAMA (MGR)": "Mohammad K Habibi",
      "TITLE": "Manager Network Operations and Productivity Yogyakarta",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Tengah and DIY Division",
      "Area": "Jawa Tengah and DIY",
      "Regional": "R5 Central Java"
    },
    {
      "NAMA GM": "Adi Wibowo",
      "Remedy Login ID": "muhammadakr",
      "NAMA (MGR)": "Muhammad Akram",
      "TITLE": "Manager Network Operations and Productivity Surakarta",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Tengah and DIY Division",
      "Area": "Jawa Tengah and DIY",
      "Regional": "R5 Central Java"
    },
    {
      "NAMA GM": "Adi Wibowo",
      "Remedy Login ID": "hermannher",
      "NAMA (MGR)": "Hermann Hermitch",
      "TITLE": "Manager Network Operations and Productivity Salatiga",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Tengah and DIY Division",
      "Area": "Jawa Tengah and DIY",
      "Regional": "R5 Central Java"
    },
    {
      "NAMA GM": "Adi Wibowo",
      "Remedy Login ID": "jokowid",
      "NAMA (MGR)": "Joko Widodo",
      "TITLE": "Manager Service Quality Assurance Jawa Tengah and DIY",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Tengah and DIY Division",
      "Area": "Jawa Tengah and DIY",
      "Regional": "R5 Central Java"
    },
    {
      "NAMA GM": "Eko Prasetyo",
      "Remedy Login ID": "harihan",
      "NAMA (MGR)": "Hari Handoko",
      "TITLE": "Manager Network Operations and Productivity Sidoarjo",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Timur Division",
      "Area": "Jawa Timur",
      "Regional": "R6 East Java"
    },
    {
      "NAMA GM": "Eko Prasetyo",
      "Remedy Login ID": "boromeusand",
      "NAMA (MGR)": "Boromeus Andigantoro",
      "TITLE": "Manager Network Operations and Productivity Surabaya",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Timur Division",
      "Area": "Jawa Timur",
      "Regional": "R6 East Java"
    },
    {
      "NAMA GM": "Eko Prasetyo",
      "Remedy Login ID": "edisuc",
      "NAMA (MGR)": "Edi Sucipto",
      "TITLE": "Manager Network Operations and Productivity Madiun",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Timur Division",
      "Area": "Jawa Timur",
      "Regional": "R6 East Java"
    },
    {
      "NAMA GM": "Eko Prasetyo",
      "Remedy Login ID": "awannug",
      "NAMA (MGR)": "Awan Nugroho",
      "TITLE": "Manager Network Operations and Productivity Malang",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Timur Division",
      "Area": "Jawa Timur",
      "Regional": "R6 East Java"
    },
    {
      "NAMA GM": "Eko Prasetyo",
      "Remedy Login ID": "syafrizalrah",
      "NAMA (MGR)": "Syafrizal Rahadian",
      "TITLE": "Manager Network Operations and Productivity Jember",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Timur Division",
      "Area": "Jawa Timur",
      "Regional": "R6 East Java"
    },
    {
      "NAMA GM": "Eko Prasetyo",
      "Remedy Login ID": "iketutpur",
      "NAMA (MGR)": "I Ketut Purbawa",
      "TITLE": "Manager Network Operations and Productivity Lamongan",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Timur Division",
      "Area": "Jawa Timur",
      "Regional": "R6 East Java"
    },
    {
      "NAMA GM": "Eko Prasetyo",
      "Remedy Login ID": "malikun",
      "NAMA (MGR)": "Malikun",
      "TITLE": "Manager Network Operations and Productivity Gresik",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Timur Division",
      "Area": "Jawa Timur",
      "Regional": "R6 East Java"
    },
    {
      "NAMA GM": "Eko Prasetyo",
      "Remedy Login ID": "yasrinaldi",
      "NAMA (MGR)": "Yasrinaldi",
      "TITLE": "Manager Service Quality Assurance Jawa Timur",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Timur Division",
      "Area": "Jawa Timur",
      "Regional": "R6 East Java"
    },
    {
      "NAMA GM": "Eko Prasetyo",
      "Remedy Login ID": "heruwar",
      "NAMA (MGR)": "Heru Indra Wardhana",
      "TITLE": "Manager Core, Transport and Datacenter Operations Jawa Timur",
      "AREA DIVISION": "Region Network Operations and Productivity Jawa Timur Division",
      "Area": "Jawa Timur",
      "Regional": "R6 East Java"
    },
    {
      "NAMA GM": "Hersetyo Pramono",
      "Remedy Login ID": "daniter",
      "NAMA (MGR)": "Dani Termindja",
      "TITLE": "Manager Network Operations and Productivity Denpasar",
      "AREA DIVISION": "Region Network Operations and Productivity Bali Nusra Division",
      "Area": "Bali Nusra",
      "Regional": "R7 Bali Nusra"
    },
    {
      "NAMA GM": "Hersetyo Pramono",
      "Remedy Login ID": "laodesaa",
      "NAMA (MGR)": "Laode Anwar Saafi",
      "TITLE": "Manager Network Operations and Productivity Mataram",
      "AREA DIVISION": "Region Network Operations and Productivity Bali Nusra Division",
      "Area": "Bali Nusra",
      "Regional": "R7 Bali Nusra"
    },
    {
      "NAMA GM": "Hersetyo Pramono",
      "Remedy Login ID": "iwayansud",
      "NAMA (MGR)": "I Wayan Sudana",
      "TITLE": "Manager Network Operations and Productivity Kupang",
      "AREA DIVISION": "Region Network Operations and Productivity Bali Nusra Division",
      "Area": "Bali Nusra",
      "Regional": "R7 Bali Nusra"
    },
    {
      "NAMA GM": "Hersetyo Pramono",
      "Remedy Login ID": "totokyul",
      "NAMA (MGR)": "Totok Yuliono",
      "TITLE": "Pj. Manager Network Operations and Productivity Flores",
      "AREA DIVISION": "Region Network Operations and Productivity Bali Nusra Division",
      "Area": "Bali Nusra",
      "Regional": "R7 Bali Nusra"
    },
    {
      "NAMA GM": "Hersetyo Pramono",
      "Remedy Login ID": "azismus",
      "NAMA (MGR)": "Azis Mustiko",
      "TITLE": "Manager Service Quality Assurance Bali Nusra",
      "AREA DIVISION": "Region Network Operations and Productivity Bali Nusra Division",
      "Area": "Bali Nusra",
      "Regional": "R7 Bali Nusra"
    },
    {
      "NAMA GM": "Hersetyo Pramono",
      "Remedy Login ID": "igdeman",
      "NAMA (MGR)": "Igde Y Manuadi",
      "TITLE": "Manager Core, Transport and Datacenter Operations Bali Nusra",
      "AREA DIVISION": "Region Network Operations and Productivity Bali Nusra Division",
      "Area": "Bali nusra",
      "Regional": "R7 Bali Nusra"
    },
    {
      "NAMA GM": "Fredy Hilton Tambunan",
      "Remedy Login ID": "ronidonboscoman",
      "NAMA (MGR)": "Roni Don Bosco Manurung",
      "TITLE": "Manager Network Operations and Productivity Pontianak",
      "AREA DIVISION": "Region Network Operations and Productivity Kalimantan Division",
      "Area": "Kalimantan",
      "Regional": "R8 Kalimantan"
    },
    {
      "NAMA GM": "Fredy Hilton Tambunan",
      "Remedy Login ID": "rionaldpar",
      "NAMA (MGR)": "Rionald Parubak",
      "TITLE": "Manager Network Operations and Productivity Samarinda",
      "AREA DIVISION": "Region Network Operations and Productivity Kalimantan Division",
      "Area": "Kalimantan",
      "Regional": "R8 Kalimantan"
    },
    {
      "NAMA GM": "Fredy Hilton Tambunan",
      "Remedy Login ID": "fajarbha",
      "NAMA (MGR)": "Fajar Surya Bhawana",
      "TITLE": "Manager Network Operations and Productivity Balikpapan",
      "AREA DIVISION": "Region Network Operations and Productivity Kalimantan Division",
      "Area": "Kalimantan",
      "Regional": "R8 Kalimantan"
    },
    {
      "NAMA GM": "Fredy Hilton Tambunan",
      "Remedy Login ID": "iwayanwid",
      "NAMA (MGR)": "I Wayan Widastra",
      "TITLE": "Manager Network Operations and Productivity Palangkaraya",
      "AREA DIVISION": "Region Network Operations and Productivity Kalimantan Division",
      "Area": "Kalimantan",
      "Regional": "R8 Kalimantan"
    },
    {
      "NAMA GM": "Fredy Hilton Tambunan",
      "Remedy Login ID": "herisur",
      "NAMA (MGR)": "Heri Suryanto",
      "TITLE": "Manager Network Operations and Productivity Banjarmasin",
      "AREA DIVISION": "Region Network Operations and Productivity Kalimantan Division",
      "Area": "Kalimantan",
      "Regional": "R8 Kalimantan"
    },
    {
      "NAMA GM": "Fredy Hilton Tambunan",
      "Remedy Login ID": "zulkiflifar",
      "NAMA (MGR)": "Zulkifli Farma",
      "TITLE": "Manager Network Operations and Productivity Tarakan",
      "AREA DIVISION": "Region Network Operations and Productivity Kalimantan Division",
      "Area": "Kalimantan",
      "Regional": "R8 Kalimantan"
    },
    {
      "NAMA GM": "Fredy Hilton Tambunan",
      "Remedy Login ID": "ratmawah",
      "NAMA (MGR)": "Ratma Wahyudi",
      "TITLE": "Manager Network Operations and Productivity Pangkalan Bun",
      "AREA DIVISION": "Region Network Operations and Productivity Kalimantan Division",
      "Area": "Kalimantan",
      "Regional": "R8 Kalimantan"
    },
    {
      "NAMA GM": "Fredy Hilton Tambunan",
      "Remedy Login ID": "priambodokur",
      "NAMA (MGR)": "Priambodo Kurniawan",
      "TITLE": "Manager Service Quality Assurance Kalimantan",
      "AREA DIVISION": "Region Network Operations and Productivity Kalimantan Division",
      "Area": "Kalimantan",
      "Regional": "R8 Kalimantan"
    },
    {
      "NAMA GM": "Fredy Hilton Tambunan",
      "Remedy Login ID": "mohammadnaf",
      "NAMA (MGR)": "Mohammad T Nafiri",
      "TITLE": "Manager Core and Transport Operations Kalimantan",
      "AREA DIVISION": "Region Network Operations and Productivity Kalimantan Division",
      "Area": "Kalimatan",
      "Regional": "R8 Kalimantan"
    },
    {
      "NAMA GM": "Muhammad Idham Kadir",
      "Remedy Login ID": "roberthoruh",
      "NAMA (MGR)": "Robertho L.R.",
      "TITLE": "Manager Network Operations and Productivity Manado",
      "AREA DIVISION": "Region Network Operations and Productivity Sulawesi Division",
      "Area": "Sulawesi",
      "Regional": "R9 Sulawesi"
    },
    {
      "NAMA GM": "Muhammad Idham Kadir",
      "Remedy Login ID": "janiator",
      "NAMA (MGR)": "Janiator",
      "TITLE": "Manager Network Operations and Productivity Gorontalo",
      "AREA DIVISION": "Region Network Operations and Productivity Sulawesi Division",
      "Area": "Sulawesi",
      "Regional": "R9 Sulawesi"
    },
    {
      "NAMA GM": "Muhammad Idham Kadir",
      "Remedy Login ID": "hanafidi",
      "NAMA (MGR)": "Hanafidi",
      "TITLE": "Manager Network Operations and Productivity Palu",
      "AREA DIVISION": "Region Network Operations and Productivity Sulawesi Division",
      "Area": "Sulawesi",
      "Regional": "R9 Sulawesi"
    },
    {
      "NAMA GM": "Muhammad Idham Kadir",
      "Remedy Login ID": "dortjebal",
      "NAMA (MGR)": "Dortje Balik",
      "TITLE": "Manager Network Operations and Productivity Kendari",
      "AREA DIVISION": "Region Network Operations and Productivity Sulawesi Division",
      "Area": "Sulawesi",
      "Regional": "R9 Sulawesi"
    },
    {
      "NAMA GM": "Muhammad Idham Kadir",
      "Remedy Login ID": "muhnur",
      "NAMA (MGR)": "Muhammad Nuryadin",
      "TITLE": "Manager Network Operations and Productivity Parepare",
      "AREA DIVISION": "Region Network Operations and Productivity Sulawesi Division",
      "Area": "Sulawesi",
      "Regional": "R9 Sulawesi"
    },
    {
      "NAMA GM": "Muhammad Idham Kadir",
      "Remedy Login ID": "nurpas",
      "NAMA (MGR)": "Nur Pasorong",
      "TITLE": "Manager Network Operations and Productivity Makassar",
      "AREA DIVISION": "Region Network Operations and Productivity Sulawesi Division",
      "Area": "Sulawesi",
      "Regional": "R9 Sulawesi"
    },
    {
      "NAMA GM": "Muhammad Idham Kadir",
      "Remedy Login ID": "freditan",
      "NAMA (MGR)": "Fredi Tandibura",
      "TITLE": "Manager Network Operations and Productivity Ternate",
      "AREA DIVISION": "Region Network Operations and Productivity Sulawesi Division",
      "Area": "Sulawesi",
      "Regional": "R9 Sulawesi"
    },
    {
      "NAMA GM": "Muhammad Idham Kadir",
      "Remedy Login ID": "andrisyal",
      "NAMA (MGR)": "Andrisyal",
      "TITLE": "Manager Service Quality Assurance Sulawesi",
      "AREA DIVISION": "Region Network Operations and Productivity Sulawesi Division",
      "Area": "Sulawesi",
      "Regional": "R9 Sulawesi"
    },
    {
      "NAMA GM": "Muhammad Idham Kadir",
      "Remedy Login ID": "achmadtaq",
      "NAMA (MGR)": "Achmad Taqiudin",
      "TITLE": "Manager Core and Transport Operations Sulawesi",
      "AREA DIVISION": "Region Network Operations and Productivity Sulawesi Division",
      "Area": "Sulawesi",
      "Regional": "R9 Sulawesi"
    },
    {
      "TITLE": "General Manager Region Network Operations and Productivity Sumbagsel"
    },
    {
      "TITLE": "Manager - Core and Transport Operations Sumbagsel"
    },
    {
      "TITLE": "Engineer - Cloud Infrastructure and Digital Services Sumbagsel"
    },
    {
      "TITLE": "Engineer - Core Communication and Database Sumbagsel"
    },
    {
      "TITLE": "Engineer - Transmission Operations Sumbagsel"
    },
    {
      "TITLE": "Engineer - Core Broadband and 5G Sumbagsel"
    },
    {
      "TITLE": "Engineer - Power System Operations Sumbagsel"
    },
    {
      "TITLE": "Staff - Core and Transport Operations Sumbagsel"
    },
    {
      "TITLE": "Manager Service Quality Assurance Sumbagsel"
    },
    {
      "TITLE": "Engineer Service Quality Assurance Sumbagsel"
    },
    {
      "TITLE": "Staff Service Quality Assurance Sumbagsel"
    },
    {
      "TITLE": "Manager Network Operations and Productivity Palembang"
    },
    {
      "TITLE": "Engineer Radio Operations Sumbagsel"
    },
    {
      "TITLE": "Engineer Network Operations and Productivity Palembang"
    },
    {
      "TITLE": "Staff Network Operations and Productivity Palembang"
    },
    {
      "TITLE": "Manager Network Operations and Productivity Bengkulu"
    },
    {
      "TITLE": "Engineer Network Operations and Productivity Bengkulu"
    },
    {
      "TITLE": "Staff Network Operations and Productivity Bengkulu"
    },
    {
      "TITLE": "Manager Network Operations and Productivity Jambi"
    },
    {
      "TITLE": "Engineer Network Operations and Productivity Jambi"
    },
    {
      "TITLE": "Staff Network Operations and Productivity Jambi"
    },
    {
      "TITLE": "Manager Network Operations and Productivity Lampung"
    },
    {
      "TITLE": "Senior Associate - Network Operations and Productivity Lampung"
    },
    {
      "TITLE": "Engineer Network Operations and Productivity Lampung"
    },
    {
      "TITLE": "Staff Network Operations and Productivity Lampung"
    },
    {
      "TITLE": "Manager Network Operations and Productivity Muaraenim"
    },
    {
      "TITLE": "Engineer Network Operations and Productivity Muaraenim"
    },
    {
      "TITLE": "Staff Network Operations and Productivity Muaraenim"
    },
    {
      "TITLE": "Manager Network Operations and Productivity Pangkal Pinang"
    },
    {
      "TITLE": "Engineer Network Operations and Productivity Pangkal Pinang"
    },
    {
      "TITLE": "Staff Network Operations and Productivity Pangkal Pinang"
    },
    {
      "TITLE": "Manager Network Operations Support Sumbagsel"
    },
    {
      "TITLE": "Supervisor Network Operations Partnership Sumbagsel"
    },
    {
      "TITLE": "Supervisor NOS Admin Sumbagsel"
    },
    {
      "TITLE": "Staff NOS Admin Sumbagsel"
    },
    {
      "TITLE": "General Manager Network Expert Domain"
    },
    {
      "TITLE": "Senior Analyst Network Expert Domain - Power"
    },
    {
      "TITLE": "Senior Associate Network Expert Domain"
    },
    {
      "TITLE": "Manager RAN Expert Domain"
    },
    {
      "TITLE": "Engineer RAN Expert Domain"
    },
    {
      "TITLE": "Manager Broadband Core Expert Domain"
    },
    {
      "TITLE": "Engineer Broadband Core Expert Domain"
    },
    {
      "TITLE": "Manager Communication Core, Interconnect and International Expert Domain"
    },
    {
      "TITLE": "Engineer Communication Core, Interconnect and International Expert Domain"
    },
    {
      "TITLE": "Manager Datacomm Expert Domain"
    },
    {
      "TITLE": "Engineer Datacomm Expert Domain"
    },
    {
      "TITLE": "Manager Transport Expert Domain"
    },
    {
      "TITLE": "Engineer Transport Expert Domain"
    },
    {
      "TITLE": "Manager Cloud Infrastructure Expert Domain"
    },
    {
      "TITLE": "Engineer Cloud Infrastructure Expert Domain"
    },
    {
      "TITLE": "General Manager Network Change and Release Management"
    },
    {
      "TITLE": "Manager RAN Change and Release"
    },
    {
      "TITLE": "Engineer RAN Change and Release"
    },
    {
      "TITLE": "Manager Broadband Core Change and Release"
    },
    {
      "TITLE": "Engineer Broadband Core Change and Release"
    },
    {
      "TITLE": "Manager Communication Core, Interconnect and International Change and Release"
    },
    {
      "TITLE": "Engineer Communication Core, Interconnect and International Change and Release"
    },
    {
      "TITLE": "Manager Datacomm Change and Release"
    },
    {
      "TITLE": "Engineer Datacomm Change and Release"
    },
    {
      "TITLE": "Pj. Manager Transport Change and Release"
    },
    {
      "TITLE": "Engineer Transport Change and Release"
    },
    {
      "TITLE": "Manager Cloud Infrastructure Change and Release"
    },
    {
      "TITLE": "Engineer Cloud Infrastructure Change and Release"
    }
  ]