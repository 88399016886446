import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const OPTIONS_CHART = {
  labels: [],
  chart: {
    height: 360,
    type: "donut",
  },
  legend: {
    position: "bottom",
  },
  stroke: {
    show: false,
  },
  dataLabels: {},
  colors: ["#02a8b5","#38618C","#299cdb","#f06548","#f7b84b","#3577f1"],
};

const StoreVisitsCharts = ({ dataColors, seriesData, domain }) => {
  console.log("Data CR Domain", seriesData)
  // let chartDonutBasicColors = getChartColorsArray(dataColors);
  const [optionChart, setOptionChart] = React.useState({...OPTIONS_CHART})
  const [seriesChart, setSeriesChart] = React.useState([...seriesData]);
  const series = seriesData;

  const prepareChartOption = (series_data, domain_data) => {
    const options_chart_data = {...optionChart};
    options_chart_data["labels"] = domain_data;
    options_chart_data["dataLabels"] = {
      formatter: (value) => {
          let sum = series_data.reduce((partialSum, a) => partialSum + a, 0);
  
          // let percentage = ((value / sum)* 100).toFixed(2) + "%";
  
          return value.toFixed(2) + "%" ;
      },
    };

    setOptionChart(options_chart_data)
  }

  React.useEffect(() => {
    prepareChartOption(series, domain)
  }, [seriesChart])
  
  return (
    <React.Fragment>
      <ReactApexChart dir="ltr"
        options={optionChart}
        series={seriesChart}
        type="donut"
        height="360"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export { StoreVisitsCharts };
