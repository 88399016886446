import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { notificationFlagAdd, loadingFalse, notificationFlagAssign } from '../../redux/reducers/notificationFlagReducer';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const dateNow = new Date();

export const deleteDataUser = createAsyncThunk(
    "/deleteUser",
    async (formData, thunkApi) => {
        const resPromise = await deleteDataUserAsync(formData.dataForm, thunkApi);
        return resPromise;
    }
);

export const deleteDataUserAsync = async (form, thunkApi) => {

    const res = await apiAuth.delete('/deleteUser/'+form.email)
    let notification = {}
    if(res !== undefined && res.data !== undefined){
        notification = {
            alert_status : "success",
            alert_message : "User Delete successfully!",
        }
    }else{
        
        notification = {
            alert_status : "warning",
            alert_message : res.response.data,
        }
    }
    thunkApi.dispatch(notificationFlagAssign(notification));
    thunkApi.dispatch(loadingFalse());

    return res    

    // return res
}