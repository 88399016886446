import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown, Label, Input, Button } from 'reactstrap';

import { API_CLIENT_AUTH_BEARER } from "../../../helpers/api-helper";
import { saveAs } from 'file-saver';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const ClassificationTab = (props) => {

    const {data} = props;
    const [dataForm, setDataForm] = React.useState({});
    const [canEdit, setCanEdit] = React.useState(false);

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        setDataForm(dataFormInput)
    }

    const downloadFile = async (e, file, idx) => {
        e.preventDefault()
        if(file !== undefined)  {
          const resFile = await apiAuth.getFile('/downloadAttachment/' + data.change_id+'/'+idx);
          if(resFile !== undefined){
            saveAs(new Blob([resFile.data], {type:file.mime_type}), file.file_name);
          }
        }
      }

    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="activity_list" className="form-label">Activity List</Label>
                                                <Input type="text" className="form-control" value={data.activity_list} name="activity_list" disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="activity_list" />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="change_category" className="form-label">Change Category</Label>
                                                <Input type="text" className="form-control" value={data.change_category} name="change_category" disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="change_category" />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="impact" className="form-label">Impact</Label>
                                                <Input type="text" className="form-control" name="impact" value={data.impact} disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="impact" />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="risk" className="form-label">Risk</Label>
                                                <Input type="text" className="form-control" name="risk" value={data.risk} disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="risk" />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="priority" className="form-label">Priority</Label>
                                                <Input type="text" className="form-control" value={data.priority} name="priority" disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="priority" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row md={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="description_of_change" className="form-label">Description of Change</Label>
                                            <Input type="textarea" className="form-control" value={data.description_of_change} name="description_of_change" disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="description_of_change" />
                                        </div>
                                    </Row>
                                    <Row md={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="reason_for_change" className="form-label">Reason For Change</Label>
                                            <Input type="textarea" className="form-control" value={data.reason_for_change} name="reason_for_change" disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="reason_for_change" />
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <div className="d-flex align-items-center mb-4">
                                <h5 className="card-title flex-grow-1">Documents</h5>
                            </div>
                            <Row>
                                <Col lg={12}>
                                    <div className="table-responsive table-card">
                                        <Table className="table-borderless align-middle mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col">File Name</th>
                                                    <th scope="col">Attach Name</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.attachments && data.attachments.map((attach,idx) => (
                                                    <tr key={idx}> 
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title bg-light text-secondary rounded fs-24">
                                                                        <i className="ri-folder-zip-line"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 flex-grow-1">
                                                                    <h5 className="fs-14 mb-0" onClick={(e) => {downloadFile(e, attach, idx)}}>{attach.file_name}</h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{attach.attachment_name}</td>
                                                        <td>
                                                            <Button color='success' onClick={(e) => {downloadFile(e, attach, idx)}}>
                                                                Download
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
        </React.Fragment>
    );
};

export default ClassificationTab;