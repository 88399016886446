import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Table
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import Flatpickr from "react-flatpickr";

//redux
import { useSelector, useDispatch } from "react-redux";

//Simple bar
import listPlugin from '@fullcalendar/list';

import {
  getEvents as onGetEvents,
  getCategories as onGetCategories,
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  updateEvent as onUpdateEvent,
  resetCalendar,
} from "../../../slices/thunks";
import { createSelector } from "reselect";
import { datetimeformatncrm } from "../../../helpers/FunctionalHelper/date-helper";
import TimelineCalendar from "../../../Components/Calendar/TimelineCalendar";

const TimelineTab = (props) => {

  const {data} = props

  const dispatch = useDispatch();

  const calendarRef = useRef(null);

  const [event, setEvent] = useState({});
  const [eventsList, setEventsList] = useState([]);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedNewDay, setSelectedNewDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditButton, setIsEditButton] = useState(true);
  const [upcommingevents, setUpcommingevents] = useState([]);
  const [dataTimeline, setDataTimeline] = useState([]);


  const selectLayoutState = (state) => state.Calendar;
  const calendarDataProperties = createSelector(
    selectLayoutState,
    (state) => ({
      events: state.events,
      categories: state.categories,
      isEventUpdated: state.isEventUpdated,
    })
  );
  // Inside your component
  const {
    events, categories, isEventUpdated
  } = useSelector(calendarDataProperties);

  const prepareEvents = () => {
    let add_timeline = [];
    dataTimeline.forEach(dt => {
      add_timeline.push({
        ...dt, 
        "className" : "bg-danger-subtle text-danger",
        "start" : dt.plan_start_time,
        "end" : dt.plan_end_time,
        "title" : data.activity_list+" "+dt.activity_description,
        "parent_id" : data.change_id
      })
    })
    // const list_timeline = [...events, ...add_timeline]
    const list_timeline = [...add_timeline]
    setEventsList(list_timeline)
  }

  const prepareDataTimeline = () => {
    if(data && data.timelines){
      setDataTimeline([...data.timelines]);
    }
  }

  useEffect(() => {
    prepareDataTimeline()
  }, [data]);

  useEffect(() => {
    prepareEvents()
  }, [events, dataTimeline]);


  useEffect(() => {
    dispatch(onGetEvents());
    dispatch(onGetCategories());
  }, [dispatch]);

  useEffect(() => {
    if (isEventUpdated) {
      setIsEdit(false);
      setEvent({});
      setTimeout(() => {
        dispatch(resetCalendar("isEventUpdated", false));
      }, 500);
    }
  }, [dispatch, isEventUpdated]);

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
      setIsEdit(false);
      setIsEditButton(true);
    } else {
      setModal(true);
    }
  };
  /**
   * Handling date click on calendar
   */

  const handleDateClick = (arg) => {
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );

    const modifiedData = { ...arg, date: modifiedDate };

    setSelectedNewDay(date);
    setSelectedDay(modifiedData);
    toggle();
  };

  const str_dt = function formatDate(date) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var d = new Date(date),
      month = "" + monthNames[d.getMonth()],
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day + " " + month, year].join(",");
  };

  const date_r = function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg) => {
    const event = arg.event;

    const st_date = event.start;
    const ed_date = event.end;
    const r_date =
      ed_date == null
        ? str_dt(st_date)
        : str_dt(st_date) + " to " + str_dt(ed_date);
    const er_date =
      ed_date == null
        ? date_r(st_date)
        : date_r(st_date) + " to " + date_r(ed_date);

    setEvent({
      id: event.id,
      title: event.title,
      start: event.start,
      end: event.end,
      parent_id: event.extendedProps.parent_id,
      className: event.classNames,
      category: event.classNames[0],
      location: event._def.extendedProps.location,
      description: event._def.extendedProps.description,
      defaultDate: er_date,
      datetag: r_date,
    });

    setIsEdit(true);
    setIsEditButton(false);
    toggle();
  };
  /**
   * On delete event
   */

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xl={12}>
              <Card>
                <div className="table-responsive">
                  <Table className="align-middle mb-3 text-align-header-centre">
                      <thead>
                          <tr>
                              <th scope="col">No</th>
                              <th scope="col">Plan Start</th>
                              <th scope="col">Plan End</th>
                              <th scope="col" style={{minWidth : '125px'}}>Act Desc</th>
                              <th scope="col">Status</th>
                              <th scope="col" style={{minWidth : '150px'}}># Impacted NE</th>
                              <th scope="col" style={{minWidth : '150px'}}>Detail of Impacted NE</th>
                          </tr>
                      </thead>
                      <tbody>
                        {dataTimeline.map((time, idx) =>
                          <tr key={idx}>
                            <td>{idx+1}</td>
                            <td>{datetimeformatncrm(time.plan_start_time)}</td>
                            <td>{datetimeformatncrm(time.plan_end_time)}</td>
                            <td>{time.activity_description}</td>
                            <td style={{textAlign : 'center'}}>{time.plan_status}</td>
                            <td style={{textAlign : 'center'}}>{time.number_of_impacted_ne}</td>
                            <td>{time.detailed_of_impacted_ne}</td>
                          </tr>    
                        )}
                      </tbody>
                  </Table>
                  </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
                <Card className="card-h-80">
                <CardBody>
                    <FullCalendar
                      ref={calendarRef}
                      plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                          listPlugin
                      ]}
                      initialView="dayGridWeek"
                      slotDuration={"00:15:00"}
                      handleWindowResize={true}
                      themeSystem="bootstrap"
                      headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridWeek",
                      }}
                      height={'400px'}
                      events={eventsList}
                      editable={true}
                      droppable={true}
                      selectable={true}
                      dateClick={handleDateClick}
                      eventClick={handleEventClick}
                      datesSet={(arg) => {
                        // console.log(arg)
                        // console.log(arg.view.activeStart) //starting visible date
                        // console.log(arg.view.activeEnd) //ending visible date
                      }}
                    />
                </CardBody>
                </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <TimelineCalendar 
        modal={modal}
        data={event}
        toggle={toggle}
      />
    </React.Fragment>
  );
};

TimelineTab.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default TimelineTab;