import React from 'react';
import { Col, Row, Label, Card, CardBody, Input } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import Select from "react-select";

import { USER_TITLE } from '../../config/data/USER_TITLE';

import { datetimeformatiso } from '../../helpers/FunctionalHelper/date-helper';
import { datetimeformatncrm, dateformatncrm, datemonthformatncrm } from "../../helpers/FunctionalHelper/date-helper";

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
const apiAuth = new API_CLIENT_AUTH_BEARER();

const CalenderFilter = (props) => {
    const Responsible_Approval = USER_TITLE;

    const [dataForm, setDataForm] = React.useState({});
    const [dataTemp, setDataTemp] = React.useState({});
    const [userName, setUserName] = React.useState("Admin");
    const [currentDate, setCurrentDate] = React.useState([]);
    const [region, setRegion] = React.useState([{"All" : "All"}]);
    const [optionDomain, setOptionDomain] = React.useState([{"All" : "All"}]);
    const [checkUser, setCheckUser] = React.useState(true);

    const current_date = new Date();
    
    const next_month_date = props.nextMonthDate;
    const previous_month_date = props.previousMonthDate;

    const profiledropdownData = createSelector(
        (state) => state.Profile.user,
        (user) => user
      );
    // Inside your component
    const user = useSelector(profiledropdownData);

    const setNewDate = (e) => {
        const dateData = {...dataForm}
        const data_time = {...dataTemp}
        if(e !== undefined && e.e[0] !== undefined && e.e[1] !== undefined){
            dateData["start_date"] = e.e[0]
            dateData["end_date"] = e.e[1]
            data_time["data_time"] = dateData
            props.searchDate(data_time)
        }

        setDataTemp(data_time)
        setDataForm(dateData)
    }

    React.useEffect(() => {
        if (sessionStorage.getItem("authUser")) {
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            setUserName(process.env.REACT_APP_DEFAULTAUTH === "fake" ? obj.username === undefined ? user.first_name ? user.first_name : obj.data.first_name : "Admin" || "Admin" :
                process.env.REACT_APP_DEFAULTAUTH === "firebase" ? obj.email && obj.email : "Admin"
            );
        }
    }, [userName, user]);

    const getDataDomainCategory = async() => {
        const reqBody = {
            "data": {
                "categorization_tier_1": "Change & Release Management"
            }
        };
        const res = await apiAuth.post('/getActivityListDomainCategory', reqBody);
        if(res && res.data){
            const option_format = [{value : "All", label : "All"}].concat(res.data.data.map(item => ( {value: item, label: item} )))
            // // console.log("Option List", option_format)
            setOptionDomain(option_format.sort((a, b) => a.label > b.label ? 1 : -1))
        }
    }

    React.useEffect(() => {
        getDataDomainCategory()
    }, [])

    const getRegionList = () => {
        const dataList = [{label : "All", value : "All", number : 0}].concat(Responsible_Approval.map(ls => ls.Regional).filter((item, index, arr) => arr.indexOf(item) === index).filter(item => item !== undefined).map(item => ({label : item, value : item, number : parseInt(item.split(" ")[0].replace("R",""))})));
        // console.log("Region List", dataList)
        setRegion(dataList.sort((a, b) => a.number > b.number ? 1 : -1));
    }
    React.useEffect(() => {
        getRegionList();
    }, []);


    const handleSelectForm = (e, name) => {
        const data_form = {...dataTemp};
        const {value, label, priority} = e;
        data_form[name] = value;
        setDataTemp(data_form);
        // if(dataTemp.data_time){
        props.searchDate(data_form)
        // }
    }

    const handleCheckForm = (e, name) => {
        let check = null;
        const data_form = {...dataTemp};
        // // console.log("Data Tampungan", checkUser)
        if(checkUser === true){
            check = false;
        }else{
            check = true;
        }
        data_form[name] = check;
        // // console.log("Data Tampungan", check)
        setCheckUser(check);
        setDataTemp(data_form);
        // if(dataTemp.data_time){
        props.searchDate(data_form)
        // }
    }

    // // console.log("Data Tampungan", dataTemp)

    return (
        <React.Fragment>
                    <Card className="card-h-100" style={{marginBottom: "5px", boxShadow: "0 1px 0px white"}}>
                        <CardBody>
                        <div className="d-flex flex-lg-row flex-column">
                            <div className="mt-3 mt-lg-0" style={{marginRight: "10px", minWidth: "50px"}}>
                                {/* <Label htmlFor="changeType" className="form-label">User</Label> */}
                                {/* <form action="#"> */}
                                <Row className="mb-0 align-items-center" style={{height: "100%"}}>
                                        <div className="col-sm-auto">
                                            <div className="input-group">
                                    <Input className="form-check-input" type="checkbox" id="formCheck2" 
                                            onChange={(e) => {
                                                handleCheckForm(e, 'user');
                                            }}
                                            defaultChecked={checkUser}
                                            style={{marginRight: "5px"}}
                                    />
                                    <Label className="form-check-label" htmlFor="formCheck2">
                                        All User
                                    </Label>
                                    </div>
                                    </div>
                                    </Row>
                                {/* </form> */}
                            </div>
                            <div className="mt-3 mt-lg-0" style={{marginRight: "10px", width: "200px"}}>
                                {/* <Label htmlFor="changeType" className="form-label">Region</Label> */}
                                <Select
                                    onChange={(e) => {
                                        handleSelectForm(e, 'region');
                                    }}
                                    options={region}
                                    placeholder="Region"
                                />
                            </div>
                            <div className="mt-3 mt-lg-0" style={{marginRight: "10px", width: "200px"}}>
                                {/* <Label htmlFor="domainCategory" className="form-label">Domain</Label> */}
                                <Select
                                    onChange={(e) => {
                                        handleSelectForm(e, 'oc_domain_category');
                                    }}
                                    options={optionDomain}
                                    placeholder="Domain"
                                />
                            </div>
                            <div className="mt-3 mt-lg-0" style={{marginRight: "10px"}}>
                                {/* <Label htmlFor="changeType" className="form-label">Date</Label> */}
                                <form action="#">
                                    <Row className="g-3 mb-0 align-items-center">
                                        <div className="col-sm-auto">
                                            <div className="input-group">
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    options={{
                                                        mode: "range",
                                                        dateFormat: "d M, Y",
                                                        defaultDate: [previous_month_date, next_month_date],
                                                        onChange: (e) => {setNewDate({e})}
                                                    }}
                                                />
                                                <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                                            </div>
                                        </div>
                                    </Row>
                                </form>
                            </div>
                        </div>
                        </CardBody>
                    </Card>
        </React.Fragment>
    );
};

export default CalenderFilter;