import React from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown, Label, Input } from 'reactstrap';

const AIInformationTab = (props) => {

    const {data} = props;

    const [dataForm, setDataForm] = React.useState({});
    const [canEdit, setCanEdit] = React.useState(false);

    const handleInputChange = (e) => {
        const dataFormInput = {...dataForm}
        const {name, value} = e.target
        dataFormInput[name] = value;
        setDataForm(dataFormInput)
    }

    return (
        <React.Fragment>
            <Row>
                <Col md="6">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="region" className="form-label">Region</Label>
                                        <Select
                                            isDisabled={true}
                                            value={data.region ? data.region.map(item => ({value : item, label : item}) ) : {}}
                                            isClearable={true}
                                            isMulti={true}
                                            onChange={(e) => {
                                                handleSelectFormMulti(e, "region");
                                            }}
                                            options={data.region ? data.region.map(item => ({value : item, label : item}) ) : {}}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="responsible_approval" className="form-label">Responsible Approval</Label>
                                        <Select
                                            isDisabled={true}
                                            value={data.responsible_approval ? data.responsible_approval.map(item => ({value : item, label : item}) ) : {}}
                                            isClearable={true}
                                            isMulti={true}
                                            onChange={(e) => {
                                                handleSelectFormMulti(e, "responsible_approval");
                                            }}
                                            options={data.responsible_approval ? data.responsible_approval.map(item => ({value : item, label : item}) ) : {}}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="ne_id_change_target" className="form-label">NE ID Change Target</Label>
                                        <Input type="text" className="form-control" name="ne_id_change_target" value={data.ne_id_change_target} disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="ne_id_change_target" />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="ne_name_change_target" className="form-label">NE Name Change Target</Label>
                                        <Input type="text" className="form-control" name="ne_name_change_target" value={data.ne_name_change_target} disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="ne_name_change_target" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="detailed_location" className="form-label">Detailed Location</Label>
                                        <Input type="text" className="form-control" name="detailed_location" value={data.detailed_location} disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="detailedLocation" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="vendor_name_ai_info" className="form-label">Vendor Name</Label>
                                        <Select
                                            isDisabled={true}
                                            value={data.vendor_name ? data.vendor_name.map(item => ({value : item, label : item}) ) : {}}
                                            isClearable={true}
                                            isMulti={true}
                                            onChange={(e) => {
                                                handleSelectFormMulti(e, "vendor_name");
                                            }}
                                            options={data.vendor_name ? data.vendor_name.map(item => ({value : item, label : item}) ) : {}}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="6">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="impact_to_ne" className="form-label">Impact to Ne</Label>
                                        <Input type="text" className="form-control" name="impact_to_ne" value={data.impact_to_ne} disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="impact_to_ne" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="ne_name_impacted_list" className="form-label">NE Name (Impacted) List</Label>
                                        <Input type="text" className="form-control" name="ne_name_impacted_list" value={data.ne_name_impacted_list} disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="ne_name_impacted_list" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="ne_impact_description" className="form-label">NE Impact Description</Label>
                                        <Input type="textarea" className="form-control" name="ne_impact_description" value={data.ne_impact_description} disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="ne_impact_description" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="ne_outage_duration_min" className="form-label">NE Outage Duration (min)</Label>
                                        <Input type="number" className="form-control" name="ne_outage_duration_min" value={data.ne_outage_duration_min} disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="ne_outage_duration_min" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="affected_services" className="form-label" style={{marginRight: "10px"}}>Affected Service</Label>
                                        <Input className="form-check-input" type="checkbox" id="affected_services_sms" name="affected_services" disabled={!canEdit} checked={data.affected_services ? data.affected_services.find(item => item === "sms") : false} onChange={(e) => handleCheckboxChange(e, "affected_services", "sms")}/>
                                        <Label className="form-check-label" htmlFor="optimization_request" style={{marginLeft : '5px', marginRight : '10px'}}>
                                            SMS
                                        </Label>
                                        <Input className="form-check-input" type="checkbox" id="affected_services_mms" name="affected_services_mms" disabled={!canEdit} checked={data.affected_services ? data.affected_services.find(item => item === "mms") : false} onChange={(e) => handleCheckboxChange(e, "affected_services", "mms")}/>
                                        <Label className="form-check-label" htmlFor="optimization_request" style={{marginLeft : '5px', marginRight : '10px'}}>
                                            MMS
                                        </Label>
                                        <Input className="form-check-input" type="checkbox" id="affected_services_data" name="affected_services_data" disabled={!canEdit} checked={data.affected_services ? data.affected_services.find(item => item === "data") : false} onChange={(e) => handleCheckboxChange(e, "affected_services", "data")}/>
                                        <Label className="form-check-label" htmlFor="optimization_request" style={{marginLeft : '5px', marginRight : '10px'}}>
                                            Data
                                        </Label>
                                        <Input className="form-check-input" type="checkbox" id="affected_services_voice" name="affected_services_voice" disabled={!canEdit} checked={data.affected_services ? data.affected_services.find(item => item === "voice") : false} onChange={(e) => handleCheckboxChange(e, "affected_services", "voice")}/>
                                        <Label className="form-check-label" htmlFor="optimization_request" style={{marginLeft : '5px', marginRight : '10px'}}>
                                            Voice
                                        </Label>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="affected_services_description" className="form-label">Affected Service Description</Label>
                                        <Input type="textarea" className="form-control" name="affected_services_description" value={data.affected_services_description} disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="affected_services_description" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="service_outage_duration_min" className="form-label">Service Outage Duration</Label>
                                        <Input type="text" className="form-control" name="service_outage_duration_min" value={data.affected_services_description} disabled={!canEdit} onChange={(e) => handleInputChange(e)} id="service_outage_duration_min" />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
        </React.Fragment>
    );
};

export default AIInformationTab;