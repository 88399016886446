import React from 'react'

import { Card, CardHeader, CardBody, Col, Container, Row, Collapse, Input, Button } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import PaginationTable from '../../Components/TableComponent/PaginationTable';
import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { Link } from 'react-router-dom';
import { HEADER_TITLE_NAME } from '../../config/config-general';
import classnames from "classnames";
import FilterCrList from './Components/FilterCrList';
import FilterCrListTable from './Components/FilterCrListTable';
import CurrentStatusBoxCrListTable from './Components/CurrentStatusBoxCrListTable';

// const api = new APIClient();
const apiAuth = new API_CLIENT_AUTH_BEARER();

const SORTING_TABLE_DEFAULT = ["desc",null,null]
const FIELD_SORTING_TABLE_DEFAULT = ["change_id","current_status","created_by"]
  
export default function CRForm() {

  document.title="CR Detail "+HEADER_TITLE_NAME;

  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [totalData, setTotalData] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [sortingList, setSortingList] = React.useState([...SORTING_TABLE_DEFAULT]);

  const onChangePage = (e) => {
    setPage(e);
  }

  const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

  const getData = async(filter_data) => {
    let where = '';

    let field_sort = 'change_id';
    let sort_direction = '-1';

    if(SORTING_TABLE_DEFAULT){
      const sorting_list = [...sortingList];
      const idx_sort = sorting_list.findIndex(item => item !== null);

      field_sort = FIELD_SORTING_TABLE_DEFAULT[idx_sort];
      sort_direction = sorting_list[idx_sort] === "desc" ? '-1': '1'
    }

    let srt_api = field_sort+':'+sort_direction

    let filter_array = [];
    let data_filter = filter_data;
    if(data_filter){
      for (const field_name in data_filter) {
        if(data_filter[field_name]){
          filter_array.push('"'+field_name+'":{"$substring" : "'+data_filter[field_name]+'"}');
        }
      }
    }
    if(filter_array){
      where = '{' + filter_array.join(',') + '}';
    }
    
    const res = await apiAuth.get('/getCRM?q='+where+'&srt='+srt_api+'&lmt=10&pg='+page);
    if(res && res.data){
        setData(res.data.data)
        setTotalData(res.data.totalResults)
    }
  }

  const getDataFilter = (filter_data) => {
    getData(filter_data)
  }

  const handleSortingTable = (index) => {
    let listSorting = [null,null,null];
    const sortIdx = sortingList[index];
    if(sortIdx === null){
      listSorting[index] = "desc";
    }else{
      listSorting[index] = sortIdx === "desc" ? "asc" : "desc";
    }
    setSortingList(listSorting)
  }

  React.useEffect(() => {
    getData();
  }, [page]);

  React.useEffect(() => {
    getData();
  }, [sortingList]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="CR Form List" pageTitle="CR Form List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">CR Form List</h4>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive mt-3">
                    <table className="table align-middle table-nowrap table-striped-columns mb-0" style={{border: "2px solid #dedede"}}>
                        <thead className="bg-primary text-white table-cr-list--cr-list-page">
                            <tr style={{textAlign: "center", verticalAlign: "middle"}}>
                              <th onClick={() => handleSortingTable(0)} className={"sort sort-cr-column-"+sortingList[0]} scope="col" rowSpan="1" colSpan={2}>CR ID</th>
                              <th scope="col" rowSpan="1" style={{border: "1px solid #fff"}}>Change Category</th>
                              <th scope="col" rowSpan="1" style={{border: "1px solid #fff"}}>Domain</th>
                              <th scope="col" rowspan="1" style={{minWidth: "150px", maxWidth: "150px", border: "1px solid #fff"}}>Region</th>
                              <th scope="col" rowSpan="1" style={{minWidth: "225px", maxWidth: "225px", border: "1px solid #fff"}}>Activity</th>
                              <th onClick={() => handleSortingTable(1)} className={"sort sort-cr-column-"+sortingList[1]} scope="col" rowSpan="1" style={{border: "1px solid #fff", minWidth : '150px'}}>Current Status</th>
                              <th onClick={() => handleSortingTable(2)} className={"sort sort-cr-column-"+sortingList[2]} scope="col" rowSpan="1" style={{border: "1px solid #fff", minWidth : '150px'}}>Creator</th>
                              <th scope="col" colSpan="2" style={{border: "1px solid #fff"}}>Implementation Date</th>
                            </tr>
                            <tr className='table-row--filter-cr'>
                              <FilterCrListTable doFilter={getDataFilter}/>
                              <th scope="col" style={{border: "1px solid rgb(64, 81, 137)", color : 'black'}}>Min Start</th>
                              <th scope="col" style={{border: "1px solid rgb(64, 81, 137)", color : 'black'}}>Max End</th>
                            </tr>
                        </thead>
                        <tbody>
                          {data.map(d => 
                            <tr key={d.change_id}>
                              <td style={{textAlign : "center"}}>
                                <Link to={'/cr-detail/'+d.change_id}>
                                  <button type="button" className="btn btn-sm btn-info">
                                    Details
                                  </button>
                                </Link>
                              </td>
                              <td>{d.change_id}</td>
                              <td>{d.change_category}</td>
                              <td>{d.oc_domain_category}</td>
                              <td style={{maxWidth: "150px", textWrap : 'wrap'}}>{d.region?d.region.join(", ") : null}</td>
                              <td style={{maxWidth: "225px", textWrap : 'wrap'}}>{d.activity_list}</td>
                              <td>
                                <CurrentStatusBoxCrListTable currentStatus={d.current_status} />
                              </td>
                              <td>{d.created_by}</td>
                              <td>{d.implementation_start}</td>
                              <td>{d.implementation_end}</td>
                            </tr>
                          )}
                        </tbody>
                    </table>
                  </div>
                  <PaginationTable 
                    totalData={totalData}
                    perPage={perPage}
                    totalShowingItem={data.length}
                    currentPage={page}
                    onChange={onChangePage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
