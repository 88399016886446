import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import { StatusCRLineCharts } from "./DashboardPieCharts";
import { getBalanceChartsData } from '../../slices/thunks';
import { createSelector } from 'reselect';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const StatusCRLineChart = (item) => {
    const dispatch = useDispatch();

    const [chartData, setChartData] = useState([{
        "name" : "Draft",
        "data" : [0]
    },{
        "name" : "Request for Authorization",
        "data" : [0]
    },{
        "name" : "Request for Change",
        "data" : [0]
    },{
        "name" : "Scheduled for Approval",
        "data" : [0]
    },{
        "name" : "Scheduled",
        "data" : [0]
    },{
        "name" : "Completed",
        "data" : [0]
    },{
        "name" : "Closed",
        "data" : [0]
    }]);
    const [dataList, setDataList] = React.useState([]);
    const [totalData, setTotalData] = React.useState(0);
    const [dataSearchDate, setDataSearchDate] = React.useState({});

    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    const newSetSearchDate = () => {
        setDataSearchDate(item.item)
    }

    React.useEffect(() => {
        newSetSearchDate();
    }, [item]);

    const current_date = new Date();
    const next_day_date = new Date(new Date().setDate(new Date().getDate()));
    const next_month_date = new Date(next_day_date.setMonth(next_day_date.getMonth() + 1));
    const previous_day_date = new Date(new Date().setDate(new Date().getDate() + 1));
    const previous_month_date = new Date(previous_day_date.setMonth(previous_day_date.getMonth() - 1));

    const getData = async() => {
        let filter_array = [];
        let role_array = [];

        if(userData.role && userData.role.find(role => role.includes("Admin") || role.includes("Line Manager")) !== undefined){
            filter_array.push('');
        }else{
            if(userData.role && userData.role.find(role => role.includes("Requestor")) !== undefined){
                role_array.push('{"created_by" : "'+userData.email+'"},{"lm_email" : "'+userData.email+'"}');
            }
            if(userData.role && userData.role.find(role => role.includes("Change Coordinator")) !== undefined){
                role_array.push('{"change_coordinator_email" : "'+userData.email+'"},{"lm_email" : "'+userData.email+'"}');
            }
            if((userData.role && userData.role.find(role => role.includes("Change Manager")) !== undefined) || (userData.title && userData.title.includes("Change and Release") === true)){
                role_array.push('{"change_category" : "Normal Minor"},{"change_category" : "Normal Major"},{"change_category" : "Normal Significan"},{"lm_email" : "'+userData.email+'"}');
            }
            if(userData.title && (userData.title.includes("Expert Domain") === true || userData.title.includes("Domain Expert") === true)){
                role_array.push('{"change_category" : "Normal Major"},{"change_category" : "Normal Significan"},{"lm_email" : "'+userData.email+'"}');
            }
            filter_array.push('"$or" : ['+role_array.join(',')+']');
        }

        let whereAnd = '{' + filter_array.join(',') + '}';

        const res = await apiAuth.get('/getCRM?q='+whereAnd+'&noPg=1');
        if(res && res.data){
            setDataList(res.data.data);
            setTotalData(res.data.totalResults);
            getChartData(res.data.data);
        }
    }
    
    React.useEffect(() => {
        getData();
    }, [dataSearchDate]);

    const getChartData = async(data_list) => {
        const year = new Date();

        for(let i = 0; i < chartData.length ; i++)  {
            for(let j = 0; j < 12 ; j++){
                chartData[i].name && (chartData[i].data[j] = data_list ? (data_list.filter(dt => dt.current_status === chartData[i].name && new Date(dt.updatedAt).getMonth() === j && new Date(dt.updatedAt).getFullYear() === year.getFullYear() )).length : 0)
            }
        }
        setChartData(chartData);
    }

    return (
        <React.Fragment>
            <Col md={12}>
                <Card className="card-height-100" style={{height: "418px"}}>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">CR Status</h4>
                        <div className="flex-shrink-0">
                        </div>
                    </CardHeader>
                    <CardBody className="px-0">
                        <ul className="list-inline main-chart text-center mb-0">
                        </ul>

                        <div id="revenue-expenses-charts" dir="ltr">
                            <StatusCRLineCharts series={chartData} dataColors='["--vz-success", "--vz-danger"]'/>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default StatusCRLineChart;