import React from 'react'

import { Card, CardHeader, CardBody, Col, Container, Row, Collapse, Input, Button } from 'reactstrap';
// import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import classnames from "classnames";

// const api = new APIClient();
// const apiAuth = new API_CLIENT_AUTH_BEARER();

const filter_array = ["change_id", "change_category", "oc_domain_category", "region", "activity_list", "current_status", "created_by", "implementation_start", "implementation_end"]
  
export default function FilterCrListTable(props) {

  const [dropdownFilter, setDropdownFilter] = React.useState(false);
  const [dataFilter, setDataFilter] = React.useState({});

  const openFilter = (e) => {
    setDropdownFilter(!dropdownFilter);
  }

  const handleFilterInputan = (e) => {
    const data_filter = {...dataFilter};
    const {value, name} = e.target;
    console.log("test", value, name)
    data_filter[name] = value;
    setDataFilter((prevState) => {
      let updatedFilters = {...prevState};
      updatedFilters = { ...updatedFilters, [name]: value };;
      return updatedFilters;
    });
  }

  const deleteFilterInputan = (e) => {
    const data_filter = {...dataFilter};
    for (const field_name in data_filter) {
      data_filter[field_name] = ''
    }
    setDataFilter(data_filter);
  }

  const getDataFilter = () => {
    props.doFilter(dataFilter)
  }

  const FilterPerColoumnTable = () => {
    return (
      <React.Fragment>
        {filter_array.map((fltr) =>
          <React.Fragment>
              <th key={fltr}>
                <Input type="text" className="form-control" size="sm" name={fltr} value={dataFilter[fltr]} onChange={(e) => handleFilterInputan(e)}/>              
              </th>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {/* <tr className='table-row--filter-cr'> */}
        <th>
          <Button size="sm" color="primary" onClick={getDataFilter}>
            Filter
          </Button>
          <Button size="sm" color="indo" onClick={deleteFilterInputan} style={{marginLeft : '25px'}}>
              Clear
          </Button>
        </th>
        <th>
          <Input type="text" className="form-control" size="sm" name={"change_id"} style={{minWidth : '150px'}} value={dataFilter["change_id"]} onChange={(e) => handleFilterInputan(e)}/>              
        </th>
        <th scope="col">
          <Input type="text" className="form-control" size="sm" name={"change_category"} value={dataFilter["change_category"]} onChange={(e) => handleFilterInputan(e)}/>              
        </th>
        <th>
          <Input type="text" className="form-control" size="sm" name={"oc_domain_category"} value={dataFilter["oc_domain_category"]} onChange={(e) => handleFilterInputan(e)}/>              
        </th>
        <th>
          <Input type="text" className="form-control" size="sm" name={"region"} value={dataFilter["region"]} onChange={(e) => handleFilterInputan(e)}/>              
        </th>
        <th>
          <Input type="text" className="form-control" size="sm" name={"activity_list"} value={dataFilter["activity_list"]} onChange={(e) => handleFilterInputan(e)}/>              
        </th>
        <th>
          <Input type="text" className="form-control" size="sm" name={"current_status"} value={dataFilter["current_status"]} onChange={(e) => handleFilterInputan(e)}/>              
        </th>
        <th>
          <Input type="text" className="form-control" size="sm" name={"created_by"} value={dataFilter["created_by"]} onChange={(e) => handleFilterInputan(e)}/>              
        </th>
        {/* <th colSpan={2}> */}
          {/* <Input type="text" className="form-control" size="sm" name={change_id} value={dataFilter["change_id"]} onChange={(e) => handleFilterInputan(e)}/>               */}
        {/* </th> */}
      {/* </tr> */}
    </React.Fragment>
  )
}
