import React, { useState, useEffect } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Card, CardBody, Col } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import { getPortfolioChartsData } from '../../slices/thunks';

//import Images
import btc from "../../assets/images/svg/crypto-icons/btc.svg";
import eth from "../../assets/images/svg/crypto-icons/eth.svg";
import ltc from "../../assets/images/svg/crypto-icons/ltc.svg";
import dash from "../../assets/images/svg/crypto-icons/dash.svg";

import { PortfolioCharts } from './DashboardPieCharts';
import { createSelector } from 'reselect';

import { datetimeformatncrm, dateformatncrm, datemonthformatncrm, dateyearsmonthdayformatncrm } from "../../helpers/FunctionalHelper/date-helper";

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const CRStatusPieChart = (crdata) => {
    const dispatch = useDispatch();

    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    const [chartData, setchartData] = useState([]);

    const current_date = new Date();
    const next_day_date = new Date(new Date().setDate(new Date().getDate()));
    const next_month_date = new Date(next_day_date.setMonth(next_day_date.getMonth() + 1));
    const previous_day_date = new Date(new Date().setDate(new Date().getDate() + 1));
    const previous_month_date = new Date(previous_day_date.setMonth(previous_day_date.getMonth() - 1));
    
    const draftCount     = crdata.crdata && crdata.crdata["Draft"] !== undefined ? parseInt(crdata.crdata["Draft"]) : 0;
    const rfaCount       = crdata.crdata && crdata.crdata["Request for Authorization"] !== undefined ? parseInt(crdata.crdata["Request for Authorization"]) : 0;
    const rfcCount       = crdata.crdata && crdata.crdata["Request for Change"] !== undefined ? parseInt(crdata.crdata["Request for Change"]) : 0;
    const sfaCount       = crdata.crdata && crdata.crdata["Scheduled for Approval"] !== undefined ? parseInt(crdata.crdata["Scheduled for Approval"]) : 0;
    const scheduledCount = crdata.crdata && crdata.crdata["Scheduled"] !== undefined ? parseInt(crdata.crdata["Scheduled"]) : 0;
    const completedCount = crdata.crdata && crdata.crdata["Completed"] !== undefined ? parseInt(crdata.crdata["Completed"]) : 0;
    const closedCount    = crdata.crdata && crdata.crdata["Closed"] !== undefined ? parseInt(crdata.crdata["Closed"]) : 0;

    useEffect(() => {
        let data = [];
        data.push(draftCount);
        data.push(rfaCount);
        data.push(rfcCount);
        data.push(sfaCount);
        data.push(scheduledCount);
        data.push(completedCount);
        
        setchartData(data);
    }, [crdata]);

    return (
        <React.Fragment>
            <Col md={12} style={{height: "526px", marginBottom: "20px"}}>
                <div className="card card-height-100" style={{height: "100%"}}>
                    <div className="card-header border-0 align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Status</h4>
                    </div>
                    <div className="card-body">
                        <PortfolioCharts seriesData={chartData} dataColors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-success"]' />
                    </div>
                </div>
            </Col>
        </React.Fragment>
    );
};

export default CRStatusPieChart;