import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

//import images
// import avatar1 from "../../assets/images/users/user-dummy-img.jpg";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatarDummy1 from "../../assets/images/users/user-dummy-img1.jpg";

import profileBg from '../../assets/images/bg-user-template.png';

import { API_CLIENT_AUTH_BEARER } from '../../helpers/api-helper';
import { currentDurationFromDateTime } from '../../helpers/FunctionalHelper/date-helper';

const apiAuth = new API_CLIENT_AUTH_BEARER();

const ProfileDropdown = ({userIconHeaderClass}) => {

    const profiledropdownData = createSelector(
        (state) => state.Profile.user,
        (user) => user
      );
    // Inside your component
    const user = useSelector(profiledropdownData);

    const [userName, setUserName] = useState("Admin");
    
    const [dataForm, setDataForm] = React.useState({});
    const [getImagePhoto, setGetImagePhoto] = React.useState([]);

    const userData = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).data : {};

    useEffect(() => {
        if (sessionStorage.getItem("authUser")) {
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            setUserName(process.env.REACT_APP_DEFAULTAUTH === "fake" ? obj.username === undefined ? user.first_name ? user.first_name : obj.data.first_name : "Admin" || "Admin" :
                process.env.REACT_APP_DEFAULTAUTH === "firebase" ? obj.email && obj.email : "Admin"
            );
        }
    }, [userName, user]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const getData = async() => {
        const res = await apiAuth.get('/getUserDetail/'+userData.email);
        if(res && res.data){
            setDataForm(res.data.data)
            getProfilePicture(res.data.data)
        }
    }

    React.useEffect(() => {
    getData();
    }, []);

    const getProfilePicture = async (data_user) => {
    const res = await apiAuth.getFile('/downloadProfile/'+userData.email+'/profile_picture', userData.token)
    if (res && res.data) {
        showPhoto(res.data, data_user.profiles[0]);
    } else {
        return null
    }
    }

    const showPhoto = (file, data_image) => {
    let images = [...getImagePhoto];
    let data_image_file = data_image;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
        const sfbase = reader.result.replace('application/octet-stream', 'image/png');
        data_image_file["image_view_file"] = sfbase;
        images.push(data_image_file)

        setGetImagePhoto(images)
    }
    }

    return (
        <React.Fragment>
            
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user" style={{minWidth: "220px", background: "transparent"}}>
                <div className={"pt-0 mt-0 pb-lg-3 " + userIconHeaderClass} style={{marginBottom: "0px", position: "absolute"}}>
                    <Row className="g-4" style={{height: "185px", margin: "0px"}}>
                    <img src={profileBg} alt="" style={{height: "185px", margin: "0px", padding: "0px", width: "240px"}} />
                    </Row>
                </div>
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <span className="d-flex align-items-center" style={{aspectRatio: 1 / 1, maxWidth: "40px", marginLeft: "auto", marginRight: "auto"}}>
                        {getImagePhoto.length === 0 ?
                          <img src={avatarDummy1} className="header-profile-user" style={{borderRadius: "50%", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                          :
                          <img src={getImagePhoto[0].image_view_file} className="header-profile-user" style={{borderRadius: "50%", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center", boxShadow: "0 0px 7px rgba(56, 65, 74, 0.15)"}}/>
                        }
                        </span>
                        <span className="text-start ms-xl-2" style={{marginTop: "20px"}}>
                            <span className="d-none d-md-inline-block ms-1 fw-medium user-name-text text-white" style={{maxWidth: "165px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}}>{userName}</span>
                            <h6 className="d-none d-md-block ms-1 fs-10 user-name-sub-text text-white" style={{maxWidth: "165px", height: "38px", textOverflow: "ellipsis", overflow: "hidden", marginTop: "-5px"}}>{userData && userData.title ? userData.title : userData && userData.role && userData.role.map((role,idx) =>
                                        <React.Fragment>
                                        <div style={{float: "left", maxWidth: "135px"}}>{idx !== 0 && ", "}{role}</div>
                                        </React.Fragment>
                                    )}</h6>
                            <span className="d-none d-md-block ms-1 fs-10 user-name-sub-text text-white">
                                {/* <span>
                                    {userData && userData.role && userData.role.map((role,idx) =>
                                        <React.Fragment>
                                        <div style={{float: "left", maxWidth: "135px"}}>{idx !== 0 && ", "}{role}</div>
                                        </React.Fragment>
                                    )}
                                </span> */}
                            </span>
                        </span>
                    </span>
                    
                </DropdownToggle>
                
                <DropdownMenu className="dropdown-menu-end" style={{width: "100%"}}>
                    <h6 className="dropdown-header">Welcome !</h6>
                    <DropdownItem className='p-0'>
                        <Link to={"/profile"} className="dropdown-item">
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Profile</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to={"/login"} className="dropdown-item">
                            <i className=" ri-logout-box-line text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Logout</span>
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;