import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import withRouter from "../../Components/Common/withRouter";
import logoMaintenance from "../../assets/images/website-maintenance.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

const indexMaintenance = (props) => {
    return (
        <React.Fragment>
            <ParticlesAuth>
            <div className="auth-page-content mt-lg-5" style={{paddingBottom : '30px'}}>
                <Container>
                    <Row>
                        <Col lg={12} className='mt-md-30' style={{paddingTop: "62px"}}>
                            <div className="text-center mt-sm-2 mb-2 text-white-50">
                            <h1 style={{fontSize: "52px", fontWeight: "bold", color: "#FFF"}}>Site Under Maintenance</h1>
                                <div>
                                    <img src={logoMaintenance} alt="" height="100" style={{padding : '5px', minHeight : "370px"}} />
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} className='mt-md-30'>
                            <div className="text-center mt-sm-2 mb-2 text-white-50">
                                <div>
                                    <h4 style={{fontSize: "34px", fontWeight: "bold", color: "#90CAF9"}}>Will Be Back Soon</h4>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(indexMaintenance);